import React from 'react'
import styled from 'styled-components'
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title'
import { Chart } from '../../common/charts/Chart'
import { Grid } from '../../common/layout/Grid';

import { PairsListWidget } from './PairsList/PairsListWidget'
import { ExchangeWidget } from './ExchangeWidget/ExchangeWidget'
import { HistoryWidget } from "../Layer2Wallet/TransactionsHistory/HistoryWidget";
import {LoadingBox} from '../../common/loading/LoadingBox'
import UnknownToken from '../../assets/images/icons/unknown_token.png';
import { Widget } from '../../common/widgets/Widget';
import { useAccount } from 'wagmi'
import { Modal } from '../../common/modal/Modal'

import { Asset } from '../../model/Asset'
import BigNumber from "bignumber.js";

import {WalletState} from '../../state'
import { getAmountOut } from '../../web3/helper'
import { formatNumber } from '../../utils/formatNumber'
import { useWalletHook } from '../../web3/walletHook'
import { useWalletSelector } from '../../state/hooks'

interface Pair {
  token1: Asset;
  token2: Asset;
  price: string;
  stable?: any;
  apy?: any;
}

export const SwapScreen = () => {
  const {isConnected} = useAccount();

  const { isETH, getETH, isUSDC, getTokenPair, getPairTradeData, getPairHourData } = useWalletHook()


  const [checked, setChecked] = React.useState(false);
  const [showTrending, setShowTrending] = React.useState(false);

  const [chartLoading, setChartLoading] = React.useState(false);
  const [pairsLoading, setPairsLoading] = React.useState(false);

  const [pairPrice, setPairPrice] = React.useState('0.00');

  const [tokenA, setTokenA] = React.useState<any>(null)
  const [tokenB, setTokenB] = React.useState<any>(null)
  const [tokenPair, setTokenPair] = React.useState<any>(null)
  const trendingPairs = useWalletSelector((state: WalletState) => state.trendingPairs)
  const allPairs = useWalletSelector((state: WalletState) => state.allPairs)

  const ethPrice = useWalletSelector((state: WalletState) => state.ethPrice)
  const tokens = useWalletSelector((state: WalletState) => state.tokens)

  const [data, setData] = React.useState<any>([])
  const [pair_data, setPairData] = React.useState<Pair[]>([])
  const externalSetTokens = React.useRef<any>(null)

  const [advancedChart, setAdvancedChart] = React.useState(false)

  const handleChange = (checked) => {
    setChecked(checked);
  }

  React.useEffect(() => {
      setPairsLoading(true)
      if(trendingPairs.length > 0){
        var res = trendingPairs
        var temp: Pair[] = []
  
        let mute_added = false
        let included_pairs = {}
        for(let i = 0; i < res.length; i++){
          if(res[i].token0.symbol == 'MUTE' || res[i].token1.symbol == 'MUTE')
            mute_added = true
  
          let token0 = tokens[res[i].token0.id.toLowerCase()]
          let token1 = tokens[res[i].token1.id.toLowerCase()]

          if (token0 == null){
            token0 = {symbol: res[i].token0.symbol, id: res[i].token0.id, address: res[i].token0.id, logo: UnknownToken, decimals:res[i].token0.decimals }
          }
          if (token1 == null){
            token1 = {symbol: res[i].token1.symbol, id: res[i].token1.id, address: res[i].token1.id, logo: UnknownToken, decimals:res[i].token1.decimals}
          }
  
          var price = new BigNumber(0)
          if(isETH(token0.address)){
            token0 = getETH()
          }

          if(isETH(token1.address)){
            token1 = getETH()
          }

          price = new BigNumber(res[i].token0.derivedETH).times(ethPrice)

          let skip = false
          for(let k in temp){
            if(temp[k].token1.address?.toLowerCase() == token0.address.toLowerCase() && temp[k].token2.address?.toLowerCase() == token1.address.toLowerCase())
              skip = true
            if(temp[k].token1.address?.toLowerCase() == token1.address.toLowerCase() && temp[k].token2.address?.toLowerCase() == token0.address.toLowerCase())
              skip = true
          }

          if(skip)
            continue


          included_pairs[res[i].token0.id.toLowerCase() + res[i].token1.id.toLowerCase()] = true
          included_pairs[res[i].token1.id.toLowerCase() + res[i].token0.id.toLowerCase()] = true

          let apy = '0.00'

          for(let j in allPairs){
            if(allPairs[j].id.toLowerCase() == res[i].pairAddress.toLowerCase())
              apy = allPairs[j].ampAPY ? new BigNumber(allPairs[j].apy).plus(allPairs[j].ampAPY).toFixed(2)  : allPairs[j].apy
          }

          temp.push({
            token1: token0,
            token2: token1,
            price: '$' + formatNumber(price, 2),
            apy: apy
          })
  
  
          if(i > (mute_added == true ? Object.keys(included_pairs).length : Object.keys(included_pairs).length - 1))
            break
        }
  
        /*
        if(mute_added == false){
          for(let i = 0; i < res.length; i++){
            if(res[i].pair.token0.symbol == 'MUTE' || res[i].pair.token1.symbol == 'MUTE'){
              var price = new BigNumber(0)
              let token0 = tokens[res[i].pair.token0.id.toLowerCase()]
              let token1 = tokens[res[i].pair.token1.id.toLowerCase()]
              if(isETH(token0.address)){
                price = new BigNumber(res[i].pair.token0Price).times(ethPrice)
                token0 = getETH()
              }
    
              if(isETH(token1.address)){
                price = new BigNumber(res[i].pair.token1Price).times(ethPrice)
                token1 = getETH()
              }
    
              if(isUSDC(token0.address)){
                price = new BigNumber(res[i].pair.token0Price)
              }
    
              if(isUSDC(token1.address)){
                price = new BigNumber(res[i].pair.token1Price)
              }
    
              temp.push({
                token1: token0,
                token2: token1,
                stable: res[i].pair.stable,
                price: '$' + (price.toFixed(2) == '0.00' ? price.toPrecision(3) : price.toFixed(2)),
                apy: '15.10'
              })
              break
            }
          }
        }
        */
  
        setPairData(temp)
        setPairsLoading(false)
      }
      


    return () => {}
  }, [isConnected, trendingPairs, tokens, ethPrice, allPairs]);

  const setToTrade = (_tokenA, _tokenB) => {
    callBackSelect(_tokenA, _tokenB)
    if(externalSetTokens.current)
      externalSetTokens.current(_tokenA, _tokenB)
  }

  const setChartTimeFrame = (time) => {
    //24h
    if(time == 0){

    } //7d
    else if (time == 1){

    } //30d
    else {

    }
  }

  const callBackSelect = async (_tokenA, _tokenB) => {
    setTokenA(_tokenA)
    setTokenB(_tokenB)
    setChartLoading(true)

    try{
      var pair = await getTokenPair(_tokenA.address, _tokenB.address, false)
      setTokenPair(pair)
      
      var _data = await getPairTradeData(pair)
      setPairPrice(new BigNumber(_data[0]).toFixed(10))
      setData(_data.reverse())
      
    } catch(e) {
      setData([])
    }

    setChartLoading(false)
  }

  return (
    <Screen>
      <Title></Title>

      <Column>
        <PairsListWidget pairs={pair_data} setToTrade={setToTrade} pairsLoading={pairsLoading}/>
        
        <ExchangeWidget callBackSelect={callBackSelect} externalSetTokens={externalSetTokens} setAdvancedChart={setAdvancedChart} advancedChart={advancedChart} showTrending={showTrending} setShowTrending={setShowTrending}/>

        {tokenPair != null ? (

          advancedChart == false ? 
           <Chart title={(tokenA && tokenB) ? (tokenA.symbol + '/' + tokenB.symbol) : 'ETH/USDC'} price={pairPrice} data={data} logo={tokenA ? tokenA.logo : ''} chartLoading={chartLoading}/>
          :

          <GeckoChart>
          <iframe height="100%" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src={`https://www.geckoterminal.com/zksync/pools/${tokenPair}?embed=1&info=0&swaps=0`} allow="clipboard-write" allowFullScreen></iframe>
          </GeckoChart>


        ) : <></>}
      </Column>
    </Screen>
  )
}
/*
            <GeckoChart>
            <iframe height="100%" width="100%" id="geckoterminal-embed" title="GeckoTerminal Embed" src={`https://www.geckoterminal.com/zksync/pools/${tokenPair}?embed=1&info=0&swaps=0`} allow="clipboard-write" allowFullScreen></iframe>
            </GeckoChart>
          <Chart title={(tokenA && tokenB) ? (tokenA.symbol + '/' + tokenB.symbol) : 'ETH/USDC'} price={pairPrice} data={data} logo={tokenA ? tokenA.logo : ''} chartLoading={chartLoading}/>

<GeckoChart>
<iframe style={{width: '100%', height: '100%'}} allow="clipboard-write" allowFullScreen
  src={`https://app.mute.io/widget`}
/>
            </GeckoChart>
            

          */

//        <Chart title={(tokenA && tokenB) ? (tokenA.symbol + '/' + tokenB.symbol) : 'ETH/USDC'} price={pairPrice} data={data} logo={tokenA ? tokenA.logo : ''} chartLoading={chartLoading}/>


const Column = styled(Grid)`
  position: relative;
  margin-bottom: 100px;

  row-gap: 40px;
  column-gap: 4%;
  grid-template-columns: 1fr;
  grid-template-areas: "pairList" "exchangeWidget" "chart";
  grid-auto-rows: minmax(min-content, max-content);

  justify-items: center;
  align-items: center;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-template-areas: "pairList" " exchangeWidget"
                          "chart";
  }

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-template-areas: "pairList" "exchangeWidget" "chart";
  }
`;

const GeckoChart = styled(Widget)`
  padding:0;
  margin: 0;
  box-shadow: none;
  overflow: hidden;
  border-radius: 10px;
  grid-area: chart;
  width: 100%;
  height: 450px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: auto;

  min-width: 250px;
  max-width: 900px;
  max-height: 450px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background: rgba(0,0,0,0);
  background: var(--color-modal-blue);
  box-shadow: rgba(255,255,255, 0.05) 0px 0px 30px;
  border: 1px solid rgba(255,255,255,0.05);
  border-radius: 16px;
  color: white;
`;



const LimitWidget = styled.div`
  padding: 10px;
  background-color: white;
  border-radius: 10px;
  width: 80%;
  justify-content: flex-end;
  align-items:flex-end;

`

const NoPair = styled.p`
  color: white;
  font-size: 24px;
  width: 100%;
  height: 100%;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;

`
/*
const Column = styled.div`
  position: relative;
  display: flex;
  margin-top: 24px;
  justify-content: space-between;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  padding-right: 0%;
  padding-left: 0%;

  @media (max-width: 767px) {
    margin: 8px 0 0;
    width: 100% !important;
    padding-right: 0;
    padding-left: 0;
  }
`;
*/

