import React, { ReactNode } from 'react'
import { Main } from './Main'
import { Header } from './Header'
import { Footer } from './Footer'
import { WalletState } from '../../state'
import styled from 'styled-components'
import { useSelector } from 'react-redux'

interface ScreenProps {
  children: ReactNode;
}

export const Screen = ({children}: ScreenProps) => {
  const chainDelayed = useSelector((state: WalletState) => state.chainDelayed)

  return (
    <>
      {chainDelayed == true ? <ErrorBanner>zkSync Era blocks delayed. The network may be down.</ErrorBanner> : <></>}
      <Main>
        {children}
      </Main>


      <Footer/>
    </>
  )
}




const ModalFooter = styled.div`
  margin-top: 21px;
  color: white;
  font-size: 16px;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
`;

const ErrorBanner = styled.div`
  height: 30px;
  padding: 5px;
  background: var(--color-modal-blue);
  text-align: center;
  color: white;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const UnderMaint = styled.p`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: white;
  font-size: 32px;
  font-weight: 700;
  z-index: 100;
  text-align: center;
  padding-top: 50px;
`
