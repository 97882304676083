import React, { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components';
import { BasicModalProps, Modal } from '../../common/modal/Modal'
import { ModalText } from '../../common/modal/ModalComponents'

interface SlipageToleranceModalProps extends BasicModalProps {
  defaultLimit: number;
  defaultLimitSlippage: number;
  disableFee: boolean;
  onLimitChange: (limit: number) => void
  onLimitChangeSlippage: (limit: number) => void

}

export const LiquiditySettingsModal = ({isVisible, onClose, defaultLimit, defaultLimitSlippage, disableFee, onLimitChange, onLimitChangeSlippage}: SlipageToleranceModalProps) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [limitSlippage, setLimitSlippage] = useState(defaultLimitSlippage);

  const [inputValue, setInputValue] = useState('');
  const [inputValueSlippage, setInputValueSlippage] = useState('');

  const onChange = (value: string) => {
    setInputValue(value)
    setLimit(Number(value))
  }

  const onChangeSlippage = (value: string) => {
    setInputValueSlippage(value)
    setLimitSlippage(Number(value))
  }


  const minValue = 0.01;
  const maxValue = 10;
  const onBlur = (value: number | undefined) => {
    if (value === undefined) return

    let slippage = value
    if (value < minValue) slippage = minValue
    else if(value > maxValue) slippage = maxValue

    onChange(slippage.toString())
    onLimitChange(slippage)
  }

  const minValue_S = 0.05;
  const maxValue_S = 20;
  const onBlurSlippage = (value: number | undefined) => {
    if (value === undefined) return

    let slippage = value
    if (value < minValue_S) slippage = minValue_S
    else if(value > maxValue_S) slippage = maxValue_S

    onChangeSlippage(slippage.toString())
    onLimitChangeSlippage(slippage)
  }

  return (
    <Modal title="Settings" isVisible={isVisible} onClose={onClose}>
      <ModalText>
        Edit the pool fee and slippage for adding/removing LP.
      </ModalText>
      <List>
        <Item className={disableFee == true ? 'disabled': 'active'}>
          <InputWrapper>
            <Input
              value={inputValue}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
              onBlur={() => onBlur(limit)}
              type="number"
              placeholder={limit?.toString()}
              disabled={disableFee}
            />
          </InputWrapper>
          Pool fee {disableFee == true ? '(disabled)' : ''}

        </Item>
        <Item  className={'active'}>
          <InputWrapper>
            <Input
              value={inputValueSlippage}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChangeSlippage(event.target.value)}
              onBlur={() => onBlurSlippage(limitSlippage)}
              type="number"
              placeholder={limitSlippage?.toString()}
            />
          </InputWrapper>
          Slippage

        </Item>


      </List>
    </Modal>
  );
}
//For every trade an account makes through this pool, this percent is what is taken and given to LPs. You can only set and edit this when launching a new pool.
const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 10px;

  @media (max-width: 500px) {
    max-width: 196px;
    flex-wrap: wrap;
    margin: 60px auto 0;
  }
`;

const Item = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  color: gray;
  opacity: 0.5;
  width: 100%;
  height: 40px;
  margin-top: 20px;

  &.active {
    color: white;
    opacity: 1;


  }


  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

const backgroundStyles = css`
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: white;
  background: var(--color-dark-blue);
  border: 1px solid var(--color-gray);
  border-radius: 14px;
`

const ItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${backgroundStyles}

  &.active {
    color: #FFFFFF;
    background: transparent;
  }

  :hover {
    color: #FFFFFF;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  padding: 8px 20px 8px 8px;
  margin-right: 10px;
  max-width: 100px;
  ${backgroundStyles}

  &::after {
    content: '%';
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
  }

  @media (max-width: 500px) {

  }
  @media (max-width: 500px) {
    &:nth-child(even) {

    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-right: 5px;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  text-align: right;
  background: none;
  border: none;
  outline: none;

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
`;
