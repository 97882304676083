import BigNumber from 'bignumber.js';
import { hexlify as EthersHexlify } from 'ethers';

/**
 * Convert to hex
 * @param value The value
 */
export function hexlify(value: BigNumber): string {
  return '0x' + value.toString(16);
}
