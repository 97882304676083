import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

//import { ApolloClient, InMemoryCache, ApolloProvider, gql } from '@apollo/client';

const latest = 'v0.0.11'

var graphURL = 'https://api.thegraph.com/subgraphs/name/mattt21/muteswitch---zksync-era'
graphURL = `https://api.studio.thegraph.com/query/12332/muteswitch---zksync-era/${latest}`

graphURL = 'https://api.goldsky.com/api/public/project_clmtie4nnezuh2nw6hhjg6mo7/subgraphs/mute_switch/v0.0.7/gn'

export function getClient(is_mainnet){
  if(is_mainnet){
    return new ApolloClient({
      link: new HttpLink({
        uri: graphURL
      }),
      cache: new InMemoryCache(),
    })
  } else {
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/mattt21/muteswitch_006',
      }),
      cache: new InMemoryCache(),
    })
  }
}

export function getBlockClient(is_mainnet){
  if(is_mainnet){
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://api.studio.thegraph.com/query/12332/blocks---zksync-era/version/latest'
      }),
      cache: new InMemoryCache(),
    })
  } else {
    return new ApolloClient({
      link: new HttpLink({
        uri: 'https://api.thegraph.com/subgraphs/name/mattt21/zksync_blocks_006',
      }),
      cache: new InMemoryCache(),
    })
  }
}