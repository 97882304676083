import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Amount } from '../text/Amount'
import { TextSemiBold } from '../text/Text'
import { BasicModalProps, Modal } from './Modal'
import { ModalDescription } from './ModalComponents'
import Confetti from 'react-dom-confetti';
interface ErrorModalProps extends BasicModalProps {
  title: string;
  description: string;
}

export const ErrorModal = (props: ErrorModalProps) => {


  useEffect(() => {
    return () => {
    }

  }, [props.isVisible])

  return (
    <Modal
      title={props.title}
      isVisible={props.isVisible}
      onClose={props.onClose}
    >

      <Savings>
        <TextWrap>{'Error: ' + props.description}</TextWrap>
      </Savings>
    </Modal>
  )
}

const StyledAmount = styled(Amount)`
  margin: 8px 0;
`;

const TextWrap = styled(TextSemiBold)`
  overflow-wrap: break-word;
  width: 100%;
  color: rgb(253, 118, 107);
  text-align: center;
`

const ConfettiWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  overflow: hidden;
`;

const IconLogo = styled.img`
  width: 28px;
  height: 28px;
`;

const Line = styled.div`
width: 100%;
height: 0px;
margin-top: 25px;
margin-bottom: 25px;
border: 1px solid #262436;

`

const Savings = styled.div`
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 2px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const Description = styled(ModalDescription)`
  margin-bottom: 44px;
`;

const Footer = styled.div`
  margin-top: 21px;
`;
