import React, { useState, ChangeEvent } from 'react'
import styled, { css } from 'styled-components';
import { BasicModalProps, Modal } from '../../../common/modal/Modal'
import { ModalButton, ModalText } from '../../../common/modal/ModalComponents'
import { ButtonSecondaryLarge, ButtonPrimaryLarge } from '../../../common/buttons/Button'

import { AmountSection } from '../../../common/section/AmountSection'
import { Asset } from '../../../model/Asset'
import plus from '../../../assets/images/icons/arrow.svg'
import { SymbolWithLogoLeft } from '../../../common/SymbolWithLogoLeft';

enum ModalType {
  AssetsListA,
  AssetsListB
}
interface RemoveLiquidityModalProps extends BasicModalProps {
  selectedAssetA: Asset | undefined;
  selectedAssetB: Asset | undefined;
  assetAmountA: string;
  assetAmountB: string;
  onSupplyButtonClick: () => void;
  disableButton: boolean;
  onApproveClick: (assetA: any, assetB: any) => any;
  onWithdrawClick: (assetA: any, assetB: any, amountA: any, amountB: any, lpBal: any, percent: any, sig: any) => any;
  lpBal: string;
}

export const RemoveLiquidityModal = ({
  isVisible,
  onClose,
  onSupplyButtonClick,
  selectedAssetA,
  selectedAssetB,
  assetAmountA,
  assetAmountB,
  disableButton,
  onApproveClick,
  onWithdrawClick,
  lpBal
}: RemoveLiquidityModalProps) => {

  const [modal, setModal] = useState<ModalType | null>();

  const [limit, setLimit] = useState('0');
  const [inputValue, setInputValue] = useState('0');

  const [approvedValue, setApprovedValue] = useState<any>(null)

  const setActiveClassName = (percent: number) => percent === Number(limit) ? 'active' : ''

  const onOptionClick = (option: number) => {
    setLimit(String(option))
    onChange(String(option))
  }

  const onChange = (value: string) => {
    const re = /^[0-9\b]+$/;
    // if value is not blank, then test the regex

    if (value === '' || re.test(value)) {
      if(Number(value) > 100 || Number(value) < 0)
        return
      setInputValue(value)
      setLimit(String(value))
    }

  }

  const onBlur = (value: string | undefined) => {
    if (value === undefined) return

    let slippage = Number(value)
    if (Number(value) < 0) slippage = 0
    else if(Number(value) > 100) slippage = 100

    onChange(slippage.toString())
  }

  const outputAValue = () => {
    return (parseFloat(assetAmountA) * parseFloat(inputValue) / 100).toFixed(4)
  }

  const outputBValue = () => {
    return (parseFloat(assetAmountB) * parseFloat(inputValue) / 100).toFixed(4)
  }

  const approveWithdraw = async () => {
    var res = await onApproveClick(selectedAssetA, selectedAssetB)
    console.log(res)
    setApprovedValue(res)
  }

  const onWithdraw = async () => {
    var res = await onWithdrawClick(
                                  selectedAssetA,
                                  selectedAssetB,
                                  assetAmountA,
                                  assetAmountB,
                                  lpBal,
                                  inputValue,
                                  approvedValue
                                )
    console.log(res)
    setApprovedValue(res)
  }

  return (
  <>
    <Modal isVisible={isVisible} onClose={onClose} title="Remove liquidity">
      <Text>
        Removing pool tokens converts your position back into underlying tokens at the current rate. Accrued fees are included in the amount you receive.
      </Text>


      <InputWrapper>
        <Input
          value={inputValue}
          onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
          onBlur={() => onBlur(limit)}
          type="string"
          placeholder={limit?.toString()}
        />
        <Percentage>{' %'}</Percentage>
      </InputWrapper>

      <List>
        <Item className={setActiveClassName(25)}>
          <ItemButton onClick={() => onOptionClick(25)} className={setActiveClassName(25)}>25%</ItemButton>
        </Item>
        <Item className={setActiveClassName(50)}>
          <ItemButton onClick={() => onOptionClick(50)} className={setActiveClassName(50)}>50%</ItemButton>
        </Item>
        <Item className={setActiveClassName(75)}>
          <ItemButton onClick={() => onOptionClick(75)} className={setActiveClassName(75)}>75%</ItemButton>
        </Item>
        <Item className={setActiveClassName(100)}>
          <ItemButton onClick={() => onOptionClick(100)} className={setActiveClassName(100)}>100%</ItemButton>
        </Item>
      </List>

      <Icon src={plus} alt="down" />

        <ValueWrapper>
        <AmountText>{outputAValue()}</AmountText>
        {
          selectedAssetA ? (        <SymbolWithLogoLeft
                    logo={selectedAssetA.logo}
                    symbol={selectedAssetA.symbol}
                  />) : <></>

        }
        </ValueWrapper>

      <ValueWrapper>
      <AmountText>{outputBValue()}</AmountText>
      {
        selectedAssetB ? (        <SymbolWithLogoLeft
                  logo={selectedAssetB.logo}
                  symbol={selectedAssetB.symbol}
                />) : <></>

      }
      </ValueWrapper>

      <ButtonWrapper>
      <ApproveButton
        onClick={approveWithdraw}
        disabled={approvedValue != null}
      >
        {'Approve'}
      </ApproveButton>
      <WithdrawButton
        onClick={onWithdraw}
        disabled={approvedValue == null || (inputValue == '0')}
      >
        {(inputValue != '0') ? 'Withdraw' : 'Enter Amount'}
      </WithdrawButton>
      </ButtonWrapper>

    </Modal>
  </>
)};

const Icon = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  margin: 24px auto;
  transform: rotate(90deg);
`;

const Text = styled(ModalText)`
  margin-bottom: 16px;
`;


const List = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media (max-width: 500px) {
    max-width: 196px;
    flex-wrap: wrap;
    margin: 60px auto 0;
  }
`;

const Item = styled.li`
  width: 86px;
  height: 40px;
  padding: 1px;
  background: rgba(84, 190, 249, 0);
  border-radius: 8px;
  border: 1px solid rgba(84, 190, 249, 0.5);

  :hover {
    border: 1px solid rgba(84, 190, 249, 1);
  }

  &.active {
    border: 1px solid rgba(84, 190, 249, 1);
  }

  & + & {
    margin-left: 24px;

    @media (max-width: 500px) {
      margin-left: 0;
    }
  }

  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

const backgroundStyles = css`
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #9fa3ad;
  background: #0d142b;
  border-radius: 8px;
`;

const ItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${backgroundStyles}

  &.active {
    color: #FFFFFF;
    background: transparent;
  }

  :hover {
    color: #FFFFFF;
  }

`;

const InputWrapper = styled.div`
  position: relative;
  padding: 10px 20px 10px 20px;
  display: flex;

  ${backgroundStyles}

  @media (max-width: 500px) {

  }
  @media (max-width: 500px) {
    &:nth-child(even) {

    }
  }
`;

const Percentage = styled.div`
  font-weight: 300;
  font-size: 30px;
  line-height: 150%;
  color: #ffffff;
  padding-left: 10px;
`;

const Input = styled.input`
  height: 100%;
  padding: 0;
  width: 100%;
  font-weight: 300;
  font-size: 30px;
  line-height: 150%;
  color: #ffffff;
  text-align: right;
  background: none;
  border: none;
  outline: none;

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  padding: 10px 0px 10px 0px;
  justify-content: space-between;
  display: flex;
  @media (max-width: 500px) {

  }
  @media (max-width: 500px) {
    &:nth-child(even) {

    }
  }
`;

const ValueWrapper = styled.div`
  position: relative;
  padding: 10px 0px 10px 0px;
  justify-content: center;
  display: flex;
  width: 100%;
  @media (max-width: 500px) {

  }
  @media (max-width: 500px) {
    &:nth-child(even) {

    }
  }
`;


const AmountText = styled(ModalText)`
color: #ffffff;
width: 50%;
text-align: right;
padding-right: 20px;
`;

const ApproveButton = styled(ButtonSecondaryLarge)`
width: 45%;
`;

const WithdrawButton = styled(ButtonPrimaryLarge)`
width: 45%;
`;
