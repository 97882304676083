import { AddressSection } from '../../common/section/AddressSection' 
import styled from 'styled-components'
import { useState } from 'react'
import { ButtonPrimaryLarge } from '../../common/buttons/Button' 
import { useEffect } from 'react'
import { Modal } from '../../common/modal/Modal'
import { isAddress } from 'ethers'
import { LoadingAnimation } from '../../common/loading/loading'

export interface ConfirmTransactionModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onChange: (val: string) => void;
  validText: boolean;
  address: string;
  id: string;
  originalDelegate: string;
}

export const VeDelegateModal = ({
  isVisible,
  onClose,
  onConfirm,
  validText,
  onChange,
  address,
  originalDelegate,
  id
}: ConfirmTransactionModalProps) => {

  const [calculating, setCalculating] = useState(false)

  useEffect(() => {
   // const timer = setTimeout(() => isVisible && onConfirm(), 100);
    return () => {} //clearTimeout(timer);
  }, [isVisible]);

  const shouldDisable = () => {
    if(address.toLowerCase() == originalDelegate.toLowerCase() || isAddress(address) == false)
      return true

    return false
  }

  const getConfirmationText = () => {
    if(address.toLowerCase() == originalDelegate.toLowerCase())
      return "Same address"
    if(isAddress(address) == false)
      return "Invalid address"

    return "Delegate"
  }

  const changeDel = async() => {
    setCalculating(true)
    await onConfirm()
    setCalculating(false)
  }

  return (
    <Modal title={"Delegate veMute Token #" + id} isVisible={isVisible} onClose={onClose}>

      <AddressSection
                address={address}
                onChange={onChange}
                label={'Delegate Address'}
        />

    <Button onClick={() => changeDel()} disabled={shouldDisable()}>
        <LoadingAnimation isLoading={calculating} />

        {getConfirmationText()}
      </Button>

    </Modal>
  );
};

const Line = styled.div`
width: 100%;
height: 0px;
margin-top: 25px;
margin-bottom: 25px;
border: 1px solid #262436;

`
const Footer = styled.div`
  margin-top: 12px;
  padding-bottom: 25px;
`;


const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;