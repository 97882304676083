import styled from 'styled-components';
import { transition } from '../../styles';

export const GradientButton = styled.button`
  ${transition.fast}

  height: 26px;
  padding: 0 4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #FFFFFF;
  background: transparent;
  border: 1px solid var(--color-blue);
  border-radius: 8px;

  :hover {
    opacity: 0.6;
  }
`;
