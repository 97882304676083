import React, { useState, useRef } from 'react'
import styled from 'styled-components';
import {useLocation, useHistory} from "react-router-dom";
import { ButtonsRow } from '../../common/layout/ButtonsRow';
import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';
import { AddLiquidity } from './LiquidityPoolModals/AddLiquidity';
import { LiquidityRewardsWidget } from './LiquidityRewardsWidget';
import { YourLiquidityWidget } from './YourLiquidityWidget';
import { AllPoolsWidget } from './AllPoolsWidget';

import { useWalletHook } from '../../web3/walletHook';
import { WalletState } from '../../state';
import { useWalletSelector } from '../../state/hooks';


export const LiquidityPoolScreen = () => {
  let location = useLocation()
  let history = useHistory()

  const addLiqModal = useRef<any>(null)

  const { createLiquidityPool, calculateLiquidityPool, getTokenFromContract } = useWalletHook()

  const tokens = useWalletSelector((state: WalletState) => state.tokens)
  const ownedPairs = useWalletSelector((state: WalletState) => state.ownedPairs)
  const allPairs = useWalletSelector((state: WalletState) => state.allPairs)
  const isLoading = useWalletSelector((state: WalletState) => state.ownedPairs == null)

  const addLiquidity = async (assetA, assetB, amountA, amountB) => {
    return await createLiquidityPool(assetA, assetB, amountA, amountB)
  }

  const calculateLiquidity = async (assetA, assetB, amountA, amountB) => {
    return await calculateLiquidityPool(assetA, assetB, amountA, amountB)
  }

  const manageLiquidity = (assetAAddress, assetBAddress, stable) => {
    history.push(`/pool/${assetAAddress}/${assetBAddress}/${stable}`)
  }


  return (
    <Screen>
      <Layout>
        <Title>Liquidity Pools</Title>
        <ButtonsRow>
          <AddLiquidity externalOpen={addLiqModal} assets={tokens} addLiquidity={addLiquidity} calculateLiquidity={calculateLiquidity} manageLiquidity={manageLiquidity}  />
        </ButtonsRow>
        <YourLiquidityWidget ownedPairs={ownedPairs} manageLiquidity={manageLiquidity} isLoading={isLoading}/>
        <AllPoolsWidget ownedPairs={allPairs} manageLiquidity={manageLiquidity} isLoading={isLoading}/>
        <LiquidityRewardsWidget isVisible={false} onClose={() => {}}/>
      </Layout>
    </Screen>
  )
}
//          <CreatePair externalOpen={createLiqModal} assets={tokens} createPair={addLiquidity} importPool={importPool} addLiquidity={addLiquidityScreenPopup}/>
//          <ImportPool assets={tokens} importPool={importPool} addPool={addPool} />
// <LiquidityHistory history={liquidity.history}/>

const Layout = styled(Grid)`
  grid-template-columns: 1fr;

  grid-template-areas:
  "title buttons"
  "yourLiquidity yourLiquidity "
  "allPools allPools "

  "description description";

  @media (max-width: 767px) {
    grid-template-areas: "title" "buttons" "yourLiquidity" "allPools" "description";
  }
`;
