import React, { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components';
import { BasicModalProps, Modal } from '../../common/modal/Modal'
import { ModalText } from '../../common/modal/ModalComponents'
import { AddressSection } from '../../common/section/AddressSection'

import { ButtonPrimaryLarge } from '../../common/buttons/Button'
import {LoadingAnimation} from '../../common/loading/loading'



interface SlipageToleranceModalProps extends BasicModalProps {
  defaultLimit: number;
  execute: (val: number) => void;
}

export const LiquidityFeeModal = ({isVisible, onClose, defaultLimit, execute}: SlipageToleranceModalProps) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [inputValue, setInputValue] = useState('');
  const [calculating, setCalculating] = useState(false)

  const setActiveClassName = (percent: number) => percent === limit ? 'active' : ''

  const onOptionClick = (option: number) => {
    setLimit(option)
    onChange(String(option))
  }

  const onChange = (value: string) => {
    setInputValue(value)
    setLimit(Number(value))
  }

  const minValue = 0.01;
  const maxValue = 10;
  const onBlur = (value: number | undefined) => {
    if (value === undefined) return

    let slippage = value
    if (value < minValue) slippage = minValue
    else if(value > maxValue) slippage = maxValue

    onChange(slippage.toString())
  }

  const executeChange = async () => {
    setCalculating(true)
    await execute(Number(inputValue))
  }

  return (
    <Modal title="Change pool fee" isVisible={isVisible} onClose={onClose}>
      <ModalText>
        Changing this pool's fee will incur a 0.1% tax on your LP holdings.
      </ModalText>
      <List>
        <Item>
          <InputWrapper>
            <Input
              value={inputValue}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
              onBlur={() => onBlur(limit)}
              type="number"
              placeholder={limit?.toString()}
            />
          </InputWrapper>
        </Item>

      </List>
      <Button onClick={() => executeChange()} disabled={inputValue == ''}>
        <LoadingAnimation isLoading={calculating} />
        {'Change fee'}
      </Button>
    </Modal>
  );
}

const List = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 35px;

  @media (max-width: 500px) {
    max-width: 196px;
    flex-wrap: wrap;
    margin: 60px auto 0;
  }
`;

const Item = styled.li`
  width: 86px;
  height: 40px;

  &.active {}

  & + & {
    margin-left: 24px;

    @media (max-width: 500px) {
      margin-left: 0;
    }
  }

  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

const backgroundStyles = css`
  width: 100%;
  height: 100%;
  font-weight: 300;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: white;
  background: var(--color-dark-blue);
  border: 1px solid var(--color-gray);
  border-radius: 14px;
`

const ItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${backgroundStyles}

  &.active {
    color: #FFFFFF;
    background: transparent;
  }

  :hover {
    color: #FFFFFF;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  padding: 8px 20px 8px 8px;
  ${backgroundStyles}

  &::after {
    content: '%';
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
  }

  @media (max-width: 500px) {

  }
  @media (max-width: 500px) {
    &:nth-child(even) {

    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-right: 5px;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  text-align: right;
  background: none;
  border: none;
  outline: none;

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
`;

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;
