import React, { useState } from 'react'
import styled from 'styled-components';
import { EmptyWidgetText, Widget } from '../../common/widgets/Widget';
import UnknownToken from '../../assets/images/icons/unknown_token.png';

import { Dropdown } from './Dropdown';
import { ButtonPrimaryMedium } from '../../common/buttons/Button';
import { useWalletHook } from '../../web3/walletHook';
import { useAccount } from 'wagmi';

import { LoadingBox } from '../../common/loading/LoadingBox';
import BigNumber from 'bignumber.js';

enum Modal {
  Switch,
  Connect,
  Confirm,
  Submitted,
}

/*
type LiquidityPair = {
  value: string;
  assetAImage: string;
  assetBImage: string;
  assetA: any;
  assetB: any;
}

interface YourLiquidityWidgetProps {
  ownedPairs: LiquidityPair[];
  addLiquidity: (LiquidityPair) => any;
  removeLiquidity: (LiquidityPair) => any;
}
*/

export const AllPoolsWidget = ({manageLiquidity, ownedPairs, isLoading}) => {
  const [modal, setModal] = useState<Modal | null>(null);
  const {isConnected} = useAccount();

  const { getTokenFromContract } = useWalletHook()

  const getAssetLogo = (id) => {
    let _asset = getTokenFromContract(id)
    if(_asset)
      return _asset.logo

    return UnknownToken
  }

  const getWidget = () => {
    if(isLoading){
      return (
          <Dropdown onManageClick={() => {}} icon0={''} icon1={''} token0={''} token1={''} fee={''} reserveA={''} reserveB={''} stable={false}>
            <LoadingBox/>
          </Dropdown>
      )
    }

    return (
      <>
      {
        ownedPairs.map((item, index) => {
          return (
            <Dropdown
              key={index}
              onManageClick={() => manageLiquidity(item.token0.id, item.token1.id, item.stable)}
              icon0={getAssetLogo(item.token0.id)}
              icon1={getAssetLogo(item.token1.id)}
              token0={item.token0.symbol}
              token1={item.token1.symbol}
              stable={item.stable}
              fee={new BigNumber(item.pairFee).div(100).toFixed(2)}
              reserveA={item.reserve0}
              reserveB={item.reserve1}
              apy={item.ampAPY ? item.apy + '-' + new BigNumber(item.apy).plus(item.ampAPY).toFixed(2)  : item.apy}
              tvl={item.reserveUSD}
              dark={true}

            />
          )
        })}
      {ownedPairs.length == 0 ?
        <EmptyWidgetText style={{textAlign: 'center'}}>You do not currently have any liquidity in a pool</EmptyWidgetText> : <></>
      }
    </>
    )
  }

  return (
    <Container>
    <HeaderTitle>Top 15 Pools</HeaderTitle>
    <View
      title=""
    >
    <OverflowWidget>
      { getWidget() }
      </OverflowWidget>
    </View>
    </Container>
  )
}

const Container = styled.div`

grid-area: allPools;

`
const View = styled(Widget)`
  min-height: 400px;
  max-height: 750px;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--color-dark-bg);
  border: none;

  box-shadow: none;

  @media (max-width: 900px) {
    min-height: initial;
  }
`;

const OverflowWidget = styled.div`
  overflow-y: scroll;
  max-height: 400px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background: transparent;

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-modal-blue);
    border-radius: 16px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white;
  }
`;


const Button = styled(ButtonPrimaryMedium)`
  display: block;
  width: 217px;
  height: 48px;
  margin: 0 auto;
`;

const HeaderTitle = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;

  /* Dark/Dark-200 */

  color: #545368;
`
