import React, { Component } from 'react'
import styled, { keyframes } from 'styled-components'

const circleSpin = keyframes`
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
`

export const CircleAnimation = styled.div`
  border: 3px solid rgba(255,255,255, 0.5);
  border-radius: 50%;
  border-top: 3px solid white;
  width: 20px;
  height: 20px;
  -webkit-animation: ${circleSpin} 1.2s linear infinite;
  animation: ${circleSpin} 1.2s linear infinite;
  margin: 0 auto;
`;

export const LoadingOverlay = styled.div`
    width: 100%;
    height: 100%;
    padding-right: 5px;
`;

interface ILoadingAnimation {
  isLoading: boolean;
}



export class LoadingAnimation extends Component<ILoadingAnimation> {
  render () {
    const { isLoading } = this.props
    let divStyle = {
      display: (isLoading === true) ? 'flex' : 'none'
    }

    return (
      <div style={divStyle}>
        <LoadingOverlay>
          <CircleAnimation  />
        </LoadingOverlay>
      </div>
    )
  }
}
