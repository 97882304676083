import React from 'react';
import ReactECharts from 'echarts-for-react';
import {graphic} from 'echarts';
import styled, {keyframes} from 'styled-components'
import {LoadingBox} from '../loading/LoadingBox'
import BigNumber from 'bignumber.js'
import {formatNumber} from '../../utils/formatNumber'


const pad = num => ("0" + num).slice(-2); // or use padStart

const getTimeFromDate = timestamp => {
  const date = new Date(timestamp * 1000);
  let hours = date.getHours(),
    minutes = date.getMinutes(),
    seconds = date.getSeconds();
  return pad(hours) + ":" + pad(minutes)// + ":" + pad(seconds)
}

export const ChartSymbol = ({stable}) => {



  const volValue = (point) => {
    if(stable){
      var x = 10;
      var y = 10;
      var _k = x * y;

      if(point == 10)
        return [0.1, 60]

      if(point == 0)
        return [10, 0.1]

      return [10-point,(_k/(x - point))/4]

    } else {

      var x = 10;
      var y = 10;
      var _k = x * y;

      if(point == 10)
        return [0.1, 100]

      if(point == 0)
        return [10, 10]

      return [10-point,_k/(x - point)]

    }

  }


  const options = {
    xAxis: {
      type: 'category',
      boundaryGap: false
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '0%',
      containLabel: false,
      splitLine: {
        show: false
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: false,
      axisLine : {
        onZero: true
      },
      splitLine: {
        show: false
      },
    },
    series: [
      {
        data: [volValue(10),volValue(9),volValue(8),volValue(7),volValue(6),volValue(5),volValue(4),volValue(3),volValue(2),volValue(0)],
        type: 'line',
        z: -1,
        smooth: true,
        sampling: 'lttb',
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#345AE1'
            },
            {
              offset: 1,
              color: '#30A8FF'
            }
          ]),
          borderRadius: '16px'
        },
        lineStyle: {
          width: 2
        },
        showSymbol: false,

        areaStyle: {
          shadowBlur: 10,
          opacity: 0.2,
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#345AE1'
            },
            {
              offset: 1,
              color: 'rgba(0,0,0,0)'
            }
          ])
        },

      },
    ],
    tooltip: {
      trigger: 'axis',
      show: false,
      showContent: false,
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: 'rbga(0,0,0,0)',
      extraCssText: 'box-shadow: 0 0 0px rgba(0, 0, 0, 0);',
      textStyle: {
          color: 'rgba(0,0,0,0)',
          boxShadow: 'rgba(0,0,0,0)',
      }
    },
  };

  const greenArrow = () => {
    return <svg width="20" height="20"
            viewBox="0 0 24 24" fill="none" stroke="rgb(118, 209, 145)" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round">
            <line x1="7" y1="17" x2="17" y2="7"></line>
            <polyline points="7 7 17 7 17 17"></polyline>
            </svg>
  }

  const redArrow = () => {
    return <svg  width="20" height="20"
            viewBox="0 0 24 24" fill="none" stroke="rgb(253, 118, 107)" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" transform="rotate(90 0 0)">
            <line x1="7" y1="17" x2="17" y2="7"></line>
            <polyline points="7 7 17 7 17 17"></polyline>
            </svg>
  }


  return <Body>
            <ReactECharts option={options} style={{ height: '100%' }} />
          </Body>
};





const Body = styled.div`
  grid-area: chart;
  position: relative;
  overflow: hidden;
  max-width: 40px;
  max-height: 40px;
  width: 200px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(0,0,0,0);
  background: var(--color-modal-blue);
  box-shadow: rgba(255,255,255, 0.1) 0px 0px 30px;
  border: 1px solid rgba(255,255,255,0.05);
  border-radius: 10px;
  color: white;

`
