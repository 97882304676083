import styled, { css } from "styled-components";

interface AmountProps {
  theme?: 'green'
}

const amountSuccessStyle = css`
  color: #17C687;
`

export const Amount = styled.p<AmountProps>`
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  color: #FFFFFF;
  text-align: right;

  ${({theme}) => theme === 'green' && amountSuccessStyle}

  @media (max-width: 900px) {
    text-align: left;
  }
`;
