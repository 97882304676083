import React, { useState } from 'react'
import styled from 'styled-components';
import { Asset } from '../../model/Asset';
import { SearchInput } from '../form/SearchInput';
import { ListItem } from '../ListItem';
import { SymbolWithLogoText } from '../SymbolWithLogoText';
import { ModalDescription } from './ModalComponents'

import { Text } from '../text/Text';
import { Modal, BasicModalProps } from './Modal'
import { ButtonPrimaryMedium } from './../buttons/Button';
import BigNumber from 'bignumber.js'

interface AssetsListModalProps extends BasicModalProps {
    assets: {};
    setSelectedAsset: (asset: Asset) => void;
}

export const PaymasterModal = ({ isVisible, onClose, assets, setSelectedAsset }: AssetsListModalProps) => {
    const [addedAssets, setAddedAssets] = useState(JSON.parse(JSON.stringify(assets)));

    React.useEffect(() => {
        setAddedAssets(JSON.parse(JSON.stringify(assets)))
        return () => {
        }
    }, [assets]);



    const onAssetClick = (asset: Asset) => {
        //@ts-ignore
        setSelectedAsset(asset.asset.address)
        onClose();
    }

    return (
        <StyledModal
            title="Paymaster"
            isVisible={isVisible}
            onClose={onClose}
        >
            <ModalDescription style={{ display: 'inline-block', textAlign: 'left', margin: '0', paddingBottom: '0px',alignItems: 'flex-start' }}>Pay for network fees with any asset from the list below!
            <br/><br/>Make sure you have at least $1 worth of the token.&nbsp;
            <a style={{color: 'var(--color-blue)'}} href='https://twitter.com/mute_io/status/1707068878558224584' target='_blank'>Learn more</a>
            </ModalDescription>

            <AssetsList key={Math.floor(Math.random() * 10000)}>
                {Object.entries(addedAssets).map(([key, asset], index) => {
                    //@ts-ignore
                    if (!asset || !asset.asset ||  !asset.asset.logo)
                        return
                    //@ts-ignore
                    var { logo, symbol, name, balance, address, active, price } = asset.asset!;
                    //@ts-ignore
                    var { discount } = asset!;

                    var needMore = new BigNumber(balance).times(price).lt(1)

                    return (
                        <>
                            <ListItem key={index} onClick={() => needMore ? {} : onAssetClick(asset as Asset)} className={needMore ? 'disabled' : ''}>
                                <SymbolWithLogoText
                                    logo={logo}
                                    symbol={symbol}
                                    name={name}
                                    text={discount != 0 ? String(discount) + '% OFF' : ''}
                                />
                                        <AssetValues>
                                            <Balance>{parseFloat(balance).toFixed(2)}</Balance>
                                            <AssetPrice>{'$' + new BigNumber(balance).times(price).toFixed(2)}</AssetPrice>
                                        </AssetValues>
                                

                            </ListItem>
                            <SeperatorLine />
                        </>
                    )
                })}
            </AssetsList>
        </StyledModal>
    )
}


const SeperatorLine = styled.div`
  width: 100%;
  height: 1px;
`

const StyledModal = styled(Modal)`
  width: 400px;
  left: calc(50% - 200px);
  top: 15%;

  overflow-y: hidden;
  height: auto;
  text-align: left;
  justify-content: flex-start;

  @media (max-width: 500px) {
    width: 100%;
    left: 0;
    top: initial;
  }
  
`;


const AssetsList = styled.ul`
  margin-top: 16px;
  max-height: 400px;
  overflow-y: scroll;

`;

const Divider = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

const RowNames = styled.div`
  font-size: 14px;
  color: var(--color-gray);
`

const Balance = styled(Text)`
  color: #EEEEEE;
  font-weight: 500;
`;

const SetActive = styled(ButtonPrimaryMedium)`
  height: 28px;
  width: 25%;
  padding-left: auto;
  padding-right: auto;
  font-size: 12px;
  border-radius: 12px;
`;

export const AssetValues = styled.div`
  text-align: right;
`;

const AssetPrice = styled(Text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777F99;
`;
