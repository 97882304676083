var COINGECKO_LIST: any = [
    {
      chainId: 324,
      id: 'aegis-token-f7934368-2fb3-4091-9edc-39283e87f55d',
      symbol: 'on',
      name: 'Onsen Token',
      decimals: 18,
      address: '0x286f34bf2384b38067709e2abb255aa16d79f0e5',
      logoURI: 'https://assets.coingecko.com/coins/images/29659/large/blobid0.png?1683529507'
    },
    {
      chainId: 324,
      id: 'bolt-token-023ba86e-eb38-41a1-8d32-8b48ecfcb2c7',
      symbol: '$bolt',
      name: 'Bolt Token',
      decimals: 18,
      address: '0x61b41e0244133b9c9c47a57e51a5ef70be2c5dd4',
      logoURI: 'https://assets.coingecko.com/coins/images/30082/large/cropped-Group-75-300x258.png?1683016447'
    },
    {
      chainId: 324,
      id: 'bullets',
      symbol: 'blt',
      name: 'Bullets',
      decimals: 18,
      address: '0x71fd7dfa7db7094e0f857ad3040f1afef76fef85',
      logoURI: 'https://assets.coingecko.com/coins/images/30475/large/Logo_BLT_Token_%28200x200%29.png?1684673128'
    },
    {
      chainId: 324,
      id: 'cheems-token',
      symbol: 'cheems',
      name: 'Cheems Token',
      decimals: 18,
      address: '0x08f328c72a9b420b7568415ed331a1c236e6f620',
      logoURI: 'https://assets.coingecko.com/coins/images/30376/large/Hg4_Lhbg_400x400.jpg?1684297200'
    },
    {
      chainId: 324,
      id: 'doge-farm',
      symbol: 'dof',
      name: 'Doge Farm',
      decimals: 18,
      address: '0xcffec2c683762831d16a9c5b9c70ba1789bba1f3',
      logoURI: 'https://assets.coingecko.com/coins/images/29665/large/photo_2023-03-25_14-48-54.jpg?1680571557'
    },
    {
      chainId: 324,
      id: 'dracula-fi',
      symbol: 'fang',
      name: 'Dracula Fi',
      decimals: 18,
      address: '0x160e07e42adbc1fce92d505b579bcd8a3fbea77d',
      logoURI: 'https://assets.coingecko.com/coins/images/31028/large/fang.png?1689838527'
    },
    {
      chainId: 324,
      id: 'era-name-service',
      symbol: 'era',
      name: 'Era Name Service',
      decimals: 18,
      address: '0xecd9f240ed3895c77db676004328dd1d246f33c9',
      logoURI: 'https://assets.coingecko.com/coins/images/30536/large/era.jpg?1684907557'
    },
    {
      chainId: 324,
      id: 'ezkalibur',
      symbol: 'sword',
      name: 'eZKalibur',
      decimals: 18,
      address: '0x240f765af2273b0cab6caff2880d6d8f8b285fa4',
      logoURI: 'https://assets.coingecko.com/coins/images/30722/large/leaf.png?1686632378'
    },
    {
      chainId: 324,
      id: 'gemswap-2',
      symbol: 'zgem',
      name: 'GemSwap',
      decimals: 18,
      address: '0x2b64237277c605d07f17b96f9627712340c32981',
      logoURI: 'https://assets.coingecko.com/coins/images/29630/large/zgem.png?1680153491'
    },
    {
      chainId: 324,
      id: 'heartx-utility-token',
      symbol: 'hnx',
      name: 'HeartX Utility Token',
      decimals: 18,
      address: '0xc76997c863bac0f6e4b6d3c2404b06013a0b29e2',
      logoURI: 'https://assets.coingecko.com/coins/images/30531/large/HNX_icon.png?1684890258'
    },
    {
      chainId: 324,
      id: 'hush-cash',
      symbol: 'hush',
      name: 'Hush.cash',
      decimals: 18,
      address: '0x6d27b6252b980ba4350a1e349a3b684237d5e986',
      logoURI: 'https://assets.coingecko.com/coins/images/29932/large/coingecko.png?1682141994'
    },
    {
      chainId: 324,
      id: 'impermax-2',
      symbol: 'ibex',
      name: 'Impermax',
      decimals: 18,
      address: '0xbe9f8c0d6f0fd7e46cdacca340747ea2f247991d',
      logoURI: 'https://assets.coingecko.com/coins/images/27606/large/IqwOmX-c_400x400.jpeg?1664846656'
    },
    {
      chainId: 324,
      id: 'izumi-finance',
      symbol: 'izi',
      name: 'iZUMi Finance',
      decimals: 18,
      address: '0x16a9494e257703797d747540f01683952547ee5b',
      logoURI: 'https://assets.coingecko.com/coins/images/21791/large/izumi-logo-symbol.png?1640053924'
    },
    {
      chainId: 324,
      id: 'kannagi-finance',
      symbol: 'kana',
      name: 'Kannagi Finance',
      decimals: 18,
      address: '0x26ac1d9945f65392b8e4e6b895969b5c01a7b414',
      logoURI: 'https://assets.coingecko.com/coins/images/30713/large/200_200.png?1686555617'
    },
    {
      chainId: 324,
      id: 'maverick-protocol',
      symbol: 'mav',
      name: 'Maverick Protocol',
      decimals: 18,
      address: '0x787c09494ec8bcb24dcaf8659e7d5d69979ee508',
      logoURI: 'https://assets.coingecko.com/coins/images/30850/large/MAV_Logo.png?1687945366'
    },
    {
      chainId: 324,
      id: 'metaelfland',
      symbol: 'meld',
      name: 'MetaElfLand',
      decimals: 18,
      address: '0xcd2cfa60f04f3421656d6eebee122b3973b3f60c',
      logoURI: 'https://assets.coingecko.com/coins/images/31469/large/MELD_LOGO.png?1692951409'
    },
    {
      chainId: 324,
      id: 'metavault-trade',
      symbol: 'mvx',
      name: 'Metavault Trade',
      decimals: 18,
      address: '0xc8ac6191cdc9c7bf846ad6b52aaaa7a0757ee305',
      logoURI: 'https://assets.coingecko.com/coins/images/25402/large/mvx.png?1660380728'
    },
    {
      chainId: 324,
      id: 'mute',
      symbol: 'mute',
      name: 'Mute',
      decimals: 18,
      address: '0x0e97c7a0f8b2c9885c8ac9fc6136e829cbc21d42',
      logoURI: 'https://assets.coingecko.com/coins/images/14331/large/MUTE.png?1617618967'
    },
    {
      chainId: 324,
      id: 'onchain-trade',
      symbol: 'ot',
      name: 'Onchain Trade',
      decimals: 18,
      address: '0xd0ea21ba66b67be636de1ec4bd9696eb8c61e9aa',
      logoURI: 'https://assets.coingecko.com/coins/images/29668/large/Group_1981.png?1680573186'
    },
    {
      chainId: 324,
      id: 'pepera',
      symbol: 'pepera',
      name: 'Pepera',
      decimals: 18,
      address: '0x60143f39b54a731ac790708c8719211878e638c3',
      logoURI: 'https://assets.coingecko.com/coins/images/30199/large/PEPERA.png?1683613325'
    },
    {
      chainId: 324,
      id: 'pinnako',
      symbol: 'piko',
      name: 'Pinnako',
      decimals: 18,
      address: '0xf8c6da1bbdc31ea5f968ace76e931685ca7f9962',
      logoURI: 'https://assets.coingecko.com/coins/images/30978/large/pinnako.jpeg?1689311272'
    },
    {
      chainId: 324,
      id: 'reactorfusion',
      symbol: 'rf',
      name: 'ReactorFusion',
      decimals: 18,
      address: '0x5f7cbcb391d33988dad74d6fd683aadda1123e4d',
      logoURI: 'https://assets.coingecko.com/coins/images/29975/large/RF_Token_logo.png?1684140406'
    },
    {
      chainId: 324,
      id: 'snark-launch',
      symbol: '$snrk',
      name: 'Snark Launch',
      decimals: 18,
      address: '0x533b5f887383196c6bc642f83338a69596465307',
      logoURI: 'https://assets.coingecko.com/coins/images/29957/large/IMG_20230422_160407_790.jpg?1682386917'
    },
    {
      chainId: 324,
      id: 'spacefi-zksync',
      symbol: 'space',
      name: 'SpaceFi',
      decimals: 18,
      address: '0x47260090ce5e83454d5f05a0abbb2c953835f777',
      logoURI: 'https://assets.coingecko.com/coins/images/30822/large/aOwhiUUo_400x400.png?1687751064'
    },
    {
      chainId: 324,
      id: 'superlauncher-dao',
      symbol: 'launch',
      name: 'Superlauncher',
      decimals: 18,
      address: '0xf6d9a093a1c69a152d87e269a7d909e9d76b1815',
      logoURI: 'https://assets.coingecko.com/coins/images/14817/large/SL-V3-1024.png?1666342249'
    },
    {
      chainId: 324,
      id: 'symbiosis-finance',
      symbol: 'sis',
      name: 'Symbiosis',
      decimals: 18,
      address: '0xdd9f72afed3631a6c85b5369d84875e6c42f1827',
      logoURI: 'https://assets.coingecko.com/coins/images/20805/large/SymbiosisFinance_logo-150x150.jpeg?1637707064'
    },
    {
      chainId: 324,
      id: 'syncdex',
      symbol: 'sydx',
      name: 'SyncDex',
      decimals: 18,
      address: '0x3a34fa9a1288597ad6c1da709f001d37fef8b19e',
      logoURI: 'https://assets.coingecko.com/coins/images/29746/large/Logo200x200.png?1681108370'
    },
    {
      chainId: 324,
      id: 'tiny-era-shard',
      symbol: 'tes',
      name: 'Tiny Era Shard',
      decimals: 18,
      address: '0xcab3f741fa54e79e34753b95717b23018332b8ac',
      logoURI: 'https://assets.coingecko.com/coins/images/31204/large/TES250.png?1691380966'
    },
    {
      chainId: 324,
      id: 'titi-governance-token',
      symbol: 'titi',
      name: 'TiTi Governance Token',
      decimals: 18,
      address: '0x4ebfb78c4780c304dff7de518db630b67e3f044b',
      logoURI: 'https://assets.coingecko.com/coins/images/30561/large/TiTi.png?1685019688'
    },
    {
      chainId: 324,
      id: 'tiusd',
      symbol: 'tiusd',
      name: 'TiUSD',
      decimals: 18,
      address: '0xc059767cb62f003e863f9e7bd1fc813beff9693c',
      logoURI: 'https://assets.coingecko.com/coins/images/30697/large/TIUDS.png?1686540260'
    },
    {
      chainId: 324,
      id: 'unlock-maverick',
      symbol: 'unkmav',
      name: 'Unlock Maverick',
      decimals: 18,
      address: '0x81fe5d73a48336129888d71bf164209cf646540f',
      logoURI: 'https://assets.coingecko.com/coins/images/31264/large/clean.jpg?1692067517'
    },
    {
      chainId: 324,
      id: 'usd',
      symbol: 'usd+',
      name: 'Overnight.fi USD+',
      decimals: 18,
      address: '0x8e86e46278518efc1c5ced245cba2c7e3ef11557',
      logoURI: 'https://assets.coingecko.com/coins/images/25757/large/USD__logo.png?1653519267'
    },
    {
      chainId: 324,
      id: 'usd-coin',
      symbol: 'usdc',
      name: 'USD Coin',
      decimals: 18,
      address: '0x3355df6d4c9c3035724fd0e3914de96a5a83aaf4',
      logoURI: 'https://assets.coingecko.com/coins/images/6319/large/USD_Coin_icon.png?1547042389'
    },
    {
      chainId: 324,
      id: 'velocore',
      symbol: 'vc',
      name: 'Velocore',
      decimals: 18,
      address: '0x85d84c774cf8e9ff85342684b0e795df72a24908',
      logoURI: 'https://assets.coingecko.com/coins/images/29702/large/VELOCORE-CIRCLE-LOGO.png?1680761868'
    },
    {
      chainId: 324,
      id: 'velocore-waifu',
      symbol: 'waifu',
      name: 'Waifu by Velocore',
      decimals: 18,
      address: '0xa4e4d9984366e74713737cb5d646bba0b7e070a4',
      logoURI: 'https://assets.coingecko.com/coins/images/30075/large/waifu-200x.png?1683013036'
    },
    {
      chainId: 324,
      id: 'vesync',
      symbol: 'vs',
      name: 'veSync',
      decimals: 18,
      address: '0x5756a28e2aae01f600fc2c01358395f5c1f8ad3a',
      logoURI: 'https://assets.coingecko.com/coins/images/30611/large/veSync-Logo-PNG-200X200.png?1685691394'
    },
    {
      chainId: 324,
      id: 'wefi-finance',
      symbol: 'wefi',
      name: 'Wefi Finance',
      decimals: 18,
      address: '0x81e7186947fb59aaaaeb476a47daac60680cbbaf',
      logoURI: 'https://assets.coingecko.com/coins/images/30540/large/wefi.png?1684981490'
    },
    {
      chainId: 324,
      id: 'weth',
      symbol: 'weth',
      name: 'WETH',
      decimals: 18,
      address: '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91',
      logoURI: 'https://assets.coingecko.com/coins/images/2518/large/weth.png?1628852295'
    },   
    {
        chainId: 324,
        id: 'whisper',
        symbol: 'wisp',
        name: 'Whisper',
        decimals: 18,
        address: '0xc8ec5b0627c794de0e4ea5d97ad9a556b361d243',
        logoURI: 'https://assets.coingecko.com/coins/images/29650/large/wisp_200.png?1681725709'
    },
      {
        chainId: 324,
        id: 'woo-network',
        symbol: 'woo',
        name: 'WOO Network',
        decimals: 18,
        address: '0x9e22d758629761fc5708c171d06c2fabb60b5159',
        logoURI: 'https://assets.coingecko.com/coins/images/12921/large/w2UiemF__400x400.jpg?1603670367'
      },
      {
        chainId: 324,
        id: 'zkapes-token',
        symbol: 'zat',
        name: 'zkApes Token',
        decimals: 18,
        address: '0x47EF4A5641992A72CFd57b9406c9D9cefEE8e0C4',
        logoURI: 'https://assets.coingecko.com/coins/images/30316/large/zkApes.png?1683885347'
      },
      {
        chainId: 324,
        id: 'zkcult',
        symbol: 'zcult',
        name: 'zkCULT',
        decimals: 18,
        address: '0x8aaaf6e6167825829ab29f260f246afe742fb243',
        logoURI: 'https://assets.coingecko.com/coins/images/29807/large/zkCULT_Logo_200x200.jpg?1681431220'
      },
      {
        chainId: 324,
        id: 'zkdoge',
        symbol: 'zkdoge',
        name: 'zkDoge',
        decimals: 18,
        address: '0xbfb4b5616044eded03e5b1ad75141f0d9cb1499b',
        logoURI: 'https://assets.coingecko.com/coins/images/29619/large/zkdoge.png?1680061339'
      },
      {
        chainId: 324,
        id: 'zkfloki',
        symbol: 'zfloki',
        name: 'zkFloki',
        decimals: 18,
        address: '0x8d266fa745b7cf3856af0c778828473b8d33a149',
        logoURI: 'https://assets.coingecko.com/coins/images/29811/large/zkfloki_logo.png?1681432029'
      },
      {
        chainId: 324,
        id: 'zk-inu',
        symbol: '$zkinu',
        name: 'ZK inu',
        decimals: 18,
        address: '0x45559297bdedf453e172833ac7086f7d03f6690b',
        logoURI: 'https://assets.coingecko.com/coins/images/29692/large/ZKINU_%281%29.png?1680670738'
      },
      {
        chainId: 324,
        id: 'zklaunchpad',
        symbol: 'zkpad',
        name: 'zkLaunchpad',
        decimals: 18,
        address: '0x959ab3394246669914bddeaeb50f8ac85648615e',
        logoURI: 'https://assets.coingecko.com/coins/images/29720/large/zkpad.jpeg?1680834995'
      },
      {
        chainId: 324,
        id: 'zkpepe',
        symbol: 'zkpepe',
        name: 'ZKPepe',
        decimals: 18,
        address: '0x90eea899185105d583d04b7bdbfb672fce902a53',
        logoURI: 'https://assets.coingecko.com/coins/images/29870/large/241.png?1681892975'
      },
      {
        chainId: 324,
        id: 'zkshib',
        symbol: 'zkshib',
        name: 'zkShib',
        decimals: 18,
        address: '0x5e38cb3e6c0faafaa5c32c482864fcef5a0660ad',
        logoURI: 'https://assets.coingecko.com/coins/images/29714/large/zkshib.jpg?1680768155'
      },




 
    {
      chainId: 324,
      id: 'zkswap-92fc4897-ea4c-4692-afc9-a9840a85b4f2',
      symbol: 'zksp',
      name: 'zkSwap',
      decimals: 18,
      address: '0x7eCf006f7c45149B457e9116392279CC8A630F14',
      logoURI: 'https://assets.coingecko.com/coins/images/30582/large/zkswap.jpeg?1685440430'
    },
    {
      chainId: 324,
      id: 'zksync-id',
      symbol: 'zkid',
      name: 'zkSync id',
      decimals: 18,
      address: '0x2141d7fe06a1d69c016fc638ba75b6ef92fa1435',
      logoURI: 'https://assets.coingecko.com/coins/images/30115/large/logo.png?1683179676'
    },
    {
      chainId: 324,
      id: 'zynergy',
      symbol: 'zyn',
      name: 'Zynergy',
      decimals: 18,
      address: '0x0231b3de40b6b3bdd28dcef037f1b7a3fcf5a95a',
      logoURI: 'https://assets.coingecko.com/coins/images/29674/large/logo_200px.png?1680576373'
    },
    {
      chainId: 324,
      id: 'flashdash',
      symbol: 'FLASHDASH',
      name: 'Flashdash',
      decimals: 18,
      address: '0x06662147240414cc0dc25e6414100750d346bc44',
      logoURI: 'https://assets.coingecko.com/coins/images/33274/standard/logo200.png?1701329106'
    },
    {
      chainId: 324,
      id: 'zkswap-finance',
      symbol: 'ZF',
      name: 'zkSwap Finance',
      decimals: 18,
      address: '0x31C2c031fDc9d33e974f327Ab0d9883Eae06cA4A',
      logoURI: 'https://assets.coingecko.com/coins/images/31633/standard/zf.png?1696530449'
    },
  ]

  export default COINGECKO_LIST
