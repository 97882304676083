import React, {ReactNode} from 'react'
import { WalletHook, useWalletHook } from '../web3/walletHook'
import { Provider } from 'react-redux'
import { store } from '../state'
import { RedeemedDMuteModal } from '../common/modal/RedeemedDMuteModal'
import muteIcon from '../assets/images/icons/mute_design_icon.png'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useEthersProvider } from './ethers'

import { createWeb3Modal } from '@web3modal/wagmi/react'
import { http, createConfig, WagmiProvider, useAccount } from 'wagmi'
import { zkSync, zkSyncSepoliaTestnet } from 'wagmi/chains'
import { walletConnect, injected, coinbaseWallet } from 'wagmi/connectors'

//walletconnectv2 id
const projectId = '3663eb9da4e7acb973bb7adc3c5b8a4c'
// 2. Create wagmiConfig
const metadata = {
  name: 'Mute',
  description: 'A zkRollup DeFi ecosystem',
  url: 'https://app.mute.io',
  icons: ['https://mute.io/web/mute_3d_dark.webp'],
  verifyUrl: 'https://app.mute.io'
}

//@ts-ignore
const config = createConfig({
  chains: [zkSync, zkSyncSepoliaTestnet],
  transports: {
    [zkSync.id]: http(),
    [zkSyncSepoliaTestnet.id]: http()

  },
  connectors: [
    walletConnect({ projectId, metadata, showQrModal: false, isNewChainsStale: false}),
    injected({ shimDisconnect: true }),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0]
    })
  ]
})

// 3. Create modal
var modal = createWeb3Modal({ wagmiConfig: config, projectId, defaultChain: zkSync})

const queryClient = new QueryClient();

interface ConnectionProviderProps {
  children: ReactNode
}

export const Providers = ({children}: ConnectionProviderProps) => {
  const [ready, setReady] = React.useState(false);

  React.useEffect(() => {
    setReady(true)

      return () => {}
  }, [])

  return (
    ready ? 
      <WagmiProvider config={config}>
        <Provider store={store}>
          <QueryClientProvider client={queryClient}>
              <WalletHook>
              {children}
              </WalletHook>
          </QueryClientProvider>
        </Provider>
      </WagmiProvider> : null
  )
}

function Tracer() {
  const [chainDelayed, setChainDelayed] = React.useState(store.getState().chainDelayed)
  const {address} = useAccount();
  const provider = useEthersProvider() 
  const shouldTrace = false

  React.useEffect(() => {
    if (shouldTrace) {
      provider?.on('debug', trace)
    }
    return () => {
      provider?.off('debug', trace)
      provider?.off('debug', trace)
    }
  }, [provider, shouldTrace])



  React.useEffect(() => {
    if(provider && address){
      let _store = store.getState()
      setChainDelayed(_store.chainDelayed)
    }
    return () => {}
  }, [address]);

  return (<></>)
}

function trace(event: any) {
  if (event.action !== 'request') return
  const { method, id, params } = event.request
  console.groupCollapsed(method, id)
  console.debug(params)
  console.groupEnd()
}