import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Amount } from '../text/Amount'
import { TextSemiBold } from '../text/Text'
import { BasicModalProps, Modal } from './Modal'
import { ModalDescription } from './ModalComponents'
import Confetti from 'react-dom-confetti';
interface SuccessfulSubmitModalProps extends BasicModalProps {
  title: string;
  description: string;
  savings: string;
  costs: string;
  section: ReactNode;
  footer?: ReactNode;
  onSubmit: () => void;
  amountIn?: number | string | undefined;
  amountOut?: number | string | undefined;
  symbolIn?: string | undefined;
  symbolOut?: string | undefined;
  iconIn?: string | undefined;
  iconOut?: string | undefined;
}

export const SuccessfulSubmitModal = (props: SuccessfulSubmitModalProps) => {
  const [conefetti, setConefetti] = useState(false);

  const config = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 120,
    dragFriction: 0.12,
    duration: 8000,
    stagger: 1,
    width: "10px",
    height: "10px",
    perspective: "500px",
    colors: ["#17C687", "#17C687"]
  };

  useEffect(() => {
    if (props.isVisible) {
      setTimeout(() => {
        setConefetti(true)
      }, 200);
      props.onSubmit();
    }

    return () => {
      setConefetti(false)
    }

  }, [props.isVisible])

  return (
    <Modal
      title={props.title}
      isVisible={props.isVisible}
      onClose={props.onClose}
    >
      <ConfettiWrapper>
        <Confetti active={conefetti} config={config} />
      </ConfettiWrapper>
      {props.iconIn !='' ? 
            <Savings>
            <TextSemiBold>You have received</TextSemiBold>
            <Row>
            <IconLogo src={props.iconOut} />
            <StyledAmount>&nbsp; {props.amountOut} {props.symbolOut}</StyledAmount>
            </Row>
          </Savings>
          : <></>
      }

      <Line/>
      {props.section}
      <Footer>{props.footer}</Footer>
    </Modal>
  )
}

const StyledAmount = styled(Amount)`
  margin: 8px 0;
`;

const ConfettiWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  justify-content: center;
  overflow: hidden;
`;

const IconLogo = styled.img`
  width: 28px;
  height: 28px;
`;

const Line = styled.div`
width: 100%;
height: 0px;
margin-top: 25px;
margin-bottom: 25px;
border: 1px solid #262436;

`

const Savings = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 32px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;

const Description = styled(ModalDescription)`
  margin-bottom: 44px;
`;

const Footer = styled.div`
  margin-top: 21px;
`;
