const month_arr = ["Jan","Feb","Mar","April","May","June","July","Aug","Sep","Oct","Nov","Dec"];


export function formatDate(milliseconds: number) {
  const date = new Date(milliseconds);
  const dayOfMonth = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  return `${dayOfMonth} ${month_arr[month]} ${year}`
}
