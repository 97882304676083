import React, { useState, useRef } from 'react'
import styled, {keyframes} from 'styled-components';
import {useLocation, useHistory, useParams} from "react-router-dom";
import BigNumber from 'bignumber.js';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import { ModalButton, ModalText } from '../../common/modal/ModalComponents'

import { ButtonPrimaryLarge } from '../../common/buttons/Button'
import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';

import angleIcon from '../../assets/images/icons/angle-bright.svg'
import externalLink from '../../assets/images/icons/external_link.svg'

import muteCustomIcon from '../../assets/images/icons/mute_custom_icon.png'
import ethCustomIcon from '../../assets/images/icons/eth_custom_icon.png'

import DOT from '../../assets/images/bond_dot.png'
import LINE from '../../assets/images/bond_line.png'

import { ShadowWidget } from '../../common/ShadowWidget'

import { reduceString } from '../../utils/reduceString';
import { formatNumber } from '../../utils/formatNumber';
import { AmountSection } from '../../common/section/AmountSection'
import {LoadingAnimation} from '../../common/loading/loading'
import {LoadingBox} from '../../common/loading/LoadingBox'

import { useWalletHook } from '../../web3/walletHook';
import { WalletState } from '../../state';
import { useWalletSelector } from '../../state/hooks';

interface ParamTypes {
  poolId: string;
}

enum Modal {
  Settings,
  ChangeFee
}

export const BondSubScreen = ({poolInfo}) => {
  let location = useLocation()
  const history = useHistory()
  const { poolId } = useParams<ParamTypes>();

  const [assetA, setAssetA] = useState<any>(undefined)
  const [assetB, setAssetB] = useState<any>(undefined)

  const [startTimeText, setStartTimeText] = useState<any>(undefined)


  const [assetAAmount, setAssetAAmount] = useState('0')
  const [assetBAmount, setAssetBAmount] = useState('0')


  const [poolAPY, setPoolAPY] = useState('0')

  const [poolData, setPoolData] = useState<any>(null)

  const [calculating, setCalculating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [isModalVisible, setIsModalVisible] = useState(false);

  const tokens = useWalletSelector((state: WalletState) => state.tokens)
  const bondInfo = useWalletSelector((state: WalletState) => state.bondInfo)

  const [modal, setModal] = useState<Modal | null>(null);


  const { depositBond } = useWalletHook()


  function timer(startTime) {
    if(startTime == null)
      return null

    let seconds = startTime - (Date.now() / 1000)

    if(seconds < 0)
      return null

    var days        = Math.floor(seconds/24/60/60);
    var hoursLeft   = Math.floor((seconds) - (days*86400));
    var hours       = Math.floor(hoursLeft/3600);
    var minutesLeft = Math.floor((hoursLeft) - (hours*3600));
    var minutes     = Math.floor(minutesLeft/60);
    var remainingSeconds = Math.floor(seconds % 60)

    function pad(n) {
      return (n < 10 ? "0" + n : n);
    }
    let time = pad(days) + "d:" + pad(hours) + "h:" + pad(minutes) + "m:" + pad(remainingSeconds) + 's'
    return time
  }

  React.useEffect(() => {
    if(bondInfo[0]){
      setPoolData(bondInfo[0])
      setIsLoading(false)
      setStartTimeText(timer(bondInfo[0].startTime))

      let _assetA = tokens[bondInfo[0].token0.address.toLowerCase()]
      let _assetB = tokens[bondInfo[0].token1.address.toLowerCase()]
      if(_assetA == null)
        _assetA = tokens[bondInfo[0].token0.address]
      if(_assetB == null)
        _assetB = tokens[bondInfo[0].token1.address]

      setAssetA(_assetA)
      setAssetB(_assetB)
    }
    
    var inter = setInterval(() => {
      if(poolData){
        setStartTimeText(timer(poolData.startTime))
      }
    }, 1000)

    return () => {
      clearInterval(inter)
    }
 
  }, [bondInfo]);


  const getDepositButtonStatus = () => {
    if(poolData && poolData.apy.includes('-'))
      return 'ROI is negative'

    if(startTimeText)
      return 'Bond not live (' + startTimeText + ')'

    if(new BigNumber(assetAAmount).lte(0))
      return 'Invalid amounts'


    if(assetA){
      if(new BigNumber(assetAAmount).gt(poolData.balance))
        return 'Invalid amounts'
    }

    if(poolData){
      if(poolData.approve == true){
        return 'Approve'
      }
    }

    return 'Bond'
  }


  const shouldDisable = () => {
    if(getDepositButtonStatus() == 'Bond' || getDepositButtonStatus() == 'Approve')
      return false

    return true
  }

  const depositBondLocal = async () => {
    setCalculating(true)
    await depositBond(poolData.pool, assetAAmount, poolData.approve, new BigNumber(assetAAmount).gte(poolData.maxPurchase), poolData.epoch)
    setCalculating(false)
  }


  const changeAssetA = (val) => {
    if(new BigNumber(val).gte(poolData.maxPurchase))
      setAssetAAmount(poolData.maxPurchase)
    else
      setAssetAAmount(val)

    if(poolData.available == true)
      setAssetBAmount(new BigNumber(val).times(poolData.assetBTotal).div(poolData.assetATotal).toFixed())
  }

  return (
    <Screen>
      <Layout>

        <TitleContainer>
          <BackButton onClick={() => history.push('/bonds')}/>
          <Spacer/>
          {isLoading == true ? <TitleContainer><LoadingBox/></TitleContainer> : (
            <TitleWrapper>
            <Title>vMLP ({assetA ? (assetA.symbol + '/' + assetB.symbol) : ''}) </Title>
            <Spacer/>
            <Subtitle>Bond Pool</Subtitle>
            </TitleWrapper>
          )}

        </TitleContainer>

        <div style={{gridArea: 'empty_section'}}>
        </div>

        <DataContainer style={{gridArea: 'exchange'}}>
          <DataContainerColumn>

            <>
            <ShadowWidget>
            <AmountSection
              value={assetAAmount} 
              onChange={changeAssetA}
              assets={[]}
              selectedAsset={{amount: '0', balance: new BigNumber(poolData ? poolData.balance : 0).toFixed(), logo: assetA ? assetA.logo : '', symbol: assetA ? (assetA.symbol + '/' + assetB.symbol + ' LP') : '', name: '', value: '', decimals: 18, price: '0'}}
              disableEdit={true}
              onAssetSelect={() => {}}
              label="Bond"
              price={new BigNumber(0).toFixed()}
            />
            </ShadowWidget>
            <Spacer/>
            <Spacer/>
            <Spacer/>

            <StatRow>
              <StatText>
              You will get
              </StatText>
              <StatTextHighlight>
              {poolData ? new BigNumber(poolData.price).times(assetAAmount).toFixed(4) : "0.00"} MUTE
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              {poolData ? (poolData.apy.includes('-') ? 'ROI' : 'ROI') : 'ROI'}

              </StatText>
              <StatTextHighlight>
              {poolData ? poolData.apy : "0.00"} %
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Vesting time
              </StatText>
              <StatTextHighlight>
              7 days
              </StatTextHighlight>
            </StatRow>


            {poolData && poolData.apy.includes('-') ? 
            <StatRow>
            <ErrorText>Warning: You are overpaying for this bond currently. It would be more beneficial to wait until it is in a discount.</ErrorText>
            </StatRow>

            :<></>}

            <BondButton className={poolData && poolData.apy.includes('-') ? 'impact' : ''} onClick={() => depositBondLocal()} disabled={shouldDisable()}>
              <LoadingAnimation isLoading={calculating} />
              {getDepositButtonStatus()}
            </BondButton>
            </>

          </DataContainerColumn>
        </DataContainer>



        <DataContainer style={{gridArea: 'pool_stats', flexDirection: 'column'}}>
          <HeaderWrapper>
          <DataContainerColumnMod>
            <ContainerText>
            Tangible book value
            </ContainerText>
            <HeaderText>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? "$" + formatNumber(new BigNumber(poolData.maxPayout).times(assetA ? assetA.price : 0), 2) : '$0.00')
              }
            </HeaderText>
          </DataContainerColumnMod>
          <DataContainerColumn>
            <ContainerText>
            {poolData ? (poolData.apy.includes('-') ? 'ROI' : 'ROI') : 'ROI'}

            </ContainerText>
            <HeaderTextGreen style={{color: poolData ? (poolData.apy.includes('-') ? 'rgb(253, 118, 107)' : 'rgb(118, 209, 145)') : '#0BD98F'}}>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? poolData.apy + '%' : poolAPY)
              }
            </HeaderTextGreen>
          </DataContainerColumn>
          </HeaderWrapper>



          <div style={{width: '100%', display:'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto', paddingBottom: '20px'}}>
          <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>{poolData ? new BigNumber(poolData.maxDeposit).div(poolData.maxPayout).times(100).toFixed(2) : '0.00'}% of {poolData ? "$" + formatNumber(new BigNumber(poolData.maxPayout).times(assetA ? assetA.price : 0), 2) : '$0.00'} available</StatTextHighlight>

          <Slider

            railStyle={{backgroundColor: '#262436'}}
            trackStyle={{backgroundColor: '#2A70BC'}}
            handleStyle={{width: '0px', height: '0px', borderRadius: '0', border: '0', color: 'rgba(0,0,0,0)'}}
            min={0}
            max={100}
            value={new BigNumber(poolData ? poolData.maxDeposit : 0).div(poolData ? poolData.maxPayout: 0).times(100).toNumber()}
            step={0.01}
          />
          </div>

            <StatRow>
              <StatText>
              Bond Price
              </StatText>
              <StatTextHighlight style={{color: poolData ? (poolData.apy.includes('-') ? 'rgb(253, 118, 107)' : 'rgb(118, 209, 145)') : '#0BD98F'}}>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? "$" + formatNumber(new BigNumber(poolData.price).times(assetA ? assetA.price : 0), 2)  : ''))
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Market Price
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? "$" + formatNumber(new BigNumber(poolData.lp_per_mute).times(assetA ? assetA.price : 0), 2) : ''))
              }
              </StatTextHighlight>
            </StatRow>

        </DataContainer>
        <DataContainer style={{gridArea: 'bond_details'}}>
          <DataContainerColumn>
            <StatRow style={{justifyContent: 'flex-start', alignItems: 'center', paddingBottom: '0px'}}>
              <BondDot src={DOT}/>
              <StatText>
              You give <TokenIcon style={{marginLeft: '5px'}} src={muteCustomIcon}/> <TokenIcon style={{marginLeft: '-6px', marginRight: '5px'}}  src={ethCustomIcon}/>  vMLP ({assetA ? (assetA.symbol + '/' + assetB.symbol) : ''})
              </StatText>
            </StatRow>
            <BondLine src={LINE}/>

            <StatRow style={{justifyContent: 'flex-start', alignItems: 'center', paddingBottom: '0px'}}>
              <BondDot src={DOT}/>
              <StatText>
              7 days vesting period in dMute (check DAO tab)
              </StatText>
            </StatRow>
            <BondLine src={LINE}/>

            <StatRow style={{justifyContent: 'flex-start', alignItems: 'center', paddingBottom: '0px'}}>
              <BondDot src={DOT}/>

              <StatText>
              You receive <TokenIcon style={{marginLeft: '5px', marginRight: '5px'}} src={muteCustomIcon}/> with a {isLoading == true ?  '':
                (poolData ? poolData.apy + '%' : poolAPY)
              } {poolData ? (poolData.apy.includes('-') ? 'ROI' : 'ROI') : 'ROI'}
              </StatText>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>
        <DataContainer style={{gridArea: 'pool_details'}}>
          <DataContainerColumn>
            <ContainerText>
            Bond details
            </ContainerText>
            <StatRow>
              <StatText>
              Epoch
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? poolData.epoch : ''))
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Total Bonded
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? '$' + formatNumber(new BigNumber(poolData.totalBonded).times(assetA ? assetA.price : 0), 2)  : '$0.00'))
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Contract
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? reduceString(poolData.id, 7, 4) : ''))
              }
              <ExternalLink src={externalLink} onClick={() => {window.open('https://explorer.zksync.io/address/' + poolData.id, "_blank")}} />
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>
      </Layout>
    </Screen>
  )
}
//          <CreatePair externalOpen={createLiqModal} assets={tokens} createPair={addLiquidity} importPool={importPool} addLiquidity={addLiquidityScreenPopup}/>
//          <ImportPool assets={tokens} importPool={importPool} addPool={addPool} />
// <LiquidityHistory history={liquidity.history}/>

const Layout = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "titlecontainer titlecontainer"
  "pool_stats  exchange"
  "bond_details exchange"
  "pool_details exchange"
  "empty_section exchange";

  grid-auto-rows: minmax(min-content, max-content);


  @media (max-width: 767px) {
    column-gap: 0px;
    row-gap: 8px;
    grid-template-columns: 1fr;
    grid-template-areas:
    "titlecontainer"
    "pool_stats"
    "exchange"
    "bond_details"
    "pool_details";
  }
`;


const ExternalLink = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 8px;
`

const TokenIcon = styled.img`
  width: 24px;
  height: 24px;
`
const BondDot = styled.img`
width: 6px;
height: 6px;
margin-right: 10px;
`

const BondLine = styled.img`
width: 2px;
height: 34px;
margin-left: 2px;
`
const Spacer = styled.div`
  width: 25px;
  height: 6px;
`
const SpacerBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2E3551;
`
const TitleContainer = styled.div`
  position: relative;
  overflow: hidden;
  grid-area: titlecontainer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TitleWrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
    margin-right: auto;

  }

`

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 30px;

  @media (max-width: 500px) {
    flex-direction: column;
  }

`


const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`

const StatText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7F7D97;
`

const StatTextHighlight = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F6F8FE;
`

const Subtitle = styled.div`
  width: auto;
  padding: 0 10px;
  height: 27px;
  background: rgba(41, 48, 74, 0.4);
  border-radius: 8px;
  display: flex;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  justify-content: center;
  align-items: center;

  color: #777F99;
`

const BackButton = styled.button`
  background: rgba(24, 29, 47, 0.7);
  border-radius: 14px;
  background-image: url(${angleIcon});
  background-size: cover;
  transform: rotate(90deg);

  width: 40px;
  height: 40px;
`

const DataContainer = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  position: relative;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 600px) {
    padding: 16px;
  }
`

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
`

const ContainerText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #777F99;
  padding-bottom: 18px;
`

const HeaderText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  position: relative;
  display: flex;
  min-height: 30px;
  width: 50%;
  align-items: center;
`

const HeaderTextGreen = styled.h1`
  position: relative;
  width: 50%;
  min-height: 30px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #0BD98F;
`


const ExchangeHeaderButton = styled.button`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #7F7D97;
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #1D2336;

  &.active {
    color: var(--color-blue-accent);
    border-bottom: 2px solid var(--color-blue-accent);
  }
`

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 20px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;


const SettingAnim = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(60deg); }
`

const SettingButton = styled.button`
  width: 24px;
  height: 35px;
  margin-left: 8px;
  padding: 0;

  :disabled{
    opacity: 0.5;
    cursor: not-allowed;

  }

  :hover {
    opacity: 0.5;
    animation: ${SettingAnim} 0.5s forwards;
  }
`;

const MuteIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;
`;

const DataContainerColumnMod = styled(DataContainerColumn)`

  @media (max-width: 500px) {
    margin-bottom: 10px;
  }

`
const ErrorText = styled(ModalText)`
  margin-top: 15px;
  color: rgb(255, 95, 21);
  margin-bottom: 15px;
`;

const BondButton = styled(Button)`
  &.impact{
    border: 1px solid #cc3300;
    background:  rgb(255, 95, 21);
  }
`;