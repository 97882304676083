import React from 'react'
import styled from 'styled-components';
import { EmptyWidgetText, Widget } from '../../../common/widgets/Widget'
import { HistoryList } from './HistoryList'

export const HistoryWidget = ({history}) => {
  return (
    <View
      title="Recent transactions"
    >
      {true
        ? <HistoryList history={history}/>
        : <EmptyWidgetText>No transactions.</EmptyWidgetText>
      }
    </View>
  )
}

const View = styled(Widget)`
  grid-area: history;
  padding-right: 16px;
  min-height: 420px;
  max-height: 420px;
  height: 420px;
  display: flex;
  flex-direction: column;
  box-shadow: none;
`;
