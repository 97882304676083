import React from 'react'
import styled from "styled-components";
import { Row } from "../../../common/Row";
import { TextGrey, TextMedium } from "../../../common/text/Text";
import { Asset } from "../../../model/Asset";
import BigNumber from "bignumber.js";

interface ModalFooterProps {
  selectedAssetA: Asset | undefined;
  selectedAssetB: Asset | undefined;
  assetAmountA: string;
  assetAmountB: string;
  share: string;
}

export const LiquidityModalFooter = ({selectedAssetA, selectedAssetB, assetAmountA, assetAmountB, share}: ModalFooterProps) => (
  <>
    <Row>
      <TextGrey>Share of Pool</TextGrey>
      <TextMedium>{share}%</TextMedium>
    </Row>
    <Row>
      <TextGrey>Network Fees</TextGrey>
      <TextMedium>None</TextMedium>
    </Row>
  </>
)


const StyledRow = styled(Row)`
  justify-content: flex-end;
`;
