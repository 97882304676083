import { useState } from 'react'
import styled from 'styled-components'
import { BasicModalProps, Modal } from '../../../common/modal/Modal'
import { ModalButton, ModalText } from '../../../common/modal/ModalComponents'
import { AssetSection } from '../../../common/section/AssetSection'
import { ChartSymbol } from '../../../common/charts/ChartSymbol'
import Tooltip from '@mui/material/Tooltip';

import { Asset } from '../../../model/Asset'
import plus from '../../../assets/images/icons/plus.svg'
import {LoadingAnimation} from '../../../common/loading/loading'

enum ModalType {
  AssetsListA,
  AssetsListB
}
interface AddLiquidityModalProps extends BasicModalProps {
  selectedAssetA: Asset | undefined;
  selectedAssetB: Asset | undefined;
  assetAmountA: string;
  assetAmountB: string;
  assets: Asset[];
  setAssetAmountA: (amount: string) => void;
  setAssetAmountB: (amount: string) => void;
  onSupplyButtonClick: () => void;
  setSelectedAssetA: (asset: Asset) => void;
  setSelectedAssetB: (asset: Asset) => void;
  disableButton: boolean;
  disableEdit: boolean;
  calculating: boolean;
  stable: boolean;
  setStable: (amount: boolean) => void;

}

export const AddLiquidityModal = ({
  isVisible,
  onClose,
  assets,
  onSupplyButtonClick,
  setSelectedAssetA,
  setSelectedAssetB,
  selectedAssetA,
  selectedAssetB,
  assetAmountA,
  setAssetAmountA,
  assetAmountB,
  setAssetAmountB,
  disableButton,
  disableEdit,
  calculating,
  stable,
  setStable
}: AddLiquidityModalProps) => {

  const [modal, setModal] = useState<ModalType | null>();

  return (
  <>
    <ModalSecondary isVisible={isVisible} onClose={onClose} title="Add liquidity">
      <Text>
        Add liquidity to a pre-existing liquidity pair. The ratio of tokens you add
        depends on the market value of the pair.
      </Text>
      <AssetSection
        selectedAsset={selectedAssetA}
        label="Input"
        assets={assets}
        onAssetSelect={setSelectedAssetA}
      />
      <Icon src={plus} alt="plus" />
      <AssetSection
        selectedAsset={selectedAssetB}
        label="Input"
        assets={assets}
        onAssetSelect={setSelectedAssetB}
      />
      <PoolTypeDiv>
        <PoolTypeButtonLeft id='vol' className={stable == false ? 'active' : ''} onClick={() => setStable(false)}>
          Normal
          <ChartSymbol stable={false}/>
        </PoolTypeButtonLeft>

        <PoolTypeButtonRight id='stable' className={stable == true ? 'active' : ''} onClick={() => setStable(true)}>
          Stable
          <ChartSymbol stable={true}/>

        </PoolTypeButtonRight>


      </PoolTypeDiv>

      {/* 
      <StyledTooltip 
        anchorId="stable"
        place="bottom"
        content="Stable pools are for tightly concentrated liquidity, typically stablecoins."
      />

      <StyledTooltip
        anchorId="vol"
        place="bottom"
        content="Normal pools are traditional AMM curves, for most pairs."
      />
    
      */}


      {stable == true ? <ErrorText>Warning: Stable pools should strictly be used for assets that trade at a 1:1 value. For regular tokens, use the normal pools instead.</ErrorText> :<></>}

      <ModalButton
        onClick={onSupplyButtonClick}
        disabled={!(selectedAssetA && selectedAssetB)}
      >
        <LoadingAnimation isLoading={calculating}/>
        {!(selectedAssetA && selectedAssetB) ? 'Invalid selection' : 'Manage'}
      </ModalButton>
    </ModalSecondary>
  </>
)};


const StyledTooltip = styled(Tooltip)`
  max-width: 250px;
  white-space: normal;
  background: black;
  opacity: 1;

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

`

const Icon = styled.img`
  display: block;
  width: 24px;
  height: 24px;
  margin: 24px auto;
`;

const PoolTypeDiv = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
`

const PoolTypeButtonLeft = styled.button`
  display: flex;
  justify-content: space-between;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;

  align-items: center;
  flex-direction: row;
  width: 120px;
  color: white;
  height: auto;
  background-color: var(--color-modal-light-blue);
  border: 1px solid var(--color-modal-light-blue);
  border-radius: 16px;

  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  &.active {
    color: var(--color-blue);
    color: white;
    background-color: var(--color-blue);
    border: 1px solid var(--color-blue);
  }

  :hover:not(.active) {
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
  }

`

const PoolTypeButtonRight = styled(PoolTypeButtonLeft)`
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
`

const ModalSecondary = styled(Modal)`
  background-color: var(--color-dark-blue);
`

const ErrorText = styled(ModalText)`
  margin-top: 15px;
  color: rgb(255, 95, 21);
  margin-bottom: 15px;
`;

const Text = styled(ModalText)`
  margin-bottom: 16px;
`;
