import React, { useState, useRef } from 'react'
import styled from 'styled-components';
import {useHistory} from "react-router-dom";
import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';
import { RemoveLiquidity } from './LiquidityPoolModals/RemoveLiquidity';
import { LiquidityRewardsWidget } from './LiquidityRewardsWidget';
import { AllAmplifiersWidget } from './AllAmplifiersWidget';
import { YourLiquidityWidget } from './YourLiquidityWidget';
import { WalletState } from '../../state'
import { useWalletSelector } from '../../state/hooks';
import BigNumber from 'bignumber.js';

export const AmplifierScreen = () => {
  let history = useHistory()

  const [isLoading, setIsLoading] = useState(true);

  const activeAmplifiers = useWalletSelector((state: WalletState) => state.amplifiers)
  const activeAmplifiersV2 = useWalletSelector((state: WalletState) => state.amplifiersv2)

  const [activePairs, setActivePairs] = useState<any>([]);

  const tokens = useWalletSelector((state: WalletState) => state.tokens)

  const manageLiquidity = (poolId, v2) => {
    if(v2)
      history.push(`/amplifierv2/${poolId}`)
    else
      history.push(`/amplifier/${poolId}`)
  }

  React.useEffect(() => {
    if(activeAmplifiers.length > 0 && activeAmplifiersV2.length > 0){
      setIsLoading(false)

      let _temp: any[] = JSON.parse(JSON.stringify(activeAmplifiersV2))
      for(let i in activeAmplifiers){
        if(new BigNumber(activeAmplifiers[i].endTime).lt(Date.now() / 1000) && new BigNumber(activeAmplifiers[i].totalUserStake).lte(0))
          continue
        
        _temp.push(activeAmplifiers[i])
      }
    
      setActivePairs(_temp)
      
    }

    return () => {}

  }, [activeAmplifiers, activeAmplifiersV2]);




  return (
    <Screen>
      <Layout>
        <Title>Amplifier</Title>
        <RemoveLiquidity externalOpen={() => {}} approveClick={() => {}} onWithdrawClick={() => {}} />
        <YourLiquidityWidget title={'Active Farms'} ownedPairs={activePairs} manageLiquidity={manageLiquidity} isLoading={isLoading}/>
        <AllAmplifiersWidget title={'Expired Farms'} ownedPairs={activeAmplifiers} manageLiquidity={manageLiquidity} isLoading={isLoading}/>

        <LiquidityRewardsWidget isVisible={false} onClose={() => {}}/>
      </Layout>
    </Screen>
  )
}
// <CreatePair externalOpen={createLiqModal} assets={tokens} createPair={addLiquidity} importPool={importPool} addLiquidity={addLiquidityScreenPopup}/>
// <ImportPool assets={tokens} importPool={importPool} addPool={addPool} />
// <LiquidityHistory history={liquidity.history}/>

const Layout = styled(Grid)`
  grid-template-areas:
  "title buttons"
  "yourLiquidity yourLiquidity "
  "allAmplifiers allAmplifiers "
  "description description";

  @media (max-width: 767px) {
    grid-template-areas: "title" "buttons" "yourLiquidity" "allAmplifiers" "description";
  }
`;
