import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { load, save } from 'redux-localstorage-simple'


import { createAction } from '@reduxjs/toolkit'
import {walletSlice} from './reducer'


// fired once when the app reloads but before the app renders
// allows any updates to be applied to store data loaded from localStorage
const updateVersion = createAction<void>('global/updateVersion')

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists', 'tokens']

export const store = configureStore({
  reducer: walletSlice.reducer,
  enhancers: (defaultEnhancers) => defaultEnhancers,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ thunk: true })
})

//store.dispatch(updateVersion())

setupListeners(store.dispatch)

export type WalletState = ReturnType<typeof store.getState>
export type WalletDispatch = typeof store.dispatch