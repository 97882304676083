import styled from 'styled-components';
import { GradientButton } from './GradientButton';

export const AccentButton = styled(GradientButton)`
  height: 27px;
  width: fit-content;
  padding: 5px 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 100%;
  color: var(--color-blue);
  background: var(--color-blue);
  background: #29304A;
  border: 1px solid var(--color-blue);

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  :disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
