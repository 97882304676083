import React, { ReactNode, useEffect } from 'react'
import { Loader } from '../Loader'
import { Modal } from './Modal'
import { IconWrapper, ModalDescription } from './ModalComponents'
import { ButtonPrimaryLarge } from '../buttons/Button'

import styled from 'styled-components'

export interface UpgradeDMuteModalProps {
  onSubmit: () => void;
  isVisible: boolean;
  onClose: () => void;
}

export const UpgradeDMuteModal = ({
  isVisible,
  onClose,
  onSubmit
}: UpgradeDMuteModalProps) => {
  useEffect(() => {
   // const timer = setTimeout(() => isVisible && onConfirm(), 100);
    return () => {} //clearTimeout(timer);
  }, [isVisible]);

  return (
    <Modal title={"Upgrade dMute to veMute"} isVisible={isVisible} onClose={onClose}>
      <Footer></Footer>
      <IconWrapper>
        <ModalDescription>
            This function can only be called once with your wallet, and will only upgrade locks that have not expired yet.
            <br/>
            You will receive a veMute NFT for each dMute lock you own. However, once your dMute lock is expired, you will need to redeem your underlying mute from the dMute lock. 
            Your converted veMute NFTs will have 0 underlying Mute, but will allow you to participate in governance as everything else will be the same. 
        </ModalDescription>
      </IconWrapper>

      <Button onClick={onSubmit}>
        I understand
      </Button>

    </Modal>
  );
};

const Line = styled.div`
width: 100%;
height: 0px;
margin-top: 25px;
margin-bottom: 25px;
border: 1px solid #262436;

`
const Footer = styled.div`
  margin-top: 12px;
  padding-bottom: 25px;
`;

const Icon = styled.img`
  margin-top: auto;
  margin-bottom: 6px;
  width: 24px;
  height: 24px;
`;

const TokenLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const TokenWrapper = styled.div`
  display: flex;
  height: auto;
  color: white;
  font-size: 18px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;


const MainWrapper = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-around;
`;

const Title = styled.p`
font-family: 'Aeonik';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;

color: #FFFFFF;

`


const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;
