var MUTE_TOKEN_LIST: any = [
  {
    "chainId": 324,
    "address": "0x0000000000000000000000000000000000000000",
    "name": "Ethereum",
    "symbol": "ETH",
    "decimals": 18,
    "logoURI": "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png"
  },
  {
    "chainId": 324,
    "address": "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
    "name": "Wrapped ETH",
    "symbol": "WETH",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/2518/small/weth.png?1628852295"
  },
  {
    "chainId": 324,
    "address": "0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
    "name": "USD Coin",
    "symbol": "USDC",
    "decimals": 6,
    "logoURI": "https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png?1547042389"
  },
  {
    "chainId": 324,
    "address": "0x493257fD37EDB34451f62EDf8D2a0C418852bA4C",
    "name": "Tether USD",
    "symbol": "USDT",
    "decimals": 6,
    "logoURI": "https://assets.coingecko.com/coins/images/325/standard/Tether.png?1696501661"
  },
  {
    "chainId": 324,
    "address": "0xa995ad25ce5eb76972ab356168f5e1d9257e4d05",
    "name": "Koi",
    "symbol": "KOI",
    "decimals": 18,
    "logoURI": "https://preview.koi.finance/static/media/koi.b19d3d1ff21069b85c9a.png"
  },
  {
    "chainId": 324,
    "address": "0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42",
    "name": "Mute",
    "symbol": "MUTE",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/14331/small/MUTE.png?1617618967"
  },
  {
    "chainId": 324,
    "address": "0xBBeB516fb02a01611cBBE0453Fe3c580D7281011",
    "name": "Wrapped BTC",
    "symbol": "WBTC",
    "decimals": 8,
    "logoURI": "https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png?1548822744"
  },
  {
    "chainId": 324,
    "address": "0xc8Ec5B0627C794de0e4ea5d97AD9A556B361d243",
    "name": "Whisper",
    "symbol": "WISP",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/29650/small/wisp_200.png?1681725709"
  },
  {
    "chainId": 324,
    "address": "0x27fb794E553DaBC2e99F6fC03F0a4B6B9C2f0E76",
    "name": "dMute",
    "symbol": "DMUTE",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/14331/small/MUTE.png?1617618967"
  },
  {
    "chainId": 324,
    "address": "0xbFB4b5616044Eded03e5b1AD75141f0D9Cb1499b",
    "name": "zkDoge",
    "symbol": "ZKDOGE",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/zkdoge.png"
  },
  {
    "chainId": 324,
    "address": "0x8aAAF6E6167825829Ab29F260f246AFE742FB243",
    "name": "ZKCULT",
    "symbol": "zCULT",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/ZKCULT.png"
  },
  {
    "chainId": 324,
    "address": "0x45559297BdEDf453e172833AC7086f7D03f6690B",
    "name": "ZK INU",
    "symbol": "$ZKINU",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/ZKINU.png"
  },
  {
    "chainId": 324,
    "address": "0xa51bc2433a33c448dd40f7074bcab751a1922706",
    "name": "ZKInu",
    "symbol": "ZINU",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/zinu.png"
  },
  {
    "chainId": 324,
    "address": "0x7C9becB53A7702244Ca260Fd01B4748756CE7BB3",
    "name": "ZKRISE",
    "symbol": "ZKRISE",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/zkrise.png"
  },
  {
    "chainId": 324,
    "address": "0x959aB3394246669914BdDEAeB50f8Ac85648615e",
    "name": "zkLaunchpad",
    "symbol": "ZKPAD",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/zklaunchpad.png"
  },
  {
    "chainId": 324,
    "address": "0x8d266fA745b7Cf3856AF0c778828473B8d33a149",
    "name": "zkFloki",
    "symbol": "ZFLOKI",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/ZFLOKI.png"
  },
  {
    "chainId": 324,
    "address": "0xF42d97Dbff4AdC5fe32966338C306859A5766511",
    "name": "ZoKe Inu",
    "symbol": "ZKINU",
    "decimals": 9,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/ZoKeInu.png"
  },
  {
    "chainId": 324,
    "address": "0x5e38CB3e6c0faaFAa5C32C482864fCef5A0660Ad",
    "name": "Zksync Shib",
    "symbol": "ZKSHIB",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/zkshib.png"
   },
   {
    "chainId": 324,
    "address": "0xC8Ac6191CDc9c7bF846AD6b52aaAA7a0757eE305",
    "name": "Metavault Trade",
    "symbol": "MVX",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/mvx.png"
   },
   {
    "chainId": 324,
    "address": "0xDea6d5161978d36b5C0FA6a491faA754f4BC809C",
    "name": "Idexo Token",
    "symbol": "IDO",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/idexo.png"
  },
  {
    "chainId": 324,
    "address": "0x503234F203fC7Eb888EEC8513210612a43Cf6115",
    "name": "Liquity USD",
    "symbol": "LUSD",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/lusd.png"
  },
  {
    "chainId": 324,
    "address": "0x89F69aD54e5D05A7A7A1314ecBc7d56D965D3020",
    "name": "Wojak Coin Zk",
    "symbol": "$WOJAK",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/Wojak.png"
  },
  {
    "chainId": 324,
    "address": "0x61b41e0244133b9c9c47a57e51a5ef70be2c5dd4",
    "name": "Bolt",
    "symbol": "BOLT",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/bolt.png"
  },
  {
    "chainId": 324,
    "address": "0xA4C00e85ceBc898e885F5055171dc424dbA8bF45",
    "name": "Panda",
    "symbol": "PANDA",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/panda.png"
  },
  {
    "chainId": 324,
    "address": "0x8E817eEe4A987EAd05d03cCACb354F67b0DBd63d",
    "name": "zkINA",
    "symbol": "ZKINA",
    "decimals": 18,
    "logoURI": "https://github.com/muteio/token-directory/raw/main/icons/zkina.png"
  },
  {
    "chainId": 324,
    "address": "0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557",
    "name": "USD+",
    "symbol": "USD+",
    "decimals": 6,
    "logoURI": "https://assets.coingecko.com/coins/images/25757/small/USD__logo.png?1653519267"
  },
  {
    "chainId": 324,
    "address": "0xc76997c863bac0f6e4b6d3c2404b06013a0b29e2",
    "name": "HeartX Utility Token",
    "symbol": "HNX",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/30531/small/HNX_icon.png?1684890258"
  },
  {
    "chainId": 324,
    "address": "0xe593853b4d603d5b8f21036Bb4AD0D1880097a6e",
    "name": "Fulcrom",
    "symbol": "FUL",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/29684/small/FUL_Token_%281%29.png?1680600867"
  },
  {
    "chainId": 324,
    "address": "0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656",
    "name": "Dai",
    "symbol": "DAI",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/9956/standard/Badge_Dai.png?1696509996"
  },
  {
    "chainId": 324,
    "address": "0x244C238325fC1Bdf6EdeD726EE1b47d55895D944",
    "name": "Zorro",
    "symbol": "ZORRO",
    "decimals": 18,
    "logoURI": "https://www.zorro.gg/static/media/logo192.94114007fca31c37dc85.png"
  },
  {
    "chainId": 324,
    "address": "0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2",
    "name": "Holdstation",
    "symbol": "HOLD",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/32726/standard/HOLD.png?1699018663"
  },
  {
    "chainId": 324,
    "address": "0x703b52F2b28fEbcB60E1372858AF5b18849FE867",
    "name": "Wrapped Staked ETH",
    "symbol": "wstETH",
    "decimals": 18,
    "logoURI": "https://assets.coingecko.com/coins/images/18834/standard/wstETH.png?1696518295"
  },
]


export default MUTE_TOKEN_LIST