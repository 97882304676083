import { useEffect, useState} from 'react'
import { Modal } from '../../common/modal/Modal'
import styled from 'styled-components'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { VeDelegateModal } from './veDelegateModal'
import { formatNumber } from '../../utils/formatNumber'
import { formatDate } from '../../utils/formatDate'
import BigNumber from 'bignumber.js'
import { AddressSection } from '../../common/section/AddressSection'
import EditIcon from '@mui/icons-material/Edit';
import { ButtonPrimaryLarge } from '../../common/buttons/Button'
import { reduceString } from '../../utils/reduceString'
import { LoadingAnimation } from '../../common/loading/loading'

export interface ConfirmTransactionModalProps {
  isVisible: boolean;
  onClose: () => void;
  onChangeDelegate: (val: string) => void;
  id: string;
  info: any;
  onRedeem: (val: string) => void;
  onConvert: (val: string) => void;
  delegateInfo: string;
  setDelegateInfo: (val: string) => void;
}

export const VeKoiLockModal = ({
  isVisible,
  onClose,
  onChangeDelegate,
  id,
  info,
  onRedeem,
  onConvert,
  delegateInfo,
  setDelegateInfo
}: ConfirmTransactionModalProps) => {
    
    const [calculating, setCalculating] = useState(false)
    const [showEdit, setShowEdit] = useState(false)

  useEffect(() => {
   // const timer = setTimeout(() => isVisible && onConfirm(), 100);
    return () => {} //clearTimeout(timer);
  }, [isVisible]);

  const redeem = async () => {
    if(shouldDisableRedeem())
      return
    setCalculating(true)
    await onRedeem(info.index)
    setCalculating(false)
  }

  const convert = async () => {
    setCalculating(true)
    await onConvert(info.id)
    setCalculating(false)
  }

  const shouldDisableRedeem = () => {
    var curDate = new BigNumber(Date.now()).div(1000)

    if(curDate.lt(info.time)){
      return true
    }

    return false
  }

  return (
    <>

    <VeDelegateModal
        isVisible={showEdit}
        onClose={() => {setDelegateInfo(info.delegate); setShowEdit(false)}}
        onConfirm={async() => {await onChangeDelegate(delegateInfo)}}
        validText={true}
        onChange={(e) => {setDelegateInfo(e)}}
        originalDelegate={info.delegate}
        address={delegateInfo}
        id={id}
    
    />
    <Modal title={"veKOI Token #" + id} isVisible={isVisible} onClose={onClose}>
        <Header/>
        <StatRow>
                <StatText>
                Vote Shares
                </StatText>
                <StatTextHighlight>
                    {formatNumber(new BigNumber(info.vote_weight).toFixed(4), 2) + ' shares'}
                </StatTextHighlight>
            </StatRow>
            <StatRow>
                <StatText>
                Underlying KOI Amount
                </StatText>
                <StatTextHighlight>
                {formatNumber(new BigNumber(info.amount).toFixed(4), 2) + ' KOI'}
                </StatTextHighlight>
            </StatRow>
            <StatRow>
                <StatText>
                Expiry Date
                </StatText>
                <StatTextHighlight>
                {formatDate(new BigNumber(info.time).times(1000).toNumber())}
                </StatTextHighlight>
            </StatRow>
            <StatRow>
                <StatText>
                Current delegate address  <EditIcon onClick={() => {setShowEdit(true)}} fill="white" style={{color: 'white', padding: '4px', cursor: 'pointer'}}/>
                </StatText>
                <StatTextHighlightLink href={('https://explorer.zksync.io/address/' + info.delegate)} target='_blank'>
                {reduceString(info.delegate, 7, 4)}
                
                </StatTextHighlightLink>
            </StatRow>

            <Button onClick={() => redeem()} disabled={shouldDisableRedeem() || calculating}>
                <LoadingAnimation isLoading={calculating} />
                {shouldDisableRedeem() == true ? 'Locked' : 'Unlock'}
            </Button>
    </Modal>
    
    </>

  );
};

const Line = styled.div`
width: 100%;
height: 0px;
margin-top: 25px;
margin-bottom: 25px;
border: 1px solid #262436;

`
const Header = styled.div`
  margin-bottom: 20px;
  border: 1px solid #262436;
  height: 0px;
`;

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`

const StatText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7F7D97;
`

const StatTextHighlightLink = styled.a`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F6F8FE;
  :hover {
    color: var(--color-blue);
  }
`

const StatTextHighlight = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F6F8FE;
`

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;
