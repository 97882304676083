import React from 'react'
import styled from 'styled-components'
import { Amount } from '../../common/text/Amount'
import { Widget } from '../../common/widgets/Widget'
import bannerImage from '../../assets/images/icons/wallet_banner.png'
import blue_checkmark from '../../assets/images/icons/blue_checkmark.png'

export const WalletBannerWidget = () => {

  return (
    <View title="">
      <BannerImage src={bannerImage}/>

      <BannerTextWrapper>
        <BannerTextHeading>
          How does your layer 2 wallet work?
        </BannerTextHeading>
        <BannerTextContent>
          Mute Switch is a Layer 2 Dex based on zk-Rollup technology.
          You first need to deposit assets from your Layer 1 ETH wallet your Layer 2 zkSync wallet to experience gas-free
          and real-time token transfer and swap.
        </BannerTextContent>
        <BannerTextWrapperTwo>
        <BannerTextContent>
         <BannerCheck src={blue_checkmark}/> Have total control over the assets through private keys
        </BannerTextContent>
        <BannerTextContent>
         <BannerCheck src={blue_checkmark}/> Your assets are safe inside a verifiable zkRollup.
        </BannerTextContent>
        <BannerTextContent>
         <BannerCheck src={blue_checkmark}/> Withdraw assets to the Layer 1 wallet at any time.
        </BannerTextContent>
        </BannerTextWrapperTwo>
      </BannerTextWrapper>
    </View>
  )
}

const View = styled(Widget)`
  grid-area: banner;
  background-color: #02040F;
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 280px;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
`;

const AmountAligned = styled(Amount)`
  text-align: left !important;
`;

const BannerImage = styled.img`
  position: absolute;
  top: 0;bottom: 0;
  height: 100%;
  right: 0%;
  z-index: 0;
`
const BannerCheck = styled.img`
  width: 20px;
  height: 20px;
  margin: 5px;
  margin-left: 0;
`

const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  z-index: 2;


  justify-content: space-between;

  @media (max-width: 767px) {
    width: 100%;
  }

`

const BannerTextHeading = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;

  color: #FFFFFF;
`

const BannerTextContent = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  display: flex;
  align-items: center;

  color: #777F99;
`

const BannerTextWrapperTwo = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 10px;
  justify-content: space-around;

`
