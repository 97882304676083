import React, { useState } from 'react'
import styled, { css } from 'styled-components';
import { AmountInput } from '../AmountInput'
import { GradientButton } from '../buttons/GradientButton';
import { SectionWithBorder, SectionRow, SectionText } from './Section'
import { Asset } from '../../model/Asset';
import angleIcon from '../../assets/images/icons/angle-light.svg'
import angleBrightIcon from '../../assets/images/icons/angle-bright.svg'
import { SymbolWithLogo } from '../SymbolWithLogo';
import { MaxButton } from '../buttons/MaxButton';
import { AssetsListModal } from '../modal/AssetsListModal';

interface AmountSectionProps {
  selectedAsset: Asset | undefined;
  assets: Asset[];
  onAssetSelect: (asset: Asset) => void;
  label?: string;
  className?: string;
}

export const AssetSection = ({
  selectedAsset,
  className,
  label,
  assets,
  onAssetSelect
}: AmountSectionProps) => {

  const [isAssetsModalVisible, setIsAssetsModalVisible] = useState(false);

  return (
    <SectionWithBorder className={className}>
      <SectionRow>
        {selectedAsset === undefined ? (
          <SelectTokenButton onClick={() => setIsAssetsModalVisible(true)}>
            Select token
          </SelectTokenButton>
        ) : (
            <SelectedAssetButton onClick={() => setIsAssetsModalVisible(true)}>
              <SymbolWithLogo
                logo={selectedAsset.logo}
                symbol={selectedAsset.symbol}
              />
            </SelectedAssetButton>
        )}
      </SectionRow>
      <AssetsListModal
        isVisible={isAssetsModalVisible}
        onClose={() => setIsAssetsModalVisible(false)}
        assets={assets}
        setSelectedAsset={onAssetSelect}
      />
    </SectionWithBorder>
  );
};

const angleIconStyles = css`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 10px;
    height: 5px;
    transform: translateY(-50%);
  }
`

const SelectTokenButton = styled.button`
  width: 100%;
  color: white;
  height: 100%;
  text-align: left;

  ${angleIconStyles}

  &::after {
    right: 27.5px;
    background: url(${angleIcon}) center no-repeat;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
`;

const SelectedAssetButton = styled.button`
  position: relative;
  width: 100%;
  text-align: right;

  ${angleIconStyles}

  &::after {
    right: 5px;
    background: url(${angleBrightIcon}) center no-repeat;
  }
`;
