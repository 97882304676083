import React from 'react'
import { ModalSection } from './modal/ModalComponents'
import { TextSemiBold } from './text/Text'
import arrowIcon from '../assets/images/icons/arrow.svg'
import styled from 'styled-components'

export interface TransactionDirectionProps {
  leftValue: string;
  rightValue: string;
  leftIcon?: string;
  rightIcon?: string;
  icon?: string;
}

export const TransactionDirection = ({icon, leftValue, rightValue, leftIcon, rightIcon}: TransactionDirectionProps) => (
  <ModalSection>
    <MainWrapper>
      <Title>Send</Title>
      <TokenWrapper>
        <TokenLogo src={leftIcon}/>
        <TextSemiBold>{leftValue}</TextSemiBold>
      </TokenWrapper>
    </MainWrapper>
    <Icon src={icon ? icon : arrowIcon} alt="arrow" />
    <MainWrapperSecond>
      <Title>Get</Title>
      <TokenWrapper>
        <TokenLogo src={rightIcon}/>
        <TextSemiBold>{rightValue}</TextSemiBold>
      </TokenWrapper>
    </MainWrapperSecond>
  </ModalSection>
)

const Icon = styled.img`
  margin-top: auto;
  margin-bottom: 6px;
  width: 24px;
  height: 24px;
`;

const TokenLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const TokenWrapper = styled.div`
  display: flex;
  height: auto;
`;


const MainWrapper = styled.div`
  display: flex;
  width: 50%;
  height: 100%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-around;
`

const MainWrapperSecond = styled(MainWrapper)`
  padding-left: 30px;
`;

const Title = styled.p`
font-family: 'Aeonik';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;

color: #FFFFFF;

`
