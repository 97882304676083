import styled from 'styled-components';

export const Badge = styled.p`
  display: inline-block;
  padding: 2px 14px;
  font-weight: 500;
  font-size: 10px;
  line-height: 150%;
  color: #FFFFFF;
  background: linear-gradient(116.23deg, #1D2337 5.44%, #193696 123.05%);
  border-radius: 6px;
`;
