import React, { useState } from 'react'
import styled from 'styled-components';
import { TextMedium } from '../../common/text/Text';
import { EmptyWidgetText, Widget } from '../../common/widgets/Widget';
import { ListItem } from '../../common/ListItem';
import { SymbolWithLogo } from '../../common/SymbolWithLogo';
import BigNumber from 'bignumber.js'
import arrow_right from '../../assets/images/icons/arrow_right.png'
import { formatNumber } from '../../utils/formatNumber';
import { useWalletHook } from '../../web3/walletHook'
import { SymbolWithDualLogo } from '../../common/SymbolWithDualLogo';


export const L2AssetsListWidget = ({assets, liq}) => {
  const { addTokenToWallet, getTokenFromContract } = useWalletHook()
  const [hideZero, setHideZero] = useState(true);
  const [orderedAssets, setOrderedAssets] = useState({})

  React.useEffect(() => {
    let _assets = JSON.parse(JSON.stringify(assets))

    for(let i in liq){
      let _tokenA = getTokenFromContract(liq[i].assetA)
      let _tokenB = getTokenFromContract(liq[i].assetB)

      let assetAPrice = _tokenA && _tokenA.price ? _tokenA.price : '0'
      let assetBPrice = _tokenB && _tokenB.price ? _tokenB.price : '0'

      _assets['lp_' + String(i)] = {
        ...liq[i],
        assetAPrice,
        assetBPrice,
        assetA: _tokenA,
        assetB: _tokenB
      }
    }

    const ordered = Object.entries(_assets).sort((a: any,b: any) => {
      let _aPrice = 0;
      let _bPrice = 0;
      if(a[1].hasLiq == true){
        _aPrice = new BigNumber(a[1].assetAAmount).times(a[1].assetAPrice).plus(new BigNumber(a[1].assetBAmount).times(a[1].assetBPrice)).toNumber()
      } else {
        _aPrice = new BigNumber(a[1].balance).times(a[1].price).toNumber()
      }

      if(b[1].hasLiq == true){
        _bPrice = new BigNumber(b[1].assetAAmount).times(b[1].assetAPrice).plus(new BigNumber(b[1].assetBAmount).times(b[1].assetBPrice)).toNumber()
        //_bPrice = new BigNumber(b[1].assetAAmount).times(b[1].assetAPrice).times(2).toNumber()
      } else {
        _bPrice = new BigNumber(b[1].balance).times(b[1].price).toNumber()
      }      

      return  _bPrice - _aPrice;
    })
  
    let _orderedAssets = {}

    for(let i in ordered){
      _orderedAssets[ordered[i][0]] = ordered[i][1]
    }


    setOrderedAssets(_orderedAssets)
  
    return () => {};
  }, [assets, liq]);

  return (
    <View
      title="Your L2 Assets"
      toggle={true}
      setToggleVal={(e) => {setHideZero(e.target.checked)}}
      toggleVal={hideZero}
    >
      {true
        ? (
          <>
            <List isExpanded={true}>
              {Object.keys(orderedAssets).map((asset, index) => 
                {
                  
                  if(hideZero == true && Number(orderedAssets[asset].balance) == 0)
                    return

                  if(orderedAssets[asset].hasLiq == true){
                    if(orderedAssets[asset].assetB.logo && orderedAssets[asset].assetA.logo)
                    return (
                      <>
                      <LI
                        key={index}
                        onClick={() => {addTokenToWallet({
                          symbol: orderedAssets[asset].symbol.slice(0,4) + '(' + orderedAssets[asset].assetA.symbol.slice(0,1) + '/' + orderedAssets[asset].assetB.symbol.slice(0,1) + ')',
                          address: orderedAssets[asset].address,
                          decimals: 18,
                          logo: ''
                        })}}
                      >
                        <SymbolWithDualLogo
                          logoA={orderedAssets[asset].assetA.logo}
                          logoB={orderedAssets[asset].assetB.logo}
                          symbol={orderedAssets[asset].symbol}
                        />
      
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <AssetValues>
                          <TextMedium>{formatNumber(orderedAssets[asset].balance, 3)}</TextMedium>
                          <AssetPrice>{'$' + formatNumber(new BigNumber(orderedAssets[asset].assetAAmount).times(orderedAssets[asset].assetAPrice).times(2), 2)}</AssetPrice>
                        </AssetValues>
      
                        <ArrowRight src={arrow_right}/>
      
      
                        </div>
      
                      </LI>
                      <SeperatorLine/>
                      </>
                    )
                    else 
                    return 
                  } else {
                    return (
                      <>
                      <LI
                        key={index}
                        onClick={() => {addTokenToWallet(orderedAssets[asset])}}
                      >
                        <SymbolWithLogo
                          logo={orderedAssets[asset].logo}
                          symbol={orderedAssets[asset].symbol}
                        />
      
                        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center'}}>
                        <AssetValues>
                          <TextMedium>{formatNumber(orderedAssets[asset].balance, 3)}</TextMedium>
                          <AssetPrice>{'$' + formatNumber(new BigNumber(orderedAssets[asset].balance).times(orderedAssets[asset].price), 2)}</AssetPrice>
                        </AssetValues>
      
                        <ArrowRight src={arrow_right}/>
      
      
                        </div>
      
                      </LI>
                      <SeperatorLine/>
                      </>
                    )
                  }

                }
              )}

            </List>
          </>
        )
        : (
          <>
            <EmptyWidgetText>No assets.</EmptyWidgetText>
          </>
        )
      }
    </View>
  )
}

/*

<BalanceModal
  isVisible={isBalanceModal && selectedAsset}
  onClose={() => setIsBalanceModal(false)}
  selectedAsset={selectedAsset!}
/>

*/

const View = styled(Widget)`
  grid-area: assetsList;
  padding-right: 16px;
  min-height: 420px;
  max-height: 420px;
  height: 420px;
  display: flex;
  flex-direction: column;
  box-shadow: none;

  @media (max-width: 900px) {
    min-height: initial;
  }
`;

interface ListProps {
  isExpanded: boolean;
}

const LI = styled(ListItem)`
  padding: 0;
  padding-top: 2px;
  padding-bottom: 2px;
  border: 0;
  :hover {
    border: 0;
    background: transparent;
    opacity: 0.8;
  }
`;

const SeperatorLine = styled.div`
  width: 100%;
  height: 1px;
  padding-top: 2px;
  padding-bottom: 2px;
  border-bottom: 1px solid #262436;
`

export const List = styled.ul<ListProps>`
  overflow-y: auto;
  height: 100%;
  padding-right: 19px;

`;


export const AssetValues = styled.div`
  text-align: right;
`;


const AssetPrice = styled(TextMedium)`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777F99;
`;

const ArrowRight = styled.img`
  width: 7px;
  height: auto;
  margin-left: 10px;
`
