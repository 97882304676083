import React, { useState, useRef } from 'react'
import styled from 'styled-components';
import {useLocation, useHistory} from "react-router-dom";
import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';
import { RemoveLiquidity } from './LiquidityPoolModals/RemoveLiquidity';
import { LiquidityRewardsWidget } from './LiquidityRewardsWidget';
import { YourLiquidityWidget } from './YourLiquidityWidget';
import { WalletState } from '../../state'
import { useWalletSelector } from '../../state/hooks';

export const BondsScreen = () => {
  let history = useHistory()

  const [isLoading, setIsLoading] = useState(true);

  const removeLiqModal = useRef<any>(null)

  const activeBonds = useWalletSelector((state: WalletState) => state.bondInfo)
  const tokens = useWalletSelector((state: WalletState) => state.tokens)

  React.useEffect(() => {
    setIsLoading(true)

    if(activeBonds.length != 0)
      setIsLoading(false)
    else
      setIsLoading(true)

    return () => {}

  }, [tokens, activeBonds]);


  const approveRemoveLiquidity = async (assetA, assetB) => {
  }

  const manageLiquidity = (poolId) => {
    history.push(`/bonds/${poolId}`)
  }

  const withdrawClick = async (assetA, assetB, amountA, amountB, lpBal, percent, sig) => {
  }



  return (
    <Screen>
      <Layout>
        <Title>Bonds</Title>
        <RemoveLiquidity externalOpen={removeLiqModal} approveClick={approveRemoveLiquidity} onWithdrawClick={withdrawClick} />
        <YourLiquidityWidget ownedPairs={activeBonds} manageLiquidity={manageLiquidity} isLoading={isLoading}/>

        <LiquidityRewardsWidget isVisible={false} onClose={() => {}}/>
      </Layout>
    </Screen>
  )
}
//          <CreatePair externalOpen={createLiqModal} assets={tokens} createPair={addLiquidity} importPool={importPool} addLiquidity={addLiquidityScreenPopup}/>
//          <ImportPool assets={tokens} importPool={importPool} addPool={addPool} />
// <LiquidityHistory history={liquidity.history}/>

const Layout = styled(Grid)`
  grid-template-areas:
  "title buttons"
  "yourLiquidity yourLiquidity "
  "description description";

  @media (max-width: 767px) {
    grid-template-areas: "title" "buttons" "yourLiquidity" "description";
  }
`;
