import React from 'react';
import styled from 'styled-components';
import { Widget } from '../../../common/widgets/Widget';
import { PairsListContent, PairsListProps } from './PairsListContent';

export const PairsListWidget = ({pairs, setToTrade, pairsLoading}: PairsListProps) => {
  return (
    <View>
      <PairsListContent pairs={pairs} setToTrade={setToTrade} pairsLoading={pairsLoading} />
    </View>
  )
}

const View = styled(Widget)`
  grid-area: pairList;
  width: 100%;
  height: 175px;
  max-width: 1000px;
  overflow-x: hidden;
  overflow-y: hidden;
  padding-left: 20px;
  padding-top: 0px;
  padding-bottom: 20px;
  border: 0px;
  background: transparent;
  box-shadow: rgba(0,0,0,0) 0 0 0 !important;
  justify-content: center;
  align-items: center;

  @media (max-width: 700px) {
    margin-right: auto;
    margin-left: auto
  }
`;