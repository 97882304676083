import React from 'react'
import styled from 'styled-components'
import { ExchangeWidget } from './ExchangeWidget/ExchangeWidget'

import { Asset } from '../../model/Asset'

interface Pair {
  token1: Asset;
  token2: Asset;
  price: string;
  stable: any;
}

export const SwapWidget = () => {

  const [tokenA, setTokenA] = React.useState<any>(null)
  const [tokenB, setTokenB] = React.useState<any>(null)

  const externalSetTokens = React.useRef<any>(null)

  const callBackSelect = async (_tokenA, _tokenB) => {
    setTokenA(_tokenA)
    setTokenB(_tokenB)
  }

  return (
      <Container>
        <ExchangeWidget callBackSelect={callBackSelect} externalSetTokens={externalSetTokens} showTrending={false} setShowTrending={() => {}} advancedChart={false} setAdvancedChart={() => {}}/>
      </Container>
  )
}

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  justify-items: center;
  align-items: center;
`;