import React, { useState } from 'react'
import styled from 'styled-components';
import { EmptyWidgetText, Widget } from '../../common/widgets/Widget';
import { Dropdown } from './Dropdown';
//import data from '../../constants/placeholders/yourLiquidity.json';
import { ButtonPrimaryMedium } from '../../common/buttons/Button';
import {LoadingBox} from '../../common/loading/LoadingBox'
import BigNumber from 'bignumber.js'
import { formatNumber } from '../../utils/formatNumber';
import { useAccount } from 'wagmi';

enum Modal {
  Switch,
  Connect,
  Confirm,
  Submitted,
}

/*
type LiquidityPair = {
  value: string;
  assetAImage: string;
  assetBImage: string;
  assetA: any;
  assetB: any;
}

interface YourLiquidityWidgetProps {
  ownedPairs: LiquidityPair[];
  addLiquidity: (LiquidityPair) => any;
  removeLiquidity: (LiquidityPair) => any;
}
*/

export const YourLiquidityWidget = ({manageLiquidity, ownedPairs, isLoading}) => {
  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => setModal(null);
  const {address, isConnected} = useAccount();


  const getWidget = () => {
    if(isConnected == false){
      return <ConnectionWrapper><w3m-button /></ConnectionWrapper>
    }

    if(isLoading){
      return (
        <Dropdown onManageClick={() => {}} icon0={''} icon1={''} token0={''} token1={''} discount={''} percent={0} tbv={'0.00'}>
          <LoadingBox/>
        </Dropdown>
      )
    }

    return (
      <>
      {ownedPairs.map((item, index) => (
        <Dropdown
          onManageClick={() => manageLiquidity(item.id)}
          icon0={item.token0.logo}
          icon1={item.token1.logo}
          token0={item.token0.symbol}
          token1={item.token1.symbol}
          discount={item.apy}
          percent={new BigNumber(item.maxDeposit).div(item.maxPayout).times(100).toNumber()}
          tbv={formatNumber(new BigNumber(item.maxPayout).times(item.token0.price), 2)}
          soldout={item.soldout}
        />
      ))}
      {ownedPairs.length == 0 ?
        <EmptyWidgetText style={{textAlign: 'center'}}>There are currently no active bonding pools</EmptyWidgetText> : <></>
      }
    </>
    )
  }

  return (
    <Container>
    <HeaderTitle>All Bond Pools</HeaderTitle>
    <View
      title=""
    >
    <OverflowWidget>
      {getWidget()}
      </OverflowWidget>
    </View>
    </Container>
  )
}

const Container = styled.div`

grid-area: yourLiquidity;

`
const View = styled(Widget)`
  min-height: 400px;
  max-height: 750px;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--color-dark-bg);
  box-shadow: none;
  border: none;

  @media (max-width: 900px) {
    min-height: initial;
  }
`;

const OverflowWidget = styled.div`
  overflow-y: scroll;
  max-height: 400px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background: transparent;

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-modal-blue);
    border-radius: 16px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white;
  }
`;


const Button = styled(ButtonPrimaryMedium)`
  display: block;
  width: 217px;
  height: 48px;
  margin: 0 auto;
`;

const HeaderTitle = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;

  /* Dark/Dark-200 */

  color: #545368;
`

const ConnectionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  font-size: 16px;
  justify-content: center;
  display: flex;
`;