import React from 'react'
import styled from 'styled-components';
import { BlockTitle } from '../../../common/text/BlockTitle';
import { Text } from '../../../common/text/Text';
import EvStationIcon from '@mui/icons-material/EvStation';

interface ExchangeInfoProps {
  slippage: number
  minReceived: number
  estReceived: number
  recipient: string
  symbol1: string
  symbol2: string
  priceImpact: string
  price: number
  fee: number
  route: string
}

export const ExchangeInfo = ({slippage, recipient, estReceived, minReceived, symbol1, symbol2 ,priceImpact, price, fee, route}: ExchangeInfoProps) => {
  const getSlippageText = () => {
    if(parseFloat(priceImpact) > 12){
      return (
        <ValueRed>{priceImpact}%</ValueRed>
      )
    } else if (parseFloat(priceImpact) > 5){
      return (
        <ValueOrange>{priceImpact}%</ValueOrange>
      )
    } else {
      return (
        <ValueGreen>{priceImpact}%</ValueGreen>
      )
    }
  }

  return (
    <List>
      <ListItem>
        <BlockTitle>Price impact</BlockTitle>
        {getSlippageText()}
      </ListItem>
      <ListItem>
        <BlockTitle>Price</BlockTitle>
        {<Value>1 {symbol2} = {price} {symbol1}</Value>}
      </ListItem>
      <ListItem>
        <BlockTitle>Max. slippage</BlockTitle>
        {<Value>{slippage}%</Value>}
      </ListItem>
      <ListItem>
        <BlockTitle>Route</BlockTitle>
        {<Value>{route}</Value>}
      </ListItem>
      <ListItem>
        <BlockTitle>Recipient</BlockTitle>
        {<Value>{recipient}</Value>}
      </ListItem>
    </List>
  )
}

/*

      <ListItem>
        <BlockTitle>Est. received</BlockTitle>
        {<Value>{estReceived} {symbol2}</Value>}
      </ListItem>

      <ListItem>
          <BlockTitle><EvStationIcon/></BlockTitle>
          <ValueGreen>{fee + '% savings'}</ValueGreen>
      </ListItem>
      */
const List = styled.ul`
  margin-top: 20px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
`;

const Value = styled(Text)`
  display: flex;
  align-items: center;
  color: #F5F5F5;
`;

const ValueGreen = styled(Text)`
  display: flex;
  align-items: center;
  color: #339900;
`;

const ValueOrange = styled(Text)`
  display: flex;
  align-items: center;
  color: #ffcc00;
`;

const ValueRed = styled(Text)`
  display: flex;
  align-items: center;
  color: #cc3300;
`;
