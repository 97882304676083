import React, { useState } from "react";
import styled from "styled-components";
import { Screen } from "../../common/layout/Screen";
import { AssetValueWidget } from "./AssetValueWidget";
import { L2AssetsListWidget } from "./L2AssetsListWidget";
import { WalletBannerWidget } from './WalletBannerWidget'
import { HistoryWidget } from "./TransactionsHistory/HistoryWidget";
import { Grid } from "../../common/layout/Grid";
import { Title } from "../../common/text/Title";
import BigNumber from 'bignumber.js'
import {WalletState} from "../../state";
import { useWalletSelector } from "../../state/hooks";
import { useWalletHook } from "../../web3/walletHook";

export function Layer2WalletScreen() {
  const [totalSaved, setTotalSaved] = useState(0)
  const [l2AssetsValue, setl2AssetsValue] = useState(0)
  const tokens = useWalletSelector((state: WalletState) => state.tokens)
  const history = useWalletSelector((state: WalletState) => state.history)
  const ethPrice = useWalletSelector((state: WalletState) => state.ethPrice)
  const swapCostPerGas = useWalletSelector((state: WalletState) => state.swapCostPerGas)
  const gasFee = useWalletSelector((state: WalletState) => state.gasFee)
  const ownedLiq = useWalletSelector((state: WalletState) => state.ownedPairs)

  const { getTokenFromContract } = useWalletHook()


  React.useEffect(() => {
    var totalValue = new BigNumber(0)
    for(let i in tokens){
      if(tokens[i].balance && tokens[i].price){
        totalValue = totalValue.plus(new BigNumber(tokens[i].price).times(tokens[i].balance))
      }
    }

    for(let i in ownedLiq){
      let _token = getTokenFromContract(ownedLiq[i].assetA)
      if(_token && _token.price && Number(ownedLiq[i].assetAAmount))
        totalValue = totalValue.plus(new BigNumber(ownedLiq[i].assetAAmount ? ownedLiq[i].assetAAmount : 0).times(_token.price ? _token.price : 0).times(2))
    }

    let currentSwapCost = new BigNumber(ethPrice).times(swapCostPerGas).times(gasFee)
    setTotalSaved(new BigNumber(history.length).times(currentSwapCost).toNumber())
    setl2AssetsValue(totalValue.toNumber())

    return () => {};
  }, [tokens, history, ownedLiq]);

  return (
    <Screen>
      <Layout>
        <Title>Wallet</Title>
        <AssetValueWidget value={l2AssetsValue} title={'L2 Assets Value'} area={'assetValue'}/>
        <AssetValueWidget value={totalSaved} title={'Saved on gas vs L1'} area={'savedOnGas'}/>
        <L2AssetsListWidget assets={tokens} liq={ownedLiq}/>
        <HistoryWidget history={history}/>
        <WalletBannerWidget/>
      </Layout>
    </Screen>
  );
}

const Layout = styled(Grid)`
  grid-template-areas:
  "title buttons"
  "assetValue savedOnGas"
  "assetsList history"
  "assetsList history"
  "banner banner";

  @media (max-width: 767px) {
    grid-template-areas:
    "title title"
    "assetValue assetValue"
    "savedOnGas savedOnGas"
    "assetsList assetsList"
    "assetsList assetsList"
    "history history"
    "history history"
    "banner banner";
  }
`;
