import React, { PropsWithChildren, useState } from 'react'
import styled from 'styled-components';
import { ButtonPrimaryMedium, ButtonSecondaryLarge } from '../../common/buttons/Button';
import angleIcon from '../../assets/images/icons/angle-light.svg';
import { TextAlt } from '../../common/text/Text';
import { LoadingBox } from '../../common/loading/LoadingBox';
import unknownToken from '../../assets/images/icons/unknown_token.svg';
import BigNumber from 'bignumber.js'
import { Grid } from '../../common/layout/Grid';
import { formatNumber, abbrNum } from '../../utils/formatNumber';
import { Tooltip } from '@mui/material';
import OfflineBoltIcon from '@mui/icons-material/OfflineBolt';

interface DropdownCommonProps {
  onManageClick: () => any;
  icon0: string;
  icon1: string;
  token0: string;
  token1: string;
  stable: boolean;
  fee: string;
  reserveA: string;
  reserveB: string;
  tvl?: string;
  apy?: string;
  dark?: boolean;
  loading?: boolean;
}

type DropdownProps = DropdownCommonProps | ({loading: true} & Partial<DropdownCommonProps>);

export const Dropdown = ({loading, onManageClick, icon0, icon1, token0, token1, stable,  reserveA, reserveB, fee, apy, tvl, dark, children}: PropsWithChildren<DropdownProps>) => {
  if (loading) {
    return (
      <StyledDropdown loading>
        <LoadingBox/>
      </StyledDropdown>
    );
  }
  return (
    <StyledDropdown>
      {children ? children : <></>}
      <DropdownButton style={{background: dark ? "#111524" : ''}}>
        <IconsRow>
          <IconWrapper>
            <Icon src={icon0 ? icon0 : unknownToken} />
            <Icon src={icon1 ? icon1 : unknownToken} />
          </IconWrapper>
          <PairName>
            {token0} / {token1}
            <PoolType>
              {stable ? 'Stable Pool' : 'Normal Pool'} {` (${fee}%)`}
            </PoolType>
          </PairName>
        </IconsRow>


        <PoolReserveGrouping>
            <PoolReserve>
            <Gray>{` ${token0}`}</Gray> {abbrNum(reserveA, 7)}
            </PoolReserve>
            <PoolReserve>
            <Gray>{` ${token1}`}</Gray> {abbrNum(reserveB, 7)}
            </PoolReserve>
        </PoolReserveGrouping>


        <APYGrouping>
            {apy != null ? (<><Gray>{`APY`} {apy.includes('-') ? 

              <StyledTooltip title={"Deposit LP into amplifier tab for boosted APY."}> 
                <OfflineBoltIcon style={{position: 'absolute', top: '-3px', right: '4px', color: 'var(--color-blue)'}}/>
              </StyledTooltip>
            
            
            : <></>}</Gray> <Green>{` ${apy}%`}</Green></>) : <></>}
        </APYGrouping>

        <TVLGrouping>
            {tvl != null ? (<><Gray>{`TVL`}</Gray> {`$${formatNumber(new BigNumber(tvl).toFixed(0), 0)}`}</>) : <></>}
        </TVLGrouping>

        <ManageButton onClick={() => {onManageClick()}}>Manage</ManageButton>

      </DropdownButton>
    </StyledDropdown>
  )
}



const StyledTooltip = styled(Tooltip)`
  max-width: 250px;
  white-space: normal;
  opacity: 1;
  font-size: 16px;

  #tooltip-top > .tooltip-inner {
    background-color: #fff;
    color: #000;
    border: 1px solid #062e56;
  }

  #tooltip-top > .tooltip-arrow {
    border-top: 5px solid #062e56;
  }

`

const StyledDropdown = styled.div<{loading?: boolean}>`
  position: relative;
  overflow: hidden;

  ${_ => _.loading && `min-height: 86px;`}

  & + & {
    margin-top: 6px;
  }
`;

const DropdownButton = styled(Grid)`
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  grid-template-areas:
  "main tvl tvlusd apy manage";


  position: relative;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  height: 86px;
  color: #FFFFFF;
  background: var(--color-modal-light-blue);
  border-radius: 16px;
  justify-content: space-between;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 6px 12px;
    grid-template-areas:
    "main tvlusd apy manage";
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    padding: 6px 12px;
    grid-template-areas:
    "main manage";
  }
`;

const IconsRow = styled.div`
  min-width: max-content;
  grid-area: main;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #F6F8FE;

  @media (max-width: 600px) {
    margin-right: auto;
    flex-direction: column;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;



const PairName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 5px;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #F6F8FE;

  @media (max-width: 600px) {
    font-size: 17px;
  }
`;

const Gray = styled.p`
  position: relative; 
  color: var(--color-gray);
  width: 60px;
  padding-right: 0px;
`
const Green = styled.p`
  color: rgb(118, 209, 145);
  padding-right: 0px;
`
const PoolType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-gray);
  @media (max-width: 600px) {
    display: none;
  }
`;

const PoolReserveGrouping = styled.div`
  grid-area: tvl;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 40%;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-gray);

  @media (max-width: 900px) {
    display: none;
  }
`;

const APYGrouping = styled.div`
  grid-area: apy;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-gray);

  @media (max-width: 600px) {
    display: none;
  }
`;

const TVLGrouping = styled.div`
  grid-area: tvlusd;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 20%;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: white;

  @media (max-width: 600px) {
    display: none;
  }
`;


const PoolReserve = styled.div`
  display: flex;
  align-items: left;
  margin-right: auto;
  margin-right: 2px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: white;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 20px;
  border: 1px solid transparent;

  &:first-child {
    z-index: 1;
  }

  & + & {
    transform: translateX(-6px)
  }
`;


const ManageButton = styled(ButtonSecondaryLarge)`
  grid-area: manage;
  max-width: 150px;
  margin-left: auto;
`

const List = styled.ul`
  margin-bottom: 24px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(ButtonPrimaryMedium)`
  width: 135px;
  height: 48px;

  & + & {
    margin-left: 30px;
  }
`;
