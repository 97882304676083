import React from 'react'
import styled from 'styled-components'
import { Amount } from '../../common/text/Amount'
import { Widget } from '../../common/widgets/Widget'
import bannerImage from '../../assets/images/icons/dao_banner_1.png'
import blue_checkmark from '../../assets/images/icons/blue_checkmark.png'

export const DaoBannerWidget = () => {

  return (
    <View title="">
      <BannerImage src={bannerImage}/>

      <BannerTextWrapper>
        <BannerTextHeading>
          What is veKOI (Vote Escrow Koi)?
        </BannerTextHeading>
        <BannerTextContent>
          A veKOI NFT is the DAO token for the koi.finance ecosystem. To receive veKOI, a user can lock their KOI tokens
          for a period of time (7-728 days), and in return receives a veKOI NFT. A veKOI NFT represents a vote weight in the DAO based on the amount of KOI locked and timeframe it was locked for. 
        </BannerTextContent>
        <BannerTextWrapperTwo>
        <BannerTextContent>
         <BannerCheck src={blue_checkmark}/> Boost your amplifier rewards by up to 3x.
        </BannerTextContent>
        <BannerTextContent>
         <BannerCheck src={blue_checkmark}/> Vote to direct KOI towards amplifier pools and bond offerings.
        </BannerTextContent>
        <BannerTextContent>
         <BannerCheck src={blue_checkmark}/> Earn platform revenue rewards and more.
        </BannerTextContent>
        </BannerTextWrapperTwo>
      </BannerTextWrapper>
    </View>
  )
}

const View = styled(Widget)`
  grid-area: banner;
  background-color: #02040F;
  display: flex;
  position: relative;
  overflow: hidden;
  min-height: 280px;
  justify-content: flex-start;
  align-items: center;
  padding: 30px;
`;

const AmountAligned = styled(Amount)`
  text-align: left !important;
`;

const BannerImage = styled.img`
  position: absolute;
  top: -12.5%;
  bottom: 0;
  height: 125%;
  right: -1%;
  z-index: 0;
`
const BannerCheck = styled.img`
  width: 20px;
  height: 20px;
  margin: 5px;
  margin-left: 0;
`

const BannerTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  height: 100%;
  z-index: 2;


  justify-content: space-between;

  @media (max-width: 767px) {
    width: 100%;
  }

`

const BannerTextHeading = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;

  color: #FFFFFF;
`

const BannerTextContent = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  align-items: center;
  display: flex;
  align-items: center;

  color: #777F99;
`

const BannerTextWrapperTwo = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 10px;
  justify-content: space-around;

`
