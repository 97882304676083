import React, { ReactNode, useCallback, useEffect } from 'react'
import { createPortal } from 'react-dom';
import styled, {keyframes} from 'styled-components';
import { CloseButton } from '../buttons/CloseButton';
import { TextSemiBold } from '../text/Text';
import { useSpring, animated } from '@react-spring/web';
import Backdrop from '@mui/material/Backdrop';
import {default as MUIModal} from '@mui/material/Modal';
import Slide from '@mui/material/Slide';

interface FadeProps {
  children: React.ReactElement;
  in?: boolean;
  onClick?: any;
  onEnter?: (node: HTMLElement, isAppearing: boolean) => void;
  onExited?: (node: HTMLElement, isAppearing: boolean) => void;
  ownerState?: any;
}

const Fade = React.forwardRef<HTMLDivElement, FadeProps>(function Fade(props, ref) {
  const {
    children,
    in: open,
    onClick,
    onEnter,
    onExited,
    ownerState,
    ...other
  } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter(null as any, true);
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited(null as any, true);
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {React.cloneElement(children, { onClick })}
    </animated.div>
  );
});

export interface BasicModalProps {
  isVisible: boolean;
  onClose: () => void;
  className?: string;
}
export interface ModalProps extends BasicModalProps {
  title: string;
  children: ReactNode;
}

export const Modal = ({isVisible, onClose, children, title, className}: ModalProps) => {
  const containerRef = React.useRef(null);

  const listenKeyboard = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.keyCode === 27) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (isVisible) {
      window.addEventListener('keydown', listenKeyboard, true);
      return () => {
        window.removeEventListener('keydown', listenKeyboard, true);
      };
    }
  }, [isVisible, listenKeyboard]);

  if (!isVisible) return null

  return (
    <MUIModal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      open={isVisible}
      onClose={onClose}
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          TransitionComponent: Fade,
        },
      }}
      ref={containerRef}
      >
        <Slide direction="up" in={isVisible} mountOnEnter unmountOnExit container={containerRef.current}>
              <ModalBody className={className}>
                <ModalHeader>
                  <ModalTitle>{title}</ModalTitle>
                  <CloseButtonWrapper>
                    <CloseButton onClick={onClose}/>
                  </CloseButtonWrapper>
                </ModalHeader>
                {children}
              </ModalBody>
        </Slide>
      </MUIModal>
    
  );
}

const ModalBody = styled.div`
  position: absolute;
  max-width: 500px;
  max-height: 600px;
  width: 100%;
  left: calc(50% - 250px);
  top: 22%;
  padding: 20px;
  background: var(--color-modal-blue);
  border-radius: 16px;
  border: 1px solid rgba(255,255,255,0.2);
  overflow-y: auto;
  overflow: hidden;

  @media (max-height: 656px) {
    max-height: 95%;
  }

  @media (max-width: 767px) {
    padding: 16px;
  }

  @media (max-width: 500px) {
    bottom: 0px;
    top: initial;
    left: 0%;
    padding: 20px;
    transform: none;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
  }
`;

const ModalHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 14px;
`;

const ModalTitle = styled(TextSemiBold)`
  text-align: center;
  font-size: 24px;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  max-width: 24px;
`;