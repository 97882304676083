import React from 'react'
import styled from 'styled-components'
import { BasicModalProps, Modal } from '../../../common/modal/Modal'
import { EtherscanLink } from '../../../common/modal/ModalComponents'
import { TextGrey, TextMedium } from '../../../common/text/Text'
import { Transaction } from '../../../model/Transaction'
import { formatDate } from '../../../utils/formatDate'

interface TransactionDetailsModalProps extends BasicModalProps {
  transaction: Transaction | undefined
}

export const TransactionDetailsModal = ({isVisible, onClose, transaction}: TransactionDetailsModalProps) => {

  if(transaction?.operation == 'Swap'){
    return (
      <Modal
        title="Transaction details"
        isVisible={isVisible}
        onClose={onClose}
      >
        <List>
          <ListItem>
            <TextGrey>Operation</TextGrey>
            <TextMedium>{transaction?.operation}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Send</TextGrey>
            <TextMedium>{transaction?.amountIn + ' ' + transaction?.fromPair}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Receive</TextGrey>
            <TextMedium>{transaction?.amountOut + ' ' + transaction?.toPair}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Status</TextGrey>
            <TextMedium>{transaction?.status}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Time</TextGrey>
            <TextMedium>{transaction?.timestamp && formatDate(transaction.timestamp)}</TextMedium>
          </ListItem>
        </List>
        <LinkWrapper>
          <EtherscanLink href={'https://explorer.zksync.io/tx/' + transaction?.hash}/>
        </LinkWrapper>
      </Modal>
    )
  } else if (transaction?.operation == 'Add LP'){
    return (
      <Modal
        title="Transaction details"
        isVisible={isVisible}
        onClose={onClose}
      >
        <List>
          <ListItem>
            <TextGrey>Operation</TextGrey>
            <TextMedium>{transaction?.operation}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Amount</TextGrey>
            <TextMedium>{transaction?.amountIn + ' ' + transaction?.fromPair}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Amount</TextGrey>
            <TextMedium>{transaction?.amountOut + ' ' + transaction?.toPair}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Status</TextGrey>
            <TextMedium>{transaction?.status}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Time</TextGrey>
            <TextMedium>{transaction?.timestamp && formatDate(transaction.timestamp)}</TextMedium>
          </ListItem>
        </List>
        <LinkWrapper>
          <EtherscanLink href={'https://explorer.zksync.io/tx/' + transaction?.hash}/>
        </LinkWrapper>
      </Modal>
    )
  } else {
    return (
      <Modal
        title="Transaction details"
        isVisible={isVisible}
        onClose={onClose}
      >
        <List>
          <ListItem>
            <TextGrey>Operation</TextGrey>
            <TextMedium>{transaction?.operation}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Amount</TextGrey>
            <TextMedium>{transaction?.amountIn + ' ' + transaction?.fromPair}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Amount</TextGrey>
            <TextMedium>{transaction?.amountOut + ' ' + transaction?.toPair}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Status</TextGrey>
            <TextMedium>{transaction?.status}</TextMedium>
          </ListItem>
          <ListItem>
            <TextGrey>Time</TextGrey>
            <TextMedium>{transaction?.timestamp && formatDate(transaction.timestamp)}</TextMedium>
          </ListItem>
        </List>
        <LinkWrapper>
          <EtherscanLink href={'https://explorer.zksync.io/tx/' + transaction?.hash}/>
        </LinkWrapper>
      </Modal>
    )

  }

}

const List = styled.ul`
  margin-bottom: 32px;
`;

const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 8px;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
