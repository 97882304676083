import { Switch, BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import { Layer2WalletScreen } from "./pages/Layer2Wallet/Layer2WalletScreen";
import { LiquidityPoolScreen } from "./pages/LiquidityPool/LiquidityPoolScreen";
import { LiquidityPoolSubScreen } from "./pages/LiquidityPool/LiquidityPoolSubScreen";
import { AmplifierScreen } from "./pages/Amplifier/AmplifierScreen";
import { AmplifierSubScreen } from "./pages/Amplifier/AmplifierSubScreen";
import { AmplifierV2SubScreen } from "./pages/Amplifier/AmplifierV2SubScreen";
import { BondsScreen } from "./pages/Bonds/BondsScreen";
import { BondSubScreen } from "./pages/Bonds/BondSubScreen";
import { SwapScreen } from "./pages/Swap/SwapScreen";
import { SwapWidget } from "./pages/Swap/SwapWidget";

import { DaoScreen } from "./pages/Dao/DaoScreen";
import { Header } from "./common/layout/Header";

import { Providers } from "./providers";
import "./styles/reset.css";
import "./styles/style.css";
import { RewardsScreen } from "./pages/Rewards/RewardsScreen";

export function App() {
  return (
    <Providers>
        <Router>
            <Header/>

              <Route exact path="/wallet" component={Layer2WalletScreen} />
              <Route exact path="/swap" component={SwapScreen} />
              <Route exact path="/widget" component={SwapWidget} />

              <Route exact path="/pool" component={LiquidityPoolScreen} />
              <Route exact path="/pool/:inputCurrency/:outputCurrency/:stable" component={LiquidityPoolSubScreen} />
              <Route exact path="/amplifier" component={AmplifierScreen} />
              <Route exact path="/amplifier/:poolId" component={AmplifierSubScreen} />
              <Route exact path="/amplifierv2/:poolId" component={AmplifierV2SubScreen} />
              
              <Route
                exact
                path="/"
                render={() => {
                    return (
                      <Redirect to="/swap" />
                    )
                }}
              />

              <Route exact path="/bonds" component={BondsScreen} />
              <Route exact path="/bonds/:poolId" component={BondSubScreen} />

              <Route exact path="/dao" component={DaoScreen} />
              <Route exact path="/rewards" component={RewardsScreen} />

        </Router>
        </Providers>
  );
}

//        
