import React from 'react'
import styled, { keyframes } from 'styled-components';
import loaderIcon from '../assets/images/icons/loader.svg';

export const Loader = () => <Image src={loaderIcon} />

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Image = styled.img`
  animation: ${rotate} 1s linear infinite;
  width: 31px;
  height: 31px;
`;
