import styled from 'styled-components';
import React from 'react'
import { TextMedium } from './text/Text';
import { HelpCircle } from 'react-feather'

interface SymbolWithLogoProps {
  logo: string;
  symbol: string;
  name?: string;
}

export const SymbolWithLogo = ({logo, symbol, name}: SymbolWithLogoProps) => {
  if(logo && logo != ''){
    return (
      <Row>
        <Logo src={logo} alt={`${symbol} logo`} />
        <Column>
        <Symbol>{symbol}</Symbol>
        <Name>{name}</Name>

        </Column>

      </Row>
    )
  } else {
    return  <Row/>

  }

}

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 40%;
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 30px;
`;

const Symbol = styled(TextMedium)`
font-weight: 500;
font-size: 16px;
line-height: 120%;
`;

const Name = styled(TextMedium)`
font-weight: 500;
font-size: 12px;
color: var(--color-gray);
`;


const Column = styled.div`
  flex-direction: column;
  display: flex;
  text-align: left;

`;
