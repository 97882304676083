import React, { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "./../../assets/images/logo_mute.png";
import menuIcon from "./../../assets/images/icons/hamburger.svg";
import { useToggler } from "../../hooks/useToggler";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Connection } from "../Connection";
import LaunchIcon from '@mui/icons-material/Launch';

import Slide from '@mui/material/Slide';
import {default as MUIMenu} from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { styled as muiStyled } from '@mui/material/styles';
import { MenuProps } from '@mui/material/Menu';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


const MenuButton = ({title, children}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const animUp = {
    animation: "spinUp 0.25s forwards",
    "@keyframes spinUp": {
      "0%": {
        transform: "rotate(0deg)",
      },
      "100%": {
        transform: "rotate(180deg)",
      },
    },
  } 

  const animDown = {
    animation: "spinDown 0.25s backwards",
    "@keyframes spinDown": {
      "0%": {
        transform: "rotate(180deg)",
      },
      "100%": {
        transform: "rotate(0deg)",
      },
    },
  } 

  const [anim, setAnim] = React.useState({})

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setAnim(animUp)
    
  };
  const handleClose = () => {
    setAnchorEl(null);
    setAnim(animDown)
  };

  return (
    <div>
      <Bridge

        onClick={handleClick}
      >
        {title}
        <KeyboardArrowDownIcon
          sx={anim}         
        />

      </Bridge>
      <StyledMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        

        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {children}
      </StyledMenu>
    </div>
  );
}


const StyledMenu = muiStyled((props: MenuProps) => (
  <MUIMenu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 12,
    zIndex: 1000,
    marginTop: theme.spacing(2),
    minWidth: 180,
    backgroundColor: 'rgba(30,34,53,0.5)',
    color: 'white',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 16,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: 'rgba(255,255,255,0.1)',
      },
    },
  },
}));



export const Header = () => {
  const location = useLocation();
  const { visible, toggle } = useToggler()
  const ref = useRef(null)

  const [showBridge, setShowBridge] = React.useState(false)
  const [isMobile, setIsMobile] = React.useState(false)

  useClickOutside(ref, () => toggle(false))


  React.useEffect(() => {
    window.addEventListener("resize", resize.bind(this));
    resize()
      return () => {
        window.removeEventListener("resize", resize.bind(this));
      };
    
  }, []);


  const resize = () => {
    setIsMobile(window.innerWidth <= 1000)
  }


  return (
    <HeaderRow>
      <HeaderSection>
        <Link style={{color: 'gray'}} to="/">
          <LogoImage src={logo} />
        </Link>
      </HeaderSection>
      <MenuBlur className={visible ? 'visible' : ''}/>


      <Slide timeout={isMobile ? 250 : 0} direction="right" in={visible || !isMobile} mountOnEnter unmountOnExit container={ref.current}>
      <Menu ref={ref} className={visible ? 'visible' : ''}>
        <MenuHeader>
          <LogoImage src={logo} />
        </MenuHeader>
        <MenuContent>
            <NavList>
              <NavItem>
                <Link to="/wallet">Wallet</Link>
                <Underline active={location.pathname.includes('wallet1')}/>
              </NavItem>
              <NavItem>
                <Link to="/swap" >Swap</Link>
                <Underline active={location.pathname.includes('swap1')}/>
              </NavItem>
              <NavItem>
                <Link to="/pool" >Pool</Link>
                <Underline active={location.pathname.includes('pool1')}/>
              </NavItem>
              <NavItem>
                <Link to="/amplifier">Amplifier</Link>
                <Underline active={location.pathname.includes('amplifier1')}/>

                {/*<Notification number={1}/>*/}
              </NavItem>
              <NavItem>
                <Link to="/bonds">Bonds</Link>
                <Underline active={location.pathname.includes('bonds1')}/>
              </NavItem>

              {isMobile ? 
                <>

                <NavItem>
                  <Link to="/dao">veMute</Link>
                  <Underline active={location.pathname.includes('dao1')}/>
                </NavItem>
                <NavItem>
                  <Bridge onClick={() => {window.open('https://discussion.mute.io', "_blank");}}>Governance &nbsp;<LaunchIcon style={{color: 'white'}}/></Bridge>
                </NavItem>
                </>
                : 
                
                <NavItem>
                <MenuButton title={'Governance'}>
                  <MenuItem>
                    <Link to="/dao">veMute</Link>
                  </MenuItem>
                  <MenuItem onClick={() => {window.open('https://discussion.mute.io', "_blank");}}>Forum &nbsp;<LaunchIcon style={{color: 'white'}}/></MenuItem>
                </MenuButton>
              </NavItem>
            }

              {isMobile ? 
                <>

                <NavItem>
                  <Bridge onClick={() => {window.open('https://bridge.zksync.io', "_blank");}}>Bridge &nbsp;<LaunchIcon style={{color: 'white'}}/></Bridge>
                </NavItem>
                <NavItem>
                  <Bridge onClick={() => {window.open('https://info.mute.io', "_blank");}}>Analytics &nbsp;<LaunchIcon style={{color: 'white'}}/></Bridge>
                </NavItem>
                </>
                : 
                
                <NavItem>
                <MenuButton title={'Explore'}>
                  <MenuItem onClick={() => {window.open('https://bridge.zksync.io', "_blank");}}>Bridge &nbsp;<LaunchIcon style={{color: 'white'}}/></MenuItem>
                  <MenuItem onClick={() => {window.open('https://info.mute.io', "_blank");}}>Analytics &nbsp;<LaunchIcon style={{color: 'white'}}/></MenuItem>
                </MenuButton>
              </NavItem>
            }
              
            </NavList>
        </MenuContent>
      </Menu>
      </Slide>


      <ConnectionWrapper>
        <Connection/>
      </ConnectionWrapper>
      <MenuOpenButton onClick={() => toggle(!visible)}>
        <img src={menuIcon} alt="Menu"/>
      </MenuOpenButton>
    </HeaderRow>
  )
}

/*

              <NavItem>
                <Link to="/rewards">Rewards</Link>
                <Underline active={location.pathname.includes('dao1')}/>
              </NavItem>
              */
// onClick={() => setShowBridge(!showBridge)}

const Notification = ({number}) => {
  return (
    <NotificationParent>
      {number}

    </NotificationParent>

  )
}

const NotificationParent = styled.div`
  position: absolute;
  width: 18px;
  height: 18px;
  color: white;
  border: 1px solid transparent;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 125%;
  background: red;
  border-radius: 100px;
  top: 0;
  right: -25%;

`

const LogoImage = styled.img`
  width: calc(0.22* 405px);
  height: calc(0.22 * 121px);
  @media (max-width: 600px) {
    width: calc(0.22* 405px);
    height: calc(0.22 * 121px);
  }
`

const HeaderRow = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  background: var(--color-dark-blue);
  z-index: 5;
  height: 70px;
  border-bottom: 1px solid var(--color-modal-light-blue);

  @media (max-width: 1000px) {
    justify-content: flex-start;
  }
  @media (max-width: 1000px) {
    padding: 8px 16px;
  }
`;

const HeaderSection = styled.div`
  width: 130px;

  @media (max-width: 1000px) {
    width: initial;
  }
`;

const Menu = styled.div`
  height: 100px;

  @media (max-width: 1000px) {
    margin-left: 24px;
  }
  @media (max-width: 1000px) {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 250px;
    height: 100%;
    margin: 0;
    background: #080A11;
    z-index: 99999;
  }
  &.visible {
    display: flex;
    flex-direction: column;
  }
`;


const MenuBlur = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;

  background: rgba(3, 5, 12, 0.3);
  backdrop-filter: blur(10px);
  z-index: 5;

  &.visible {
    display: flex;
  }
`;


const MenuHeader = styled.div`
  display: none;
  justify-content: center;
  align-items: center;
  padding: 16px;
  height: 120px;

  @media (max-width: 1000px) {
    display: none;
  }
`;

const MenuContent = styled.div`
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 150px;

  @media (max-width: 1000px) {
    display: flex;
    width: auto;
    flex-direction: column;
    flex-grow: 1;
    position: relative;
    justify-content: flex-start;
    padding: 48px 32px 32px;
    margin-left: 0px;
  }
`;


const MenuOpenButton = styled.button`
  padding-left: 10px;
  display: none;
  height: 24px;
  width: 24px;
  z-index: 10;

  @media (max-width: 1000px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;


const NavList = styled.ul`
  display: flex;
  align-items: flex-start;
  color: white;

  height: 50px;
  margin-top: auto;
  margin-bottom: auto;

  @media (max-width: 1000px) {
    background: none;
    border: none;
    margin-top: 50px;
    height: auto;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: none;
  }
`;

const Link = styled(NavLink)`
  font-family: 'Aeonik';
  font-weight: 500;
  font-size: 15px;
  line-height: 13px;
  color: var(--color-dark-gray);
  color: white;
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;

  &.active {
    color: var(--color-blue-accent) !important;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

const Bridge = styled.button`
  position: relative;
  font-family: 'Aeonik';
  font-weight: 500;
  font-size: 15px;
  line-height: 13px;
  color: var(--color-dark-gray);
  color: white;
  height: 100%;
  border: none;
  display: flex;
  align-items: center;

  :hover {
    color: var(--color-blue-accent) !important;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

const BridgeDropDown = styled.div`
  position: absolute;
  top: 50px;
  border-radius: 8px;
  border: 1px solid var(--color-blue-accent) ;
  display: flex;
  flex-direction: column;
  flex-direction: flex-start;
  align-items: flex-start;

  height: 110px;
  width: 185px;
  background: var(--color-modal-blue);
  padding: 15px;

  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

const BridgeLink = styled.button`
  position: relative;
  
  font-family: 'Aeonik';
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  padding-bottom: 5px;
  color: white;
  border: none;
  display: flex;

  :hover {
    color: var(--color-blue-accent) !important;
  }


`

const NavItem = styled.li`
  position: relative;
  height: 100%;
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;

  :hover {
    ${Link} {
      color: rgba(84,190,249, 1);
    }
  }

  &:hover {
    ${Link} {
      color: rgba(84,190,249, 1);
    }
  }

  margin-left: 15px;
  margin-right: 15px;

  @media (max-width: 1000px) {
    margin-left: 0;
  }

  & + & {
    @media (max-width: 1000px) {
      margin: 50px 0 0;
      margin-left: 0;
    }
  }
`;

const Underline = styled.div<{active: boolean}>`
  display: ${props => props.active == true ? 'flex' : 'none'};
  position: absolute;
  border-bottom: 1px solid var(--color-blue-accent);
  height: 1px;
  width: 100%;
  bottom: -1px;
`

const ConnectionWrapper = styled.div`
  z-index: 2;
  @media (max-width: 1000px) {
    margin-left: auto;
  }
`;
