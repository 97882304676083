import React, { useState } from 'react'
import { Asset } from '../../../model/Asset'
import { RemoveLiquidityModal } from './RemoveLiquidityModal'
import { ConfirmTransactionModal } from '../../../common/modal/ConfirmTransactionModal'
import { LiquidityModalFooter } from './LiquidityModalFooter'
import { SuccessfulSubmitModal } from '../../../common/modal/SuccessfulSubmitModal'
import { TransactionDirection } from '../../../common/TransactionDirection'
import plusIcon from '../../../assets/images/icons/plus-bright.svg'
import BigNumber from "bignumber.js";

enum Modal {
  AddLiquidity,
  Confirm,
  Submitted,
  AssetsListA,
  AssetsListB
}

interface RemoveLiquidityProps {
  assets: Asset[];
}

export const RemoveLiquidity = ({externalOpen, approveClick, onWithdrawClick}) => {

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => {
    setModal(null)
    setAssetAmountA('')
    setAssetAmountB('')
    setSelectedAssetA(undefined)
    setSelectedAssetB(undefined)
  };

  const [selectedAssetA, setSelectedAssetA] = useState<Asset | undefined>();
  const [assetAmountA, setAssetAmountA] = useState('');

  const [selectedAssetB, setSelectedAssetB] = useState<Asset | undefined>();
  const [assetAmountB, setAssetAmountB] = useState('');

  const [lpBal, setLPBal] = useState('');

  const [confirmReady, setConfirmReady] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [importButtonText, setImportButtonText] = useState('Import')
  const [share, setShare] = useState('');

  const onSupplyButtonClick = () => {
    setConfirmReady(true)
  }

  const externalOpenFunc = (assetA, assetB, _assetAAmount, _assetBAmount, _lpBal, _share) => {
    setSelectedAssetA(assetA)
    setSelectedAssetB(assetB)
    setAssetAmountA(_assetAAmount)
    setAssetAmountB(_assetBAmount)
    setLPBal(_lpBal)
    setShare(_share)
    setModal(Modal.AddLiquidity)
  }

  React.useEffect(() => {
    externalOpen.current = externalOpenFunc

    return () => {
      console.log('unmounted')
    }

  }, [confirmReady]);


  const withdrawLiq = async (selectedAssetA, selectedAssetB, assetAmountA, assetAmountB, lpBal, inputValue, approvedValue) => {
    setModal(Modal.Confirm)
    setShare(String(Number(share) * Number(inputValue) / 100))
    var res = await onWithdrawClick(selectedAssetA, selectedAssetB, assetAmountA, assetAmountB, lpBal, inputValue, approvedValue);
    if(res.success == true){
      setShare(res.share)
      setModal(Modal.Submitted)
    }
    else
      closeModal()
  }



  return (
    <>
      <RemoveLiquidityModal
        isVisible={modal === Modal.AddLiquidity}
        onClose={closeModal}
        assetAmountA={assetAmountA}
        assetAmountB={assetAmountB}
        selectedAssetA={selectedAssetA}
        selectedAssetB={selectedAssetB}
        onSupplyButtonClick={onSupplyButtonClick}
        disableButton={disableButton}
        onApproveClick={approveClick}
        onWithdrawClick={withdrawLiq}
        lpBal={lpBal}
      />
      <ConfirmTransactionModal
        isVisible={modal === Modal.Confirm}
        onClose={closeModal}
        onConfirm={() => {}}
        leftValue={`${parseFloat(assetAmountA).toFixed(4)} ${selectedAssetA?.symbol}`}
        rightValue={`${parseFloat(assetAmountB).toFixed(4)} ${selectedAssetB?.symbol}`}
        icon={plusIcon}
        footer={
          <LiquidityModalFooter
            selectedAssetA={selectedAssetA}
            selectedAssetB={selectedAssetB}
            assetAmountA={assetAmountA}
            assetAmountB={assetAmountB}
            share={share}
          />
        }
      />
      <SuccessfulSubmitModal
        isVisible={modal === Modal.Submitted}
        onClose={closeModal}
        title="Removing liquidity has been submitted!"
        description="Your underlying tokens are now in your wallet."
        savings="$67.43"
        costs={"$0.55"}
        onSubmit={() => null}
        section={
          <TransactionDirection
            icon={plusIcon}
            leftValue={`${parseFloat(assetAmountA).toFixed(4)} ${selectedAssetA?.symbol}`}
            rightValue={`${parseFloat(assetAmountB).toFixed(4)} ${selectedAssetB?.symbol}`}
          />
        }
        footer={
          <LiquidityModalFooter
            selectedAssetA={selectedAssetA}
            selectedAssetB={selectedAssetB}
            assetAmountA={parseFloat(assetAmountA).toFixed(4)}
            assetAmountB={parseFloat(assetAmountB).toFixed(4)}
            share={share}
          />
        }
      />
    </>
  );
}
