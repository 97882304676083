import React, { useState } from 'react'
import styled from 'styled-components';
import { Asset } from '../../model/Asset';
import { SearchInput } from '../form/SearchInput';
import { ListItem } from '../ListItem';
import { SymbolWithLogo } from '../SymbolWithLogo';
import UnknownToken from '../../assets/images/icons/unknown_token.png';

import { Text } from '../text/Text';
import { Modal, BasicModalProps } from './Modal'
import { ButtonPrimaryMedium } from './../buttons/Button';
import BigNumber from 'bignumber.js'

import { useWalletHook } from '../../web3/walletHook'

interface AssetsListModalProps extends BasicModalProps {
  assets: {};
  setSelectedAsset: (asset: Asset) => void;
}

export const AssetsListModal = ({isVisible, onClose, assets, setSelectedAsset}: AssetsListModalProps) => {
  const [searchValue, setSearchValue] = useState('');
  const [addedAssets, setAddedAssets] = useState<Record<string, Asset>>(JSON.parse(JSON.stringify(assets)));
  const { addTokenToStorage, getTokenInformation, GetPinnedTokens } = useWalletHook()

  const showAmount = 999;

  React.useEffect(() => {
    setAddedAssets(JSON.parse(JSON.stringify(assets)))
    return () => {
    }
  }, [assets]);

  React.useEffect(() => {
    if(searchValue != '')
    searchForAsset(searchValue).then(res => {

      if(res.success == true){
        var asset =    {
          logo: UnknownToken,
          symbol: res.symbol,
          name: res.name,
          amount: res.balance,
          address: res.address,
          active: false,
          price: '0.00',
          decimals: Number(res.decimals),
          value: '0'
        }

        let hasDuplicates = false
        for(let i in addedAssets){
          if(addedAssets[i].address!.toLowerCase() == asset.address.toLowerCase())
            hasDuplicates = true
        }

        let _addedAssets = JSON.parse(JSON.stringify(addedAssets))
        if(hasDuplicates == false){
          _addedAssets[asset.address.toLowerCase()] = asset
          setAddedAssets(_addedAssets)
        }

      }

    }).catch(e => {})

    return () => {}

  }, [searchValue]);


  const onAssetClick = (asset: Asset) => {
    setSelectedAsset(asset)
    onClose();
  }

  const onAssetAdd = (asset: Asset) => {
    addTokenToStorage(asset)
    setSelectedAsset(asset)
    onClose();
  }

  const searchForAsset = async (contract) => {
    var info = await getTokenInformation(contract)
    return info
  }

  const filterAssets = (address, symbol, name, searchValue) => {
    return !address?.toLowerCase().includes(searchValue.toLowerCase()) &&
      !symbol.toLowerCase().includes(searchValue.toLowerCase()) &&
      !name.toLowerCase().includes(searchValue.toLowerCase())
  }

  const getPinnedAssets = () => {
    if(addedAssets){

      let pinned = GetPinnedTokens()

      return (
        <>
        {
          pinned.map((pin) => 
            { 
              if(addedAssets[pin]){
                return  (
                  <PinnedAsset key={pin} onClick={() => onAssetClick(addedAssets[pin])}>
                    <PinnedAssetLogo src={addedAssets[pin].logo}/>
                    {addedAssets[pin].symbol}
                  </PinnedAsset>
                )
              } else 
                return <></>

            }

          )
        }

      </>
      )


    } else {
      return <></>
    }
  }

  return (
    <StyledModal
      title="Select asset"
      isVisible={isVisible}
      onClose={onClose}
    >
      <SearchInput
        value={searchValue}
        onChange={(event) => setSearchValue(event.target.value)}
        placeholder="Search"
      />
      <PinnedGrid>
      {getPinnedAssets()}
      </PinnedGrid>
      <Divider>
      <RowNames>Asset</RowNames>
      <RowNames>Balance</RowNames>
      </Divider>
      <AssetsList key={Math.floor(Math.random() * 10000)}>
        {Object.entries(addedAssets).map(([key, asset], index) => {
          var {logo, symbol, name, balance, address, active, price} = asset!;
          if(searchValue != ''){
            if(filterAssets(address, symbol, name, searchValue)){
                return
            }
          } else if(active == false && Number(balance) == 0 && (showAmount < index)){
            return
          }

          return (
            <>
            <ListItem key={index} onClick={() => onAssetClick(asset)}>
              <SymbolWithLogo
                logo={logo}
                symbol={symbol}
                name={name}
              />
              {
                active ?
                (<AssetValues>
                  <Balance>{parseFloat(balance).toFixed(2)}</Balance>
                  <AssetPrice>{'$' + new BigNumber(balance).times(price).toFixed(2)}</AssetPrice>
                </AssetValues>) :
                <SetActive onClick={() => onAssetAdd(asset as Asset)}>{'Import'}</SetActive>
              }

            </ListItem>
            <SeperatorLine/>
            </>
          )
        })}
      </AssetsList>
    </StyledModal>
  )
}

const SeperatorLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #262436;
`

const StyledModal = styled(Modal)`
  width: 400px;
  left: calc(50% - 200px);
  top: 15%;

  overflow-y: hidden;
  height: 70%;
  text-align: left;
  justify-content: flex-start;

  @media (max-width: 500px) {
    width: 100%;
    left: 0;
    top: initial;
  }
  
`;

const PinnedGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 10px;
`

const PinnedAsset = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 13px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #EEEEEE;
  border: 1.5px solid #3B4568;
  background-color: var(--color-modal-light-blue);
  font-weight: 700;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  height: 35px;

  :hover {
    background-color: rgba(84,190,249, 0.1);
    border: 1.5px solid rgba(84,190,249, 0.5);
  }

`

const PinnedAssetLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 30px;
`;

const AssetsList = styled.ul`
  margin-top: 16px;
  max-height: 58%;
  overflow-y: auto;
`;

const Divider = styled.div`
  position: relative;
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  width: 100%;
`;

const RowNames = styled.div`
  font-size: 14px;
  color: var(--color-gray);
`

const Balance = styled(Text)`
  color: #EEEEEE;
  font-weight: 500;
`;

const SetActive = styled(ButtonPrimaryMedium)`
  height: 28px;
  width: 25%;
  padding-left: auto;
  padding-right: auto;
  font-size: 12px;
  border-radius: 12px;
`;

export const AssetValues = styled.div`
  text-align: right;
`;

const AssetPrice = styled(Text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777F99;
`;
