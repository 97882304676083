const CONTRACTS_TESTNET = {
  //base amm
  "factory": "0x40be1cBa6C5B47cDF9da7f963B6F761F4C60627D",
  "paircode": "0x95d5c05820d58f1c8cc736b47fe10a29ddcd2cf73a0d842e8537b9fe510fc618",
  "weth": "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
  "router": "0x8B791913eB07C32779a16750e3868aA8495F5964",

  //helpers
  "multicall": "0xb1F9b5FCD56122CdfD7086e017ec63E50dC075e7",
  "paymaster": '0x3874810dD94DA643EB2eFdA05860D06EE91aBFFd',
  "paymaster_v2": "0x4ae2Ba9A5C653038C6d2F5D9F80B28011A454597",

  //gov
  VEMUTE: "0x99864455cAb18354a9D2e41c66A9B6E869ba218C",
  "TIMELOCKCONTROLLER": "0x96c2Cf9edbEA24ce659EfBC9a6e3942b7895b5e8",
  "VEGOVERNOR": "0x1dD55B7DEd83Be6A308E64b0711493fE6E38E2D2",

  //amp v2
  "MUTE_ORACLE": "0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700",
  "AMPLIFIER_FACTORY": "0x4772D618AD88b602a2ea76F2155D0356E6756b3e",
  "AMPLIFIER_TREASURY_MUTE": "0x750921e5a5eA4C5BeEA9Ec3C0C3F3CBd73A6bF3B",
  
  "eth": '0x0000000000000000000000000000000000000000',
  //mute based
  MUTE: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
  DMUTE: '0x27fb794E553DaBC2e99F6fC03F0a4B6B9C2f0E76',
  DMUTE_v3: '0x4336e06Be4F62bD757c4248c48D4C0b32615A2Df',

  //KOI
  KOI_MAINNET: '0x9d14bce1daddf408d77295bb1be9b343814f44de',
  KOI: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
  KOI_CONVERSION: '0x85EcD6f21671819C0a10116DBE5B49d581340b6F',
  VEKOI: '0x98dB4e3Df6502369dAD7AC99f3aEE5D064721C4C',


  //tokens
  USDC: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
  USDT: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
  WBTC: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
  LUSD: '0x503234f203fc7eb888eec8513210612a43cf6115',
  DAI: '0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656',
  HOLD: '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2',
  ZORRO: '0x244c238325fc1bdf6eded726ee1b47d55895d944',

  LP: [
    "0xdfaab828f5f515e104baaba4d8d554da9096f0e4",
    "0x7c21e2e777cecaf969bf7b7aef0e3bf23d043672",
    "0xb85feb6af3412d690dfda280b73eaed73a2315bc",
    "0x9d2811b85c1d736427722817b69e4d1e98016bb0",
    "0x1bb4855770eb93e96f5793abcacc3106c2becf31",
    "0x042ded90c26eea4d860f8e3bd363cc4b17caa00c",
    "0xff2c6230b241a5c183e8f42d3b1805a4acd9fb3e",
    "0x96af3aa1d4648cd75749cbd8e70c8bf1e7900aee",
    "0x3848dbd3eac429497abd464a18fbec78ef76f750",
    "0xbdee183dec1a3df6b514c6aa9e69951ce1fa8162",
    "0xbe21bcd3a21dc4dd6c58945f0f5de4132644020a",
    "0x6499a449d7f2defab76f54a45c9003e0160e385a",
    "0x5a3a6a4f9f29b4b240298d6f9adc775412933eb1",
    "0xe6f6947b335c887a7715f8561ee240f9d3ae462a",
    "0xbc47de79f57b450e6c6d61e0b3adb9237b5f8116",
    "0xf9a7ff47a346e242590f87546d1995de91b8b8b1",
    "0xa0ba071f191b39e12b3d16f00c2b40089e5b76e0",
    "0x8fc43ef21794821f8979078e142196e3827950c0",
    "0xf44624b150fd126748ed7f7fb819459bef13cbe4",
    "0x553a6fd0bcb69450f4050fd57b5b99ca9157e74a",
    "0x203cc35a44c384a560d6f598dde33bb0580c0355",
    "0x9110c58abf53aa40f38b3fa3856d8f0d80d17d31",
    "0xb67e768aee21aa86ad9230f2951f260b9908e896",
    "0xa60e25b317eeb5e7e12af002500304a3f9d0bca5",
    "0x4612c6e696173575c187a360c8326429dbf63645"
  ],
  
  AMPLIFIER: [
    //weth/usdc v1
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0x3bdb188cfb6A9173E36A916004144b122eA507cE',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      stable: false
    },
    //weth/usdc v2
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0xdd3C34aDf2851766DDc302766228d59F43a4386F',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      stable: false
    },
    //weth/panda v1
    {
      pair: '0x203cc35A44c384a560D6F598dDe33bB0580C0355',
      id: '0xBFdD2e0d527A00aA14aAf84ea01a613299aa6908',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xA4C00e85ceBc898e885F5055171dc424dbA8bF45',
      stable: false
    },
    //usdc/usd+ v1

    {
      pair: '0x3848dbd3EAc429497abd464A18fBEC78EF76f750',
      id: '0x1267Fad068A83b454233F536100eD8974FD77BA3',
      token0: '0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557',
      token1: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      stable: true
    },
    //weth/usdc v3
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0x06e8D81f824F8b8758A75Fe9616Dd154400Cb7a4',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      stable: false
    },
    //wbtc/eth
    {
      pair: '0x7c21e2e777cecaf969bf7b7aef0e3bf23d043672',
      id: '0x0B5d073eA715f38Fde1dE52689CFaB041cC8d278',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
      stable: false
    },
        //weth/usdc v4
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0xCc05E242b4A82f813a895111bCa072c8BBbA4a0e',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      stable: false
    },
  ],
    
  AMPLIFIER_V2: [
    // KOI POOLS
    //weth/usdc normal
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0xd2de0fbfb4778c303fe44b40c471b9471b60990f',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      fee: 65, // 0.65%
      ampBoost: 2, // 2x
      apy: 1000, // 10%
      stable: false,
      treasury: '0x63F44e1Ca8960Df9f463f7E42D2ecB81b7BC2225',
      payoutToken: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
      oracle: '0x98c3664F8e2B3Af7e220c441682B24F54bc21Adf',
      maxRewards: 625000

    },
    //weth/wbtc normal
    {
      pair: '0x7c21e2e777cecaf969bf7b7aef0e3bf23d043672',
      id: '0x947cf593ba7b97452a2c4b7f461e2998ab77b74b',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
      fee: 65, // 0.65%
      ampBoost: 2, // 2x
      apy: 1000, // 10%
      stable: false,
      treasury: '0x63F44e1Ca8960Df9f463f7E42D2ecB81b7BC2225',
      payoutToken: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
      oracle: '0x98c3664F8e2B3Af7e220c441682B24F54bc21Adf',
      maxRewards: 625000
    },
    //usdc/usdt stable
    {
      pair: '0x9d2811B85c1d736427722817B69e4D1E98016BB0',
      id: '0xf383033e45c01d73be4cb6f5d8d08164256d35a0',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
      fee: 33, // 0.65%
      ampBoost: 2, // 2x
      apy: 500, // 10%
      stable: true,
      treasury: '0x63F44e1Ca8960Df9f463f7E42D2ecB81b7BC2225',
      payoutToken: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
      oracle: '0x98c3664F8e2B3Af7e220c441682B24F54bc21Adf',
      maxRewards: 625000
    },

    // MUTE POOLS
    //eth/zorro normal 
    {
      pair: '0x33EC51BEc17A25A7Bea04BCD600Cc805142efDeD',
      id: '0x359da4b89d09f70c4db49be44aabd9cda176f9a0',
      token0: '0x244c238325fc1bdf6eded726ee1b47d55895d944',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      fee: 25, // 0.25%
      ampBoost: 2, // 2x
      apy: 5000, // 50%
      stable: false,
      treasury: '0x37d29EfF3210A51912Df2c354e2d025B4412dd51',
      payoutToken: '0x244c238325fc1bdf6eded726ee1b47d55895d944',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 20000000
    },
    //eth/hold normal 
    {
      pair: '0xd5a69D2bD59ae04EA931B4e2bD30aED6A412Ce10',
      id: '0xf1a088c5b364088cf88551b0bf2453651faf4029',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2',
      fee: 50, // 0.5%
      ampBoost: 2, // 2x
      apy: 2000, // 20%
      stable: false,
      treasury: '0x74f2aFb4a0311394662979179790c669bf139876',
      payoutToken: '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 1560
    },

    //weth/usdc normal
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0x974B1bAc17fdFD233FD3c4A64A926813D479d40b',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      fee: 65, // 0.65%
      ampBoost: 2, // 2x
      apy: 1000, // 10%
      stable: false,
      treasury: '0x750921e5a5eA4C5BeEA9Ec3C0C3F3CBd73A6bF3B',
      payoutToken: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 215000

    },
    //weth/wbtc normal
    {
      pair: '0x7c21e2e777cecaf969bf7b7aef0e3bf23d043672',
      id: '0xfF76E49f36C4f593639AecB6b6F17d4C94d831c0',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
      fee: 65, // 0.65%
      ampBoost: 2, // 2x
      apy: 1000, // 10%
      stable: false,
      treasury: '0x750921e5a5eA4C5BeEA9Ec3C0C3F3CBd73A6bF3B',
      payoutToken: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 215000
    },
    //usdc/usdt stable
    {
      pair: '0x9d2811B85c1d736427722817B69e4D1E98016BB0',
      id: '0x820681cA09f44dc8637B49194022dce7e1578E29',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
      fee: 33, // 0.65%
      ampBoost: 2, // 2x
      apy: 500, // 10%
      stable: true,
      treasury: '0x750921e5a5eA4C5BeEA9Ec3C0C3F3CBd73A6bF3B',
      payoutToken: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 215000
    },
  ],

  
  BOND: {
    "bond": "0x10cC3Ec960726948B3491AC32a44980C7C9D5c30",
    "treasury": "0xeb07c0b21D8ebE15717d224E375476FD0CEbE128",
    "lp": "0xb85feb6aF3412d690DFDA280b73EaED73a2315bC"
  }
}

const CONTRACTS_MAINNET = {
  //base amm
  "factory": "0x40be1cBa6C5B47cDF9da7f963B6F761F4C60627D",
  "paircode": "0x95d5c05820d58f1c8cc736b47fe10a29ddcd2cf73a0d842e8537b9fe510fc618",
  "weth": "0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91",
  "router": "0x8B791913eB07C32779a16750e3868aA8495F5964",

  //helpers
  "multicall": "0xb1F9b5FCD56122CdfD7086e017ec63E50dC075e7",
  "paymaster": '0x3874810dD94DA643EB2eFdA05860D06EE91aBFFd',
  //"paymaster_v2": "0x61b3B4F1f20dE3d01eE735bdE0D62bfcEa147b04",
  "paymaster_v2": "0x4ae2Ba9A5C653038C6d2F5D9F80B28011A454597",

  //gov
  VEMUTE: "0x99864455cAb18354a9D2e41c66A9B6E869ba218C",
  "TIMELOCKCONTROLLER": "0x96c2Cf9edbEA24ce659EfBC9a6e3942b7895b5e8",
  "VEGOVERNOR": "0x1dD55B7DEd83Be6A308E64b0711493fE6E38E2D2",

  //amp v2
  "MUTE_ORACLE": "0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700",
  "KOI_ORACLE": "0xA366894a5Bf1Cd7317A7bF49715eF76f0edC72E6",
  "AMPLIFIER_FACTORY": "0x4772D618AD88b602a2ea76F2155D0356E6756b3e",
  "AMPLIFIER_TREASURY_MUTE": "0x750921e5a5eA4C5BeEA9Ec3C0C3F3CBd73A6bF3B",
  "AMPLIFIER_TREASURY_KOI": "0x63F44e1Ca8960Df9f463f7E42D2ecB81b7BC2225",

  "eth": '0x0000000000000000000000000000000000000000',
  //mute based
  MUTE: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
  DMUTE: '0x27fb794E553DaBC2e99F6fC03F0a4B6B9C2f0E76',
  DMUTE_v3: '0x4336e06Be4F62bD757c4248c48D4C0b32615A2Df',

  //KOI
  KOI_MAINNET: '0x9d14bce1daddf408d77295bb1be9b343814f44de',
  KOI: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
  KOI_CONVERSION: '0x85EcD6f21671819C0a10116DBE5B49d581340b6F',
  VEKOI: '0x98dB4e3Df6502369dAD7AC99f3aEE5D064721C4C',

  //tokens
  USDC: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
  USDT: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
  WBTC: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
  LUSD: '0x503234f203fc7eb888eec8513210612a43cf6115',
  DAI: '0x4B9eb6c0b6ea15176BBF62841C6B2A8a398cb656',
  HOLD: '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2',
  ZORRO: '0x244c238325fc1bdf6eded726ee1b47d55895d944',

  LP: [
    "0xdfaab828f5f515e104baaba4d8d554da9096f0e4",
    "0x7c21e2e777cecaf969bf7b7aef0e3bf23d043672",
    "0xb85feb6af3412d690dfda280b73eaed73a2315bc",
    "0x9d2811b85c1d736427722817b69e4d1e98016bb0",
    "0x1bb4855770eb93e96f5793abcacc3106c2becf31",
    "0x042ded90c26eea4d860f8e3bd363cc4b17caa00c",
    "0xff2c6230b241a5c183e8f42d3b1805a4acd9fb3e",
    "0x96af3aa1d4648cd75749cbd8e70c8bf1e7900aee",
    "0x3848dbd3eac429497abd464a18fbec78ef76f750",
    "0xbdee183dec1a3df6b514c6aa9e69951ce1fa8162",
    "0xbe21bcd3a21dc4dd6c58945f0f5de4132644020a",
    "0x6499a449d7f2defab76f54a45c9003e0160e385a",
    "0x5a3a6a4f9f29b4b240298d6f9adc775412933eb1",
    "0xe6f6947b335c887a7715f8561ee240f9d3ae462a",
    "0xbc47de79f57b450e6c6d61e0b3adb9237b5f8116",
    "0xf9a7ff47a346e242590f87546d1995de91b8b8b1",
    "0xa0ba071f191b39e12b3d16f00c2b40089e5b76e0",
    "0x8fc43ef21794821f8979078e142196e3827950c0",
    "0xf44624b150fd126748ed7f7fb819459bef13cbe4",
    "0x553a6fd0bcb69450f4050fd57b5b99ca9157e74a",
    "0x203cc35a44c384a560d6f598dde33bb0580c0355",
    "0x9110c58abf53aa40f38b3fa3856d8f0d80d17d31",
    "0xb67e768aee21aa86ad9230f2951f260b9908e896",
    "0xa60e25b317eeb5e7e12af002500304a3f9d0bca5",
    "0x4612c6e696173575c187a360c8326429dbf63645"
  ],
  
  AMPLIFIER: [
    //weth/usdc v1
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0x3bdb188cfb6A9173E36A916004144b122eA507cE',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      stable: false
    },
    //weth/usdc v2
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0xdd3C34aDf2851766DDc302766228d59F43a4386F',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      stable: false
    },
    /*
    //weth/panda v1
    {
      pair: '0x203cc35A44c384a560D6F598dDe33bB0580C0355',
      id: '0xBFdD2e0d527A00aA14aAf84ea01a613299aa6908',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xA4C00e85ceBc898e885F5055171dc424dbA8bF45',
      stable: false
    },
    //usdc/usd+ v1

    {
      pair: '0x3848dbd3EAc429497abd464A18fBEC78EF76f750',
      id: '0x1267Fad068A83b454233F536100eD8974FD77BA3',
      token0: '0x8E86e46278518EFc1C5CEd245cBA2C7e3ef11557',
      token1: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      stable: true
    },
    */
    //weth/usdc v3
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0x06e8D81f824F8b8758A75Fe9616Dd154400Cb7a4',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      stable: false
    },
    //wbtc/eth
    {
      pair: '0x7c21e2e777cecaf969bf7b7aef0e3bf23d043672',
      id: '0x0B5d073eA715f38Fde1dE52689CFaB041cC8d278',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
      stable: false
    },
        //weth/usdc v4
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0xCc05E242b4A82f813a895111bCa072c8BBbA4a0e',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      stable: false
    },
  ],
    
  AMPLIFIER_V2: [
    // KOI POOLS
    //weth/usdc normal
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0xe5095a9d6fe9573722706062eaafae422134cd33',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      fee: 65, // 0.65%
      ampBoost: 2, // 2x
      apy: 1000, // 10%
      stable: false,
      treasury: '0x63F44e1Ca8960Df9f463f7E42D2ecB81b7BC2225',
      payoutToken: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
      oracle: '0xA366894a5Bf1Cd7317A7bF49715eF76f0edC72E6',
      maxRewards: 625000

    },
    //weth/wbtc normal
    {
      pair: '0x7c21e2e777cecaf969bf7b7aef0e3bf23d043672',
      id: '0xa5bfd75f4693f8358a38f3d63cc4b9cceedb61cb',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
      fee: 65, // 0.65%
      ampBoost: 2, // 2x
      apy: 1000, // 10%
      stable: false,
      treasury: '0x63F44e1Ca8960Df9f463f7E42D2ecB81b7BC2225',
      payoutToken: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
      oracle: '0xA366894a5Bf1Cd7317A7bF49715eF76f0edC72E6',
      maxRewards: 625000
    },
    //usdc/usdt stable
    {
      pair: '0x9d2811B85c1d736427722817B69e4D1E98016BB0',
      id: '0x7ab201850808c19E882876d8DF573bA07488a72e',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
      fee: 33, // 0.65%
      ampBoost: 2, // 2x
      apy: 500, // 10%
      stable: true,
      treasury: '0x63F44e1Ca8960Df9f463f7E42D2ecB81b7BC2225',
      payoutToken: '0xa995ad25ce5eb76972ab356168f5e1d9257e4d05',
      oracle: '0xA366894a5Bf1Cd7317A7bF49715eF76f0edC72E6',
      maxRewards: 625000
    },

    // MUTE POOLS
    //eth/zorro normal 
    {
      pair: '0x33EC51BEc17A25A7Bea04BCD600Cc805142efDeD',
      id: '0x359da4b89d09f70c4db49be44aabd9cda176f9a0',
      token0: '0x244c238325fc1bdf6eded726ee1b47d55895d944',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      fee: 25, // 0.25%
      ampBoost: 2, // 2x
      apy: 5000, // 50%
      stable: false,
      treasury: '0x37d29EfF3210A51912Df2c354e2d025B4412dd51',
      payoutToken: '0x244c238325fc1bdf6eded726ee1b47d55895d944',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 20000000
    },
    //eth/hold normal 
    {
      pair: '0xd5a69D2bD59ae04EA931B4e2bD30aED6A412Ce10',
      id: '0xf1a088c5b364088cf88551b0bf2453651faf4029',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2',
      fee: 50, // 0.5%
      ampBoost: 2, // 2x
      apy: 2000, // 20%
      stable: false,
      treasury: '0x74f2aFb4a0311394662979179790c669bf139876',
      payoutToken: '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 1560
    },

    //weth/usdc normal
    {
      pair: '0xDFAaB828f5F515E104BaaBa4d8D554DA9096f0e4',
      id: '0x974B1bAc17fdFD233FD3c4A64A926813D479d40b',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      fee: 65, // 0.65%
      ampBoost: 2, // 2x
      apy: 1000, // 10%
      stable: false,
      treasury: '0x750921e5a5eA4C5BeEA9Ec3C0C3F3CBd73A6bF3B',
      payoutToken: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 215000

    },
    //weth/wbtc normal
    {
      pair: '0x7c21e2e777cecaf969bf7b7aef0e3bf23d043672',
      id: '0xfF76E49f36C4f593639AecB6b6F17d4C94d831c0',
      token0: '0x5AEa5775959fBC2557Cc8789bC1bf90A239D9a91',
      token1: '0xBBeB516fb02a01611cBBE0453Fe3c580D7281011',
      fee: 65, // 0.65%
      ampBoost: 2, // 2x
      apy: 1000, // 10%
      stable: false,
      treasury: '0x750921e5a5eA4C5BeEA9Ec3C0C3F3CBd73A6bF3B',
      payoutToken: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 215000
    },
    //usdc/usdt stable
    {
      pair: '0x9d2811B85c1d736427722817B69e4D1E98016BB0',
      id: '0x820681cA09f44dc8637B49194022dce7e1578E29',
      token0: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
      token1: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
      fee: 33, // 0.65%
      ampBoost: 2, // 2x
      apy: 500, // 10%
      stable: true,
      treasury: '0x750921e5a5eA4C5BeEA9Ec3C0C3F3CBd73A6bF3B',
      payoutToken: '0x0e97C7a0F8B2C9885C8ac9fC6136e829CbC21d42',
      oracle: '0xee6C10f802d99890FBeac45b3e9A9f6CDB9B1700',
      maxRewards: 215000
    },
  ],

  
  BOND: {
    "bond": "0x10cC3Ec960726948B3491AC32a44980C7C9D5c30",
    "treasury": "0xeb07c0b21D8ebE15717d224E375476FD0CEbE128",
    "lp": "0xb85feb6aF3412d690DFDA280b73EaED73a2315bC"
  }
}

export default function getContracts(isMainnet) {
  if(isMainnet)
    return CONTRACTS_MAINNET

  return CONTRACTS_TESTNET
}