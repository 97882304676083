import React, { useState, useRef } from 'react'
import styled, {keyframes} from 'styled-components';
import {useLocation, useHistory, useParams} from "react-router-dom";
import BigNumber from 'bignumber.js';

import { ButtonPrimaryLarge } from '../../common/buttons/Button'

import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';

import angleIcon from '../../assets/images/icons/angle-bright.svg'
import externalLink from '../../assets/images/icons/external_link.svg'
import muteDesignIcon from '../../assets/images/icons/mute_design_icon.png'
import { ShadowWidget } from '../../common/ShadowWidget'

import { reduceString } from '../../utils/reduceString';
import { formatNumber } from '../../utils/formatNumber';
import { AmountSection } from '../../common/section/AmountSection'
import {LoadingAnimation} from '../../common/loading/loading'
import {LoadingBox} from '../../common/loading/LoadingBox'
import { useWalletHook } from '../../web3/walletHook';
import { WalletState } from '../../state';
import { useWalletSelector } from '../../state/hooks';

const queryString = require('query-string');

interface ParamTypes {
  poolId: string;
}

enum Modal {
  Settings,
  ChangeFee
}


function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':00';
  return time;
}

function timer(startTime) {
  if(startTime == null)
    return null

  let seconds = startTime - (Date.now() / 1000)

  if(seconds < 0)
    return null

  var days        = Math.floor(seconds/24/60/60);
  var hoursLeft   = Math.floor((seconds) - (days*86400));
  var hours       = Math.floor(hoursLeft/3600);
  var minutesLeft = Math.floor((hoursLeft) - (hours*3600));
  var minutes     = Math.floor(minutesLeft/60);
  var remainingSeconds = Math.floor(seconds % 60)

  function pad(n) {
    return (n < 10 ? "0" + n : n);
  }
  let time = pad(days) + "d:" + pad(hours) + "h:" + pad(minutes) + "m:" + pad(remainingSeconds) + 's'
  return time
}

export const AmplifierSubScreen = ({poolInfo}) => {
  let location = useLocation()
  const history = useHistory()
  const { poolId } = useParams<ParamTypes>();

  const { stakeInAmplifier, withdrawFromAmplifier, payoutFromAmplifier, updateAmp } = useWalletHook()

  const [assetA, setAssetA] = useState<any>(undefined)
  const [assetB, setAssetB] = useState<any>(undefined)

  const [assetAAmount, setAssetAAmount] = useState('0')
  const [estimateAmount, setEstimateAmount] = useState('0')


  const [poolAPY, setPoolAPY] = useState('0')
  const [poolAPYBase, setPoolAPYBase] = useState('0')

  const [isDeposit, setIsDeposit] = useState(true)
  const [calculating, setCalculating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [countdown, setCountdown] = useState<String | null>('')
  const tokens = useWalletSelector((state: WalletState) => state.tokens)
  const amplifiers = useWalletSelector((state: WalletState) => state.amplifiers)
  const [poolData, setPoolData] = useState<any | null>(null);



  React.useEffect(() => {
    
    for(let i in amplifiers){
      if(amplifiers[i].id == poolId){
        setPoolData(amplifiers[i])
        setIsLoading(false)
        setCountdown(timer(amplifiers[i].startTime))
        if(new BigNumber(amplifiers[i].baseAPY).gt(10000)){
          setPoolAPY('10000%+')
          setPoolAPYBase('10000%+')
        }
        else{
          setPoolAPY(amplifiers[i].maxAPY + '%')
          setPoolAPYBase(amplifiers[i].baseAPY + '%')
        }
        break
      }
    }

    return () => {}

  }, [amplifiers]);


  React.useEffect(() => {
    if(poolData){
      setAssetA(tokens[poolData.token0.address.toLowerCase()])
      setAssetB(tokens[poolData.token1.address.toLowerCase()])
      
      var timeFunc = setInterval(() => {
        setCountdown(timer(poolData.startTime))
      }, 1000)
    }

    return () => {
      if(timeFunc)
        clearInterval(timeFunc)
    }


  }, [poolData, tokens]);


  const getDepositButtonStatus = () => {
    if(poolData && poolData.approved){
      if(new BigNumber(poolData.approved).lt(assetAAmount)){
        return 'Approve'
      }
    }

    if(poolData){
      if(new BigNumber(poolData.startTime).gt(Date.now() / 1000)){
        return countdown
      }

      if(new BigNumber(poolData.endTime).lt(Date.now() / 1000)){
        return "Pool expired"
      }
    }

    if(new BigNumber(assetAAmount).lte(0))
      return 'Invalid amounts'


    if(assetA && assetB){
      if(new BigNumber(assetAAmount).gt(poolData.balance))
        return 'Invalid amounts'
    }


    return 'Stake LP'
  }

  const getWithdrawButtonStatus = () => {
    if(new BigNumber(poolData ? poolData.totalUserStake : 0).lte(0))
      return 'Unavailable'

    return 'Withdraw LP'
  }

  const getCollectStatus = () => {
    if(poolData && new BigNumber(poolData.endTime).lt(Date.now() / 1000)){
      return "Collect & Unstake"
    }
    return 'Collect'
  }


  const shouldDisable = () => {
    if(getDepositButtonStatus() == 'Stake LP' || getDepositButtonStatus() == 'Approve')
      return false

    return true
  }

  const shouldDisableWithdraw = () => {
    if(getWithdrawButtonStatus() == 'Withdraw LP')
      return false

    return true
  }

  const stakeInAmplifierLocal = async () => {
    setCalculating(true)
    await stakeInAmplifier(poolId, assetAAmount, poolData.pair)
    await updateAmp()
    setCalculating(false)
  }

  const withdrawLiquidity = async () => {
    setCalculating(true)
    await withdrawFromAmplifier(poolId)
    await updateAmp()
    setCalculating(false)
  }

  const payoutFromAmplifierLocal = async () => {
    setCalculating(true)
    if(new BigNumber(poolData.endTime).lt(Date.now() / 1000)){
      await withdrawFromAmplifier(poolId)
    } else {
      await payoutFromAmplifier(poolId)
    }
    await updateAmp()
    setCalculating(false)
  }

  const changeAssetA = (val) => {
    setAssetAAmount(val)
  }

  const getROICalculator = () => {
    let apy = new BigNumber(poolData.multiplier).times(poolData.baseAPY).plus(poolData.lpAPY).div(100)

    let days_left = new BigNumber(poolData.endTime).minus(Date.now() / 1000).div(60 * 60 * 24)
    
    return apy.times(estimateAmount).div(365).times(days_left).minus(new BigNumber(estimateAmount).times(poolData.managementFee).div(10000)).toFixed(2)
  }

  const getROICalculatorLP = () => {
    let apy = new BigNumber(poolData.lpAPY).div(100)

    let days_left = new BigNumber(poolData.endTime).minus(Date.now() / 1000).div(60 * 60 * 24)
    
    return apy.times(estimateAmount).div(365).times(days_left).toFixed(2)
  }

  const getROICalculatorAmp = () => {
    let apy = new BigNumber(poolData.multiplier).times(poolData.baseAPY).div(100)

    let days_left = new BigNumber(poolData.endTime).minus(Date.now() / 1000).div(60 * 60 * 24)
    
    return apy.times(estimateAmount).div(365).times(days_left).toFixed(2)
  }

  return (
    <Screen>
      <Layout>

        <TitleContainer>
          <BackButton onClick={() => history.push('/amplifier')}/>
          <Spacer/>
          {isLoading == true ? <TitleContainer><LoadingBox/></TitleContainer> : (
            <>
            <Title>{assetA ? (assetA.symbol + '/' + assetB.symbol) : ''} {poolData.stable ? ' sMLP' : ' vMLP'} </Title>
            <Spacer/>
            <Subtitle>Amplifier Pool</Subtitle>
            </>
          )}

        </TitleContainer>

        <div style={{gridArea: 'empty_section'}}>
        </div>

        <DataContainer style={{gridArea: 'exchange'}}>
          <DataContainerColumn>
            <StatRow>
            <ExchangeHeaderButton className={isDeposit ? 'active' : ''} onClick={() => setIsDeposit(true)}>
            Stake
            </ExchangeHeaderButton>
            <ExchangeHeaderButton className={isDeposit ? '' : 'active'} onClick={() => setIsDeposit(false)}>
              Unstake
            </ExchangeHeaderButton>
            </StatRow>

            {
              isDeposit ?
              (
                <>
                <ShadowWidget>
                <AmountSection
                  value={assetAAmount}
                  onChange={changeAssetA}
                  assets={[]}
                  selectedAsset={{amount: '0', balance: new BigNumber(poolData ? poolData.balance : 0).toFixed(), logo: assetA ? assetA.logo : '', symbol: assetA ? (assetA.symbol + '/' + assetB.symbol + ' LP') : '', name: '', value: '', decimals: 18, price: ''}}
                  disableEdit={true}
                  onAssetSelect={() => {}}
                  label="Add"
                  price={new BigNumber(poolData ? new BigNumber(poolData.lpPrice).times(assetAAmount) : 0).toFixed()}
                />
                </ShadowWidget>
                <Spacer/>
                <Spacer/>
                <Spacer/>


                <StatRow>
                  <StatText>
                  Farm fee (applied to LP deposited)
                  </StatText>
                  <StatTextHighlight>
                  {poolData ? new BigNumber(poolData.managementFee).div(100).toFixed(2) : '0.00'}%
                  </StatTextHighlight>
                </StatRow>
                <Button onClick={() => stakeInAmplifierLocal()} disabled={shouldDisable()}>
                  <LoadingAnimation isLoading={calculating} />
                  {getDepositButtonStatus()}
                </Button>
                </>
              ) :

              (
                <>



                <Spacer/>
                <Spacer/>
                <Spacer/>
                <StatRow>
                  <StatText>
                  Amount
                  </StatText>
                  <StatTextHighlight>
                  {formatNumber(poolData ? poolData.totalUserStake : 0, 2) + ' ' + (assetA ? (assetA.symbol + '/' + assetB.symbol + ' LP') : 'LP')}
                  </StatTextHighlight>
                </StatRow>

                <StatRow>

                  <ErrorText>
                    Notice: Once you withdraw your LP, you will have to repay the management fee again to stake in a similar pool.
                  </ErrorText>
                </StatRow>

                <Button onClick={() => withdrawLiquidity()} disabled={shouldDisableWithdraw()}>
                  <LoadingAnimation isLoading={calculating} />
                  {getWithdrawButtonStatus()}
                </Button>
                </>

              )

            }

          </DataContainerColumn>
        </DataContainer>

        <DataContainer style={{gridArea: 'rewards'}}>
          <DataContainerColumn>
          <ContainerText>
          Rewards (vested for 7 days when claimed via dMute)
          </ContainerText>

            <HeaderText style={{width: '100%'}}>
            <MuteIcon src={muteDesignIcon} />
              {poolData ? new BigNumber(poolData.currentClaimable).toFixed(2) : '0.00'} MUTE

            </HeaderText>
            <HeaderText style={{width: '100%'}}>
            {assetA  && assetA.logo ? <MuteIcon src={assetA ? assetA.logo : ''} /> : <></>}
            {poolData ? formatNumber(new BigNumber(poolData.currentClaimableFee0).div(Math.pow(10,poolData.token0.decimals)).toFixed(), 2) : '0.00'}  {assetA ? assetA.symbol : ''}

            </HeaderText>
            <HeaderText style={{width: '100%'}}>
            {assetB  && assetB.logo ? <MuteIcon src={assetB ? assetB.logo : ''} /> : <></>}
              {poolData ? formatNumber(new BigNumber(poolData.currentClaimableFee1).div(Math.pow(10,poolData.token1.decimals)).toFixed(), 2) : '0.00'}  {assetB ? assetB.symbol : ''}
            </HeaderText>
            <Button onClick={() => payoutFromAmplifierLocal()} disabled={poolData ? new BigNumber(poolData.currentClaimable).lte(0) : true}>
              <LoadingAnimation isLoading={calculating} />
              {getCollectStatus()}
            </Button>

          </DataContainerColumn>
        </DataContainer>


        <DataContainerAPY style={{gridArea: 'pool_stats'}}>
          <DataContainerColumn>
            <ContainerText>
            Total staked
            </ContainerText>
            <HeaderText>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? "$" + formatNumber(new BigNumber(poolData.lpPrice).times(poolData.totalStaked), 2) : '$0.00')
              }
            </HeaderText>
          </DataContainerColumn>
          <DataContainerColumn>
            <ContainerText>
            APY
            </ContainerText>
            <HeaderTextGreen>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? new BigNumber(poolData.multiplier).times(poolData.baseAPY).plus(poolData.lpAPY).toFixed(2) + '%' : poolAPY)
              }
            </HeaderTextGreen>
          </DataContainerColumn>
        </DataContainerAPY>

        <DataContainerAPY style={{gridArea: 'estimate_apy'}}>
          <DataContainerColumn>
            <ContainerText>
            Estimate ROI Calculator (based on pool expiry and your multiplier)
            </ContainerText>

            <ShadowWidget>
                <AmountSection
                  value={estimateAmount}
                  onChange={setEstimateAmount}
                  assets={[]}
                  selectedAsset={{amount: '0', balance: '', logo: '', symbol: '', name: '', value: '', decimals: 18, price: ''}}
                  disableEdit={true}
                  onAssetSelect={() => {}}
                  label="Estimate"
                  price={estimateAmount}
                />
            </ShadowWidget>
            <Spacer/>
            <Spacer/>
            <Spacer/>

            <StatRow>
              <StatText>
                  LP ROI
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? "$" + formatNumber(getROICalculatorLP(), 2) : '$0.00')
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                  Amplifier ROI
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? "$" + formatNumber(getROICalculatorAmp(), 2) : '$0.00')
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                  Management Fee
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? "$" + formatNumber(new BigNumber(estimateAmount).times(poolData.managementFee).div(10000).toFixed(), 2) : '$0.00')
              }
              </StatTextHighlight>
            </StatRow>

            <StatRow>
              <StatText>
                  Net ROI
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? "$" + formatNumber(getROICalculator(), 2) : '$0.00')
              }
              </StatTextHighlight>
            </StatRow>

          </DataContainerColumn>
        </DataContainerAPY>

        <DataContainer style={{gridArea: 'my_stats'}}>
          <DataContainerColumn>
            <ContainerText>
            My stats
            </ContainerText>
            <StatRow>
              <StatText>
              Total staked amount
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? formatNumber(poolData.totalStaked, 2) + " " : "0") + (assetA ? assetA.symbol + "/" : "") + (assetB ? assetB.symbol : '') + (poolData.stable ? ' sMLP' : ' vMLP'))
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Underlying assets
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? formatNumber(poolData.underlyingAssetA != 'NaN' ? poolData.underlyingAssetA : '0', 2) + " " : "0") + (assetA ? assetA.symbol + " / " : "") + (poolData ? formatNumber(poolData.underlyingAssetB != 'NaN' ? poolData.underlyingAssetB : '0', 2) + " " : "0") + (assetB ? assetB.symbol : ''))
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Multiplier (up to {(poolData ? new BigNumber(poolData.divisor).toFixed(2) + 'x' : '3.00x')})
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                (poolData ? new BigNumber(poolData.multiplier).toFixed(2) + 'x' : '0.00x')
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Pool share
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? new BigNumber(poolData.totalUserStake).div(poolData.totalStaked == "0" ? 1 : poolData.totalStaked).times(100).toFixed(2) + '%' : '0%'))
              }
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>


        <DataContainer style={{gridArea: 'pool_details'}}>
          <DataContainerColumn>
            <ContainerText>
            Amplifier farm details
            </ContainerText>
            <StatRow>
              <StatText>
              Min - Max APY
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                poolData ? (new BigNumber(poolData.baseAPY).plus(poolData.lpAPY).toFixed(2) + '% - ' + new BigNumber(poolData.maxAPY).plus(poolData.lpAPY).toFixed(2)) + '%' : '0.00%'
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Total rewards
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox/>) :
                  ((poolData ? formatNumber(poolData.totalRewards, 2) : "0") + " MUTE")
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Total claimed
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox/>) :
                  ((poolData ? formatNumber(poolData.totalClaimedRewards, 2) : "0") + " MUTE")
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Total reclaimed
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox/>) :
                  ((poolData ? formatNumber(poolData.totalReclaimed, 2) : "0") + " MUTE")
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Vault address
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? reduceString(poolData.id, 7, 4) : ''))
              }
              <ExternalLink src={externalLink} onClick={() => {window.open('https://explorer.zksync.io/address/' + poolData.id, "_blank")}} />
              </StatTextHighlight>
            </StatRow>

            <StatRow>
              <StatText>
              End date
              </StatText>
              <StatTextHighlight>
              {isLoading == true ? (<LoadingBox/>) :
                ((poolData ? timeConverter(poolData.endTime) : ''))
              }
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>
      </Layout>
    </Screen>
  )
}
//          <CreatePair externalOpen={createLiqModal} assets={tokens} createPair={addLiquidity} importPool={importPool} addLiquidity={addLiquidityScreenPopup}/>
//          <ImportPool assets={tokens} importPool={importPool} addPool={addPool} />
// <LiquidityHistory history={liquidity.history}/>

const Layout = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "titlecontainer titlecontainer"
  "pool_stats  exchange"
  "my_stats exchange"
  "pool_details rewards"
  "estimate_apy rewards";

  grid-auto-rows: minmax(min-content, max-content);


  @media (max-width: 767px) {
    column-gap: 0px;
    row-gap: 8px;
    grid-template-columns: 1fr;
    grid-template-areas:
    "titlecontainer"
    "pool_stats"
    "exchange"
    "rewards"
    "my_stats"
    "estimate_apy"
    "pool_details";
  }
`;


const ExternalLink = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 8px;
`

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`
const SpacerBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2E3551;
`
const TitleContainer = styled.div`
  position: relative;
  overflow: hidden;
  grid-area: titlecontainer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`

const StatText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: left;
  color: #7F7D97;
`

const StatTextHighlight = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F6F8FE;
`

const Subtitle = styled.div`
  width: auto;
  padding: 0 10px;
  height: 27px;
  background: rgba(41, 48, 74, 0.4);
  border-radius: 8px;
  display: flex;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  justify-content: center;
  align-items: center;

  color: #777F99;
`

const BackButton = styled.button`
  background: rgba(24, 29, 47, 0.7);
  border-radius: 14px;
  background-image: url(${angleIcon});
  background-size: cover;
  transform: rotate(90deg);

  width: 40px;
  height: 40px;
`

const DataContainer = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 600px) {
    padding: 16px;
  }
`

const DataContainerAPY = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 600px) {
    padding: 16px;
    flex-direction: column;
  }
`

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
`

const ContainerText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #777F99;
  padding-bottom: 18px;
`

const HeaderText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  position: relative;
  display: flex;
  min-height: 30px;
  width: 50%;
  align-items: center;
`

const HeaderTextGreen = styled.h1`
  position: relative;
  width: 50%;
  min-height: 30px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #0BD98F;
`


const ExchangeHeaderButton = styled.button`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #7F7D97;
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #1D2336;

  &.active {
    color: var(--color-blue-accent);
    border-bottom: 2px solid var(--color-blue-accent);
  }
`

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 20px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;


const SettingAnim = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(60deg); }
`

const SettingButton = styled.button`
  width: 24px;
  height: 35px;
  margin-left: 8px;
  padding: 0;

  :disabled{
    opacity: 0.5;
    cursor: not-allowed;

  }

  :hover {
    opacity: 0.5;
    animation: ${SettingAnim} 0.5s forwards;
  }
`;

const MuteIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 20px;
`;


const ErrorText = styled.p`
  margin-top: 46px;
  font-size: 18px;
  color: rgb(255, 95, 21);
  margin-bottom: 15px;
`;