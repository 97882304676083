import styled from 'styled-components';
import React from 'react'
import { TextMedium } from './text/Text';
import { HelpCircle } from 'react-feather'

interface SymbolWithLogoProps {
  logo: string;
  symbol: string;
  name?: string;
  text?: string
}

export const SymbolWithLogoText = ({logo, symbol, name, text}: SymbolWithLogoProps) => {
  if(logo && logo != ''){
    return (
      <Row>
        <Logo src={logo} alt={`${symbol} logo`} />
        <Column>
        <Symbol>{symbol} {text != '' ? <FeeDiscount>{text}</FeeDiscount> : <></> }</Symbol>
        <Name>{name}</Name>

        </Column>

      </Row>
    )
  } else {
    return  <Row/>

  }

}

const FeeDiscount = styled.div`
    color: var(--color-blue);
    border: 1px solid var(--color-blue);
    border-radius: 18px;
    padding:7px;
    margin-left: 15px;
    height: 23px;
    align-items: center;
    text-align: center;
    justify-content: center;
    line-height: 50%;
    width: 100%;


    font-weight: 400;
    font-size: 14px;

    margin-top: -5px;

`

const Row = styled.div`
  display: flex;
  align-items: center;
  max-width: 60%;
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 30px;
`;

const Symbol = styled(TextMedium)`
display: flex;
flex-direction: row;
font-weight: 500;
font-size: 16px;
line-height: 120%;
width: auto;
`;

const Name = styled(TextMedium)`
font-weight: 500;
font-size: 12px;
color: var(--color-gray);
`;


const Column = styled.div`
  flex-direction: column;
  display: flex;
  text-align: left;

`;
