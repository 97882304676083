import React, { useState } from 'react'
import { ButtonSecondaryLarge } from '../../../common/buttons/Button'
import { Asset } from '../../../model/Asset'
import { AddLiquidityModal } from './AddLiquidityModal'
import { ConfirmTransactionModal } from '../../../common/modal/ConfirmTransactionModal'
import { LiquidityModalFooter } from './LiquidityModalFooter'
import { SuccessfulSubmitModal } from '../../../common/modal/SuccessfulSubmitModal'
import { TransactionDirection } from '../../../common/TransactionDirection'
import plusIcon from '../../../assets/images/icons/plus-bright.svg'
import BigNumber from "bignumber.js";

enum Modal {
  AddLiquidity,
  Confirm,
  Submitted,
  AssetsListA,
  AssetsListB
}

interface AddLiquidityProps {
  assets: Asset[];
}

export const AddLiquidity = ({externalOpen, assets, calculateLiquidity, addLiquidity, manageLiquidity}) => {

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => {
    setDisableEdit(false)
    setModal(null)
    setAssetAmountA('')
    setAssetAmountB('')
    setSelectedAssetA(undefined)
    setSelectedAssetB(undefined)
  };

  const [selectedAssetA, setSelectedAssetA] = useState<Asset | undefined>();
  const [assetAmountA, setAssetAmountA] = useState('');

  const [selectedAssetB, setSelectedAssetB] = useState<Asset | undefined>();
  const [assetAmountB, setAssetAmountB] = useState('');

  const [disableEdit, setDisableEdit] = useState(false)
  const [confirmReady, setConfirmReady] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [share, setShare] = useState('');
  const [calculating, setCalculating] = useState(false);
  const [stable, setStable] = useState(false);


  const onSupplyButtonClick = () => {
    manageLiquidity(selectedAssetA!.address, selectedAssetB!.address, stable)
    closeModal()
    //setConfirmReady(true)
  }

  const externalOpenFunc = (token0, token1) => {
    setDisableEdit(true)
    setSelectedAssetA(token0)
    setSelectedAssetB(token1)
    setModal(Modal.AddLiquidity)
  }

  React.useEffect(() => {
    externalOpen.current = externalOpenFunc
    if(confirmReady == true){
      setConfirmReady(false)
      setModal(Modal.Confirm)
      addLiquidity(selectedAssetA, selectedAssetB, assetAmountA, assetAmountB).then(res => {
        if(res.success == true){
          setModal(Modal.Submitted)
        } else {
          closeModal()
        }
      })
    }

    return () => {
      console.log('unmounted')
    }

  }, [confirmReady]);

  const setAssetAAmountAndChange = async (val) =>{
    if(new BigNumber(val).toNumber() == 0){
      setAssetAmountA(val)
      setAssetAmountB('0')
      return
    }
    setAssetAmountA(val)

    if(selectedAssetA && selectedAssetB) {
      setCalculating(true)
      var res = await calculateLiquidity(selectedAssetA, selectedAssetB, val, null, false)
      setCalculating(false)

      if(res.success == false){
        closeModal()
        return
      }

      setShare(res.share)

      if(res.pair1 && selectedAssetA.symbol == res.pair1.symbol){
        setAssetAmountA(res.amountA)
        setAssetAmountB(res.amountB)
        if(new BigNumber(selectedAssetA.amount).gte(res.amountA) && new BigNumber(selectedAssetB.amount).gte(res.amountB)){
          setDisableButton(false)
        } else
          setDisableButton(true)

      } else if(res.pair2 && selectedAssetA.symbol == res.pair2.symbol){
        setAssetAmountA(res.amountB)
        setAssetAmountB(res.amountA)

        if(new BigNumber(selectedAssetA.amount).gte(res.amountB) && new BigNumber(selectedAssetB.amount).gte(res.amountA)){
          setDisableButton(false)
        } else
          setDisableButton(true)
      } else {
        setDisableButton(true)
      }
    }
  }

  const setAssetBAmountAndChange = async (val) =>{
    if(new BigNumber(val).toNumber() == 0){
      setAssetAmountB(val)
      return
    }
    setAssetAmountB(val)
    if(selectedAssetA && selectedAssetB) {
      setCalculating(true)
      var res = await calculateLiquidity(selectedAssetA, selectedAssetB, null, val, false)
      setCalculating(false)
      if(res.success == false){
        closeModal()
        return
      }

      setShare(res.share)

      if(res.pair1 && selectedAssetA.symbol == res.pair1.symbol){
        setAssetAmountA(res.amountA)
        setAssetAmountB(res.amountB)
        if(new BigNumber(selectedAssetA.amount).gte(res.amountA) && new BigNumber(selectedAssetB.amount).gte(res.amountB)){
          setDisableButton(false)
        } else
          setDisableButton(true)

      } else if(res.pair2 && selectedAssetA.symbol == res.pair2.symbol){
        setAssetAmountA(res.amountB)
        setAssetAmountB(res.amountA)
        if(new BigNumber(selectedAssetA.amount).gte(res.amountB) && new BigNumber(selectedAssetB.amount).gte(res.amountA)){
          setDisableButton(false)
        } else
          setDisableButton(true)
      } else {
        setDisableButton(true)
      }

    }
  }


  return (
    <>
      <ButtonSecondaryLarge
        disabled={false}
        onClick={() => setModal(Modal.AddLiquidity)}
      >
        Add Liquidity
      </ButtonSecondaryLarge>
      <AddLiquidityModal
        isVisible={modal === Modal.AddLiquidity}
        onClose={closeModal}
        assets={assets}
        assetAmountA={assetAmountA}
        setAssetAmountA={setAssetAAmountAndChange}
        assetAmountB={assetAmountB}
        setAssetAmountB={setAssetBAmountAndChange}
        selectedAssetA={selectedAssetA}
        setSelectedAssetA={setSelectedAssetA}
        selectedAssetB={selectedAssetB}
        setSelectedAssetB={setSelectedAssetB}
        onSupplyButtonClick={onSupplyButtonClick}
        disableButton={disableButton}
        disableEdit={disableEdit}
        calculating={calculating}
        stable={stable}
        setStable={setStable}
      />
      <ConfirmTransactionModal
        isVisible={modal === Modal.Confirm}
        onClose={closeModal}
        onConfirm={() => {}}
        leftValue={`${parseFloat(assetAmountA).toFixed(4)} ${selectedAssetA?.symbol}`}
        rightValue={`${parseFloat(assetAmountB).toFixed(4)} ${selectedAssetB?.symbol}`}
        icon={plusIcon}
        footer={
          <LiquidityModalFooter
            selectedAssetA={selectedAssetA}
            selectedAssetB={selectedAssetB}
            assetAmountA={assetAmountA}
            assetAmountB={assetAmountB}
            share={share}
          />
        }
      />
      <SuccessfulSubmitModal
        isVisible={modal === Modal.Submitted}
        onClose={closeModal}
        title="Adding liquidity has been submitted!"
        description="You can add or remove liqudity any time from Pool page."
        savings="$67.43"
        costs={"$0.55"}
        onSubmit={() => null}
        section={
          <TransactionDirection
            icon={plusIcon}
            leftValue={`${parseFloat(assetAmountA).toFixed(4)} ${selectedAssetA?.symbol}`}
            rightValue={`${parseFloat(assetAmountB).toFixed(4)} ${selectedAssetB?.symbol}`}
          />
        }
        footer={
          <LiquidityModalFooter
            selectedAssetA={selectedAssetA}
            selectedAssetB={selectedAssetB}
            assetAmountA={parseFloat(assetAmountA).toFixed(4)}
            assetAmountB={parseFloat(assetAmountB).toFixed(4)}
            share={share}
          />
        }
      />
    </>
  );
}
