import React from 'react'
import styled from 'styled-components'
import { Amount } from '../../common/text/Amount'
import { Widget } from '../../common/widgets/Widget'
import {useSpring, animated} from 'react-spring'
import { formatNumber } from '../../utils/formatNumber'

function Number({n, prev}){
  const {number} = useSpring({
    from: {number: prev},
    number: n,
    delay: 50,
    config: {mass: 1, tension: 20, friction: 10, duration: 1000},
  })

  return <AnimatedAmount>{number.to(value => ('$' + formatNumber(value, 2)))}</AnimatedAmount>
}
export const AssetValueWidget = ({value, title, area}) => {
  return (
    <View
      title={title}
      className={area}
    >
      <Number n={value} prev={0}/>
    </View>
  )
}

const View = styled(Widget)`
  &.assetValue {
    grid-area: assetValue;
  }
  &.savedOnGas {
    grid-area: savedOnGas;
  }
  background: var(--color-modal-light-blue);
  box-shadow: none;
`;

const AnimatedAmount= styled(animated.div)`
  text-align: left !important;
  color: #17C687;
  font-weight: 500;
  font-size: 32px;
  line-height: 150%;
  text-align: right;
`;