import React, { useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import styled from "styled-components";
import logo from "./../../assets/images/logo_mute.png";
import discord from "./../../assets/images/Discord_white.png";
import twitter from "./../../assets/images/Twitter_white.png";
import telegram from "./../../assets/images/Telegram_white.png";

import menuIcon from "./../../assets/images/icons/hamburger.svg";
import closeIcon from './../../assets/images/icons/cross-grey.svg'
import { useToggler } from "../../hooks/useToggler";
import { useClickOutside } from "../../hooks/useClickOutside";
import { Connection } from "../Connection";

export const Footer = () => {
  const location = useLocation();

  return (
    <FooterRow>
      <HeaderSection>
        <Link to={{ pathname: "https://mute.io" }} target="_blank" >
          <LogoImage src={logo} />
        </Link>
      </HeaderSection>

      <Menu>
        <MenuContent>
            <NavList>
              <NavItem>
                <Link to={{ pathname: "https://discord.com/invite/muteio" }} target="_blank" >
                  <SocialImage src={discord}/>
                </Link>
              </NavItem>
              <NavItem>
                <Link to={{ pathname: "https://twitter.com/mute_io" }} target="_blank" >
                  <SocialImage src={twitter}/>
                </Link>

              </NavItem>
              <NavItem>
                <Link to={{ pathname: "https://t.me/mute_io" }} target="_blank" >
                  <SocialImage src={telegram}/>
                </Link>

              </NavItem>

            </NavList>
        </MenuContent>
      </Menu>

    </FooterRow>
  )
}

const LogoImage = styled.img`
  width: calc(0.3* 405px);
  height: calc(0.3 * 121px);
  @media (max-width: 600px) {
    width: calc(0.2* 405px);
    height: calc(0.2 * 121px);
  }
`

const SocialImage = styled.img`
  width: 30px;
  height: 30px;
  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`
const FooterRow = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 32px;
  z-index: 5;
  height: 150px;

  @media (max-width: 1000px) {
    justify-content: flex-start;
  }

  @media (max-width: 1000px) {
    padding: 8px 16px;
  }
`;

const HeaderSection = styled.div`
  width: 130px;

  @media (max-width: 1000px) {
    width: initial;
  }
`;

const Preview = styled.div`
font-family: 'Aeonik';
text-transform: uppercase;
font-size: 14px;
line-height: 13px;
text-transform: uppercase;
color: #FFFFFF;
`;

const Menu = styled.div`
  height: 100px;
  @media (max-width: 1000px) {
    margin-left: auto;
  }

`;


const MenuContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 1000px) {
    flex-direction: column;
    padding: 48px 32px 32px;
  }
`;

const NavList = styled.ul`
  display: flex;
  align-items: center;


`;

const Link = styled(NavLink)`
  font-family: 'Aeonik';
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: var(--color-dark-gray);
  height: 100%;
  display: flex;
  align-items: center;

  &.active {
    color: var(--color-blue-accent) !important;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

const NavItem = styled.li`
  height: 100%;
  align-items: center;
  cursor: pointer;
  display: flex;
  position: relative;
  margin-left: 15px;

  :hover {
    ${Link} {
      color: rgba(84,190,249, 1);
    }
  }

  &:hover {
    ${Link} {
      color: rgba(84,190,249, 1);
    }
  }



`;
