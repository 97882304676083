import styled from "styled-components";

export const BlockTitle = styled.h2`
display: flex;
align-items: center;
justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #777F99;
`;
