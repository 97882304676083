import styled from "styled-components";
import angleIcon from '../../assets/images/icons/angle-light.svg'

const BasicText = styled.p`
  font-size: 14px;
  line-height: 150%;
  color: #ffffff;
`;

export const Text = styled(BasicText)`
  font-size: 14px;
  line-height: 150%;
  color: #D2D5DD;

  & b {
    font-weight: 700;
  }
`;

export const TextGrey = styled(BasicText)`
  font-weight: 500;
  color: #777F99;
`;


export const TextWhite = styled(BasicText)`
  font-weight: 500;
  color: #ffffff;
`;

export const TextGreen = styled(TextWhite)`
  color: #17C687;

`

export const TextBright = styled(BasicText)`
  font-weight: 500;
  color: #00C0FF;
`;

export const TextMedium = styled(Text)`
  font-weight: 500;
  color: #fff;
`

export const TextSemiBold = styled.p`
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
  color: #fff;
`

export const TextMediumArrow = styled(TextMedium)`
  position: relative;
  padding-right: 13.5px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 8px;
    height: 5px;
    transform: translateY(-50%) rotate(-90deg);
    background: url(${angleIcon}) center no-repeat;
    background-size: contain;
  }
`

export const TextAlt = styled(Text)`
  color: #f5f5f5;
`;
