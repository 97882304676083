import React, { useState } from 'react'
import styled, { css } from 'styled-components';
import { AmountInput } from '../AmountInput'
import { GradientButton } from '../buttons/GradientButton';
import { SectionWithBorder, SectionRow, SectionText, SectionTextHeader } from './Section'
import { Text } from '../text/Text';
import { Asset } from '../../model/Asset';
import angleIcon from '../../assets/images/icons/angle-light.svg'
import angleBrightIcon from '../../assets/images/icons/angle-bright.svg'
import { SymbolWithLogo } from '../SymbolWithLogo';
import { MaxButton } from '../buttons/MaxButton';
import { AssetsListModal } from '../modal/AssetsListModal';

import { formatNumber } from '../../utils/formatNumber';

import BigNumber from "bignumber.js";
import { transition } from '../../styles';

interface AmountSectionProps {
  value: string;
  onChange: (value: string) => void;
  selectedAsset: Asset | undefined;
  assets: {};
  onAssetSelect: (asset: Asset) => void;
  label?: string;
  className?: string;
  maxDisabled?: boolean;
  disableEdit?: boolean
  balance?: string;
  price?: string
}
//<SectionTextHeader>{label}</SectionTextHeader>

export const AmountSection = ({
  value,
  onChange,
  selectedAsset,
  className,
  label,
  assets,
  onAssetSelect,
  maxDisabled,
  disableEdit,
  price
}: AmountSectionProps) => {
  const [isAssetsModalVisible, setIsAssetsModalVisible] = useState(false);


  const getUpdatedAsset = () => {
    for(let i in assets){
      if(assets[i].address.toLowerCase() == selectedAsset?.address?.toLowerCase())
        return assets[i]
    }

    return selectedAsset
  }

  const currentAsset = getUpdatedAsset()

  return (
    <SectionWithBorder className={className}>
      <SectionRow>
      <AssetValues>
        <AmountInput
          value={value}
          onChange={(event) => {
            if(event.target.value == '')
              onChange(event.target.value)

            if(new BigNumber(event.target.value).toString() != 'NaN')
              event.target.value.length > 1 && !event.target.value.includes('0.')&& !event.target.value.includes('0,') ? onChange(event.target.value.replace(/^0+/, '')) : onChange(event.target.value)
            else if (event.target.value == '.')
              onChange('0.')
          }}
          autoComplete="off"
          autoCorrect="off"
          placeholder="0"
          minLength={1}
          disabled={maxDisabled ? true : !selectedAsset}
        />
        </AssetValues>

        {!selectedAsset ? (
          <SelectTokenButton onClick={() => setIsAssetsModalVisible(true)}>
            Select token
          </SelectTokenButton>
        ) : (
          <Row>

            {disableEdit ? (
              <SelectedAssetButtonDisabled>
                <SymbolWithLogo
                  logo={selectedAsset.logo}
                  symbol={selectedAsset.symbol}
                />
              </SelectedAssetButtonDisabled>) :
            (<SelectedAssetButton onClick={() => setIsAssetsModalVisible(true)}>
                <SymbolWithLogo
                  logo={selectedAsset.logo}
                  symbol={selectedAsset.symbol}
                />
              </SelectedAssetButton>)
            }
          </Row>
        )}
      </SectionRow>
      <SectionRow>
      <AssetPrice>{price ? '~$' + formatNumber(price, 2) : '~$0.00'}</AssetPrice>

      <SectionText style={{cursor: 'pointer'}} onClick={() => maxDisabled ? null : onChange(currentAsset!.balance.toString())}>
       {formatNumber(selectedAsset ? currentAsset.balance : '0.0', 2) != 'NaN' ? 'Balance: ' + formatNumber(selectedAsset ? currentAsset.balance : '0.0', 2) : '' } 
        </SectionText>

      </SectionRow>
      <AssetsListModal
        isVisible={isAssetsModalVisible}
        onClose={() => setIsAssetsModalVisible(false)}
        assets={assets}
        setSelectedAsset={onAssetSelect}
      />
    </SectionWithBorder>
  );
};

const angleIconStyles = css`
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 10px;
    height: 5px;
    transform: translateY(-50%);
  }
`

const SelectTokenButton = styled(GradientButton)`
  position: relative;
  padding: 0 26px 0 11px;
  min-width: 110px;
  border-radius: 12px;
  height: 28px;
  ${angleIconStyles}

  &::after {
    right: 11px;
    background: url(${angleIcon}) center no-repeat;
  }
`;

const Row = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 30%;
  justify-content: flex-end;
`;

const SelectedAssetButton = styled.button`
  position: relative;
  padding: 0 20px 0 0;
  ${angleIconStyles}

  &::after {
    right: 5px;
    background: url(${angleBrightIcon}) center no-repeat;
  }
`;


const SelectedAssetButtonDisabled = styled.button`
  position: absolute;
  padding: 0;
  right: 0;
`;


export const AssetValues = styled.div`
  text-align: left;
  max-width: 70%;
  min-width: 50%;
`;


const AssetPrice = styled(Text)`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777F99;
`;
