import React, { useState, useRef } from 'react'
import styled, {keyframes} from 'styled-components';
import BigNumber from 'bignumber.js';

import { ListItem } from '../../common/ListItem';
import { TextMedium } from '../../common/text/Text';
import { formatDate } from '../../utils/formatDate';
import { Badge } from '../../common/Badge';
import { VeLockModal } from './veLockModal';
import LockClockIcon from '@mui/icons-material/LockClock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

import { reduceString } from '../../utils/reduceString';
import { formatNumber } from '../../utils/formatNumber';

export const VELockInfo = ({info, onRedeem, onChangeDelegate, onConvert}) => {
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [delegateInfo, setDelegateInfo] = useState(info.delegate)

  React.useEffect(() => {
    return () => {}

  }, []);

  const getRedeemImage = () => {
      var curDate = new BigNumber(Date.now()).div(1000)

      if(curDate.lt(info.time)){
        return <LockClockIcon></LockClockIcon>
      }
      return <LockOpenIcon/>
  }

  return (
    <>
        <VeLockModal
          isVisible={showInfoModal}
          onClose={() => {setShowInfoModal(false)}}
          onChangeDelegate={onChangeDelegate}
          setDelegateInfo={setDelegateInfo}
          delegateInfo={delegateInfo}
          id={info.index}
          info={info}
          onRedeem={async () => {await onRedeem(); setShowInfoModal(false)}}
          onConvert={async () => {await onConvert(); setShowInfoModal(false)}}
        />
        <StyledDropdown>
          <LI onClick={() => {setShowInfoModal(true)}}>
              <TextMedium style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', width: '100%'}}>
              {getRedeemImage()}
              </TextMedium>

              <DateText>{formatDate(new BigNumber(info.time).times(1000).toNumber())}</DateText>

              <Amount>{formatNumber(new BigNumber(info.amount).toFixed(4), 2)}</Amount>

              <Amount style={{justifyContent: 'flex-start'}}>{formatNumber(new BigNumber(info.vote_weight).toFixed(4), 2)}</Amount>

              <DateText style={{justifyContent: 'flex-end', textAlign: 'right'}}>{reduceString(info.delegate, 7, 4)}</DateText>
          </LI>
          <SeperatorLine/>
        </StyledDropdown>
    
    </>

  )
}
//            <ManageButton onClick={() => {redeem()}} disabled={shouldDisableRedeem()}>Redeem</ManageButton>


const StyledDropdown = styled.div`
  position: relative;
  overflow: hidden;

  & + & {
    margin-top: 6px;
  }
`;


const NotMaturedIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(113, 121, 145, 0.1);
  border-radius: 8px;
  color: #717991;
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-right: 10px;
`

const TxIcon = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 10px;

`


const LI = styled(ListItem)`
  margin-top: 0.5px;
  margin-bottom: 0.5px;
`;

const SeperatorLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #262436;
`


const DateText = styled.p`
  text-align: left;
  align-items: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777F99;
  width: 100%;
`;

const Amount = styled(TextMedium)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;


