import { useState } from 'react'
import styled from 'styled-components';
import { EmptyWidgetText, Widget } from '../../common/widgets/Widget';
import { Dropdown } from './Dropdown';
import { ButtonPrimaryMedium } from '../../common/buttons/Button';
import {LoadingBox} from '../../common/loading/LoadingBox'
import BigNumber from 'bignumber.js';
import { useAccount } from 'wagmi';
import { useWalletHook } from '../../web3/walletHook';
import UnknownToken from '../../assets/images/icons/unknown_token.png';


enum Modal {
  Switch,
  Connect,
  Confirm,
  Submitted,
}

/*
type LiquidityPair = {
  value: string;
  assetAImage: string;
  assetBImage: string;
  assetA: any;
  assetB: any;
}

interface YourLiquidityWidgetProps {
  ownedPairs: LiquidityPair[];
  addLiquidity: (LiquidityPair) => any;
  removeLiquidity: (LiquidityPair) => any;
}
*/

export const YourLiquidityWidget = ({manageLiquidity, ownedPairs, isLoading}) => {
  const {isConnected} = useAccount();

  const { getTokenFromContract } = useWalletHook()

  const getAssetLogo = (id) => {
    let _asset = getTokenFromContract(id)
    if(_asset)
      return _asset.logo

    return UnknownToken
  }

  const getWidget = () => {
    if(isConnected == false){
      return <ConnectionWrapper><w3m-button /></ConnectionWrapper>
    }

    if(isLoading){
      return (
        <Dropdown loading />
      )
    }

    return (
      <>
      {ownedPairs.map((item, index) => (
        <Dropdown
          onManageClick={() => manageLiquidity(item.assetA, item.assetB, item.stable)}
          icon0={getAssetLogo(item.assetA)}
          icon1={getAssetLogo(item.assetB)}
          token0={item.assetASymbol}
          token1={item.assetBSymbol}
          stable={item.stable}
          fee={item.pairFeePercent}
          reserveA={item.assetATotal}
          reserveB={item.assetBTotal}
          tvl={item.reserveUSD}
          apy={item.ampAPY && item.apy ? item.apy + '-' + new BigNumber(item.apy).plus(item.ampAPY).toFixed(2)  : item.apy}

        />
      ))}
      {ownedPairs.length == 0 ?
        <EmptyWidgetText style={{textAlign: 'center'}}>You do not currently have any liquidity in a pool</EmptyWidgetText> : <></>
      }
    </>
    )
  }

  return (
    <Container>
    <HeaderTitle>Your Liquidity</HeaderTitle>
    <View
      title=""
    >
    <OverflowWidget>

      {getWidget()}

      </OverflowWidget>
    </View>
    </Container>
  )
}

const Container = styled.div`

grid-area: yourLiquidity;

`
const View = styled(Widget)`
  min-height: 150px;
  max-height: 750px;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--color-dark-bg);
  border: none;

  box-shadow: none;

  @media (max-width: 900px) {
    min-height: initial;
  }
`;

const OverflowWidget = styled.div`
  overflow-y: scroll;
  max-height: 400px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background: transparent;

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-modal-blue);
    border-radius: 16px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white;
  }
`;


const Button = styled(ButtonPrimaryMedium)`
  display: block;
  width: 217px;
  height: 48px;
  margin: 0 auto;
`;

const HeaderTitle = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;

  /* Dark/Dark-200 */

  color: #545368;
`


const ConnectionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  font-size: 16px;
  justify-content: center;
  display: flex;
`;