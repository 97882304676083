import React from 'react'
import { Widget } from '../../common/widgets/Widget';

import { Text } from '../../common/text/Text';
import { ButtonSecondaryLarge } from '../../common/buttons/Button'
import { Modal, ModalProps } from '../../common/modal/Modal';

import styled from 'styled-components';

export const LiquidityRewardsWidget = ({isVisible, onClose}) => {

  return (
    <>
    <Container>
      <HeaderRow>
        <HeaderText>
        How it works?
        </HeaderText>

        <ButtonSecondaryLarge disabled={false} onClick={() => {window.open('https://wiki.mute.io/mute/mute-switch/introduction', '_blank')}}>
        Read docs
        </ButtonSecondaryLarge>
      </HeaderRow>

      <Column>
      <Row>
      <NumberBg>1</NumberBg>
      <DescriptionText>Deposit Liquidity</DescriptionText>
      <BodyText>
      Deposit two tokens to create liquidity in a pool, and receive a LP
      tokens to signify ownership in that pool. If you are the first person to create liquidity in a pool,
      you can designate the fee % the pool charges traders.
     </BodyText>
      </Row>

      <Row>
        <NumberBg>2</NumberBg>
        <DescriptionText>Earn Fees</DescriptionText>
        <BodyText>
        Fees for LPs are accumulated into a vault that allows you to claim fees seperately from your LP tokens. This is the most 
        capital efficient way to capture trading fees and yield its value. 
       </BodyText>
      </Row>

      <Row>
      <NumberBg>3</NumberBg>
      <DescriptionText>Delegate & Change Pool Fees</DescriptionText>
        <BodyText>
        An account with at at least 50% of the LP vote weight delegated to it is able to change pool fees at any time. 
        The fee for stable pools can be set from a range of 0.01% to 2%, whereas normal pools can be set from 0.01% to 5%. 
        Changing an LP fee taxes a 0.1% LP fee on the account to prevent sybil attacks.
       </BodyText>
      </Row>
      </Column>

    </Container>

    </>
  )
}


const Container = styled(Widget)`
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: flex-start;
  width: 100%;
  padding: 34px 48px;

  grid-area: description;
  @media (max-width: 767px) {
    flex-direction: column;
    height: auto;
  }
`

const StyledModal = styled(Modal)`
  display: none;

  @media (max-width: 767px) {
    display: block;
  }
`;

const HeaderRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

const HeaderText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  color: #FFFFFF;
`

const DescriptionText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 26px;
  color: #FFFFFF;
`

const BodyText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  color: #777F99;
`

const Column = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  margin-top: 20px;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`

const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  height: 100%;
  @media (max-width: 767px) {
    width: 100%;
    padding-top: 20px;
  }
`

const NumberBg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-blue);
  width: 30px;
  height: 30px;
  border-radius: 100px;
  font-family: 'Aeonik';
  font-size: 16px;
  font-weight: 700;
  color: #111524;
  margin-bottom: 10px;
`
