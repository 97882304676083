import React from 'react'
import styled from 'styled-components';
import { Modal, BasicModalProps } from './modal/Modal';
import metamaskLogo1x from '../assets/images/icons/metamask@1x.png'
import metamaskLogo2x from '../assets/images/icons/metamask@2x.png'
import walletConnectLogo1x from '../assets/images/icons/walletConnect@1x.png'
import walletConnectLogo2x from '../assets/images/icons/walletConnect@2x.png'
import coinbaseLogo1x from '../assets/images/icons/coinbase@1x.png'
import argentLogo from '../assets/images/icons/argent.png'
import okxLogo from '../assets/images/icons/okx.png'

import coinbaseLogo2x from '../assets/images/icons/coinbase@2x.png'
import fortmaticLogo1x from '../assets/images/icons/fortmatic@1x.png'
import fortmaticLogo2x from '../assets/images/icons/fortmatic@2x.png'
import { ModalText } from './modal/ModalComponents';

interface ConnectModalProps extends BasicModalProps {
  onConnectCallback: (prov: any) => void;
}

export const ConnectModal = ({isVisible, onConnectCallback, onClose}: ConnectModalProps) => {

  return (
    <ModalView
      isVisible={isVisible}
      onClose={onClose}
      title="Connect"
    >
      <Subtitle>Connect your zkSync Era Wallet to start using the Mute Switch</Subtitle>
      <List>
        <ListItem>
          <ConnectButton onClick={() => onConnectCallback('injected')}>
            Metamask (injected)
            <Logo src={metamaskLogo1x} srcSet={metamaskLogo2x} alt="logo" />
          </ConnectButton>
        </ListItem>
        <ListItem>
          <ConnectButton onClick={() => onConnectCallback('walletconnect')}>
            WalletConnect
            <Logo src={walletConnectLogo1x} srcSet={walletConnectLogo2x} alt="logo" />
          </ConnectButton>
        </ListItem>
        <ListItem>
          <ConnectButton onClick={() => onConnectCallback('coinbasewallet')}>
            Coinbase Wallet
            <Logo src={coinbaseLogo1x} srcSet={`${coinbaseLogo2x} 2x`} alt="logo" />
          </ConnectButton>
        </ListItem>
        <ListItem>
          <ConnectButton onClick={() => onConnectCallback('walletconnect')}>
            Argent
            <Logo src={argentLogo}  alt="logo" />
          </ConnectButton>
        </ListItem>
        <ListItem>
          <ConnectButton onClick={() => onConnectCallback('injected')}>
            OKX Wallet
            <Logo src={okxLogo}  alt="logo" />
          </ConnectButton>
        </ListItem>
      </List>
    </ModalView>
  )
}



const ModalView = styled(Modal)`
  max-width: 400px;

  @media (max-width: 500px) {
    bottom: 0px;
    top: initial;
    left: 0%;
    padding: 20px;
    transform: none;
  }
`;

const Subtitle = styled(ModalText)`
  text-align: center;
`;

const List = styled.ul`
  margin-top: 20px;

  @media (max-width: 600px) {
    margin-top: 16px;
  }
`;

const ListItem = styled.li`
  & + & {
    margin-top: 16px;
  }
`;

const ConnectButton = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 58px;
  padding: 15px;
  border: 1px solid #35415A;
  border-radius: 12px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #FFFFFF;
  cursor: pointer;
  &:hover {
    outline: none;
    border: 1px solid #54bef9;
  }
`;

const Logo = styled.img`
  width: auto;
  height: 28px;
`;
