import React, { useState } from 'react'
import styled from 'styled-components';
import { Asset } from '../../../model/Asset'
import {LoadingBox} from '../../../common/loading/LoadingBox'
import WhatshotIcon from '@mui/icons-material/Whatshot';
import SwipeableViews from 'react-swipeable-views';
import Radio from '@mui/material/Radio';
import Lens from '@mui/icons-material/Lens'
import RadioButtonUnchecked from '@mui/icons-material/RadioButtonUnchecked'
import { useAccount } from 'wagmi';

interface Pair {
  token1: Asset;
  token2: Asset;
  price: string;
  stable?: any;
  apy?: any;
}

export interface PairsListProps {
  pairs: Pair[]
  setToTrade: (tokenA: Asset, tokenB: Asset) => void;
  pairsLoading: boolean;
}


function useInterval(callback, delay) {
  const savedCallback = React.useRef<any>();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

export const PairsListContent = ({pairs, setToTrade, pairsLoading}: PairsListProps) => {
  const {isConnected} = useAccount();

  const [pairsMaps, setPairMaps] = React.useState([])
  const [activeStep, setActiveStep] = React.useState(0);


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step: number) => {
    setActiveStep(step);
  };

  const [isMobile, setIsMobile] = React.useState(false)


  const resize = () => {
    setIsMobile(window.innerWidth <= 900)
  }

  const tabs = ['eth', 'wbtc', 'usdc', 'all'];

  React.useEffect(() => {
    genPairs()

    window.addEventListener("resize", resize.bind(this));
    resize()

    return () => {
      window.removeEventListener("resize", resize.bind(this));
    };
      
  }, []);

  
  React.useEffect(() => {
    genPairs()
  }, [pairsLoading, pairs]);

  useInterval(() => {
    let _step = isMobile ? 5 : 1
    if(pairsMaps.length == 0)
      return

    if(activeStep >= _step){
      setActiveStep(0)
    } else {
      setActiveStep(activeStep + 1)
    }
      

  }, 8000)

  const genPairs = () => {
    let tempPairs: any = []
    for(let i in pairs){
      let token1 = pairs[i].token1
      let token2 = pairs[i].token2
      let price = pairs[i].price
      let apy = pairs[i].apy

      tempPairs.push(
        (
        <ListItem key={Math.floor(Math.random() * 10000)}>
          <IconsRow>
            <Icon src={token1.logo} />
            <Icon src={token2.logo} />
          </IconsRow>
          <RankRow>
            #{Number(i) + 1}
          </RankRow>
        <ListButton className={'active'} onClick={() => setToTrade(token1, token2)}>
      
          <MarketPair>

          {token1.symbol + ' / ' + token2.symbol}
          </MarketPair>

          <Price>
            Price
          <LastPrice>{price}</LastPrice>

          <Price style={{marginLeft: 'auto', justifyContent: 'flex-end'}}>
            APY
          <LastPrice>{apy}%</LastPrice>
          </Price>
          </Price>
          
        </ListButton>
      </ListItem>
      )
      )
    }
    setPairMaps(tempPairs)
  }


  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveStep(Number(event.target.value));
  };

  const getWidget = () => {
    // if(isConnected == false && pairsLoading == false && false){
    //   return (
    //   <ConnectionWrapper><w3m-button /></ConnectionWrapper>
    //   )
    // }

    if (pairsLoading) {
      return (
        <List key={Math.floor(Math.random() * 10000)}>
          <ListItem key={Math.floor(Math.random() * 10000)}>
            <ListButton>
              <LoadingBox/>
            </ListButton>
          </ListItem>

          {isMobile == false ?
          <ListItem key={Math.floor(Math.random() * 10000)}>
            <ListButton>
              <LoadingBox/>
            </ListButton>
          </ListItem>

          : <></>}

          {isMobile == false ?
          <ListItem key={Math.floor(Math.random() * 10000)}>
            <ListButton>
              <LoadingBox/>
            </ListButton>
          </ListItem>
          : <></>}

        </List>
        )
    }

    return (
      <SwipeableViews
        axis={'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        slideStyle={{ overflow: 'hidden'}}
        style={{ overflow: 'hidden'}}
      >
        {times.map((step, index) => {
          const slicedData = getSlicedData(step);
            return (
              <SwipeView key={Math.floor(Math.random() * 10000)}>
                {slicedData.map((item, index) => {
                  return item
                })}
              </SwipeView>
            );
          })}             
      </SwipeableViews>
    )
  }

  const controlProps = (item: string) => ({
    checked: Number(activeStep) == Number(item),
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  const cardsPerView =  isMobile ? 1 : 3; 

  const getSlicedData = React.useCallback((step) => {
      const cardStartIndex = step * cardsPerView;
      return pairsMaps.slice(cardStartIndex, cardStartIndex + cardsPerView);
  },[cardsPerView, pairsMaps]);

  const times = isMobile ? [0,1,2,3,4,5] : [0,1]


  const style = {
    color: 'white',
    height: '25px',
    width: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    '&.Mui-checked': {
      color: 'rgba(50, 148, 248, 1)',
    },
    '&.MuiRadio-colorPrimary': {
    }
  }
  return (
    <>
      <Header>
        <WhatshotIcon style={{color: 'white', paddingRight: '8px'}}/>
        <HeaderTitle>Trending pairs</HeaderTitle>
        {isMobile ? 
        <></> :
        <div style={{marginLeft: 'auto', paddingRight: '5px'}}>
          <Radio checkedIcon={<Lens style={{height: '15px'}}/>} icon={<RadioButtonUnchecked style={{height: '15px'}}/>} {...controlProps('0')} sx={style} /> 
          <Radio checkedIcon={<Lens style={{height: '15px'}}/>} {...controlProps('1')} icon={<RadioButtonUnchecked style={{height: '15px'}}/>} sx={style} />
          </div>
        }
      </Header>

      {getWidget()}

      <Header>

        {isMobile ? 
        <div style={{margin: 'auto', paddingTop: '10px'}}>
        <Radio checkedIcon={<Lens style={{height: '15px'}}/>} icon={<RadioButtonUnchecked style={{height: '15px'}}/>} {...controlProps('0')} sx={style} /> 
        <Radio checkedIcon={<Lens style={{height: '15px'}}/>} {...controlProps('1')} icon={<RadioButtonUnchecked style={{height: '15px'}}/>} sx={style} />
        <Radio checkedIcon={<Lens style={{height: '15px'}}/>} {...controlProps('2')} icon={<RadioButtonUnchecked style={{height: '15px'}}/>} sx={style} />
        <Radio checkedIcon={<Lens style={{height: '15px'}}/>} {...controlProps('3')} icon={<RadioButtonUnchecked style={{height: '15px'}}/>} sx={style} />
        <Radio checkedIcon={<Lens style={{height: '15px'}}/>} {...controlProps('4')} icon={<RadioButtonUnchecked style={{height: '15px'}}/>} sx={style} />
        <Radio checkedIcon={<Lens style={{height: '15px'}}/>} {...controlProps('5')} icon={<RadioButtonUnchecked style={{height: '15px'}}/>} sx={style} />
        </div> :
        <></>
        }
      </Header>

    </>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0 8px;
  align-items: center;

  @media (max-width: 767px) {
    padding-bottom: 20px;

  }
`;

const SwipeView = styled.div`
  display: flex;
  padding-top: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  overflow: visible;
  width: 100%;
  @media (max-width: 767px) {
    padding: 0;
    align-items: center;
    justify-content: center;
  }


  @media (max-width: 1000px) {
    align-items: center;
    justify-content: center;
  }
`;


const List = styled.ul`
  display: flex;
  flex-direction: row;
  max-height: 160px;
  margin-top: 20px;
  width: 100%;
  justify-content: space-between;
  overflow-x: visible;
  overflow-y: visible;
  padding-right: 10px;
  @media (max-width: 767px) {
    align-items: center;
    justify-content: center;
  }
`;
/*
  & + & {
    margin-top: 8px;
  }
*/

const ListItem = styled.li`
  position: relative;
  height: 80%;
  overflow: visible;
  width: auto;
  padding-right: 0x;

  @media (max-width: 767px) {
    &:nth-child(-n+10) {
      display: flex;
    }

    display: 'flex';
  }
`;

const ListButton = styled.button`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  width: 280px;
  height: 100px;
  overflow: visible;
  border: 1px solid rgba(255,255,255,0.05);
  background: var(--color-modal-blue);
  
  padding: 18px 18px;
  margin-left: 4px;
  margin-right: 8px;
  border-radius: 16px;

  &.active {

  }

  @media (max-width: 1000) {
    align-items: center;
    justify-content: center;

  }

  :hover {
    background: linear-gradient(to right top, var(--color-modal-blue) , var(--color-modal-light-blue));

  }
`;

const MarketPair = styled.p`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  text-align: right;
  position: relative;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  color: #FFFFFF;
  flex-direction: row;
`;

const LastPrice = styled.p`
  padding-left: 5px;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #FFFFFF;
  color: rgb(118,209,145);
  text-align: left;
`;

const Price = styled.p`
  width: 100%;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: var(--color-gray);
  text-align: left;
`;

const HeaderTitle = styled.p`
  font-weight: 700;
  font-size: 17px;
  line-height: 150%;
  color: #FFFFFF;
`;


const IconsRow = styled.div`
  position: absolute;
  overflow-y: visible;
  left: 55px;
  top: 20px;


  display: flex;
  align-items: center;
  margin-right: 2px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #F6F8FE;
`;

const RankRow = styled.div`
  position: absolute;
  left: 20px;
  top: 18px;
  z-index: 5;
  overflow-y: visible;



  display: flex;
  align-items: center;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: white;
`;

const Icon = styled.img`
  width: 32px;
  height: 32px;
  border: 1px solid rgba(0,0,0,0);
  border-radius: 30px;
  z-index: 1;

  &:first-child {
    z-index: 2;
  }

  & + & {
    transform: translateX(-6px)
  }
`;

const ConnectionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
  width: 200px;
  font-size: 16px;
  justify-content: center;
  display: flex;
`;