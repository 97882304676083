import styled from 'styled-components';

export const AmountInput = styled.input`
  max-width: 100%;
  height: auto;
  margin: 0;
  font-weight: 500;
  font-size: 30px;
  width: 100%;
  line-height: 120%;
  color: #FFFFFF;
  background: none;
  border: none;
  outline: none;
  text-overflow: ellipsis;

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }

  &::-webkit-input-placeholder {
    color: #FFFFFF;
  }
  &::-moz-placeholder {
    color: #FFFFFF;
  }
  &:-ms-input-placeholder {
    color: #FFFFFF;
  }
  &:-moz-placeholder {
    color: #FFFFFF;
  }

  &[disabled]::-webkit-input-placeholder {
    color: #777F99;
  }
  &[disabled]::-moz-placeholder {
    color: #777F99;
  }
  &[disabled]:-ms-input-placeholder {
    color: #777F99;
  }
  &[disabled]:-moz-placeholder {
    color: #777F99;
  }

  @media (max-width: 500px) {
  }
`;
