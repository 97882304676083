import styled from 'styled-components';
import React from 'react'
import { TextMedium } from './text/Text';
import { HelpCircle } from 'react-feather'

interface SymbolWithLogoLeftProps {
  logo: string;
  symbol: string;
  name?: string;
}

export const SymbolWithLogoLeft = ({logo, symbol, name}: SymbolWithLogoLeftProps) => {
  if(logo && logo != ''){
    return (
      <Row>
        <Logo src={logo} alt={`${symbol} logo`} />
        <Column>
        <Symbol>{symbol}</Symbol>
        {name != '' ? <Name>{name}</Name> : <Row/>}
        </Column>

      </Row>
    )
  } else {
    return  <Row/>

  }

}

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
`;

const Logo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  border-radius: 12px;
`;

const Symbol = styled(TextMedium)`
  text-transform: uppercase;
`;

const Name = styled(TextMedium)`
  font-weight: 300;
  font-size: 12px;
  text-transform: uppercase;
`;

const Column = styled.div`
  flex-direction: column;
  display: flex;
  text-align: left;

`;
