import React, { useState } from 'react'
import styled from 'styled-components';
import { EmptyWidgetText, Widget } from '../../common/widgets/Widget';
import { Dropdown } from './Dropdown';
import { ButtonPrimaryMedium } from '../../common/buttons/Button';
import {LoadingBox} from '../../common/loading/LoadingBox'
import BigNumber from 'bignumber.js';
import { useAccount } from 'wagmi';
import { format } from 'prettier';
import { formatNumber } from '../../utils/formatNumber';

enum Modal {
  Switch,
  Connect,
  Confirm,
  Submitted,
}

/*
type LiquidityPair = {
  value: string;
  assetAImage: string;
  assetBImage: string;
  assetA: any;
  assetB: any;
}

interface YourLiquidityWidgetProps {
  ownedPairs: LiquidityPair[];
  addLiquidity: (LiquidityPair) => any;
  removeLiquidity: (LiquidityPair) => any;
}
*/

export const YourLiquidityWidget = ({title, manageLiquidity, ownedPairs, isLoading}) => {
  const {address, isConnected} = useAccount();

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => setModal(null);

  const getWidget = () => {
    if(isConnected == false){
      return <ConnectionWrapper><w3m-button /></ConnectionWrapper>
    }

    if(isLoading || !ownedPairs){
      return (
        <Dropdown onManageClick={() => {}} icon0={''} icon1={''} token0={''} token1={''} apy={'0'} tvl={'0'} payoutToken={null}>
            <LoadingBox/>
        </Dropdown>
      )
    }

    return (
      <>
      {ownedPairs.map((item, index) => {

        var expired = new BigNumber(item.tbv).div(item.maxTBV).times(100).lte(0.1)
                     if(item.token0 && item.token1 && (!expired || (expired && item.user_stakes && item.user_stakes.length > 0)) ){
                      return (
                        <Dropdown
                       onManageClick={() => manageLiquidity(item.id, item.v2)}
                       icon0={item.token0 && item.token0.logo  ? item.token0.logo : ''}
                       icon1={item.token0 && item.token1.logo ? item.token1.logo : ''}
                       token0={item.token0.symbol}
                       token1={item.token1.symbol}
                       apy={new BigNumber(item.apy ? item.apy : item.maxAPY).div(100).times(item.ampBoost ? item.ampBoost : 1).plus(item.lp_apy ? item.lp_apy : item.lpAPY).toFixed(2)}
                       tvl={item.tvl}
                       stable={item.stable}
                       tbv={formatNumber(item.maxTBV, 2)}
                       percent={new BigNumber(item.tbv).div(item.maxTBV).times(100).toNumber()}
                       soldout={new BigNumber(item.maxDeposit).lte(0)}
                       v2={item.v2}
                       stakes={item.user_stakes ? item.user_stakes.length : 0}
                       payoutToken={item.payoutToken}
                     />
                      )
                     }
      }
      )}                         

      {ownedPairs && ownedPairs.length == 0 ?
        <EmptyWidgetText style={{textAlign: 'center'}}>There are currently no active farms</EmptyWidgetText> : <></>
      }
    </>
    )
  }

  return (
    <Container>
    <HeaderTitle>{title}</HeaderTitle>
    <View
      title=""
    >
    <OverflowWidget>
      {getWidget()}
    </OverflowWidget>
    </View>
    </Container>
  )
}

const Container = styled.div`

  grid-area: yourLiquidity;

  margin-bottom: 50px;

`
const View = styled(Widget)`
  min-height: 90px;
  max-height: 750px;
  padding-left: 0;
  padding-right: 0;
  background-color: var(--color-dark-bg);
  box-shadow: none;
  border: none;

  @media (max-width: 900px) {
    min-height: initial;
  }
`;

const OverflowWidget = styled.div`
  overflow-y: scroll;
  max-height: 400px;

  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border-radius: 0px;
    background: transparent;

  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--color-modal-blue);
    border-radius: 16px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white;
  }
`;


const Button = styled(ButtonPrimaryMedium)`
  display: block;
  width: 217px;
  height: 48px;
  margin: 0 auto;
`;

const HeaderTitle = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: left;

  /* Dark/Dark-200 */

  color: #545368;
`


const ConnectionWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 200px;
  font-size: 16px;
  justify-content: center;
  display: flex;
`;
