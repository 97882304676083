import React, { useState } from 'react'
import styled from 'styled-components';
import { TextMedium, TextMediumArrow } from '../../../common/text/Text';
import { ViewMoreButton } from '../ViewMoreButton';
import { ListItem } from '../../../common/ListItem';
import { formatDate } from '../../../utils/formatDate';
import { Badge } from '../../../common/Badge';
import { TransactionDetailsModal } from './TransactionDetailsModal';
import { Transaction } from '../../../model/Transaction';
import succesful_tx from '../../../assets/images/icons/successful_tx.png';

export const HistoryList = ({history}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedTransaction, setSelectedTransaction] = useState<Transaction | undefined>();

  const onTransactionClick = (transaction: Transaction) => {
    setSelectedTransaction(transaction)
    setIsModalVisible(true)
  }


  return (
    <>
      <List isExpanded={isExpanded}>
        {history.map((item, index) => (
          (
              <>
              <LI key={index} onClick={() => {}}>
                <Button onClick={() => onTransactionClick(item)}>
                  <TextMedium style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginRight: '10px'}}><TxIcon src={succesful_tx}/>{item.operation}</TextMedium>
                  {item.status === 'Confirmed'
                    ? <Date>{formatDate(item.timestamp)}</Date>
                    : <Badge>{item.status}</Badge>
                  }
                  <Amount>${parseFloat(item.amountUSD).toFixed(4)} </Amount>
                </Button>
              </LI>
              <SeperatorLine/>
              </>
            )



        ))}
      </List>
      <TransactionDetailsModal
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        transaction={selectedTransaction}
      />
    </>
  )
}

interface ListProps {
  isExpanded: boolean;
}

const List = styled.ul<ListProps>`
  padding-right: 19px;
  height: 100%;
  overflow-y: auto;
`;

const TxIcon = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 10px;

`

const SeperatorLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #262436;
`

const LI = styled(ListItem)`
  margin-top: 0.5px;
  margin-bottom: 0.5px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  text-align: left;

  & > *:first-child {
    min-width: 82px;
  }

  & > *:last-child {
    margin-left: auto;
    text-align: right;
  }
`;


const Date = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777F99;
`;

const Amount = styled(TextMediumArrow)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;
