import styled from "styled-components";

export const Title = styled.h1`
  font-family: 'Aeonik';
  font-weight: 500;
  font-size: 46px;
  line-height: 130%;
  color: #ffffff;

  @media (max-width: 600px) {
    font-size: 24px;
    line-height: 200%;
  }
`;
