import React from 'react'
import styled from 'styled-components';
import { WalletState } from '../state';

import { useAccount } from 'wagmi';
import { PaymasterModal } from './modal/PaymasterModal';
import { useWalletHook } from '../web3/walletHook';
import { formatNumber } from '../utils/formatNumber';
import { useWalletSelector } from '../state/hooks';

enum Modal {
  Connect,
  Switch
}


export const Connection = () => {
  const {isConnected, address} = useAccount();
  const {getFeeAssets, setFeeAsset, getFeeAsset} = useWalletHook()
  const [showPaymaster, setShowPaymaster] = React.useState(false)
  const [feeAsset, setFee] = React.useState(getFeeAsset())
  const wrongChain = useWalletSelector((state: WalletState) => state.wrongChain)
  const zkID = useWalletSelector((state: WalletState) => state.zkID)
  const ens = useWalletSelector((state: WalletState) => state.ens)
  const feeAssetSelector = useWalletSelector((state: WalletState) => state.tokens)
  const feeChanged = useWalletSelector((state: WalletState) => state.feeAsset)


  React.useEffect(() => {
    setFee(getFeeAsset())
    return () => {}
  }, [wrongChain, isConnected, address, feeAssetSelector, feeChanged])

  return (
    <ConnectionParent> 
      <FeeDropdown onClick={() => setShowPaymaster(true)}>
        <FeeIcon src={feeAsset && feeAsset.logo ? feeAsset.logo : null}/>
        <FeeName>{feeAsset && feeAsset.symbol ? formatNumber(feeAsset.balance, 2) + ' ' + feeAsset.symbol : ''}</FeeName>
        ⛽
      </FeeDropdown>
      <PaymasterModal isVisible={showPaymaster} onClose={() => setShowPaymaster(false)} assets={getFeeAssets()} setSelectedAsset={setFeeAsset}/>
      <AccountParent >
          <w3m-button balance={'hide'} />
        </AccountParent>
    </ConnectionParent>
  )
}


const ConnectionParent = styled.div`
  display: flex;
  flex-direction: row;
`


const AccountParent = styled.div`
display: flex;
justify-content: center;
align-items: center;
border: 1px solid transparent;
border-radius: 16px;
border-top-left-radius: 0px;
border-bottom-left-radius: 0px;
overflow: hidden;
background: var(--color-button-bg-dark);


`
const FeeIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;

  @media (max-width: 450px) {
    margin-right: 0px;
  }

`

const FeeName = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;


  text-align: center;
  color: #FFFFFF;

  @media (max-width: 450px) {
    display: none;
  }

`

const FeeDropdown = styled.button`
  position: relative;
  display: flex;
  background: var(--color-button-bg-dark);
  border: 1px solid transparent;
  border-radius: 12px;
  width: auto;
  height: 45px;
  color: #7F7D97;
  font-size: 20px;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 14px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  &:hover {
    outline: none;
    border: 1px solid #54bef9;
  }

  @media (max-width: 450px) {
    margin-right: 0px;
  }
`;





const DropdownMaster = styled.div`
  position: relative;
  display: flex;
  width: auto;
  align-items: right;
  justify-content: space-around;
  text-align: right;
  align-items: center;

  border: 1px solid rgba(255,255,255,0.1);
  border-radius: 9px;
  box-shadow: rgba(255,255,255, 0.1) 0px 0px 10px;

  @media (max-width: 500px) {
    position: absolute;
    bottom: -20px;
    right: 10px;
  }
`;


const DisconnectButton = styled.button`
  position: absolute;
  right: 0;
  bottom: -3px;
  transform: translateY(100%);
  max-width:  180px;
  width: 100%;
  padding: 12px 10px;
  text-align: center;
  border: 1px solid #35415A;
  border-radius: 8px;
  background: #0B1126;
  font-size: 14px;
  line-height: 16px;
  color: #FFFFFF;
  z-index: 10;


    &:hover {
      outline: none;
      border: 1px solid #54bef9;
    }

`;
