import React, { useState, useMemo, useEffect } from 'react'
import {useHistory, useLocation} from "react-router-dom";
import styled, {keyframes} from 'styled-components'
import { ButtonPrimaryLarge } from '../../../common/buttons/Button'
import { Widget } from '../../../common/widgets/Widget'
import { ExchangeInfo } from './ExchangeInfo'
import arrowIcon from '../../../assets/images/icons/arrows.svg'
import arrowRight from '../../../assets/images/icons/arrow.svg'
import { Row } from '../../../common/Row'
import { TextGrey, TextWhite, TextGreen } from '../../../common/text/Text'
import { ConfirmTransactionModal } from '../../../common/modal/ConfirmTransactionModal'
import { SuccessfulSubmitModal } from '../../../common/modal/SuccessfulSubmitModal'
import { ErrorModal } from '../../../common/modal/ErrorModal'
import SettingsIcon from '@mui/icons-material/Settings';
import Tooltip from '@mui/material/Tooltip';

import { ConnectModal } from '../../../common/ConnectModal'
import { SlipageToleranceModal } from './SlipageToleranceModal'
import { AmountSection } from '../../../common/section/AmountSection'
import { ShadowWidget } from '../../../common/ShadowWidget'

import { Asset } from '../../../model/Asset'
import BigNumber from "bignumber.js";
import {LoadingAnimation} from '../../../common/loading/loading'
import { reduceString, getCleanNumber } from '../../../utils';
import { transition, uiTransition } from '../../../styles';

import { WalletState } from '../../../state'
import { useWalletHook } from '../../../web3/walletHook'
import { useAccount } from 'wagmi';
import { useWalletSelector } from '../../../state/hooks';


const queryString = require('query-string');

enum Modal {
  Pair,
  Confirm,
  Submitted,
  Switch,
  Connect,
  Slipage,
  Error
}

enum Direction {
  Input = 1,
  Output = 2,
}


const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  zIndex: 1,
  //margin: 4,
  [`& .MuiTooltip-tooltip`]: {
    maxWidth: 200,
    fontFamily: "Aeonik",
    fontSize: '12px',
    backgroundColor: "var(--color-dark-blue)",
    //color: "deepskyblue", see sx value
    margin: 4,
    padding: 8,
    whiteSpace: "pre-line"
    //border: "solid yellow 1px"
  }
}));

interface ReadOnlyURLSearchParams extends URLSearchParams {
  append: never;
  set: never;
  delete: never;
  sort: never;
}

export const ExchangeWidget = ({callBackSelect, externalSetTokens, setAdvancedChart, advancedChart, setShowTrending, showTrending}) => {
  let location = useLocation()
  const history = useHistory();

  const searchParams = useMemo(
    () => new URLSearchParams(location.search) as ReadOnlyURLSearchParams,
    [location.search],
  );

  const {isConnected, address} = useAccount();

  const { prepareTrade, executeTrade, getTokenFromContract, destroyCurrentTrade, getFeeAsset } = useWalletHook()

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => setModal(null);
  const [modalError, setModalError] = useState('')

  const [sendAmount, setSendAmount] = useState('');
  const [getAmount, setGetAmount] = useState('');
  const [gasSaved, setGasSaved] = useState('');
  const [gasSpent, setGasSpent] = useState('');
  const [confirmTime, setConfirmTime] = useState('0.00');
  const [txHash, settxHash] = useState('');


  const [route, setRoute] = useState('');
  const [path, setPath] = useState<Array<String> | null>([]);
  //const [trade, setTrade] = useState<TradeContext | null>(null)

  const [token1, setToken1] = useState<Asset | undefined>(undefined);
  const [token2, setToken2] = useState<Asset | undefined>(undefined);

  const [minReceived, setMinReceived] = useState('0')
  const [liquidityProviderFee, setLiquidityProviderFee] = useState(0)
  const [price, setPrice] = useState(0)
  const [hasEnoughAllowance, setHasEnoughAllowance] = useState(false)
  const [allowance, setAllowance] = useState('0')
  const [hasEnough, setHasEnough] = useState(true)
  const [priceImpact, setPriceImpact] = useState('0')
  const [stable, setStable] = useState([])


  const [slippage, setSlippage] = useState(0.95);
  const [currentSwapCost, setCurrentSwapCost] = useState('0.00');

  const [calculating, setCalculating] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hasEth, setHasEth] = useState(false);
  const [gettingTrade, setGettingTrade] = useState(false);

  //1 = input is exact, 2 = output is exact
  const [direction, setDirection] = useState(Direction.Input);

  // #1 ref to cache current text value
  //const tradeRef = React.useRef<TradeContext | null>();
  // #2 cache current text value
  //tradeRef.current = trade;
  const trade = useWalletSelector((state: WalletState) => state.cachedPair)
  const assets = useWalletSelector((state: WalletState) => state.tokens) //useWalletSelector(() => store.getState().tokens)
  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund)

  const setTokensFunc = (tokenA, tokenB) => {
    clearWidget()
    setToken1(tokenA)
    setToken2(tokenB)

    // @ts-expect-error
    searchParams.set('inputCurrency', tokenA.address);
    // @ts-expect-error
    searchParams.set('outputCurrency', tokenB.address);
    history.replace({ search: searchParams.toString() });
  }

  const setToken1Parent = async (val) => {
    if(token2)
      callBackSelect(val, token2)

    setToken1(val)

    // @ts-expect-error
    searchParams.set('inputCurrency', val.address);
    history.replace({ search: searchParams.toString() });

    if(token2 && !(new BigNumber(sendAmount).eq(0)) && sendAmount != ''){
      setCalculating(true)
      setGetAmount('')
      var res = await prepareTrade(val, token2, sendAmount, slippage, direction)
    } else {
      destroyCurrentTrade()
    }
  }

  const setToken2Parent = async (val) => {
    if(token1)
      callBackSelect(token1, val)
    setToken2(val)

    // @ts-expect-error
    searchParams.set('outputCurrency', val.address);
    history.replace({ search: searchParams.toString() });
    
    if(token1 && !(new BigNumber(sendAmount).eq(0)) && sendAmount != ''){
      setCalculating(true)
      setGetAmount('')
      var res = await prepareTrade(token1, val, sendAmount, slippage, direction)
    } else {
      destroyCurrentTrade()
    }
  }

  const onSubmit = () => {
  }

  const clearWidget = () => {
    destroyCurrentTrade()
    setModal(null)
    //setToken1(undefined)
    //setToken2(undefined)
    setSendAmount('');
    setGetAmount('');
  }


  const switchPairs = async () => {
    var tokenTemp = token1

    setToken1(token2)
    setToken2(tokenTemp)
    setSendAmountFunction(getAmount, token2, tokenTemp)
    setGetAmount('')
  }

  const setSendAmountFunction = async (r, tokenA: undefined | Asset, tokenB: undefined | Asset) => {
    setSendAmount(r);
    setDirection(Direction.Input);

    if(new BigNumber(r).eq(0) || r == '.')
      setGetAmount('0')

    if(r.slice(-1) != '.'){
      await prepareTradeLocal(r, Direction.Input, tokenA, tokenB)
    }
  }

  const setGetAmountFunction = async (r) => {
    setGetAmount(r);
    //setDirection(Direction.Output);
    return
    // if (isConnected) {
    //   await prepareTradeLocal(r, Direction.Output, undefined, undefined)
    // }
  }

  const setSlippageChange = async (r) => {
    setSlippage(r)
    setCalculating(true)
    await prepareTrade(token1, token2, direction == Direction.Input ? sendAmount : getAmount, r, direction)
    setCalculating(false)

  }

  const prepareTradeLocal = async (r, dir, tokenA: undefined | Asset, tokenB: undefined | Asset) => {
    setCalculating(true)

    return await prepareTrade(tokenA ? tokenA : token1, tokenB ? tokenB : token2, r, slippage, dir)
  }

  const sendTx = async () => {
    try{
      if(direction == Direction.Input)
        var res = await executeTrade(token1, token2, sendAmount, minReceived, path, allowance, direction, stable, getAmount)
      else
        var res = await executeTrade(token1, token2, minReceived, getAmount, path, allowance, direction, stable)

        destroyCurrentTrade()

      if(res.success == true){
        setGasSaved(res.gasSavedUSD!)
        setGasSpent(res.gasSpentUSD!)
        setConfirmTime(res.time)
        settxHash(res.tx)

        setModal(Modal.Submitted)
      }
      else{
        setModalError(res.error)
        setModal(null)
        clearWidget()
      }
    } catch(e){
      destroyCurrentTrade()
      setModalError(JSON.stringify(e))
      setModal(null)
      clearWidget()
    }

  }

  const getSwapButtonStatus = () => {
    if(isConnected == false){
      return "Connect to zkSync"
    }
    
    if(calculating == true)
      return 'Loading...'
    else if (sendAmount == '0' || sendAmount == '')
      return 'Enter amount'
    else if(hasEnough == false)
      return 'Insufficient balance'
    else if(success == false)
      return 'Insufficient liquidity'
    else if(hasEth == false){
      let feeAsset = getFeeAsset()
      return `Need ${feeAsset.symbol} for gas`
    } else if(hasEnoughAllowance == false)
      return 'Approve' + (new BigNumber(priceImpact).gte(20) ? ' (high price impact)' : '')
    else if((trade && trade.routeText.includes('Wrap')) || (trade && trade.routeText.includes('Unwrap')))
      return trade.routeText
    else if (new BigNumber(priceImpact).gte(20))
      return 'Swap (high price impact)'
    else
      return 'Swap'
  }

  useEffect(() => {
    if(trade && new BigNumber(trade.baseConvertRequest).eq(sendAmount)){
        setCalculating(false)
        setSuccess(true)
        setGetAmount(new BigNumber(trade.expectedConvertQuote).toFixed())
        setMinReceived(new BigNumber(trade.minAmountConvertQuote).toFixed())
        //setSendAmount(new BigNumber(trade.baseConvertRequest).toFixed())
        setLiquidityProviderFee(Number(new BigNumber(trade.liquidityProviderFee[0]).times(trade.baseConvertRequest).div(10000).toPrecision(6)))
        setPrice(Number(new BigNumber(trade.baseConvertRequest).div(trade.expectedConvertQuote).toFixed(6)))
        setStable(trade.stable)
        setHasEth(trade.hasETH)
        setPriceImpact(trade.priceImpact)
        setHasEnoughAllowance(trade.hasEnoughAllowance)
        setAllowance(trade.fromAllowance)
        setRoute(trade.routeText)
        setPath(trade.routePath)
        setHasEnough(trade.fromBalance.hasEnough)
    } else if (trade == null) {
      setSuccess(false)
      setPriceImpact('0')
    }

    return () => {}

  }, [trade]);

  useEffect(() => {
    externalSetTokens.current = setTokensFunc
    return () => {}
  }, [])


  useEffect(() => {
    var url = queryString.parse(location.search)
    if(url.inputCurrency){
        let _token1 = undefined
        if(url.inputCurrency.toLowerCase() == 'eth' || url.inputCurrency.toLowerCase() == '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91')
          _token1 = getTokenFromContract('0x0000000000000000000000000000000000000000')
        else
          _token1 = getTokenFromContract(url.inputCurrency)

        if(!token1 && _token1)
          setToken1(_token1)
    }
    if(url.outputCurrency){
        let _token2 = undefined
        if(url.outputCurrency.toLowerCase() == 'eth' || url.outputCurrency.toLowerCase() == '0x5aea5775959fbc2557cc8789bc1bf90a239d9a91')
          _token2 = getTokenFromContract('0x0000000000000000000000000000000000000000')
        else
          _token2 = getTokenFromContract(url.outputCurrency)

        if(!token2 && _token2)
          setToken2(_token2)
    }

    if(token1){
      setToken1(assets[token1!.address!.toLowerCase()])
    }
    if(token2){
      setToken2(assets[token2!.address!.toLowerCase()])
    }
    return () => {}
  }, [assets]);

  return (
    <WidgetMod>

      <SettingRow>
        <HeaderTitle>Swap</HeaderTitle>
        <div>
          <StyledTooltip placement="bottom" title="Settings">
            <SettingButton onClick={() => setModal(Modal.Slipage)}>
              <SettingsIcon style={{color: 'white'}} />
            </SettingButton>
          </StyledTooltip>
        </div>
      </SettingRow>

      <ShadowWidget>
        <AmountSection
          value={sendAmount}
          onChange={(r) => setSendAmountFunction(r, undefined, undefined)}
          assets={assets}
          selectedAsset={token1}
          onAssetSelect={setToken1Parent}
          label="Send"
          price={(token1 && sendAmount) ? new BigNumber(sendAmount).times(token1.price).toFixed(2) : '0.00'}
        />
        <SwapDivider>
          <SwapButton onClick={() => switchPairs()}/>
        </SwapDivider>
        <AmountSection
          maxDisabled={true}
          value={getAmount}
          onChange={setGetAmountFunction}
          assets={assets}
          selectedAsset={token2}
          onAssetSelect={setToken2Parent}
          label="Get"
          price={(token2 && getAmount) ? new BigNumber(getAmount).times(token2.price).toFixed(2) : '0.00'}
        />
      </ShadowWidget>
      
        <SwapButtonMain
          onClick={() => {setModal(Modal.Confirm); sendTx()}} 
          className={new BigNumber(priceImpact).gte(20) ? 'impact': ''}
          disabled={!Number(sendAmount) || !Number(getAmount) || calculating || !hasEnough || !hasEth}
        >
          <LoadingAnimation isLoading={calculating} />
          {getSwapButtonStatus()}
        </SwapButtonMain>
      

{
        (token1 && token2 && sendAmount != '0' && getAmount != '0' && sendAmount != '' && getAmount != '' && success) ? <ExchangeInfo
          slippage={slippage}
          minReceived={Number(new BigNumber(minReceived).toFixed(4))}
          estReceived={Number(new BigNumber(getAmount).toFixed(4))}
          symbol1={token1 ? token1.symbol : ''}
          symbol2={token2 ? token2.symbol : ''}
          recipient={address ? reduceString(address as string, 7, 4) : 'Not Connected'}
          priceImpact={priceImpact}
          price={price}
          fee={Math.floor(Math.random() * (35 - 20) + 20)}
          route={route}
        /> : <></>
      }

      <ConfirmTransactionModal
        isVisible={modal === Modal.Confirm}
        title={'Swap'}
        onClose={closeModal}
        onConfirm={() => sendTx()}
        leftValue={`${getCleanNumber(sendAmount)} ` + (token1 ? token1.symbol : '')}
        leftIcon={token1 ? token1.logo : ''}
        rightValue={`${getCleanNumber(getAmount)} ` + (token2 ? token2.symbol : '')}
        rightIcon={token2 ? token2.logo : ''}
        icon={arrowIcon}
        footer={
          <>
            <Row>
              <TextGrey>Gas Cost Estimate</TextGrey>
              <TextWhite>{"$" + gasRefund.actual}</TextWhite>
            </Row>
            <Row>
              <TextGrey>Gas Refund Estimate</TextGrey>
              <TextGreen>{"$" + gasRefund.refund}</TextGreen>
            </Row>
            <Row>
              <TextGrey>Minimum received</TextGrey>
              <TextWhite>{getCleanNumber(minReceived)} {token2 ? token2.symbol : ''}</TextWhite>
            </Row>
            <Row>
              <TextGrey>Price impact</TextGrey>
              <TextWhite>{priceImpact}%</TextWhite>
            </Row>
            <Row>
              <TextGrey>Route</TextGrey>
              <TextWhite>{route}</TextWhite>
            </Row>
          </>
        }
      />
      <ErrorModal
        isVisible={modal === Modal.Error}
        onClose={clearWidget}
        title="Swap error!"
        description={modalError}
      />
      <SuccessfulSubmitModal
        isVisible={modal === Modal.Submitted}
        onClose={clearWidget}
        title="Swap confirmed!"
        description="Funds are avaliable in your wallet."
        savings={'$' + currentSwapCost}
        costs={'$0.00'}
        onSubmit={onSubmit}
        symbolIn={token1 ? token1.symbol : ''}
        iconIn={token1 ? token1.logo : ''}
        symbolOut={token2 ? token2.symbol : ''}
        iconOut={token2 ? token2.logo : ''}
        amountIn={getCleanNumber(sendAmount)}
        amountOut={getCleanNumber(getAmount)}
        section={
            <>
              <Row>
                <TextGrey>Fee cost</TextGrey>
                <TextWhite>{'$' + gasSpent}</TextWhite>
              </Row>
              <Row>
                <TextGrey>Refunded gas fees</TextGrey>
                <TextGreen>{'$' + gasSaved}</TextGreen>
              </Row>
              <Row>
                <TextGrey>Confirmation time</TextGrey>
                <TextGreen>{confirmTime}s</TextGreen>
              </Row>
              <Row>
                <TextGrey>Amount spent</TextGrey>
                <TextWhite>{getCleanNumber(sendAmount)} {token1? token1.symbol: ''}</TextWhite>
              </Row>
              <Row>
                <TextGrey>Tx hash</TextGrey>
                <TextWhite><TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>{reduceString(txHash, 6, 4)}</TxButton></TextWhite>
              </Row>
            </>
        }
      />
      <ConnectModal
        isVisible={modal === Modal.Connect}
        onClose={closeModal}
        onConnectCallback={() => setModal(Modal.Switch)}
      />
      <SlipageToleranceModal
        isVisible={modal === Modal.Slipage}
        onClose={closeModal}
        defaultLimit={slippage}
        onLimitChange={setSlippageChange}
        onAdvancedChart={setAdvancedChart}
        advancedChart={advancedChart}
      />
    </WidgetMod>
  );
}

const WidgetMod = styled(Widget)`
  position: relative;
  grid-area: exchangeWidget;
  max-width: 450px;
  min-width: 320px;
  width: 100%;
  overflow: hidden;
  padding: 15px 15px;



  align-items: center;
  justify-content: center;

  @media (max-width: 1200px) {
    
  }

  @media (max-width: 700px) {
    margin-left: auto;
    margin-right: auto;
  }

`

const SwapButton = styled.button`
  ${transition.fast}
  
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 2;
  left: calc(50% - 20px);
  top: -19px;
  background-image: url(${arrowRight});
  background-size: 30px 20px;
  background-repeat: no-repeat;
  background-position: center center;
  transform: rotate(90deg);

  background-color: var(--color-modal-light-blue);
  border: 3px solid var(--color-modal-blue);
  border-radius: 12px;

  :hover {
    background-color: rgba(9,12,22, 0.8);
  }
`;


const SwapDivider = styled.div`

  position: relative;
  height: 3px;
  width: 100%;

`

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;

const SwapButtonMain = styled(Button)`
  margin-top: 20px;
  &.impact{
    border: 1px solid #cc3300;
    background: #cc3300;
  }
`;


const TxButton = styled.button`
  cursor: pointer;
  color: white;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  z-index: 500;
  position: relative;
`;

const SettingButton = styled.button`
  ${uiTransition.iconTransition}

  width: 24px;
  height: 35px;
  margin-left: auto;
  padding: 0;
`;


const SettingRow = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
width: 100%;
padding-bottom: 10px;

`


const HeaderTitle = styled.p`
font-weight: 700;
font-size: 17px;
line-height: 150%;
color: #FFFFFF;

`