import React from 'react'
import { ModalSection } from './modal/ModalComponents'
import { TextSemiBold } from './text/Text'
import arrowIcon from '../assets/images/icons/arrow.svg'
import styled from 'styled-components'

export interface LiquidityTransactionDirectionProps {
  leftValue: string;
  rightValue: string;
  leftIcon?: string;
  rightIcon?: string;
  icon?: string;
  deposit: number;
}

export const LiquidityTransactionDirection = ({icon, leftValue, rightValue, leftIcon, rightIcon, deposit}: LiquidityTransactionDirectionProps) => (
  <ModalSectionWrapper>
    <MainWrapper>

      {deposit == 0 || deposit == 1 ? 
        <>
        <TokenWrapper>
          <TokenLogo src={leftIcon}/>
          <TextSemiBold>{leftValue}</TextSemiBold>
        </TokenWrapper>
        <Icon src={icon ? icon : arrowIcon} alt="arrow" />
        <TokenWrapper>
          <TokenLogo src={rightIcon}/>
          <TextSemiBold>{rightValue}</TextSemiBold>
        </TokenWrapper>
        </> : 
        <>
        </> 
      }

    </MainWrapper>
  </ModalSectionWrapper>
)


const ModalSectionWrapper = styled(ModalSection)`
  height: auto;
`
const Icon = styled.img`
  margin-top: auto;
  margin-bottom: 6px;
  transform: rotate(45deg);

  width: 24px;
  height: 24px;
`;

const TokenLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 10px;
`;

const TokenWrapper = styled.div`
  display: flex;
  height: auto;
  margin-top: 15px;
  margin-bottom: 15px;
`;


const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding: 20px;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
`

const Title = styled.p`
font-family: 'Aeonik';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 150%;

color: #FFFFFF;

`
