import React, { useState, useRef } from 'react'
import styled, {keyframes} from 'styled-components';
import BigNumber from 'bignumber.js';


import { ListItem } from '../../common/ListItem';
import { TextMedium } from '../../common/text/Text';
import { formatDate } from '../../utils/formatDate';
import { Badge } from '../../common/Badge';
import succesful_tx from '../../assets/images/icons/successful_tx.png';
import loading_tx from '../../assets/images/icons/loading_tx.png';
import LockClockIcon from '@mui/icons-material/LockClock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { formatNumber } from '../../utils/formatNumber';

export const AmplifierV2StakeInfo = ({info, onRedeem, symbol}) => {

  const [calculating, setCalculating] = useState(false)

  React.useEffect(() => {
    return () => {}

  }, []);

  const redeem = async () => {
    if(shouldDisableRedeem())
      return
    setCalculating(true)
    await onRedeem(info.index)
    setCalculating(false)
  }

  const shouldDisableRedeem = () => {
    var curDate = new BigNumber(Date.now()).div(1000)

    if(curDate.lt(info.unlock_time)){
      return true
    }

    return false
  }

  const getRedeemStatus = () => {
    return
    var curDate = new BigNumber(Date.now()).div(1000)

    if(curDate.lt(info.unlock_time)){
      return "Unmature"
    }

    return 'Mature'
  }


  const getRedeemImage = () => {
      var curDate = new BigNumber(Date.now()).div(1000)

      if(curDate.lt(info.unlock_time)){
        return <LockClockIcon></LockClockIcon> //loading_tx
      }
      return <LockOpenIcon/>
      //return succesful_tx
  }

  return (
        <StyledDropdown>
          <LI onClick={() => {redeem()}}>
              <DateText>{formatDate(new BigNumber(info.stakedTime).times(1000).plus(24 * 60 * 60 * 30 * 1000).toNumber())}</DateText>

              <Amount>{formatNumber(info.rewardAmount, 2)} {symbol}</Amount>
              <Amount>{formatNumber(info.rewardClaimed, 2)} {symbol}</Amount>
              <Amount style={{justifyContent: 'flex-end'}}>{formatNumber(info.stakeAmount, 2)} LP</Amount>

          </LI>
          <SeperatorLine/>
        </StyledDropdown>
  )
}
//            <ManageButton onClick={() => {redeem()}} disabled={shouldDisableRedeem()}>Redeem</ManageButton>


const StyledDropdown = styled.div`
  position: relative;

  & + & {
    margin-top: 6px;
  }
`;


const NotMaturedIcon = styled.div`
  width: 24px;
  height: 24px;
  background: rgba(113, 121, 145, 0.1);
  border-radius: 8px;
  color: #717991;
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-right: 10px;
`

const TxIcon = styled.img`
  width: 24px;
  height: 24px;

  margin-right: 10px;

`


const LI = styled(ListItem)`
  margin-top: 0.5px;
  margin-bottom: 0.5px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  text-align: left;

  & > *:first-child {
    min-width: 82px;
  }

  & > *:last-child {
    margin-left: auto;
    text-align: right;
  }
`;


const SeperatorLine = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 1px solid #262436;
`


const DateText = styled.p`
  text-align: left;
  align-items: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777F99;
  width: 100%;
`;

const Amount = styled(TextMedium)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  @media (max-width: 900px) {
    font-size: 12px;
  }
`;
