import React, { ReactNode } from 'react'
import styled, {keyframes} from 'styled-components'


export const LoadingBox = () => {

    return (
        <LoadingBoxDiv/>
    )

}


const Shimmer = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`

export const LoadingBoxDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  animation: ${Shimmer} 3s infinite;
  background: linear-gradient(90deg, rgba(9,12,22,1) 0%, rgba(29,34,55,1) 50%, rgba(9,12,22,1) 100%);
  background-size: 500% 500%;
  border-radius: 16px;
  width: 100%;
  height: 100%;
`
