import React, { ChangeEvent, useState } from 'react'
import styled, { css } from 'styled-components';
import { BasicModalProps, Modal } from '../../../common/modal/Modal'
import { ModalText } from '../../../common/modal/ModalComponents'
import { TextSemiBold, TextWhite } from '../../../common/text/Text';
import Toggle from 'react-toggle'
import { useWalletHook } from '../../../web3/walletHook';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';

import Select, { SelectChangeEvent } from '@mui/material/Select';

interface SlipageToleranceModalProps extends BasicModalProps {
  defaultLimit: number;
  onLimitChange: (limit: number) => void
  onAdvancedChart: (advanced: boolean) => void
  advancedChart: boolean;
}



const MenuButton = ({asset, children}) => {
  const [address, setAddress] = React.useState(asset.address);
  const { setFeeAsset } = useWalletHook()


  const handleChange = (event: SelectChangeEvent) => {
    setAddress(event.target.value);
    setFeeAsset(event.target.value.toLowerCase())
  };

  return (
      <FormControl sx={{ m: 1, minWidth: 120, margin: 0, marginTop: '10px' }}>
        <Select
          value={address}
          onChange={handleChange}
          input={<BootstrapInput/>}
          inputProps={{
            sx: {
              padding: '0px',
              borderRadius: '0px'
            },
            MenuProps: {
                MenuListProps: {
                    sx: {
                        backgroundColor: 'var(--color-modal-light-blue)',
                        color: 'white',
                        padding: '0'
                    }
                }
            }
          }}
        >
          {children}
        </Select>
      </FormControl>
  );
}


const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: 0,
  },
  "& .MuiSvgIcon-root": {
    color: "white",
  },
  '& .MuiInputBase-input': {
    borderRadius: 12,
    position: 'relative',
    backgroundColor: 'var(--color-modal-light-blue)',
    border: '1px solid var(--color-blue)',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    '&:focus': {
      borderRadius: 12,
      borderColor: 'var(--color-blue)',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,0.0)',
    },
  },
}));

export const SlipageToleranceModal = ({isVisible, onClose, defaultLimit, onLimitChange, onAdvancedChart, advancedChart}: SlipageToleranceModalProps) => {
  const [limit, setLimit] = useState(defaultLimit);
  const [inputValue, setInputValue] = useState('');
  const { setFeeAsset, getFeeAsset, getFeeAssets } = useWalletHook()

  const onChange = (value: string) => {
    setInputValue(value)
    setLimit(Number(value))
  }

  const minValue = 0.01;
  const maxValue = 100;
  const onBlur = (value: number | undefined) => {
    if (value === undefined) return

    let slippage = value
    if (value < minValue) slippage = minValue
    else if(value > maxValue) slippage = maxValue

    onChange(slippage.toString())
    onLimitChange(slippage)
  }

  return (
    <Modal title="Settings" isVisible={isVisible} onClose={onClose} >
      <List>
        <Item>
          <TextWhiteLarge>
            Slippage
          </TextWhiteLarge>
          <ModalText>
          Your transaction will revert if the settled price deviates by more than this percentage.
          </ModalText>
          <InputWrapper>
            <Input
              value={inputValue}
              onChange={(event: ChangeEvent<HTMLInputElement>) => onChange(event.target.value)}
              onBlur={() => onBlur(limit)}
              type="number"
              placeholder={limit?.toString()}
            /> %
          </InputWrapper>
        </Item>
        <Item>
          <TextWhiteLarge>
            Advanced Chart
          </TextWhiteLarge>
          <ModalText>
          Toggle GeckoTerminal charting versus the standard simple mute chart.
          </ModalText>
          <ToggleDiv>
          <ToggleCustom
            defaultChecked={advancedChart}
            icons={false}
            onChange={(e) => {onAdvancedChart(e.target.checked)}} />
        </ToggleDiv>
        </Item>
      </List>
    </Modal>
  );
}

/*

        <Item>
          <TextWhiteLarge>
            Fee Asset
          </TextWhiteLarge>
          <ModalText>
          Select the token you'd like to use as gas.
          </ModalText>
          <MenuButton asset={getFeeAsset()}>

                {getFeeAssets().map((item, index) => {
                  if(item != null){

                    return (
                      <MenuItem value={item.address}>
                      <FeeWrapper>
                      <FeeIcon src={item.logo}/>
                      <FeeName>{item.symbol}</FeeName>
                      </FeeWrapper>
                      </MenuItem>
                    )
                  }


              })}
          </MenuButton>
        </Item>

        */

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 15px;

  @media (max-width: 500px) {
    flex-wrap: wrap;
    margin: 0px 0px 50px 0px;
  }
`;

const ModalTitle = styled(TextSemiBold)`
  text-align: center;
  font-size: 24px;
`;

const TextWhiteLarge = styled(TextWhite)`
  font-size: 16px;
  padding-bottom: 5px;
`;

const Item = styled.li`
  width: 100%;
  height: 100%;
  margin-bottom: 20px;

  &.active {}

  @media (max-width: 500px) {
    margin-top: 16px;
  }
`;

const backgroundStyles = css`
  width: 100%;
  height: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  color: var(--color-blue);
  background: transparent;
  border: 1.5px solid var(--color-blue);
  border-radius: 14px;
`

const ItemButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  ${backgroundStyles}

  &.active {
    color: #FFFFFF;
    background: transparent;
  }

  :hover {
    color: #FFFFFF;
  }
`;

const InputWrapper = styled.div`
  position: relative;
  padding: 8px 20px 8px 8px;
  display: flex;
  ${backgroundStyles}
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 86px;
  height: 40px;

  margin-top: 10px;

  @media (max-width: 500px) {

  }
  @media (max-width: 500px) {
    &:nth-child(even) {

    }
  }
`;

const Input = styled.input`
  width: 100%;
  height: 100%;
  padding: 0;
  padding-right: 5px;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: var(--color-blue);
  text-align: right;
  background: none;
  border: none;
  outline: none;

  &[type='number'] {
    -moz-appearance:textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
      -webkit-appearance: none;
  }
`;


const ToggleDiv = styled.div`
  text-align: left;
  position: relative;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: auto;

`;

const BalText = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  color: #777F99;
  padding-right: 5px;
`;

const ToggleCustom = styled(Toggle)`

`

const Bridge = styled.button`
  position: relative;
  font-family: 'Aeonik';
  font-weight: 500;
  font-size: 15px;
  line-height: 13px;
  color: var(--color-dark-gray);
  color: white;
  height: 100%;
  border: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 8px 8px 8px;

  width: auto;

  background: var(--color-modal-light-blue);
  border: 1px solid var(--color-blue);
  border-radius: 12px;

  margin-top: 10px;

  :hover {
    color: var(--color-blue-accent) !important;
  }

  @media (max-width: 1000px) {
    font-size: 24px;
  }
`;

const FeeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

`

const FeeIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 8px;
  margin-left: 0px;
`

const FeeName = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  color: #FFFFFF;
`