import React, { useState, } from 'react'
import styled, { keyframes } from 'styled-components';
import { useHistory, useParams } from "react-router-dom";
import BigNumber from 'bignumber.js';

import { ButtonPrimaryLarge } from '../../common/buttons/Button'

import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';
import AddIcon from '@mui/icons-material/Add';

import angleIcon from '../../assets/images/icons/angle-bright.svg'
import externalLink from '../../assets/images/icons/external_link.svg'
import muteDesignIcon from '../../assets/images/icons/mute_design_icon.png'
import { ShadowWidget } from '../../common/ShadowWidget'

import { reduceString } from '../../utils/reduceString';
import { formatNumber } from '../../utils/formatNumber';
import { AmountSection } from '../../common/section/AmountSection'
import { LoadingAnimation } from '../../common/loading/loading'
import { LoadingBox } from '../../common/loading/LoadingBox'
import { AmountInput } from '../../common/AmountInput';
import { TextBright, TextGrey, Text, TextWhite, TextGreen } from '../../common/text/Text'

import { Row } from '../../common/Row';

import { ConfirmTransactionModal } from '../../common/modal/ConfirmTransactionModal';

import { AmplifierV2StakeInfo } from './AmplifierV2StakeInfo';

import { useWalletHook } from '../../web3/walletHook';
import { WalletState } from '../../state';
import { useWalletSelector } from '../../state/hooks';

const queryString = require('query-string');

interface ParamTypes {
  poolId: string;
}

enum Modal {
  Confirm,
  Deposit
}

export const AmplifierV2SubScreen = ({ poolInfo }) => {
  const history = useHistory()
  const { poolId } = useParams<ParamTypes>();

  const { stakeInAmplifier, withdrawFromAmplifier, payoutFromAmplifierV2, getAmplifierV2Info, getAmplifierV2Pools, getAmplifierV2Multiplier } = useWalletHook()

  const [assetA, setAssetA] = useState<any>(undefined)
  const [assetB, setAssetB] = useState<any>(undefined)
  
  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund)

  const [assetAAmount, setAssetAAmount] = useState('0')
  const [estimateAmount, setEstimateAmount] = useState('0')


  const [poolAPY, setPoolAPY] = useState('0')
  const [inputMultiplier, setInputMultiplier] = useState('1.00')
  const [inputPayout, setInputPayout] = useState('0.00')

  
  const [isDeposit, setIsDeposit] = useState(0)
  const [calculating, setCalculating] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const tokens = useWalletSelector((state: WalletState) => state.tokens)
  const ampInfo = useWalletSelector((state: WalletState) => state.amplifiersv2)

  const [poolData, setPoolData] = useState<any | null>(null);

  const [modal, setModal] = useState<Modal | null>(null);


  const RetrieveInfo = () => {
    for (let i in ampInfo) {
      if (ampInfo[i].id.toLowerCase() == poolId.toLowerCase()) {        
        if(ampInfo[i].success == false)
          break

        let poolInfo = {
          ...ampInfo[i],
        }

        setPoolData(poolInfo)

        setIsLoading(false)
        setPoolAPY(new BigNumber(ampInfo[i].apy).times(2).toFixed(2) + '%')
        break
      }
    }
  }

  React.useEffect(() => {
    RetrieveInfo()

    return () => { }

  }, []);


  React.useEffect(() => {
    if (poolData) {
      setAssetA(tokens[poolData.token0.address.toLowerCase()])
      setAssetB(tokens[poolData.token1.address.toLowerCase()])
    } else {
      RetrieveInfo()
    }

    return () => { }


  }, [poolData, ampInfo]);

  React.useEffect(() => {
    if (assetAAmount && poolData) {
      getAmplifierV2Multiplier(poolId, new BigNumber(assetAAmount).times(Math.pow(10,18)).toFixed()).then(res => {
        setInputMultiplier(res.multiplier)
        setInputPayout(res.payout)
      })
    } 

    return () => { }


  }, [assetAAmount]);


  const getDepositButtonStatus = () => {
    if (poolData && poolData.approved) {
      if (new BigNumber(poolData.approved).lt(assetAAmount)) {
        return 'Approve'
      }
    }

    if (poolData) {
      if (new BigNumber(poolData.maxDeposit).lt(inputPayout)) {
        return "Not enough rewards"
      }
    }

    if (new BigNumber(assetAAmount).lte(0))
      return 'Invalid amounts'


    if (assetA && assetB) {
      if (new BigNumber(assetAAmount).gt(poolData.balance))
        return 'Invalid amounts'
    }


    return 'Stake LP'
  }

  const getWithdrawButtonStatus = () => {
    if (new BigNumber(poolData ? poolData.totalUserStake : 0).lte(0))
      return 'Unavailable'

    return 'Withdraw LP'
  }

  const getCollectStatus = () => {
    if (poolData && new BigNumber(poolData.endTime).lt(Date.now() / 1000)) {
      return "Collect & Unstake"
    }
    return 'Collect'
  }


  const shouldDisable = () => {
    if (getDepositButtonStatus() == 'Stake LP' || getDepositButtonStatus() == 'Approve')
      return false

    return true
  }

  const shouldDisableWithdraw = () => {
    if (getWithdrawButtonStatus() == 'Withdraw LP')
      return false

    return true
  }

  const stakeInAmplifierLocal = async () => {
    setCalculating(true)
    setModal(Modal.Deposit)
    await stakeInAmplifier(poolId, assetAAmount, poolData.pair, true)
    await getAmplifierV2Info()
    RetrieveInfo()
    setAssetAAmount('0')
    setCalculating(false)
    setModal(null)

  }

  const withdrawLiquidity = async () => {
    setCalculating(true)
    setModal(Modal.Confirm)
    await withdrawFromAmplifier(poolId)
    await getAmplifierV2Info()
    RetrieveInfo()
    setCalculating(false)
    setModal(null)
  }

  const payoutFromAmplifierLocal = async () => {
    setCalculating(true)
    setModal(Modal.Confirm)
    await payoutFromAmplifierV2(poolId)
    await getAmplifierV2Info()
    RetrieveInfo()
    setCalculating(false)
    setModal(null)
  }

  const changeAssetA = (val) => {
    setAssetAAmount(val)
  }

  const getROICalculator = () => {
    let apy = new BigNumber(poolData.multiplier).times(poolData.baseAPY).plus(poolData.lpAPY).div(100)
    return apy.times(estimateAmount).div(365).times(30).minus(new BigNumber(estimateAmount).times(poolData.managementFee).div(10000)).toFixed(2)
  }

  const getROICalculatorLP = () => {
    let apy = new BigNumber(poolData.lpAPY).div(100)
    return apy.times(estimateAmount).div(365).times(30).toFixed(2)
  }

  const getROICalculatorAmp = () => {
    let apy = new BigNumber(poolData.multiplier).times(poolData.baseAPY).div(100)
    return apy.times(estimateAmount).div(365).times(30).toFixed(2)
  }

  const getMultiplier = async() => {
    return getAmplifierV2Multiplier(poolData.poolId, new BigNumber(assetAAmount).times(Math.pow(10,18)).toFixed())
  }

  return (
    <Screen>
      <Layout>

        <TitleContainer>
          <BackButton onClick={() => history.push('/amplifier')} />
          <Spacer />
          {isLoading == true ? <TitleContainer><LoadingBox /></TitleContainer> : (
            <>
              <Title>{assetA ? (assetA.symbol + '/' + assetB.symbol) : ''} {poolData.stable ? ' sMLP' : ' vMLP'} </Title>
              <Spacer />
              <Subtitle>Amplifier Pool</Subtitle>
            </>
          )}

        </TitleContainer>

        <div style={{ gridArea: 'empty_section' }}>
        </div>

        <DataContainer style={{ gridArea: 'exchange' }}>
          <DataContainerColumn>
            <StatRow>
              <ExchangeHeaderButton className={isDeposit == 0 ? 'active' : ''} onClick={() => setIsDeposit(0)}>
                Stake
              </ExchangeHeaderButton>
              <ExchangeHeaderButton className={isDeposit == 1 ? 'active' : ''} onClick={() => setIsDeposit(1)}>
                Unstake
              </ExchangeHeaderButton>
              <ExchangeHeaderButton className={isDeposit == 2 ? 'active' : ''} onClick={() => setIsDeposit(2)}>
                Info
              </ExchangeHeaderButton>
            </StatRow>

            {
              isDeposit == 0 ?
                (
                  <>
                    <ShadowWidget>
                      <AmountSection
                        value={assetAAmount}
                        onChange={changeAssetA}
                        assets={[]}
                        selectedAsset={{ amount: '0', balance: new BigNumber(poolData ? poolData.balance : 0).toFixed(), logo: assetA ? assetA.logo : '', symbol: assetA ? (assetA.symbol + '/' + assetB.symbol + ' LP') : '', name: '', value: '', decimals: 18, price: '' }}
                        disableEdit={true}
                        onAssetSelect={() => { }}
                        label="Add"
                        price={new BigNumber(poolData ? new BigNumber(poolData.lpPrice).times(assetAAmount) : 0).toFixed()}
                      />
                    </ShadowWidget>
                    <Spacer />
                    <Spacer />
                    <Spacer />


                    <StatRow>
                      <StatText>
                        Farm fee
                      </StatText>
                      <StatTextHighlight>
                        {poolData ? new BigNumber(poolData.managementFee).div(100).toFixed(2) : '0.00'}%
                      </StatTextHighlight>
                    </StatRow>
                    <StatRow>
                      <StatText>
                        Applied veMute multiplier
                      </StatText>
                      <StatTextHighlight>
                        {poolData ? inputMultiplier : '1.00'}x
                      </StatTextHighlight>
                    </StatRow>
                    <StatRow>
                      <StatText>
                      {poolData ? poolData.payoutToken.symbol : "MUTE"} APY
                      </StatText>
                      <StatTextHighlight>
                        {(poolData ? new BigNumber(poolData.baseAPY).times(inputMultiplier).toFixed(0) + '%' : poolAPY)}
                      </StatTextHighlight>
                    </StatRow>
                    <StatRow>
                      <StatText>
                        Combined APY (Amplifier + LP)
                      </StatText>
                      <StatTextHighlight>
                        {(poolData ? new BigNumber(poolData.baseAPY).times(inputMultiplier).plus(poolData.lpAPY).toFixed(0) + '%' : poolAPY)}
                      </StatTextHighlight>
                    </StatRow>
                    <StatRow>
                      <StatText>
                        {poolData ? poolData.payoutToken.symbol : "MUTE"} Rewards
                      </StatText>
                      <StatTextHighlight>
                        {poolData ? inputPayout : '0.00'}  {poolData ?  " " + poolData.payoutToken.symbol : " MUTE"}
                      </StatTextHighlight>
                    </StatRow>
                    <Button onClick={() => stakeInAmplifierLocal()} disabled={shouldDisable()}>
                      <LoadingAnimation isLoading={calculating} />
                      {getDepositButtonStatus()}
                    </Button>
                  </>
                ) :

                (
                  isDeposit == 1 ?
                  <>
                    <Spacer />
                    <Spacer />
                    <Spacer />
                    <StatRow>
                      <StatText>
                        Amount
                      </StatText>
                      <StatTextHighlight>
                        {formatNumber(poolData ? poolData.totalUserStake : 0, 2) + ' ' + (assetA ? (assetA.symbol + '/' + assetB.symbol + ' LP') : 'LP')}
                      </StatTextHighlight>
                    </StatRow>

                    <StatRow>

                      <ErrorText>
                        Notice: Withdrawing will forfeit any future yield for your deposits. If you want to claim rewards and redeem expired deposits, use the 'collect' button.
                      </ErrorText>
                    </StatRow>

                    <Button onClick={() => withdrawLiquidity()} disabled={shouldDisableWithdraw()}>
                      <LoadingAnimation isLoading={calculating} />
                      {getWithdrawButtonStatus()}
                    </Button>
                  </>

                  :

                  <>
                <List>
                <StatRow style={{paddingLeft: '10px', paddingRight: '10px'}}>
                    <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Exp. Date</StatTextHighlight>
                    <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Rewards</StatTextHighlight>
                    <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Claimed</StatTextHighlight>
                    <StatTextHighlight style={{width: '100%', justifyContent: 'flex-end'}}>LP Amount</StatTextHighlight>
                </StatRow>

                {poolData && poolData.user_stakes.length > 0 ? poolData.user_stakes.map((item, index) => (
                  <AmplifierV2StakeInfo info={item} onRedeem={(res) => {}} symbol={poolData.payoutToken.symbol ? poolData.payoutToken.symbol : ""}/>
                )) : <StatTextHighlight>No deposits available</StatTextHighlight>}

                </List>
                </>


                )

            }

          </DataContainerColumn>
        </DataContainer>

        <DataContainer style={{ gridArea: 'rewards' }}>
          <DataContainerColumn>
            <ContainerText>
              Amplifier Rewards
            </ContainerText>
            <HeaderText style={{ width: '100%' }}>
              {poolData ?  <MuteIcon src={poolData.payoutToken.logo} /> : <></>}
              {poolData ? new BigNumber(poolData.currentClaimable).toFixed(2) + ' ' + poolData.payoutToken.symbol : '0.00'} 
            </HeaderText>

            <ContainerText style={{paddingTop: '15px'}}>
              LP Rewards
            </ContainerText>
            <HeaderText style={{ width: '100%' }}>
              {assetA && assetA.logo ? <MuteIcon src={assetA ? assetA.logo : ''} /> : <></>}
              {poolData && assetA ? formatNumber(new BigNumber(poolData.currentClaimableFee0).div(Math.pow(10, assetA.decimals)).toFixed(), 2) : '0.00'}  {assetA ? assetA.symbol : ''}

            </HeaderText>
            <HeaderText style={{ width: '100%' }}>
              {assetB && assetB.logo ? <MuteIcon src={assetB ? assetB.logo : ''} /> : <></>}
              {poolData && assetB ? formatNumber(new BigNumber(poolData.currentClaimableFee1).div(Math.pow(10, assetB.decimals)).toFixed(), 2) : '0.00'}  {assetB ? assetB.symbol : ''}
            </HeaderText>
            <Button onClick={() => payoutFromAmplifierLocal()} disabled={poolData ? new BigNumber(poolData.currentClaimable).lte(0) : true}>
              <LoadingAnimation isLoading={calculating} />
              {getCollectStatus()}
            </Button>

          </DataContainerColumn>
        </DataContainer>


        <DataContainerAPY style={{ gridArea: 'pool_stats' }}>
          <DataContainerColumn>
            <ContainerText>
              Total staked
            </ContainerText>
            <HeaderText>
              {isLoading == true ? (<LoadingBox />) :
                (poolData ? "$" + formatNumber(new BigNumber(poolData.lpPrice).times(poolData.totalStaked), 2) : '$0.00')
              }
            </HeaderText>
          </DataContainerColumn>
          <DataContainerColumn>
            <ContainerText>
              APY
            </ContainerText>
            <HeaderTextGreen>
              {isLoading == true ? (<LoadingBox />) :
                (poolData ? new BigNumber(poolData.baseAPY).plus(poolData.lpAPY).toFixed(0) + '% to ' + new BigNumber(poolData.maxAPY).plus(poolData.lpAPY).toFixed(0) + '%' : poolAPY)
              }
            </HeaderTextGreen>
          </DataContainerColumn>
        </DataContainerAPY>

        <DataContainer style={{ gridArea: 'my_stats' }}>
          <DataContainerColumn>
            <ContainerText>
              My stats
            </ContainerText>
            <StatRow>
              <StatText>
                Current LP balance
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? formatNumber(poolData.balance, 2) + " " : "0") + (assetA ? assetA.symbol + "/" : "") + (assetB ? assetB.symbol : '') + (poolData.stable ? ' sMLP' : ' vMLP'))
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Total staked amount
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? formatNumber(poolData.totalStaked, 2) + " " : "0") + (assetA ? assetA.symbol + "/" : "") + (assetB ? assetB.symbol : '') + (poolData.stable ? ' sMLP' : ' vMLP'))
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Underlying assets
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? formatNumber(poolData.underlyingAssetA != 'NaN' ? poolData.underlyingAssetA : '0', 2) + " " : "0") + (assetA ? assetA.symbol + " / " : "") + (poolData ? formatNumber(poolData.underlyingAssetB != 'NaN' ? poolData.underlyingAssetB : '0', 2) + " " : "0") + (assetB ? assetB.symbol : ''))
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Pool share
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? new BigNumber(poolData.totalUserStake).div(poolData.totalStaked == "0" ? 1 : poolData.totalStaked).times(100).toFixed(2) + '%' : '0%'))
                }
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>


        <DataContainer style={{ gridArea: 'pool_details' }}>
          <DataContainerColumn>
            <ContainerText>
              Amplifier farm details
            </ContainerText>
            <StatRow>
              <StatText>
                Min - Max APY
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  poolData ? (new BigNumber(poolData.baseAPY).plus(poolData.lpAPY).toFixed(2) + '% - ' + new BigNumber(poolData.maxAPY).plus(poolData.lpAPY).toFixed(2)) + '%' : '0.00%'
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Farm veMute multiplier
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  (poolData ? new BigNumber(poolData.multiplier).toFixed(2) + 'x' : '0.00x')
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Vault address
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? reduceString(poolData.id, 7, 4) : ''))
                }
                <ExternalLink src={externalLink} onClick={() => { window.open('https://explorer.zksync.io/address/' + poolData.id, "_blank") }} />
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>
      </Layout>


        <ConfirmTransactionModal
          isVisible={modal === Modal.Confirm}
          title={'Collect Rewards'}
          onClose={() => setModal(null)}
          onConfirm={() => {}}
          leftValue={''}
          leftIcon={assetA ? assetA.logo : ''}
          rightValue={''}
          rightIcon={assetB ? assetB.logo : ''}
          icon={''}
          isLP={true}
          deposit={2}
          footer={
            <>
            <StatTextHighlight style={{justifyContent: "center", width: '100%', alignItems: 'center',  marginBottom: '10px', marginTop: '-12px'}}>
            <AssetImage src={muteDesignIcon}/>
              {formatNumber(poolData ? poolData.currentClaimable : 0, 2) + ' ' + (poolData ? poolData.payoutToken.symbol : 'MUTE')}
            </StatTextHighlight>
            <AddIcon style={{color: 'white', marginLeft: 'auto', marginRight: 'auto', width: '100%'}}/>
            <StatTextHighlight style={{justifyContent: "center", width: '100%', alignItems: 'center',  marginTop: '10px', marginBottom: '10px'}}>
            <AssetImage src={assetA ? assetA.logo : ''}/>
              {formatNumber(poolData && assetA ? new BigNumber(poolData.currentClaimableFee0).div(Math.pow(10, assetA.decimals)).toFixed() : 0, 2)}
              {' ' + (assetA ? (assetA.symbol  == 'ETH' ? 'WETH' : assetA.symbol)  : '')}
            </StatTextHighlight>
            <AddIcon style={{color: 'white', marginLeft: 'auto', marginRight: 'auto', width: '100%'}}/>
            <StatTextHighlight style={{justifyContent: "center", width: '100%', alignItems: 'center',  marginTop: '10px', marginBottom: '60px'}}>
            <AssetImage src={assetB ? assetB.logo : ''}/>
              {formatNumber(poolData && assetB ? new BigNumber(poolData.currentClaimableFee1).div(Math.pow(10, assetB.decimals)).toFixed() : 0, 2)}
              {' ' + (assetB ? (assetB.symbol  == 'ETH' ? 'WETH' : assetB.symbol)  : '')}
            </StatTextHighlight>
            <Row>
              <TextGrey>Gas Cost Estimate</TextGrey>
              <TextWhite>{"$" + gasRefund.actual}</TextWhite>
            </Row>
            <Row>
              <TextGrey>Gas Refund Estimate</TextGrey>
              <TextGreen>{"$" + gasRefund.refund}</TextGreen>
            </Row>
          </>
          }

            />

        <ConfirmTransactionModal
          isVisible={modal === Modal.Deposit}
          title={'Stake LP'}
          onClose={() => setModal(null)}
          onConfirm={() => {}}
          leftValue={''}
          leftIcon={assetA ? assetA.logo : ''}
          rightValue={''}
          rightIcon={assetB ? assetB.logo : ''}
          icon={''}
          isLP={true}
          deposit={2}
          footer={
            <>
            <StatTextHighlight style={{justifyContent: "center", width: '100%', alignItems: 'center',  marginBottom: '30px', marginTop: '-40px'}}>
            <AssetImage src={assetA ? assetA.logo : ''}/>
            <AssetImage src={assetB ? assetB.logo : ''} style={{marginLeft: '-12px'}}/>
              {(poolData ? formatNumber(assetAAmount, 2) : 0) + ' ' + (assetA ? assetA.symbol + "/" : "") + (assetB ? assetB.symbol : '') + (poolData && poolData.stable ? ' sMLP' : ' vMLP')}
            </StatTextHighlight>

            <Row>
              <TextGrey>Gas Cost Estimate</TextGrey>
              <TextWhite>{"$" + gasRefund.actual}</TextWhite>
            </Row>
            <Row>
              <TextGrey>Gas Refund Estimate</TextGrey>
              <TextGreen>{"$" + gasRefund.refund}</TextGreen>
            </Row>
          </>
          }

            />
    </Screen>
  )
}
//          <CreatePair externalOpen={createLiqModal} assets={tokens} createPair={addLiquidity} importPool={importPool} addLiquidity={addLiquidityScreenPopup}/>
//          <ImportPool assets={tokens} importPool={importPool} addPool={addPool} />
// <LiquidityHistory history={liquidity.history}/>

/*
        <DataContainerAPY style={{ gridArea: 'estimate_apy' }}>
          <DataContainerColumn>
            <ContainerText>
              Estimate ROI Calculator
            </ContainerText>

            <ShadowWidget>
              <AmountSection
                value={estimateAmount}
                onChange={setEstimateAmount}
                assets={[]}
                selectedAsset={{ amount: '0', balance: '', logo: '', symbol: '', name: '', value: '', decimals: 18, price: '' }}
                disableEdit={true}
                onAssetSelect={() => { }}
                label="Estimate"
                price={estimateAmount}
              />
            </ShadowWidget>
            <Spacer />
            <Spacer />
            <Spacer />

            <StatRow>
              <StatText>
                LP ROI
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  (poolData ? "$" + formatNumber(getROICalculatorLP(), 2) : '$0.00')
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Amplifier ROI
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  (poolData ? "$" + formatNumber(getROICalculatorAmp(), 2) : '$0.00')
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Management Fee
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  (poolData ? "$" + formatNumber(new BigNumber(estimateAmount).times(poolData.managementFee).div(10000).toFixed(), 2) : '$0.00')
                }
              </StatTextHighlight>
            </StatRow>

            <StatRow>
              <StatText>
                Net ROI
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  (poolData ? "$" + formatNumber(getROICalculator(), 2) : '$0.00')
                }
              </StatTextHighlight>
            </StatRow>

          </DataContainerColumn>
        </DataContainerAPY>
  */

  const List = styled.ul`
  padding-right: 19px;
  min-height: 300px;
  max-height: 325px;
  overflow-y: auto;
`;

const Layout = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "titlecontainer titlecontainer"
  "pool_stats  exchange"
  "my_stats exchange"
  "pool_details exchange"
  "pool_details rewards"
  "pool_details rewards"
  "pool_details rewards";

  grid-auto-rows: minmax(min-content, max-content);

  @media (max-width: 767px) {
    column-gap: 0px;
    row-gap: 8px;
    grid-template-columns: 1fr;
    grid-template-areas:
    "titlecontainer"
    "pool_stats"
    "exchange"
    "rewards"
    "my_stats"
    "pool_details";
  }
`;


const ExternalLink = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 8px;
`

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`
const SpacerBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2E3551;
`
const TitleContainer = styled.div`
  position: relative;
  overflow: hidden;
  grid-area: titlecontainer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`

const StatText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: left;
  color: #7F7D97;
`

const StatTextHighlight = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F6F8FE;
`

const Subtitle = styled.div`
  width: auto;
  padding: 0 10px;
  height: 27px;
  background: rgba(41, 48, 74, 0.4);
  border-radius: 8px;
  display: flex;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  justify-content: center;
  align-items: center;

  color: #777F99;
`

const BackButton = styled.button`
  background: rgba(24, 29, 47, 0.7);
  border-radius: 14px;
  background-image: url(${angleIcon});
  background-size: cover;
  transform: rotate(90deg);

  width: 40px;
  height: 40px;
`

const DataContainer = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 600px) {
    padding: 16px;
  }
`

const DataContainerAPY = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 600px) {
    padding: 16px;
    flex-direction: column;
  }
`

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
`

const ContainerText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #777F99;
  padding-bottom: 18px;
`

const HeaderText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  position: relative;
  display: flex;
  min-height: 30px;
  width: 50%;
  align-items: center;
`

const HeaderTextGreen = styled.h1`
  position: relative;
  min-height: 30px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #0BD98F;
`


const ExchangeHeaderButton = styled.button`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #7F7D97;
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #1D2336;

  &.active {
    color: var(--color-blue-accent);
    border-bottom: 2px solid var(--color-blue-accent);
  }
`

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 20px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;


const SettingAnim = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(60deg); }
`

const SettingButton = styled.button`
  width: 24px;
  height: 35px;
  margin-left: 8px;
  padding: 0;

  :disabled{
    opacity: 0.5;
    cursor: not-allowed;

  }

  :hover {
    opacity: 0.5;
    animation: ${SettingAnim} 0.5s forwards;
  }
`;

const MuteIcon = styled.img`
  width: 35px;
  height: 35px;
  margin-right: 20px;
`;


const ErrorText = styled.p`
  margin-top: 46px;
  font-size: 18px;
  color: rgb(255, 95, 21);
  margin-bottom: 15px;
`;