import React, { useState } from 'react'
import styled from 'styled-components';
import { ButtonPrimaryMedium, ButtonSecondaryLarge } from '../../common/buttons/Button';
import angleIcon from '../../assets/images/icons/angle-light.svg';
import { TextAlt } from '../../common/text/Text';
import BigNumber from 'bignumber.js'
import { Grid } from '../../common/layout/Grid';
import { formatNumber } from '../../utils/formatNumber';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import { transition } from '../../styles';

interface DropdownProps {
  onManageClick: () => any;
  icon0: string;
  icon1: string;
  token0: string;
  token1: string;
  tvl?: string;
  apy?: string;
  children?: any;
  stable?: boolean;
  dark?: boolean;
  disabled?: boolean
  tbv?: string;
  percent?: number;
  soldout?: boolean;
  v2?: boolean;
  stakes?: number;
  payoutToken?: any;
}

export const Dropdown = ({onManageClick, icon0, icon1, token0, token1, apy, tvl, children, stable, dark, disabled, tbv, percent, soldout, v2, stakes, payoutToken}: DropdownProps) => {

  return (
    <StyledDropdown>
      {children ? children : <></>}
      <DropdownButton style={{background: dark ? "#111524" : ''}}>
        <IconsRow>

          <IconWrapper>
            <Icon src={icon0} />
            <Icon src={icon1} />
          </IconWrapper>
          <div style={{marginLeft: '25px'}}>
          <TitleText>{token0} / {token1}</TitleText>

          <BasicText>{stable ? 'sMLP' : 'vMLP'} Liquidity</BasicText>
          </div>
        </IconsRow>

        <APYGrouping>
            {apy != null ? (<><Gray>{`Max APY`}</Gray> <Green>{` ${apy}%`}</Green></>) : <></>}
        </APYGrouping>

        {!dark && v2 ? (

          <PoolTBV>
          Rewards
          <PoolTBVDetail>
          ${tbv}

          <div style={{marginRight: '10px', marginLeft: '10px', width: '100%', height: '100%'}}>
          <Slider
            railStyle={{backgroundColor: 'rgba(255,255,255,0.15)'}}
            trackStyle={{backgroundColor: '#2A70BC'}}
            handleStyle={{width: '0px', height: '0px', borderRadius: '0', border: '0', color: 'rgba(0,0,0,0)'}}
            min={0}
            max={100}
            value={soldout ? 0 : percent}
            step={0.01}
          />
          </div>
          {new BigNumber(soldout ? 0 : percent as number).toFixed(2)}%
          </PoolTBVDetail>
          {payoutToken ? <TokenPayout src={payoutToken.logo}/> : <></>}
          </PoolTBV>


        ) : (
                <TVLGrouping>
                  {tvl != null ? (<><Gray>{dark ? `Staked` : 'TVL'}</Gray> {`$${formatNumber(tvl, 2)}`}</>) : <></>}
              </TVLGrouping>
        )}





        <ManageButton disabled={disabled ? disabled : false} onClick={() => {onManageClick()}}>
          {disabled || !v2 ? 'Expired' : soldout ? 'empty' : 'Stake'}
          {stakes && stakes > 0 ? <NotificationNumber>{stakes}</NotificationNumber> : <></>}
          </ManageButton>
          {payoutToken ? <TokenPayoutMobile src={payoutToken.logo}/> : <></>}
      </DropdownButton>
    </StyledDropdown>
  )
}


const NotificationNumber = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  right: -15px;
  top: -15px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  background: var(--color-blue);
  color: white;
  border: 1px solid transparent;
  border-radius: 100px;


`

const TitleText = styled.p`

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #F6F8FE;
`
const BasicText = styled.p`

font-family: 'Aeonik';
font-style: normal;
font-weight: 400;
font-size: 13px;
line-height: 150%;
/* identical to box height, or 20px */

display: flex;
align-items: center;

/* Dark/100 */

color: #7F7D97;

`

const StyledDropdown = styled.div`
  position: relative;
  overflow: hidden;

  & + & {
    margin-top: 6px;
  }
`;

const DropdownButton = styled(Grid)`

  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  grid-template-areas:
  "main apy tvlusd  manage";


  position: relative;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  height: 86px;
  color: #FFFFFF;
  background: var(--color-modal-light-blue);
  border-radius: 16px;
  justify-content: space-between;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr;
    padding: 6px 12px;
    grid-template-areas:
    "main apy manage";
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr 1fr;
    padding: 6px 12px;
    grid-template-areas:
    "main manage";
  }

  @media (max-width: 400px) {
    grid-template-columns: 2fr 1fr;
    padding: 6px 12px;
    grid-template-areas:
    "main manage";
  }
`;

const IconsRow = styled.div`
  grid-area: main;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 2px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #F6F8FE;

  @media (max-width: 600px) {
    margin-right: auto;
    flex-direction: column;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;



const PairName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 5px;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #F6F8FE;

  @media (max-width: 600px) {
    font-size: 17px;
  }
`;

const Gray = styled.p`
color: var(--color-gray);
width: 60px;
padding-right: 0px;
`
const Green = styled.p`
color: rgb(118, 209, 145);
width: 60px;
padding-right: 0px;
`
const PoolType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-gray);
  @media (max-width: 600px) {
    display: none;
  }
`;

const PoolReserveGrouping = styled.div`
  grid-area: tvl;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 40%;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-gray);

  @media (max-width: 900px) {
    display: none;
  }
`;

const APYGrouping = styled.div`
  grid-area: apy;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 20%;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-gray);

  @media (max-width: 600px) {
    display: none;
  }
`;

const TVLGrouping = styled.div`
  grid-area: tvlusd;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  width: 20%;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: white;

  @media (max-width: 900px) {
    display: none;
  }
`;


const PoolReserve = styled.div`
  display: flex;
  align-items: left;
  margin-right: auto;
  margin-right: 2px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: white;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;

  border-radius: 20px;
  border: 1px solid transparent;

  &:first-child {
    z-index: 1;
  }

  & + & {
    transform: translateX(-6px)
  }
`;

const TokenPayout = styled.img`
  position: absolute;
  bottom: 14%;
  right: 105%;
  width: 30px;
  height: 30px;

  border-radius: 20px;
  border: 1px solid transparent;

  @media (max-width: 900px) {
    display: none;
  }
`;

const TokenPayoutMobile = styled(TokenPayout)`
  top: calc(50% - 15px);
  left: 55%;
  z-index: 5;
  @media (max-width: 900px) {
    display: initial;
  }
  @media (min-width: 901px) {
    display: none;
  }
`;

const ManageButton = styled(ButtonSecondaryLarge)`
  position: relative;
  grid-area: manage;
  max-width: 150px;
  margin-left: auto;
`

const PoolTBV = styled.div`
  position: relative;
  grid-area: tvlusd;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-gray);
  width: 80%;
  line-height: 150%;
  letter-spacing: -0.01em;

  @media (max-width: 900px) {
    display: none;
  }
`;
const PoolTBVDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  line-height: 150%;
  letter-spacing: -0.01em;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;
