import React, { useEffect, useState } from 'react';
import { useAccount, useSwitchChain, useBalance } from 'wagmi'
import { zkSync } from 'wagmi/chains';
import { useEthersProvider } from '../providers/ethers';
import { useZkSyncProvider } from '../providers/zksync';

import axios from 'axios'
import { Contract, utils, Provider, BrowserProvider } from "zksync-ethers";
import BigNumber from "bignumber.js";
import DMUTEREFUND from './dmutedata.json'

import { multicall } from "@argent/era-multicall";
//import { multicallMute, isSupported, getDeployedAA, handleDeployAA } from './multicall/index';
import UnknownToken from '../assets/images/icons/unknown_token.png';

import getContracts from './contracts/contracts';

import {
  FACTORY,
  ROUTER,
  PAIR,
  WETH,
  BOND,
  ERC20,
  DMUTE,
  AMPLIFIER,
  MULTICALL,
  VEMUTE,
  MUTE_ORACLE,
  AMPLIFIER_REDUX,
  AMPLIFIER_TREASURY,
  KOI_CONVERSION,
  VEKOI
} from './ABI/index'

// Token Lists
import ALLTOKENS from './tokens/index.json';
import MUTE_TOKEN_LIST from './tokens/custom';
import COINGECKO_LIST from './tokens/coingecko';

//import CACHED_POOLS from './pools/cachedPools';

import { GLOBAL_INFO, SWAP_HISTORY, TOKEN_PRICES, TOP_PAIRS, LIQ_POSITIONS } from './apollo/queries'
import { getClient } from './apollo/client'

//import { MuteSwitchPair, TradeContext, MuteSwitchPairSettings, TradeDirection } from 'simple-muteswitch-sdk'
import { MuteSwitchPair } from '../sdk/factories/pair/muteswitch-pair';
import { TradeContext } from '../sdk/factories/pair/models/trade-context';
import { MuteSwitchPairSettings } from '../sdk/factories/pair/models/muteswitch-pair-settings';
import { TradeDirection } from '../sdk/factories/pair/models/trade-direction';
import { Multicall } from './multicall/multicall';
import { Utils } from './multicall/utils';
import { ContractCallResults, ContractCallContext } from './multicall/models';
import { getAmountOut } from './helper';
import * as ethers from "ethers";
import { store, WalletDispatch } from '../state'
import { useWalletDispatch, useWalletSelector } from '../state/hooks';
import { walletSlice } from '../state/reducer'
import { zeroAddress } from 'viem';

const abiDecoder = require('abi-decoder');
abiDecoder.addABI(ROUTER);

const approve_amount = '0xFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFFF';
const min_approve_amount = new BigNumber(approve_amount).div(2);

BigNumber.config({ ROUNDING_MODE: BigNumber.ROUND_DOWN })


export const walletHookContext = React.createContext<any>(undefined)

export const WalletHook = ({ children }) => {
  const dispatch = useWalletDispatch()

  const { address, isConnected, chain } = useAccount();
  
  const account = address
  const { error, switchChain } = useSwitchChain()

  const provider = useEthersProvider({chainId: 324})
  const zk_signer = useZkSyncProvider({chainId: 324})

  const wagmiBal = useBalance({ address: (provider ? account : undefined) })

  const isActive = isConnected
  const chainId = chain?.id

  // 0 not loaded, 1 loading, 2 loaded
  const calculatingTrade = React.useRef<any>([]);

  const [eth_bal, setETHBal] = useState<any>(null);

  const [isMainnet, setIsMainnet] = useState(true);
  const [loadedInitialList, setLoadedInitialList] = useState(false);

  const TOKEN_STORAGE = isMainnet ? 'TOKEN_STORAGE_MAINNET' : 'TOKEN_STORAGE'
  const LIQ_STORAGE = isMainnet ? 'LIQUIDITY_STORAGE_MAINNET' : 'LIQUIDITY_STORAGE'
  const SAVE_STORAGE = isMainnet ? 'SWITCH_SAVEDATA_MAINNET' : 'SWITCH_SAVEDATA'

  const client = getClient(isMainnet)
  const CONTRACTS = getContracts(isMainnet)

  var saveStorage: any = (localStorage.getItem(SAVE_STORAGE) == null ? {} : JSON.parse(localStorage.getItem(SAVE_STORAGE)!))

  if (saveStorage.addedTokens == null) {
    saveStorage.addedTokens = ['ETH']
    localStorage.setItem(SAVE_STORAGE, JSON.stringify(saveStorage));
  }

  if (saveStorage.addedLiquidity == null) {
    saveStorage.addedLiquidity = []
    localStorage.setItem(SAVE_STORAGE, JSON.stringify(saveStorage));
  }

  const [tokenList, setTokenList] = useState<any[]>([]);
  const [cachedPair, setCachedPair] = useState<any>(null)



  useEffect(() => {
    addNewTokens()
    const fetchData = async () => {
      await Promise.all([getTokenPrices(), getTopPairs(), getTXHistory()])
      await Promise.all([getPairAPYs()])
      dispatch(walletSlice.actions.updateOwnedPairs([]))
    }

    fetchData()
  }, [])

  useEffect(() => {
    if (wagmiBal.data && provider && account && zk_signer) {
      setETHBal(wagmiBal.data?.value)
      let decimals = 18
      let balance = new BigNumber(wagmiBal.data?.value.toString()).div(Math.pow(10, 18)).toFixed()
      let address = CONTRACTS.eth.toLowerCase()
      let symbol = 'ETH'
      let name = 'Ethereum'
      let logo = null
      let active = true
      dispatch(walletSlice.actions.updateToken({ balance, symbol, address, decimals, name, logo, active }))
    }
    return () => { }

  }, [wagmiBal, provider, account, zk_signer])

  useEffect(() => {
    if (provider && account) {
      checkNetwork();

      /*
      let mainnet_prov = new ethers.providers.JsonRpcProvider('https://eth-mainnet.g.alchemy.com/v2/j_ArXr5r-WgeQgIZaWPkR7FPNbw0oazR')
      
      mainnet_prov.lookupAddress(account).then(res => {
        dispatch(walletSlice.actions.updateENS(res))
      }).catch(e => console.log(e))

      axios.create().get('https://omniapi.zkns.app/domain-resolver/getReverseRecord/' + account).then(res => {
        dispatch(walletSlice.actions.updatezkID(res.data))
      }).catch(e => {console.log(e)})
      */
    }

    return () => { }

  }, [isActive, provider, account, chainId, isMainnet]);

  useEffect(() => {
    if (provider && account && eth_bal != null) {
      const fetchData = async () => {
        //getTokenPrices()
        var timeer = Date.now()
        await onGetBalance()
        console.log('Get Balance: ' + (Date.now() - timeer) / 1000)

        timeer = Date.now()
        //getLiquidityBalance()
        await Promise.all([getTXHistory(), getDAOInfo()])

        console.log('getTXHistory  & getDAOInfo: ' + (Date.now() - timeer) / 1000)
        timeer = Date.now()

        await Promise.all([getLiquidityBalance(), getBondInfo()])

        console.log('getLiquidityBalance  & getBondInfo: ' + (Date.now() - timeer) / 1000)
        timeer = Date.now()

        //await Promise.all([getSwapHistory()])
        await Promise.all([getAmplifierV2Info(), getAmplifierPools(CONTRACTS.AMPLIFIER)])
        console.log('getamps: ' + (Date.now() - timeer) / 1000)

        let _allPairs = Utils.deepClone(store.getState().allPairs)
        let _ownedPairs = Utils.deepClone(store.getState().ownedPairs)

        let _ampPools = store.getState().amplifiersv2

        for (let i in _ampPools) {
          let _amp = _ampPools[i]

          for (let k in _allPairs) {
            if (_allPairs[k].id.toLowerCase() == _amp.pair.toLowerCase())
              _allPairs[k].ampAPY = _amp.maxAPY
          }
          for (let k in _ownedPairs) {
            if (_ownedPairs[k].address.toLowerCase() == _amp.pair.toLowerCase())
              _ownedPairs[k].ampAPY = _amp.maxAPY
          }
        }

        for (let k in _ownedPairs) {
          for (let id in _allPairs) {
            if (_allPairs[id].id.toLowerCase() == _ownedPairs[k].address.toLowerCase()) {
              _ownedPairs[k].apy = _allPairs[id].apy
            }
          }
        }

        dispatch(walletSlice.actions.updateAllPairs(_allPairs))
        dispatch(walletSlice.actions.updateOwnedPairs(_ownedPairs))

      }

      fetchData().then(() => { })

    }
    return () => { }

  }, [eth_bal]);

  const setFeeAsset = (address) => {
    dispatch(walletSlice.actions.updateFeeAsset(address.toLowerCase()))
  }

  const getFeeAsset = () => {
    return store.getState().tokens[store.getState().feeAsset]
  }

  const getFeeAssets = () => {
    return [
      {asset: store.getState().tokens[CONTRACTS.eth.toLowerCase()], discount: 0},
      {asset: store.getState().tokens[CONTRACTS.KOI.toLowerCase()], discount: 30},
      {asset: store.getState().tokens[CONTRACTS.USDC.toLowerCase()], discount: 15},
      {asset: store.getState().tokens[CONTRACTS.USDT.toLowerCase()], discount: 15},
      {asset: store.getState().tokens[CONTRACTS.WBTC.toLowerCase()], discount: 15},
      {asset: store.getState().tokens[CONTRACTS.ZORRO.toLowerCase()], discount: 15},
      {asset: store.getState().tokens[CONTRACTS.HOLD.toLowerCase()], discount: 0},
    ]
  }

  const updateAmp = async () => {
    let _amplifiers: any = []
    let _ampPools = await getAmplifierPools(CONTRACTS.AMPLIFIER)
    for (let i in _ampPools) {
      let _amp = _ampPools[i]

      /*
      if(!(new BigNumber(_amp.endTime).lt(Date.now() / 1000) && new BigNumber(_amp.totalUserStake).lte(0)))
        _amplifiers.push(_amp)     
      */
    }
    dispatch(walletSlice.actions.updateAmplifiers(_amplifiers))
  }

  const addNewTokens = () => {
    var tokenStorage: any = null // localStorage.getItem(TOKEN_STORAGE)
    let _tokens_temp: any[] = []

    if (isMainnet == true) {
      var directory = MUTE_TOKEN_LIST.concat(COINGECKO_LIST)
      directory = directory.filter((value, index) => {
        const _value = JSON.stringify(value.address.toLowerCase());
        return index === directory.findIndex(obj => {
          return JSON.stringify(obj.address.toLowerCase()) === _value;
        });
      });

      let res = directory
      for (let i in res) {
        let name = res[i].name
        let symbol = res[i].symbol
        let address = res[i].address.toLowerCase()
        let logo = res[i].logoURI
        let decimals = res[i].decimals
        let balance = '0'
        let active = true

        if (symbol == 'DMUTE')
          active = false

        _tokens_temp.push({
          name,
          symbol,
          address,
          logo,
          decimals,
          balance,
          active
        })
      }
    }


    if (tokenStorage == null) {
      localStorage.setItem(TOKEN_STORAGE, JSON.stringify(_tokens_temp));
    } else {
      _tokens_temp = _tokens_temp.concat(JSON.parse(tokenStorage))
    }

    _tokens_temp = _tokens_temp.filter((value, index) => {
      const _value = JSON.stringify(value.address.toLowerCase());
      return index === _tokens_temp.findIndex(obj => {
        return JSON.stringify(obj.address.toLowerCase()) === _value;
      });
    });


    setTokenList(_tokens_temp)

    var allTokens: any[] = []
    for (let item in _tokens_temp) {
      if (_tokens_temp[item].active == true) {
        if (_tokens_temp[item].address.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
          let decimals = _tokens_temp[item].decimals
          let balance = new BigNumber(0).toFixed()
          let address = _tokens_temp[item].address
          let symbol = _tokens_temp[item].symbol
          let name = _tokens_temp[item].name
          let logo = _tokens_temp[item].logo
          let active = true

          allTokens.push({ balance, symbol, address, decimals, name, logo, active })
        } else {
          var decimals = _tokens_temp[item].decimals
          var address = _tokens_temp[item].address
          let symbol = _tokens_temp[item].symbol
          let name = _tokens_temp[item].name
          let logo = _tokens_temp[item].logo
          let val = new BigNumber(0).toFixed()
          let active = true
          allTokens.push({ balance: val, symbol, address, decimals, name, logo, active })
        }

      }
    }

    dispatch(walletSlice.actions.updateTokens(allTokens))
  }

  const onGetBalance = async () => {
    try {
      var contractCallContext: ContractCallContext[] = []
      for (let item in tokenList) {
        if (tokenList[item].active == true) {
          if (tokenList[item].address.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
            //@ts-ignore
            //const eth_bal_local = await provider!.getSigner().getBalance();
            let decimals = tokenList[item].decimals
            let balance = new BigNumber(eth_bal!.toString()).div(Math.pow(10, 18)).toFixed()
            let address = tokenList[item].address
            let symbol = tokenList[item].symbol
            let name = tokenList[item].name
            let logo = tokenList[item].logo
            let active = true

            dispatch(walletSlice.actions.updateToken({ balance, symbol, address, decimals, name, logo, active }))

          } else {
            contractCallContext.push({
              reference: item,
              contractAddress: tokenList[item].address,
              abi: ERC20,
              calls: [{ reference: item, methodName: 'balanceOf', methodParameters: [account] }]
            })

          }

        }
      }

      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });

      contractCallContext.push({
        reference: 'time',
        contractAddress: CONTRACTS.multicall,
        abi: MULTICALL,
        calls: [{ reference: 'time', methodName: 'getCurrentBlockTimestamp', methodParameters: [] }]
      })

      var results: ContractCallResults = await multicall.call(contractCallContext);
      var allTokens: any[] = []

      for (let i in results.results) {
        if (results.results[i].callsReturnContext[0].success == false)
          continue
        if (i == 'time') {
          dispatch(walletSlice.actions.updateChainDelayed(new BigNumber(results.results[i].callsReturnContext[0].returnValues[0]).lte((Date.now() / 1000) - (60 * 5))))
        } else {
          var decimals = tokenList[i].decimals
          var address = tokenList[i].address
          let symbol = tokenList[i].symbol
          let name = tokenList[i].name
          let logo = tokenList[i].logo
          let val = new BigNumber(results.results[i].callsReturnContext[0].returnValues[0]).div(Math.pow(10, decimals)).toFixed()
          let active = true
          allTokens.push({ balance: val, symbol, address, decimals, name, logo, active })
          //dispatch(walletSlice.actions.updateToken({balance: val, symbol, address, decimals, name, logo, active}))
        }
      }

      dispatch(walletSlice.actions.updateTokens(allTokens))
      setLoadedInitialList(true)

    } catch (e) {
      console.log('error finding balances! ! ')
      console.log(e)
    }

  }

  const chainSafetyCheck = async () => {
    await checkNetwork()
    if (store.getState().wrongChain == true)
      throw 'Wrong network'
  }

  const addTokenToStorage = (new_token) => {
    let _tokens = tokenList
    // @ts-ignore
    let dupe = _tokens.filter(e => e.address.toLowerCase() == new_token.address.toLowerCase()).length > 0
    if (dupe == false) {
      // @ts-ignore
      new_token.active = true
      // @ts-ignore
      _tokens.push(new_token)
    } else {
      for (let i in _tokens) {
        // @ts-ignore
        if (new_token.address.toLowerCase() == _tokens[i].address.toLowerCase()) {
          // @ts-ignore
          _tokens[i].active = true
          break
        }
      }
    }

    // @ts-ignore
    localStorage.setItem(TOKEN_STORAGE, JSON.stringify(_tokens));
    setTokenList(_tokens)
  }

  const addLiquidityToStorage = (pair) => {
    try {
      let _addedLiquidity = Utils.deepClone(store.getState().addedLiquidity)
      _addedLiquidity.push(pair)
      _addedLiquidity = _addedLiquidity.map(x => x.toLowerCase());
      _addedLiquidity = Array.from(new Set(_addedLiquidity));
      var saveStorage: any = (localStorage.getItem(SAVE_STORAGE) == null ? {} : JSON.parse(localStorage.getItem(SAVE_STORAGE)!))
      saveStorage.addedLiquidity = _addedLiquidity

      localStorage.setItem(SAVE_STORAGE, JSON.stringify(saveStorage));

      dispatch(walletSlice.actions.updateAddedLiquidity(_addedLiquidity))
    } catch (e) {
      console.log(e)
    }
  }

  const addTokenToWallet = async (token) => {
    if (provider) {
      try {
        if (token.address.toLowerCase() != CONTRACTS.eth.toLowerCase()) {
          // wasAdded is a boolean. Like any RPC method, an error may be thrown.
          //@ts-ignore
          const wasAdded = await provider.provider.request({
            method: 'wallet_watchAsset',
            params: {
              //@ts-ignore
              type: 'ERC20', // Initially only supports ERC20, but eventually more!
              options: {
                address: token.address, // The address that the token is at.
                symbol: token.symbol, // A ticker symbol or shorthand, up to 5 chars.
                decimals: token.decimals, // The number of decimals in the token
                image: token.logo, // A string url of the token logo
              },
            },
          });
        }
      } catch (error) {
        console.log(error);
      }

    }
  }


  const isETH = (token) => {
    if (token.toLowerCase() == CONTRACTS.weth.toLowerCase() || token.toLowerCase() == CONTRACTS.eth.toLowerCase())
      return true
    else
      return false
  }

  const getETH = () => {
    return store.getState().tokens[CONTRACTS.eth]
  }

  const getMuteToken = () => {
    return store.getState().tokens[CONTRACTS.MUTE] || store.getState().tokens[CONTRACTS.MUTE.toLowerCase()]
  }

  const getMuteTokenPrice = () => {
    if (store.getState().tokens[CONTRACTS.MUTE.toLowerCase()])
      return store.getState().tokens[CONTRACTS.MUTE.toLowerCase()].price

    return '0.00'
  }

  const getKoiToken = () => {
    return store.getState().tokens[CONTRACTS.KOI.toLowerCase()]
  }

  const getKoiTokenPrice = () => {
    if (store.getState().tokens[CONTRACTS.KOI.toLowerCase()])
      return store.getState().tokens[CONTRACTS.KOI.toLowerCase()].price

    return '0.00'
  }

  const isUSDC = (token) => {
    if (token.toLowerCase() == CONTRACTS.USDC.toLowerCase())
      return true
    else
      return false
  }

  const currentSwapCost = () => {
    return (new BigNumber(store.getState().ethPrice).times(store.getState().swapCostPerGas).times(store.getState().gasFee).toFixed(2))
  }

  const networkChanged = (networkId) => {
    if (!(new BigNumber(networkId).eq('0x144'))) {
      dispatch(walletSlice.actions.updateWrongChain(true))
    } else {
      dispatch(walletSlice.actions.updateWrongChain(false))
    }
  }

  const getSignerAddress = () => {
    return account
  }

  const getZkSigner = async () => {
    return await (zk_signer as BrowserProvider)?.getSigner()
  }

  const checkNetwork = async () => {
    dispatch(walletSlice.actions.updateWrongChain(false))
    if (chain && chain.id == zkSync.id) {

    } else {
      dispatch(walletSlice.actions.updateWrongChain(true))
      switchChain({chainId: zkSync.id})

    }
  }

  const searchForAllLiquidity = async (to, _addedLiquidity) => {
    try {
      // @ts-ignore
      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });
      var contractCallContext: ContractCallContext[] = [];
      var resultArray: any = {}
      for (let item = 0; item < _addedLiquidity.length; item++) {
        // @ts-ignore
        let pair = _addedLiquidity[item]

        if (pair == '0x000000000000000000000000000000000')
          continue

        contractCallContext.push(
          {
            reference: pair.toLowerCase() + '_' + String(item),
            contractAddress: pair,
            abi: PAIR,
            calls: [
              { reference: 'balance', methodName: 'balanceOf', methodParameters: [to] },
              { reference: 'reserves', methodName: 'getReserves', methodParameters: [] },
              { reference: 'totalSupply', methodName: 'totalSupply', methodParameters: [] },
              { reference: 'token0', methodName: 'token0', methodParameters: [] },
              { reference: 'token1', methodName: 'token1', methodParameters: [] },
              { reference: 'symbol', methodName: 'symbol', methodParameters: [] },
              { reference: 'token1', methodName: 'token1', methodParameters: [] },
              { reference: 'pairFee', methodName: 'pairFee', methodParameters: [] },
              { reference: 'delegates', methodName: 'delegates', methodParameters: [to] },
              { reference: 'approveLP', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] },
              { reference: 'stable', methodName: 'stable', methodParameters: [] }
            ]
          })
      }

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      const contractCallContextTokens: ContractCallContext[] = [];

      for (let i in multiRes.results) {
        if (multiRes.results[i].callsReturnContext[0].success == false)
          continue
        var pair = i.split('_')[0].toLowerCase()
        var index = i.split('_')[1]
        var bal = multiRes.results[i].callsReturnContext[0].returnValues[0]
        var reserves = [multiRes.results[i].callsReturnContext[1].returnValues[0], multiRes.results[i].callsReturnContext[1].returnValues[1]]
        var supply = multiRes.results[i].callsReturnContext[2].returnValues[0]
        var token0 = multiRes.results[i].callsReturnContext[3].returnValues[0].toLowerCase()
        var token1 = multiRes.results[i].callsReturnContext[4].returnValues[0].toLowerCase()


        var reserveANormalized = new BigNumber(reserves[0])//.div(Math.pow(10, pair1.decimals))
        var reserveBNormalized = new BigNumber(reserves[1])//.div(Math.pow(10, pair2.decimals))
        var pairFeePercent = new BigNumber(multiRes.results[i].callsReturnContext[7].returnValues[0]).div(100).toFixed(2)

        resultArray[pair.toLowerCase()] = {
          hasLiq: (new BigNumber(bal).gt(0)),
          available: true,
          assetAAmount: new BigNumber(bal).div(supply).times(reserveANormalized).toFixed(),
          assetBAmount: new BigNumber(bal).div(supply).times(reserveBNormalized).toFixed(),
          assetATotal: reserveANormalized.toFixed(),
          assetBTotal: reserveBNormalized.toFixed(),
          address: pair,
          pairFeePercent,
          symbol: multiRes.results[i].callsReturnContext[5].returnValues[0],
          approveAssetLP: new BigNumber(multiRes.results[i].callsReturnContext[9].returnValues[0]).lte(min_approve_amount),
          delegate: multiRes.results[i].callsReturnContext[8].returnValues[0],
          balance: new BigNumber(bal).div(Math.pow(10, 18)).toFixed(),
          share: new BigNumber(bal).div(supply).times(100).toFixed(2),
          index: index,
          stable: multiRes.results[i].callsReturnContext[10].returnValues[0]
        }


        contractCallContextTokens.push(
          {
            reference: pair.toLowerCase() + '_0_' + token0.toLowerCase(),
            contractAddress: token0,
            abi: ERC20,
            calls: [
              { reference: 'approve', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] },
              { reference: 'decimals', methodName: 'decimals', methodParameters: [] },
              { reference: 'symbol', methodName: 'symbol', methodParameters: [] }
            ]
          },
          {
            reference: pair.toLowerCase() + '_1_' + token1.toLowerCase(),
            contractAddress: token1,
            abi: ERC20,
            calls: [
              { reference: 'approve', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] },
              { reference: 'decimals', methodName: 'decimals', methodParameters: [] },
              { reference: 'symbol', methodName: 'symbol', methodParameters: [] }

            ]
          }
        )
      }


      const multiResTokens: ContractCallResults = await multicall.call(contractCallContextTokens);

      for (let i in multiResTokens.results) {
        if (multiResTokens.results[i].callsReturnContext[0].success == false)
          continue

        var pair = i.split('_')[0].toLowerCase()
        var id = i.split('_')[1]
        var token = i.split('_')[2]
        let _decimals = (multiResTokens.results[i].callsReturnContext[1].returnValues[0])
        var symbol = multiResTokens.results[i].callsReturnContext[2].returnValues[0]
        if (id == '0') {
          resultArray[pair.toLowerCase()].assetASymbol = symbol
          resultArray[pair.toLowerCase()].assetA = token
          resultArray[pair.toLowerCase()].approveAssetA = (token.toLowerCase() == CONTRACTS.eth.toLowerCase()) ? false : new BigNumber(multiResTokens.results[i].callsReturnContext[0].returnValues[0]).lte(min_approve_amount)
          resultArray[pair.toLowerCase()].assetAAmount = new BigNumber(resultArray[pair.toLowerCase()].assetAAmount).div(Math.pow(10, _decimals)).toFixed()
          resultArray[pair.toLowerCase()].assetATotal = new BigNumber(resultArray[pair.toLowerCase()].assetATotal).div(Math.pow(10, _decimals)).toFixed()
        } else {
          resultArray[pair.toLowerCase()].assetB = token
          resultArray[pair.toLowerCase()].assetBSymbol = symbol
          resultArray[pair.toLowerCase()].approveAssetB = (token.toLowerCase() == CONTRACTS.eth.toLowerCase()) ? false : new BigNumber(multiResTokens.results[i].callsReturnContext[0].returnValues[0]).lte(min_approve_amount)
          resultArray[pair.toLowerCase()].assetBAmount = new BigNumber(resultArray[pair.toLowerCase()].assetBAmount).div(Math.pow(10, _decimals)).toFixed()
          resultArray[pair.toLowerCase()].assetBTotal = new BigNumber(resultArray[pair.toLowerCase()].assetBTotal).div(Math.pow(10, _decimals)).toFixed()
        }
      }

      return resultArray
    } catch (e) {
      console.log(e)
      return []
    }
  }

  const getAllZkSyncTokens = async () => {
    let response = await fetch('https://api.coingecko.com/api/v3/coins/list?include_platform=true')
    const data = await response.json();
    let tokens = {}
    for (let i in data) {
      let token = data[i]
      if (token.platforms['zksync'])
        tokens[token.platforms['zksync']] = {
          name: token.name,
          symbol: token.symbol,
          id: token.id
        }
    }

    return tokens
  }
  const getLiquidityBalance = async () => {
    var saveStorage: any = (localStorage.getItem(SAVE_STORAGE) == null ? {} : JSON.parse(localStorage.getItem(SAVE_STORAGE)!))

    var _addedLiquidity = store.getState().addedLiquidity

    /*
    if (_addedLiquidity.length == 0) {
      _addedLiquidity = Utils.deepClone(CONTRACTS.LP))
    }

    //_addedLiquidity = _addedLiquidity.concat(saveStorage.addedLiquidity)
    //_addedLiquidity = _addedLiquidity.map(x => x.toLowerCase());
    //
    */
    _addedLiquidity = Array.from(new Set(_addedLiquidity));

    var all_liq = await searchForAllLiquidity(getSignerAddress(), _addedLiquidity)
    try {
      var _ownedPairs: any = []
      for (let i in all_liq) {
        if (new BigNumber(all_liq[i].assetAAmount).lte(0) || new BigNumber(all_liq[i].assetBAmount).lte(0)) {
          _addedLiquidity.splice(all_liq[i].index, 1)
          continue
        }

        _ownedPairs.push(all_liq[i])
      }

      // sory liquidity by value 
      let arrayForSort = [..._ownedPairs]

      const orderedPairs = arrayForSort.sort((a: any, b: any) => {
        let _aa = getTokenFromContract(a.assetA)
        let _ab = getTokenFromContract(a.assetB)
        let _ba = getTokenFromContract(b.assetA)
        let _bb = getTokenFromContract(b.assetB)
        let _aPrice = new BigNumber(a.assetAAmount).times(_aa.price ? _aa.price : 0).plus(new BigNumber(a.assetBAmount).times(_ab.price ? _ab.price : 0)).toNumber()
        let _bPrice = new BigNumber(b.assetAAmount).times(_ba.price ? _ba.price : 0).plus(new BigNumber(b.assetBAmount).times(_bb.price ? _bb.price : 0)).toNumber()

        return _bPrice - _aPrice;
      })

      dispatch(walletSlice.actions.updateAddedLiquidity(_addedLiquidity))
      dispatch(walletSlice.actions.updateOwnedPairs(orderedPairs))
    } catch (e) {
      console.log(e)
    }

    //console.log(res)
  }

  const getCurrentAsset = (asset) => {
    let _tokens = store.getState()
    for (let i in _tokens) {
      if (asset.symbol == _tokens[i].symbol)
        return _tokens[i]
    }
  }

  const getTokenPrice = (symbol) => {
    let _tokens = store.getState()

    for (let i in _tokens) {
      if (_tokens[i].symbol == symbol)
        return _tokens[i].price
    }

    return 0
  }

  const getTokenFromContract = (contract) => {
    let _tokens = store.getState().tokens
    contract = contract.toLowerCase() == CONTRACTS.weth.toLowerCase() ? CONTRACTS.eth.toLowerCase() : contract.toLowerCase()
    for (let i in _tokens) {
      if (i.toLowerCase() == contract.toLowerCase()) {
        return _tokens[i]
      }
    }

    return { address: contract, decimals: 18, logo: UnknownToken }
  }

  const getTokenFromSymbol = (symbol) => {
    let _tokens = store.getState()

    for (let i in _tokens) {
      if (_tokens[i] && _tokens[i].symbol && _tokens[i].symbol.toLowerCase() == symbol.toLowerCase())
        return _tokens[i]
    }

    return 0
  }

  const getLiqPositions = async () => {
    try {

      // dont need to continually fetch 
      //if(store.getState().history.length > 0)
      //return

      var start = Date.now()
      let result = await client.query({
        query: LIQ_POSITIONS,
        variables: {
          user: getSignerAddress()?.toLowerCase(),
        },
        fetchPolicy: 'no-cache',
      })

      console.log('liqPositions ' + (Date.now() - start) / 1000)

      let _addedLiquidity = store.getState().addedLiquidity
      if (_addedLiquidity.length == 0) {
        dispatch(walletSlice.actions.updateAddedLiquidity(Utils.deepClone(CONTRACTS.LP)))
        _addedLiquidity = Utils.deepClone(CONTRACTS.LP)
      }

      if (result?.data && result.data.user && result.data.user.liquidityPositions) {
        for (let swap_index in result.data.user.liquidityPositions) {
          var swap = result.data.user.liquidityPositions[swap_index]
          var pairID = swap.id.split('-')[0]

          if (!_addedLiquidity.includes(pairID) && !new BigNumber(swap.liquidityTokenBalance).eq(0))
            _addedLiquidity = [..._addedLiquidity, pairID]
        }

        _addedLiquidity = _addedLiquidity.map(x => x.toLowerCase());
        _addedLiquidity = Array.from(new Set(_addedLiquidity));

        dispatch(walletSlice.actions.updateAddedLiquidity(Utils.deepClone(_addedLiquidity)))
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getTXHistory = async () => {
    try {

      // dont need to continually fetch 
      //if(store.getState().history.length > 0)
      //return

      var start = Date.now()
      let result = await client.query({
        query: GLOBAL_INFO,
        variables: {
          user: getSignerAddress() ? getSignerAddress()?.toLowerCase() : '0x00',
          hourStartUnix: (new BigNumber(Math.floor(Date.now() / (1000 * 60 * 60 * 24)) - 1).times(60 * 60 * 24).toNumber()),
        },
        fetchPolicy: 'no-cache',
      })

      console.log('getTXHistory(): ' + (Date.now() - start) / 1000)

      let _addedLiquidity: any[] = [] // store.getState().addedLiquidity
      /*
      if (_addedLiquidity.length == 0) {
        dispatch(walletSlice.actions.updateAddedLiquidity(Utils.deepClone(CONTRACTS.LP))))
        _addedLiquidity = Utils.deepClone(CONTRACTS.LP))
      }
      */

      if (result?.data) {
        let _history: any = []

        if (result.data.user && result.data.user.liquidityPositions) {
          for (let swap_index in result.data.user.liquidityPositions) {
            var swap = result.data.user.liquidityPositions[swap_index]
            var pairID = swap.id.split('-')[0]

            if (!_addedLiquidity.includes(pairID))
              _addedLiquidity = [..._addedLiquidity, pairID]
          }
        }


        for (let swap_index in result.data.swaps) {
          var swap = result.data.swaps[swap_index]

          let fromToken = swap.amount0In != '0' ? swap.pair.token0.symbol : swap.pair.token1.symbol
          let toToken = swap.amount0In != '0' ? swap.pair.token1.symbol : swap.pair.token0.symbol

          let amountIn = swap.amount0In != '0' ? swap.amount0In : swap.amount1In
          let amountOut = swap.amount0Out != '0' ? swap.amount0Out : swap.amount1Out

          let trade = {
            operation: 'Swap',
            status: 'Confirmed',
            timestamp: Number(swap.timestamp) * 1000,
            amount: amountOut,
            amountIn: amountIn,
            amountOut: amountOut,
            amountUSD: swap.amountUSD,
            fromPair: fromToken,
            toPair: toToken,
            hash: swap.id.split('-')[0]
          }

          _history = [..._history, trade]
        }

        for (let swap_index in result.data.mints) {
          var swap = result.data.mints[swap_index]

          let fromToken = swap.pair.token0.symbol
          let toToken = swap.pair.token1.symbol
          let pairAddress = swap.pair.id

          let amountIn = swap.amount0
          let amountOut = swap.amount1

          let trade = {
            operation: 'Add LP',
            status: 'Confirmed',
            timestamp: Number(swap.timestamp) * 1000,
            amount: amountOut,
            amountIn: amountIn,
            amountOut: amountOut,
            amountUSD: swap.amountUSD,
            lp: new BigNumber(swap.liquidity).toFixed(4),
            fromPair: fromToken,
            toPair: toToken,
            hash: swap.id.split('-')[0]
          }

          _history = [..._history, trade]

          //if(!_addedLiquidity.includes(pairAddress))
          //_addedLiquidity = [..._addedLiquidity, pairAddress]
        }

        for (let swap_index in result.data.burns) {

          var swap = result.data.burns[swap_index]

          let fromToken = swap.pair.token0.symbol
          let toToken = swap.pair.token1.symbol

          let amountIn = swap.amount0
          let amountOut = swap.amount1

          let trade = {
            operation: 'Remove LP',
            status: 'Confirmed',
            timestamp: Number(swap.timestamp) * 1000,
            amount: amountOut,
            amountIn: amountIn,
            amountOut: amountOut,
            amountUSD: swap.amountUSD,
            lp: new BigNumber(swap.liquidity).toFixed(4),
            fromPair: fromToken,
            toPair: toToken,
            hash: swap.id.split('-')[0]
          }

          _history = [..._history, trade]
        }


        //dispatch(walletSlice.actions.updateAllPairs(Utils.deepClone(result.data.pairs))))
        dispatch(walletSlice.actions.updateTrendingPairs(Utils.deepClone(result.data.pairDayDatas)))
        dispatch(walletSlice.actions.updateETHPrice(Utils.deepClone(result.data.bundle.ethPrice)))
        _history.sort((a, b) => b.timestamp - a.timestamp);
        dispatch(walletSlice.actions.updateHistory(Utils.deepClone(_history)))
        _addedLiquidity = _addedLiquidity.map(x => x.toLowerCase());
        _addedLiquidity = Array.from(new Set(_addedLiquidity));

        dispatch(walletSlice.actions.updateAddedLiquidity(Utils.deepClone(_addedLiquidity)))

        /*
        let tokenUpdates: any[] = []
        for(let i in result.data.tokens){
          let token0 = result.data.tokens[i]
          
          let _tokens = store.getState().tokens
          let _token0 =  _tokens[token0.id.toLowerCase()]
 
          const eth_price = result.data.bundle.ethPrice
 
          let decimals = token0.decimals
          let address = token0.id.toLowerCase()
          let symbol = token0.symbol
          let name = token0.name
          let price = new BigNumber(token0.derivedETH).times(eth_price).toFixed()
 
          //cull 0 value tokens
          if(new BigNumber(price).eq(0))
            continue
 
          let logo = _token0 && _token0.logo ? _token0.logo : UnknownToken
          let balance = _token0 && _token0.balance ? _token0.balance : '0.00'
          let active = _token0 && _token0.active ? _token0.active : false
            //update eth using weth data
          if(address.toLowerCase() == CONTRACTS.weth.toLowerCase())
            tokenUpdates.push({ decimals : decimals, 
                                address: CONTRACTS.eth, 
                                symbol: getETH().symbol, 
                                name: getETH().name, 
                                logo: getETH().logo, 
                                balance: getETH().balance, 
                                active: true, 
                                price: eth_price })
 
          tokenUpdates.push({decimals, address, symbol, name, logo, balance, active, price})
        }
        
        dispatch(walletSlice.actions.updateTokens(tokenUpdates))
        */
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getTopPairs = async () => {
    try {
      var start = Date.now()

      let result = await client.query({
        query: TOP_PAIRS,
        variables: {
          user: '0x0',
          extra: String('0xd5a69D2bD59ae04EA931B4e2bD30aED6A412Ce10').toLowerCase()
        },
        fetchPolicy: 'cache-first',
      })

      console.log('getTopPairs(): ' + (Date.now() - start) / 1000)
      if (result?.data) {
        dispatch(walletSlice.actions.updateAllPairs(Utils.deepClone(result.data.pairs.concat(result.data.extra))))
      }

    } catch (e) {
      console.log(e)
    }
  }

  /*
  const getTransactionHistory = async () => {
    const url = 'https://block-explorer-api.mainnet.zksync.io/api?module=account&action=txlist&page=1&offset=100&sort=desc&endblock=99999999&startblock=0&address=' + getSignerAddress()
  
    var res = await axios.create().get(url)
    var dat_list = res.data.result
    let _tokens = store.getState().tokens
    let _history: any = []

    for(let i in dat_list){
      let tx = dat_list[i]
      if(tx.to.toLowerCase() == CONTRACTS.router.toLowerCase()){

        const iface = new ethers.utils.Interface(ROUTER)

        const decodedArgs = iface.decodeFunctionData(tx.input.slice(0,10), tx.input)
        const functionName = iface.getFunction(tx.input.slice(0,10)).name

        console.log(decodedArgs)
        console.log(functionName)


        if(functionName.includes('removeLiquidityETH')){
          let trade = {
            operation: 'Remove LP',
            status: 'Confirmed',
            timestamp: Number(tx.timeStamp) * 1000,
            amount: new BigNumber(decodedArgs.amountETHMin.toString()),
            amountIn: new BigNumber(decodedArgs.amountTokenMin.toString()),
            amountOut: new BigNumber(decodedArgs.amountETHMin.toString()),
            amountUSD: 0,
            lp: new BigNumber(decodedArgs.liquidity).div(Math.pow(10,18)).toFixed,
            fromPair: getETH(),
            toPair: _tokens[decodedArgs.token.toLowerCase()],
            hash: tx.blockHash
          }
          
          _history = [..._history, trade]
        } else if(functionName.includes('removeLiquidity')){
          let trade = {
            operation: 'Remove LP',
            status: 'Confirmed',
            timestamp: Number(tx.timeStamp) * 1000,
            amount: new BigNumber(decodedArgs.amountAMin.toString()),
            amountIn: new BigNumber(decodedArgs.amountAMin.toString()),
            amountOut: new BigNumber(decodedArgs.amountBMin.toString()),
            amountUSD: 0,
            lp: 0,
            fromPair: _tokens[decodedArgs.tokenA.toLowerCase()],
            toPair: _tokens[decodedArgs.tokenB.toLowerCase()],
            hash: tx.blockHash
          }
          
          _history = [..._history, trade]
        } else if(functionName.includes('addLiquidityETH')){
          let trade = {
            operation: 'Add LP',
            status: 'Confirmed',
            timestamp: Number(tx.timeStamp) * 1000,
            amount: new BigNumber(decodedArgs.amountETHMin.toString()),
            amountIn: new BigNumber(decodedArgs.amountETHMin.toString()),
            amountOut: new BigNumber(decodedArgs.amountTokenMin.toString()),
            amountUSD: 0,
            lp: 0,
            fromPair: getETH(),
            toPair: _tokens[decodedArgs.token.toLowerCase()],
            hash: tx.blockHash
          }
          _history = [..._history, trade]

        } else if(functionName.includes('addLiquidity')){
          let trade = {
            operation: 'Add LP',
            status: 'Confirmed',
            timestamp: Number(tx.timeStamp) * 1000,
            amount: new BigNumber(decodedArgs.amountADesired.toString()),
            amountIn: new BigNumber(decodedArgs.amountADesired.toString()),
            amountOut: new BigNumber(decodedArgs.amountBDesired.toString()),
            amountUSD: 0,
            lp: 0,
            fromPair: _tokens[decodedArgs.tokenA.toLowerCase()],
            toPair: _tokens[decodedArgs.tokenB.toLowerCase()],
            hash: tx.blockHash
          }
          _history = [..._history, trade]

        } else if (functionName.includes('swap')){

        }
        
      }


      
    }

    _history.sort((a,b) => b.timeStamp - a.timeStamp);
    dispatch(walletSlice.actions.updateHistory(Utils.deepClone(_history))))    
  }
  */

  const getTokenPrices = async () => {
    try {
      var start = Date.now()
      let result = await client.query({
        query: TOKEN_PRICES,
        variables: {
          user: '0x0'
        },
        fetchPolicy: 'cache-first',
      })

      console.log('getTokenPrices(): ' + (Date.now() - start) / 1000)

      var addresses = "0x244c238325fc1bdf6eded726ee1b47d55895d944"
      var addy_index = 0
      
      if (result?.data) {
        let tokenUpdates: any[] = []

        for (let i in result.data.tokens) {
          let token0 = result.data.tokens[i]

          let _tokens = store.getState().tokens
          let _token0 = _tokens[token0.id.toLowerCase()]

          //if(addy_index < 10)
          //addresses += token0.id.toLowerCase() + ","

          //addy_index++

          const eth_price = result.data.bundle.ethPrice

          let decimals = token0.decimals
          let address = token0.id.toLowerCase()
          let symbol = token0.symbol
          let name = token0.name
          let price = new BigNumber(token0.derivedETH).times(eth_price).toFixed()

          //cull 0 value tokens
          if (new BigNumber(price).eq(0))
            continue

          let logo = _token0 && _token0.logo ? _token0.logo : UnknownToken
          let balance = _token0 && _token0.balance ? _token0.balance : '0.00'
          let active = _token0 && _token0.active ? _token0.active : false

          if(active == false)
            continue
          //update eth using weth data
          if (address.toLowerCase() == CONTRACTS.weth.toLowerCase())
            tokenUpdates.push({
              decimals: decimals,
              address: CONTRACTS.eth,
              symbol: getETH().symbol,
              name: getETH().name,
              logo: getETH().logo,
              balance: getETH().balance,
              active: true,
              price: eth_price
            })

          tokenUpdates.push({ decimals, address, symbol, name, logo, balance, active, price })
        }
        /*
        var prices = await axios.create().get(`https://api.coingecko.com/api/v3/simple/token_price/zksync?contract_addresses=${addresses}&vs_currencies=usd`)
        //console.log(prices.data)
        for(let i in prices.data){
          var addy = i
          var obj = prices.data[i]
          var foundIndex = tokenUpdates.findIndex(x => x.address.toLowerCase() == addy.toLowerCase());
          tokenUpdates[foundIndex].price = obj.usd
          console.log('updating')
        }
        */

        dispatch(walletSlice.actions.updateTokens(tokenUpdates))
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getSwapHistory = async () => {
    try {
      return
      let weekID = new BigNumber(Math.floor(Date.now() / (1000))).div(604800).toFixed(0)
      let dateTimestamp = new BigNumber(weekID).times(604800).toNumber()
      let result = await client.query({
        query: SWAP_HISTORY,
        variables: {
          user: getSignerAddress(),
          timestampStartUnix: dateTimestamp,
          id: weekID + '-' + getSignerAddress()?.toLowerCase()
        },
        fetchPolicy: 'no-cache',
      })

      if (result?.data) {
        let userInfo = { totalVolume: '0.00', totalFees: '0.00', rank: 'unranked', globalFees: '0.00' }
        let globalFees = new BigNumber(0)
        for (let user_rank in result.data.global) {
          var user_info = result.data.global[user_rank]
          if (user_info['id'].includes(getSignerAddress()?.toLowerCase())) {
            userInfo.rank = String(Number(user_rank) + 1)
            userInfo.totalVolume = new BigNumber(user_info.totalVolumeUSD).toFixed(2)
            userInfo.totalFees = new BigNumber(user_info.feesPaidUSD).toFixed(2)

          }

          globalFees = globalFees.plus(user_info.feesPaidUSD)
        }

        if (userInfo.rank == 'unranked' && result.data.user[0])
          userInfo.totalVolume = new BigNumber(result.data.user[0].totalVolumeUSD).toFixed(2)

        userInfo.globalFees = globalFees.toFixed(2)
        dispatch(walletSlice.actions.updateUserRanking(userInfo))
      }
    } catch (e) {
      console.log(e)
    }
  }
  const getPairAPYs = async () => {
    try {

      let _allPairs = Utils.deepClone(store.getState().allPairs)
      let _ownedPairs = Utils.deepClone(store.getState().ownedPairs)

      let pair_text = ''
      for (let i in _allPairs) {
        pair_text += _allPairs[i].id + ','
      }

      var base_url = "https://muteswitchcorsproxy.dev-d91.workers.dev/api?link="
      let pair_info = await fetch(`https://api.dexscreener.com/latest/dex/pairs/zksync/${pair_text}`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      pair_info = await pair_info.json()

      //console.log(pair_info)

      var token_prices: any = []

      for (let k in _allPairs) {
        let pair = _allPairs[k]
        //@ts-ignore
        for (let id in pair_info.pairs) {
         
        //@ts-ignore
          token_prices.push({
            //@ts-ignore
            price: pair_info.pairs[id].priceUsd,
            //@ts-ignore
            address: pair_info.pairs[id].baseToken.address.toLowerCase()
          })
          
          //@ts-ignore
          if (pair_info.pairs[id].pairAddress.toLowerCase() == pair.id.toLowerCase() && new BigNumber(pair.reserveUSD).gt(0)) {
            //20% protocol fees
            //@ts-ignore
            let apy = new BigNumber(pair_info.pairs[id].volume.h24).times(pair.pairFee).div(10000).times(365).div(pair.reserveUSD).times(80).toFixed(2)
            _allPairs[k].apy = apy
          }
        }
      }

      for (let k in _ownedPairs) {
        for (let id in _allPairs) {
          if (_allPairs[id].id.toLowerCase() == _ownedPairs[k].address.toLowerCase()) {
            _ownedPairs[k].apy = _allPairs[id].apy
          }
        }
      }



      dispatch(walletSlice.actions.updateAllPairs(_allPairs))
      dispatch(walletSlice.actions.updateTokenPrices(token_prices))
      dispatch(walletSlice.actions.updateOwnedPairs(_ownedPairs))

      return
    } catch (e) {
      console.log(e)
    }
  }


  const getPairDayData = async (pair, pairFee = 30) => {
    try {
      var base_url = "https://muteswitchcorsproxy.dev-d91.workers.dev/api?link="
      let pair_info = await fetch(`https://api.dexscreener.com/latest/dex/pairs/zksync/${pair}`, {
        method: 'GET',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json'
        }
      })
      pair_info = await pair_info.json()
      try {
        //@ts-ignore
        var apy = new BigNumber(pair_info.pair.volume.h24).times(pairFee).div(10000).times(0.8).times(365).div(pair_info.pair.liquidity.usd).times(100).toFixed(2)
      } catch (e) {
        apy = '0'
      }
      //@ts-ignore
      return { apy, tvl: pair_info.pair.liquidity.usd }

    } catch (e) {
      console.log(e)
      return { apy: '0', tvl: '0' }
      return '0.00'
    }
  }


  const getPairTradeData = async (pair) => {
    try {

      var res = await axios.create().get(`https://api.geckoterminal.com/api/v2/networks/zksync/pools/${pair}/ohlcv/hour?aggregate=1&limit=24`)
      var dat_list = res.data.data.attributes.ohlcv_list
      var vals: any = []
      var totalVol = new BigNumber(0)
      for (let i in dat_list) {
        vals.push(dat_list[i][4])
        totalVol.plus(dat_list[i][5])
      }
      return vals

    } catch (e) {

    }
  }




  const getCurrentGasFees = async () => {
    let res: any = await axios.create().get("https://ethgasstation.info/api/ethgasAPI.json?")
    res = res.data.average / 10
    return res
  }

  const getCurrentSwapCost = () => {
    return new BigNumber(store.getState().ethPrice).times(store.getState().swapCostPerGas).times(store.getState().gasFee).toFixed(2)
  }

  const getTokenPair = async (pair1, pair2, stable) => {
    try {
      var path: any = [ethers.getAddress(pair1), ethers.getAddress(pair2)];

      if (path[0].toLowerCase() == CONTRACTS.eth.toLowerCase())
        path[0] = CONTRACTS.weth

      if (path[1].toLowerCase() == CONTRACTS.eth.toLowerCase())
        path[1] = CONTRACTS.weth

      const factory = new Contract(CONTRACTS.factory, FACTORY, provider);
      var res = await factory.getPair(path[0], path[1], stable)
      return res

    } catch (e) {
      console.log(e)
    }
  }

  const prepareTrade = async (pair1, pair2, amountIn, slippage, direction = 1) => {
    try {
      if (amountIn == '' || new BigNumber(amountIn).eq(0) || pair1.address == pair2.address)
        throw 'bad amounts'

      //if user calculates too fast, push amount to stack and return latest value
      calculatingTrade.current.push(amountIn)
      await chainSafetyCheck()
      var path: any = [ethers.getAddress(pair1.address), ethers.getAddress(pair2.address)];

      var wrap = 'Unwrap wETH'
      if (path[0].toLowerCase() == CONTRACTS.eth.toLowerCase())
        wrap = 'Wrap ETH'

      if (path[0].toLowerCase() == CONTRACTS.eth.toLowerCase())
        path[0] = CONTRACTS.weth

      if (path[1].toLowerCase() == CONTRACTS.eth.toLowerCase())
        path[1] = CONTRACTS.weth


      if (path[1] == CONTRACTS.weth && path[0] == CONTRACTS.weth) {

        dispatch(walletSlice.actions.updateCachedPair({
          baseConvertRequest: amountIn,
          expectedConvertQuote: amountIn,
          minAmountConvertQuote: amountIn,
          liquidityProviderFee: [0, 0],
          stable: false,
          routeText: wrap,
          routePath: [pair1, pair2],
          fromAllowance: new BigNumber(approve_amount).toFixed(),
          fromBalance: { hasEnough: wrap == 'Wrap ETH' ? new BigNumber(getETHBalance()).gte(amountIn) : new BigNumber(getWETHBalance()).gte(amountIn) },
          fromToken: { contractAddress: pair1 },
          toToken: { contractAddress: pair2 },
          priceImpact: '0.00',
          hasETH: getHasEnoughTokensForGas(),
        }))

        return {
          trade: {
            baseConvertRequest: amountIn,
            expectedConvertQuote: amountIn,
            minAmountConvertQuote: amountIn,
            liquidityProviderFee: [0, 0],
            stable: false,
            routeText: wrap,
            routePath: [pair1, pair2],
            fromBalance: { hasEnough: wrap == 'Wrap ETH' ? new BigNumber(getETHBalance()).gte(amountIn) : new BigNumber(getWETHBalance()).gte(amountIn) },
            fromAllowance: new BigNumber(approve_amount).toFixed(),
            fromToken: { contractAddress: pair1 },
            toToken: { contractAddress: pair2 },
          },
          priceImpact: '0.00',
          success: true,
          hasETH: getHasEnoughTokensForGas(),
          fee: 0
        }
      }

      if (path[0].toLowerCase() == CONTRACTS.MUTE.toLowerCase() && path[1].toLowerCase() == CONTRACTS.KOI.toLowerCase()) {
        
        var allowance = await getAllowance(CONTRACTS.MUTE, CONTRACTS.KOI_CONVERSION)
        
        dispatch(walletSlice.actions.updateCachedPair({
          baseConvertRequest: amountIn,
          expectedConvertQuote: new BigNumber(amountIn).times(12.5).toFixed(),
          minAmountConvertQuote: new BigNumber(amountIn).times(12.5).toFixed(),
          liquidityProviderFee: [0, 0],
          stable: false,
          routeText: 'Convert to KOI',
          routePath: [pair1, pair2],
          fromAllowance: allowance,
          fromBalance: { hasEnough: new BigNumber(getMUTEBalance()).gte(amountIn) },
          hasEnoughAllowance: new BigNumber(amountIn).lte(allowance),
          fromToken: { contractAddress: pair1 },
          toToken: { contractAddress: pair2 },
          priceImpact: '0.00',
          hasETH: getHasEnoughTokensForGas(),
        }))

        return {
          trade: {
            baseConvertRequest: amountIn,
            expectedConvertQuote: new BigNumber(amountIn).times(12.5).toFixed(),
            minAmountConvertQuote: new BigNumber(amountIn).times(12.5).toFixed(),
            liquidityProviderFee: [0, 0],
            stable: false,
            routeText: 'Convert to KOI',
            routePath: [pair1, pair2],
            fromBalance: { hasEnough: new BigNumber(getMUTEBalance()).gte(amountIn)},
            fromAllowance: allowance,
            hasEnoughAllowance: new BigNumber(amountIn).lte(allowance),
            fromToken: { contractAddress: pair1 },
            toToken: { contractAddress: pair2 },
          },
          priceImpact: '0.00',
          success: true,
          hasETH: getHasEnoughTokensForGas(),
          fee: 0
        }
      }


      const to = ethers.getAddress(getSignerAddress() ? getSignerAddress() as string : zeroAddress);

      var muteswitchPairFactory = cachedPair
      let one = new BigNumber(1)

      if (cachedPair == null ||
        //if tokens have been changed reset
        (cachedPair.fromToken.contractAddress.toLowerCase() != path[0].toLowerCase() ||
          cachedPair.toToken.contractAddress.toLowerCase() != path[1].toLowerCase()) ||
        //if slippage has been changed, reset
        new BigNumber(cachedPair._muteswitchPairFactoryContext.settings.slippage).eq(new BigNumber(slippage).div(100).toNumber()) == false
      ) {


        if (cachedPair != null)
          cachedPair.destroy()

          const muteswitchPair = new MuteSwitchPair({
          // the contract address of the token you want to convert FROM
          fromTokenContractAddress: path[0],
          // the contract address of the token you want to convert TO
          toTokenContractAddress: path[1],
          // the ethereum address of the user using this part of the dApp
          ethereumAddress: to,
          // you can pass in the provider url as well if you want
          ethereumProvider: new Provider(chain!.rpcUrls.default.http[0]),//provider ? provider : new Provider(chain!.rpcUrls.default.http[0]),
          settings: new MuteSwitchPairSettings({
            slippage: new BigNumber(slippage).div(100).toNumber(),
            // @ts-ignore
            customNetwork: {
              multicallContractAddress: CONTRACTS.multicall,
              nodeUrl: chain!.rpcUrls.default.http[0],
              chainId: chain!.id
            }
          }),
        });
        // now to create the factory you just do
        muteswitchPairFactory = await muteswitchPair.createFactory();
        setCachedPair(muteswitchPairFactory)
      }

      var trade = await muteswitchPairFactory.trade(amountIn, TradeDirection.input);

      const factory = new Contract(CONTRACTS.factory, FACTORY, provider);
      const router = new Contract(CONTRACTS.router, ROUTER, provider);

      //[wbtc - > eth - > dai]
      let priceImpact = '0'
      const targettedRoute = trade.allTriedRoutesQuotes[0]

      let _token0 = getTokenFromContract(pair1.address.toLowerCase())
      let _token1 = getTokenFromContract(pair2.address.toLowerCase())

      
      if (_token0.price && !new BigNumber(_token0.price).eq(0) && _token1.price && !new BigNumber(_token1.price).eq(0)) {
        let lp_fee = targettedRoute.liquidityProviderFee[0]
        let pairFee = new BigNumber(1).minus(new BigNumber(lp_fee).div(10000))
        let _tradeAm = new BigNumber(trade.baseConvertRequest).times(pairFee)
        priceImpact = new BigNumber(1).minus(new BigNumber(trade.expectedConvertQuote).times(_token1.price).div(new BigNumber(_tradeAm).times(_token0.price))).times(100).toFixed(2)
      } else {
        for (let i = 0; i < targettedRoute.routePathArray.length - 1; i++) {
          let addr1 = targettedRoute.routePathArray[i].toLowerCase()
          let addr2 = targettedRoute.routePathArray[i + 1].toLowerCase()
          let lp_fee = targettedRoute.liquidityProviderFee[i]
          let _stable = targettedRoute.stable[i]
          let pairAddr = await factory.getPair(addr1, addr2, _stable)
          let pairContract = new Contract(pairAddr, PAIR, provider);

          let metadata = (await pairContract.metadata())
          let pairFee = new BigNumber(1).minus(new BigNumber(lp_fee).div(10000))
          let reserveA = new BigNumber(metadata.r0.toString()).div(metadata.dec0.toString())
          let reserveB = new BigNumber(metadata.r1.toString()).div(metadata.dec1.toString())
          let dec0 = new BigNumber(metadata.dec0.toString())
          let dec1 = new BigNumber(metadata.dec1.toString())

          let tradeAmount = new BigNumber(targettedRoute.expectedAmounts[i]).div(metadata.t0.toLowerCase() == addr1 ? dec0 : dec1)
          if (_stable == true) {
            //x^3y+y^3x
            let _reserveA = metadata.t0.toLowerCase() == addr1 ? reserveA : reserveB
            let _reserveB = metadata.t0.toLowerCase() == addr1 ? reserveB : reserveA
            let amountInCAKE = new BigNumber(tradeAmount).times(pairFee).div(10000)

            var noSlip = getAmountOut(amountInCAKE, _reserveA, _reserveB).times(10000)
            let expected = new BigNumber(trade.expectedConvertQuote)

            if (new BigNumber(1).minus(expected.div(noSlip)).times(100).gt(priceImpact))
              priceImpact = new BigNumber(1).minus(expected.div(noSlip)).times(100).toFixed(2)
          } else {
            // x * y = k

            let _reserveA = metadata.t0.toLowerCase() == addr1 ? reserveA : reserveB
            let _reserveB = metadata.t0.toLowerCase() == addr1 ? reserveB : reserveA

            let constant = _reserveA.times(_reserveB)
            let newResA = _reserveA.plus(tradeAmount)
            let newResB = constant.div(newResA)

            if (_reserveB.minus(newResB).div(_reserveB).times(100).gt(priceImpact))
              priceImpact = _reserveB.minus(newResB).div(_reserveB).times(100).toFixed(2)

            //console.log(_price.toFixed())
            //console.log(_executedPrice.toFixed())

            /*
            let amountInCAKE = new BigNumber(tradeAmount).times(pairFee).times(metadata.t0.toLowerCase() == addr1 ? dec0 : dec1).div(10000000)
            let noSlip = _reserveB.div(_reserveA).times(amountInCAKE).div(metadata.t0.toLowerCase() == addr1 ? dec1 : dec0).times(10000000)
            let expected = new BigNumber(trade.expectedConvertQuote)
            priceImpact = new BigNumber(1).minus(expected.div(noSlip)).times(100).toFixed(2)
            */
          }
        }
      }



      if (calculatingTrade.current[calculatingTrade.current.length - 1] != amountIn) {
        return
      }

      calculatingTrade.current = []

      dispatch(walletSlice.actions.updateCachedPair({
        expectedConvertQuote: trade.expectedConvertQuote,
        minAmountConvertQuote: trade.minAmountConvertQuote,
        baseConvertRequest: trade.baseConvertRequest,
        liquidityProviderFee: trade.liquidityProviderFee,
        fromAllowance: trade.fromAllowance,
        stable: trade.stable,
        hasEnoughAllowance: pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase() ? true : trade.hasEnoughAllowance,
        routeText: trade.routeText,
        routePath: trade.routePath,
        fromBalance: pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase() ? { hasEnough: new BigNumber(getETHBalance()).gte(amountIn) } : trade.fromBalance,
        priceImpact: new BigNumber(priceImpact).lte(0) ? '0.00' : priceImpact,
        hasETH: getHasEnoughTokensForGas(),
      }))

      trade.quoteChanged$.subscribe((value: TradeContext) => {
        // value will hold the same info as below but obviously with
        // the new trade info.
        dispatch(walletSlice.actions.updateCachedPair({
          expectedConvertQuote: value.expectedConvertQuote,
          minAmountConvertQuote: value.minAmountConvertQuote,
          baseConvertRequest: value.baseConvertRequest,
          liquidityProviderFee: value.liquidityProviderFee,
          stable: value.stable,
          hasEnoughAllowance: pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase() ? true : value.hasEnoughAllowance,
          fromAllowance: value.fromAllowance,
          routeText: value.routeText,
          routePath: value.routePath,
          fromBalance: pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase() ? { hasEnough: new BigNumber(getETHBalance()).gte(amountIn) } : trade.fromBalance,
          priceImpact: new BigNumber(priceImpact).lte(0) ? '0.00' : priceImpact,
          hasETH: getHasEnoughTokensForGas(),
        }))

      });


      return {
        trade: {
          expectedConvertQuote: trade.expectedConvertQuote,
          minAmountConvertQuote: trade.minAmountConvertQuote,
          baseConvertRequest: trade.baseConvertRequest,
          liquidityProviderFee: trade.liquidityProviderFee,
          stable: trade.stable,
          hasEnoughAllowance: pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase() ? true : trade.hasEnoughAllowance,
          fromAllowance: trade.fromAllowance,
          routeText: trade.routeText,
          routePath: trade.routePath,
          fromBalance: pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase() ? { hasEnough: new BigNumber(getETHBalance()).gte(amountIn) } : trade.fromBalance,
          priceImpact: new BigNumber(priceImpact).lte(0) ? '0.00' : priceImpact,
          hasETH: getHasEnoughTokensForGas(),
        },
        priceImpact: new BigNumber(priceImpact).lte(0) ? '0.00' : priceImpact,
        success: true,
        hasETH: getHasEnoughTokensForGas(), //new BigNumber(getTokenFromContract('0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE').amount).gt(0)
        fee: 0
      }
    } catch (e) {
      console.log(e)
      return {
        trade: null,
        priceImpact: 0,
        success: false,
        hasETH: false,
        fee: 0,
      }
    }

  }

  const getETHBalance = () => {
    let eth = store.getState().tokens[CONTRACTS.eth]

    if (eth)
      return eth.balance

    return '0'
  }

  const getMUTEBalance = () => {
    let eth = store.getState().tokens[CONTRACTS.MUTE.toLowerCase()]

    if (eth)
      return eth.balance

    return '0'
  }

  const getKOIBalance = () => {
    let eth = store.getState().tokens[CONTRACTS.KOI.toLowerCase()]

    if (eth)
      return eth.balance

    return '0'
  }

  const getWETHBalance = () => {
    let eth = store.getState().tokens[CONTRACTS.weth] || store.getState().tokens[CONTRACTS.weth.toLowerCase()]

    if (eth)
      return eth.balance

    return '0'
  }

  const destroyCurrentTrade = () => {
    if (cachedPair)
      cachedPair.destroy()

    dispatch(walletSlice.actions.updateCachedPair(null))
    setCachedPair(null)
  }

  const getDeadline = async () => {
    var latest_block = await provider!.getBlock('latest')
    return latest_block!
  }

  const estimateGasRefund = (gasFee, gasLimit) => {
    let gFee = new BigNumber(gasFee).div(Math.pow(10, 9))
    let gLimit = new BigNumber(gasLimit).div(Math.pow(10, 9))
    let ePrice = store.getState().ethPrice
    let cost = gFee.times(gLimit).times(ePrice)
    dispatch(walletSlice.actions.updateGasEstimate({ total: cost.toFixed(2), refund: cost.times(0.7).toFixed(2), actual: cost.times(0.3).toFixed(2) }))

    return { total: cost.toFixed(2), refund: cost.times(0.7).toFixed(2), actual: cost.times(0.3).toFixed(2) }
  }

  const executeTrade = async (pair1, pair2, inAm, outAm, path, allowance, direction = 1, stable = [], expectedAmount) => {
    try {
      direction = 1
      //slippage could be 100%
      if (inAm == '' || new BigNumber(inAm).eq(0) || outAm == '' /*|| new BigNumber(outAm).eq(0)*/)
        throw 'e'

      await chainSafetyCheck()

      // in any event we receive an execution that is not equal, make sure we reset the minOutAmount
      if (new BigNumber(store.getState().cachedPair.baseConvertRequest).eq(inAm) && !new BigNumber(store.getState().cachedPair.minAmountConvertQuote).eq(outAm)) {
        if (new BigNumber(expectedAmount).lt(store.getState().cachedPair.minAmountConvertQuote))
          throw "Large price change. Increase slippage or try again"
      } else if (!new BigNumber(store.getState().cachedPair.baseConvertRequest).eq(inAm)) {
        throw 'Invalid input amount ' + String(inAm) + ' ' + String(store.getState().cachedPair.baseConvertRequest)
      }

      var zksigner = await getZkSigner()// provider!.getSigner();
      const to = ethers.getAddress(getSignerAddress() as string);
      var path_weth: any = [ethers.getAddress(pair1.address), ethers.getAddress(pair2.address)];

      if (path_weth[0].toLowerCase() == CONTRACTS.eth.toLowerCase())
        path_weth[0] = CONTRACTS.weth

      if (path_weth[1].toLowerCase() == CONTRACTS.eth.toLowerCase())
        path_weth[1] = CONTRACTS.weth

      var inAmount = new BigNumber(inAm).times(Math.pow(10, pair1.decimals)).toFixed()
      var outAmount = new BigNumber(outAm).times(Math.pow(10, pair2.decimals)).toFixed()

      var deadline = (await getDeadline()).timestamp + (60 * 60)
      var paymasterParams = await getPaymasterParams()
      var additionalParams = paymasterParams.customData ? Utils.deepClone(paymasterParams) :  { gasLimit: paymasterParams.gasLimit, customData: null }

      var receipts
      var startTime = Date.now()

      if (path_weth[1] == CONTRACTS.weth && path_weth[0] == CONTRACTS.weth) {

        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"))

        var wethContract = new Contract(CONTRACTS.weth, WETH, zksigner!);

        if (ethers.getAddress(pair1.address).toLowerCase() == CONTRACTS.eth.toLowerCase()){
          try{
            let gas_es = await wethContract.deposit.estimateGas({ ...additionalParams, gasLimit: null, value: inAmount, })
            additionalParams.gasLimit = BigInt(gas_es.toString())
            paymasterParams.gasLimit = BigInt(gas_es.toString())
          } catch(e) {
            console.log(e)
          }

          estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())
          
          receipts = await wethContract.deposit({ ...additionalParams, value: inAmount })
        } else {
          try{
            let gas_es = await wethContract.withdraw.estimateGas(inAmount, { ...additionalParams, gasLimit: null, })
            additionalParams.gasLimit = BigInt(gas_es.toString())
            paymasterParams.gasLimit = BigInt(gas_es.toString())
          } catch(e) {
            console.log(e)
          }

          estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())
          
          receipts = await wethContract.withdraw(inAmount, {...additionalParams})
        }
        
        startTime = Date.now()
      } else if (path_weth[0].toLowerCase() == CONTRACTS.MUTE.toLowerCase() && path_weth[1].toLowerCase() == CONTRACTS.KOI.toLowerCase()) {
        // check for mute to koi swap
        if (new BigNumber(allowance).lt(inAmount)) {
          dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve token for spending"))
          //multi_calls.push(await approveTokenAmount(pair1.address, CONTRACTS.router))
          await approveTokenAmount(pair1.address, CONTRACTS.KOI_CONVERSION)
        }

        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"))

        var conversion = new Contract(CONTRACTS.KOI_CONVERSION, KOI_CONVERSION, zksigner!);
  
        const gasLimit = await conversion.convert.estimateGas(inAmount, {...additionalParams, gasLimit: null});
        
        additionalParams.gasLimit = BigInt(gasLimit.toString())
        paymasterParams.gasLimit = BigInt(gasLimit.toString())
  
        estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())
  
        receipts = await conversion.convert(inAmount, {...additionalParams});
        
        startTime = Date.now()

      } else {

        var routerContract = new Contract(CONTRACTS.router, ROUTER, zksigner!);

        const multi_calls: any = []

        if (ethers.getAddress(pair1.address) != CONTRACTS.eth && new BigNumber(allowance).lt(inAmount)) {
          dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve token for spending"))
          //multi_calls.push(await approveTokenAmount(pair1.address, CONTRACTS.router))
          await approveTokenAmount(pair1.address, CONTRACTS.router)
        }

        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"))

        if (pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
          if (direction == 1) {
            try {
            let gas_es = await routerContract.swapExactETHForTokens.estimateGas(
                outAmount, path, to, deadline, stable,
                {
                  ...additionalParams,
                  gasLimit: null,
                  value: inAmount,
                }
              )

            additionalParams.gasLimit = BigInt(gas_es.toString())
            paymasterParams.gasLimit = BigInt(gas_es.toString())
            } catch(e) {
              console.log(e)
            }

            
            estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())
            receipts = await routerContract.swapExactETHForTokens(
              outAmount, path, to, deadline, stable,
              {
                ...additionalParams,
                value: inAmount
              }
            )

            startTime = Date.now()

            //receipts = await multicall(zksigner!, multi_calls);

          }
        } else if (pair2.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
          if (direction == 1) {
            
            try {
                let gas_es = await routerContract.swapExactTokensForETH.estimateGas(
                  inAmount, outAmount, path, to, deadline, stable,
                  {
                    ...additionalParams,
                    gasLimit: null,
                  }
                )

                additionalParams.gasLimit = BigInt(gas_es.toString())
                paymasterParams.gasLimit = BigInt(gas_es.toString())
              
            } catch(e) {console.log(e)}
            


            estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())
            receipts = await routerContract.swapExactTokensForETH(
              inAmount, outAmount, path, to, deadline, stable,
              {
                ...additionalParams,
              }
            )
            startTime = Date.now()

          }
        } else {
          if (direction == 1) {
            try {
                let gas_es = await routerContract.swapExactTokensForTokens.estimateGas(
                  inAmount, outAmount, path, to, deadline, stable,
                  {
                    ...additionalParams,
                    gasLimit: null
                  }
                )

                additionalParams.gasLimit = BigInt(gas_es.toString())
                paymasterParams.gasLimit = BigInt(gas_es.toString())
            } catch (e) {  }

            estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())
            receipts = await routerContract.swapExactTokensForTokens(
              inAmount, outAmount, path, to, deadline, stable,
              {
                ...additionalParams,
              }
            )
            startTime = Date.now()
            //receipts = await multicall(zksigner!, multi_calls)
          }
        }
      }

      var receipt =  await receipts.wait()

      let txResults = getTransactionResults(paymasterParams, receipt, startTime)

      addTokenToStorage(pair1)
      addTokenToStorage(pair2)

      await onGetBalance()

      return { success: true, error: '', ...txResults }

    } catch (e) {
      console.log(e)
      let txResults = getTransactionResults()

      return { success: false, error: JSON.stringify(e), ...txResults }
    }
  }

  const searchForLiquidity = async (pair1, pair2, index = 0, stable = false) => {
    var multiResTokens: ContractCallResults

    try {
      var pair1Address = pair1.address
      var pair2Address = pair2.address
      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });

      if (pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase())
        pair1Address = CONTRACTS.weth

      if (pair2.address.toLowerCase() == CONTRACTS.eth.toLowerCase())
        pair2Address = CONTRACTS.weth


      const to = ethers.getAddress(getSignerAddress() as string);

      const contractCallContextTokens: ContractCallContext[] = [
        {
          reference: 'approveA',
          contractAddress: pair1Address,
          abi: ERC20,
          calls: [{ reference: 'approveA', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] }]
        },
        {
          reference: 'approveB',
          contractAddress: pair2Address,
          abi: ERC20,
          calls: [{ reference: 'approveB', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] }]
        },
      ]

      multiResTokens = await multicall.call(contractCallContextTokens);

      const pair = await getTokenPair(pair1Address, pair2Address, stable);

      if (pair == '0x0000000000000000000000000000000000000000')
        throw '0 pair address'

      const contractCallContext: ContractCallContext[] = [
        {
          reference: 'balance',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'balance', methodName: 'balanceOf', methodParameters: [to] }]
        },
        {
          reference: 'reserves',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'reserves', methodName: 'getReserves', methodParameters: [] }]
        },
        {
          reference: 'totalSupply',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'totalSupply', methodName: 'totalSupply', methodParameters: [] }]
        },
        {
          reference: 'token0',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'token0', methodName: 'token0', methodParameters: [] }]
        },
        {
          reference: 'symbol',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'symbol', methodName: 'symbol', methodParameters: [] }]
        },
        {
          reference: 'token1',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'token1', methodName: 'token1', methodParameters: [] }]
        },
        {
          reference: 'pairFee',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'pairFee', methodName: 'pairFee', methodParameters: [] }]
        },
        {
          reference: 'delegates',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'delegates', methodName: 'delegates', methodParameters: [to] }]
        },
        {
          reference: 'approveA',
          contractAddress: pair1Address,
          abi: ERC20,
          calls: [{ reference: 'approveA', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] }]
        },
        {
          reference: 'approveB',
          contractAddress: pair2Address,
          abi: ERC20,
          calls: [{ reference: 'approveB', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] }]
        },
        {
          reference: 'approveLP',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'approveLP', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] }]
        },

        // calculate fees
        {
          reference: 'supplyIndex0',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'supplyIndex0', methodName: 'supplyIndex0', methodParameters: [to] }]
        },
        {
          reference: 'supplyIndex1',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'supplyIndex1', methodName: 'supplyIndex1', methodParameters: [to] }]
        },
        {
          reference: 'claimable0',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'claimable0', methodName: 'claimable0', methodParameters: [to] }]
        },
        {
          reference: 'claimable1',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'claimable1', methodName: 'claimable1', methodParameters: [to] }]
        },
        {
          reference: 'index0',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'index0', methodName: 'index0', methodParameters: [] }]
        },
        {
          reference: 'index1',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'index1', methodName: 'index1', methodParameters: [] }]
        },
        {
          reference: 'stable',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'stable', methodName: 'stable', methodParameters: [] }]
        },
      ]


      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      var bal = multiRes.results['balance'].callsReturnContext[0].returnValues[0]
      var reserves = [multiRes.results['reserves'].callsReturnContext[0].returnValues[0], multiRes.results['reserves'].callsReturnContext[0].returnValues[1]]
      var supply = multiRes.results['totalSupply'].callsReturnContext[0].returnValues[0]
      const token0 = multiRes.results['token0'].callsReturnContext[0].returnValues[0].toLowerCase()
      const token1 = multiRes.results['token1'].callsReturnContext[0].returnValues[0].toLowerCase()

      var reserveANormalized = pair1Address.toLowerCase() == token0 ? new BigNumber(reserves[0]).div(Math.pow(10, pair1.decimals)) : new BigNumber(reserves[1]).div(Math.pow(10, pair1.decimals));
      var reserveBNormalized = pair1Address.toLowerCase() == token0 ? new BigNumber(reserves[1]).div(Math.pow(10, pair2.decimals)) : new BigNumber(reserves[0]).div(Math.pow(10, pair2.decimals));
      var pairFeePercent = new BigNumber(multiRes.results['pairFee'].callsReturnContext[0].returnValues[0]).div(100).toFixed(2)

      var lp_price = new BigNumber(0)

      if (!new BigNumber(pair1.price).eq(0)) {
        let res = reserveANormalized
        lp_price = new BigNumber(new BigNumber(res).times(pair1.price).times(2).div(new BigNumber(supply).div(Math.pow(10, 18))))
      } else if (!new BigNumber(pair2.price).eq(0)) {
        let res = reserveBNormalized
        lp_price = new BigNumber(new BigNumber(res).times(pair2.price).times(2).div(new BigNumber(supply).div(Math.pow(10, 18))))
      }

      var claimable0 = new BigNumber(multiRes.results['claimable0'].callsReturnContext[0].returnValues[0])
      var claimable1 = new BigNumber(multiRes.results['claimable1'].callsReturnContext[0].returnValues[0])
      console.log(claimable0.toString())
      console.log(claimable1.toString())
      var claimable0_f = claimable0.div(Math.pow(10, (pair1Address.toLowerCase() == token0 ? pair1.decimals : pair2.decimals))).toFixed()
      var claimable1_f = claimable1.div(Math.pow(10, (pair1Address.toLowerCase() == token0 ? pair2.decimals : pair1.decimals))).toFixed()
      var delta0 = new BigNumber(multiRes.results['index0'].callsReturnContext[0].returnValues[0]).minus(multiRes.results['supplyIndex0'].callsReturnContext[0].returnValues[0])
      var delta1 = new BigNumber(multiRes.results['index1'].callsReturnContext[0].returnValues[0]).minus(multiRes.results['supplyIndex1'].callsReturnContext[0].returnValues[0])

      console.log(delta0.toString())
      console.log(delta1.toString())

      if (delta0.gt(0)) {
        claimable0 = claimable0.plus(delta0.times(bal).div(Math.pow(10, 18)))
        claimable0_f = claimable0.div(Math.pow(10, (pair1Address.toLowerCase() == token0 ? pair1.decimals : pair2.decimals))).toFixed()
      }

      if (delta1.gt(0)) {
        claimable1 = claimable1.plus(delta1.times(bal).div(Math.pow(10, 18)))
        claimable1_f = claimable1.div(Math.pow(10, (pair1Address.toLowerCase() == token0 ? pair2.decimals : pair1.decimals))).toFixed()
      }

      return {
        hasLiq: (new BigNumber(bal).gt(0)),
        available: true,
        assetA: pair1,
        assetB: pair2,
        assetAAmount: new BigNumber(bal).div(supply).times(reserveANormalized).toFixed(),
        assetBAmount: new BigNumber(bal).div(supply).times(reserveBNormalized).toFixed(),
        assetATotal: reserveANormalized.toFixed(),
        assetBTotal: reserveBNormalized.toFixed(),
        address: pair,
        lpPrice: lp_price.toFixed(),
        claimable0: pair1Address.toLowerCase() == token0 ? claimable0_f : claimable1_f,
        claimable1: pair1Address.toLowerCase() == token0 ? claimable1_f : claimable0_f,
        stable: multiRes.results['stable'].callsReturnContext[0].returnValues[0],
        pairFeePercent,
        symbol: multiRes.results['symbol'].callsReturnContext[0].returnValues[0],
        approveAssetA: (pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) ? false : new BigNumber(multiResTokens.results['approveA'].callsReturnContext[0].returnValues[0]).lte(min_approve_amount),
        approveAssetB: (pair2.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) ? false : new BigNumber(multiResTokens.results['approveB'].callsReturnContext[0].returnValues[0]).lte(min_approve_amount),
        approveAssetAAmount: new BigNumber(multiResTokens.results['approveA'].callsReturnContext[0].returnValues[0]).div(Math.pow(10, (pair1Address.toLowerCase() == token0 ? pair1.decimals : pair2.decimals))).toFixed(),
        approveAssetBAmount: new BigNumber(multiResTokens.results['approveB'].callsReturnContext[0].returnValues[0]).div(Math.pow(10, (pair1Address.toLowerCase() == token0 ? pair2.decimals : pair1.decimals))).toFixed(),
        approveAssetLP: new BigNumber(multiRes.results['approveLP'].callsReturnContext[0].returnValues[0]).lte(0),
        approveLPValue: new BigNumber(multiRes.results['approveLP'].callsReturnContext[0].returnValues[0]).div(Math.pow(10, 18)).toFixed(),
        delegate: multiRes.results['delegates'].callsReturnContext[0].returnValues[0],
        balance: new BigNumber(bal).div(Math.pow(10, 18)).toFixed(),
        share: new BigNumber(bal).div(supply).times(100).toFixed(2),
        supply: new BigNumber(supply).div(Math.pow(10, 18)).toFixed(),
        index: index
      }

    } catch (e) {
      console.log(e)
      return {
        hasLiq: false,
        available: false,
        assetA: pair1,
        assetB: pair2,
        assetAAmount: '0.00',
        assetBAmount: '0.00',
        assetATotal: '0.00',
        assetBTotal: '0.00',
        claimable0: '0.00',
        claimable1: '0.00',
        stable: false,
        lpPrice: '0',
        address: '0x000000000000000000000000000000000',
        pairFeePercent: '0.30',
        symbol: '',
        approveAssetA: (pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) ? false : new BigNumber(multiResTokens!.results['approveA'].callsReturnContext[0].returnValues[0]).lte(min_approve_amount),
        approveAssetB: (pair2.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) ? false : new BigNumber(multiResTokens!.results['approveB'].callsReturnContext[0].returnValues[0]).lte(min_approve_amount),
        approveLPValue: '0',
        delegate: '0x000000000000000000000000000000000',
        balance: '0.00',
        share: '0.00',
        supply: '0.00',
        index: index
      }
    }
  }

  const searchForLiquidityWithAddress = async (pair, index = 0, stable = false) => {
    try {
      if (pair == '0x000000000000000000000000000000000')
        throw '0 pair address'

      const to = ethers.getAddress(getSignerAddress() as string);

      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });
      const contractCallContext: ContractCallContext[] = [
        {
          reference: 'balance',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'balance', methodName: 'balanceOf', methodParameters: [to] }]
        },
        {
          reference: 'reserves',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'reserves', methodName: 'getReserves', methodParameters: [] }]
        },
        {
          reference: 'totalSupply',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'totalSupply', methodName: 'totalSupply', methodParameters: [] }]
        },
        {
          reference: 'token0',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'token0', methodName: 'token0', methodParameters: [] }]
        },
        {
          reference: 'symbol',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'symbol', methodName: 'symbol', methodParameters: [] }]
        },
        {
          reference: 'token1',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'token1', methodName: 'token1', methodParameters: [] }]
        },
        {
          reference: 'pairFee',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'pairFee', methodName: 'pairFee', methodParameters: [] }]
        },
        {
          reference: 'delegates',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'delegates', methodName: 'delegates', methodParameters: [to] }]
        },
        {
          reference: 'approveLP',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'approveLP', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] }]
        },
        {
          reference: 'stable',
          contractAddress: pair,
          abi: PAIR,
          calls: [{ reference: 'stable', methodName: 'stable', methodParameters: [] }]
        }
      ]

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);

      var bal = multiRes.results['balance'].callsReturnContext[0].returnValues[0]
      var reserves = [multiRes.results['reserves'].callsReturnContext[0].returnValues[0], multiRes.results['reserves'].callsReturnContext[0].returnValues[1]]
      var supply = multiRes.results['totalSupply'].callsReturnContext[0].returnValues[0]
      var token0 = multiRes.results['token0'].callsReturnContext[0].returnValues[0].toLowerCase()
      var token1 = multiRes.results['token1'].callsReturnContext[0].returnValues[0].toLowerCase()

      const pair1 = getTokenFromContract(token0);
      const pair2 = getTokenFromContract(token1);

      //@ts-ignore
      var reserveANormalized = new BigNumber(reserves[0]).div(Math.pow(10, pair1!.decimals))
      //@ts-ignore
      var reserveBNormalized = new BigNumber(reserves[1]).div(Math.pow(10, pair2!.decimals))
      var pairFeePercent = new BigNumber(multiRes.results['pairFee'].callsReturnContext[0].returnValues[0]).div(100).toFixed(2)

      const contractCallContextTokens: ContractCallContext[] = [
        {
          reference: 'approveA',
          contractAddress: token0,
          abi: ERC20,
          calls: [{ reference: 'approveA', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] }]
        },
        {
          reference: 'approveB',
          contractAddress: token1,
          abi: ERC20,
          calls: [{ reference: 'approveB', methodName: 'allowance', methodParameters: [to, CONTRACTS.router] }]
        },
      ]
      const multiResTokens: ContractCallResults = await multicall.call(contractCallContextTokens);

      return {
        hasLiq: (new BigNumber(bal).gt(0)),
        available: true,
        assetA: pair1,
        assetB: pair2,
        assetAAmount: new BigNumber(bal).div(supply).times(reserveANormalized).toFixed(),
        assetBAmount: new BigNumber(bal).div(supply).times(reserveBNormalized).toFixed(),
        assetATotal: reserveANormalized.toFixed(),
        assetBTotal: reserveBNormalized.toFixed(),
        address: pair,
        pairFeePercent,
        symbol: multiRes.results['symbol'].callsReturnContext[0].returnValues[0],
        //@ts-ignore
        approveAssetA: (pair1!.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) ? false : new BigNumber(multiResTokens.results['approveA'].callsReturnContext[0].returnValues[0]).lte(min_approve_amount),
        //@ts-ignore
        approveAssetB: (pair2!.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) ? false : new BigNumber(multiResTokens.results['approveB'].callsReturnContext[0].returnValues[0]).lte(min_approve_amount),
        approveAssetLP: new BigNumber(multiRes.results['approveLP'].callsReturnContext[0].returnValues[0]).lte(min_approve_amount),
        delegate: multiRes.results['delegates'].callsReturnContext[0].returnValues[0],
        balance: new BigNumber(bal).div(Math.pow(10, 18)).toFixed(),
        share: new BigNumber(bal).div(supply).times(100).toFixed(2),
        index: index,
        stable: multiRes.results['stable'].callsReturnContext[0].returnValues[0]
      }

    } catch (e) {
      return {
        hasLiq: false,
        available: false,
        assetA: {},
        assetB: {},
        assetAAmount: '0.00',
        assetBAmount: '0.00',
        assetATotal: '0.00',
        assetBTotal: '0.00',
        address: pair,
        pairFeePercent: stable ? '0.05' : '0.30',
        symbol: '',
        approveAssetA: true,
        approveAssetB: true,
        delegate: '0x000000000000000000000000000000000',
        balance: '0.00',
        share: '0.00',
        index: index,
        stable: false
      }
    }
  }

  const getLiquidityInfo = async (pair1, pair2, index) => {
    var res = await searchForLiquidity(pair1, pair2, index)
    return res
  }

  const getLiquidityInfoPair = async (pair, index) => {
    var res = await searchForLiquidityWithAddress(pair, index)
    return res
  }

  const approveTokenAmount = async (token, address) => {
    const zksigner = await getZkSigner();
    var contract = new Contract(token, ERC20, zksigner!);
    
    dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve token for spend"))

    var txParams = await getPaymasterParams()

    try{
      var gasLimitEstimate = await contract.approve.estimateGas(address, approve_amount, txParams)
      txParams.gasLimit = BigInt(gasLimitEstimate.toString())
    } catch (e){
      console.log('error')
      console.log(e)
    }

    const txHandle = await contract.approve(address, approve_amount, txParams);
    await txHandle.wait();
  }

  const approveTokenAmountBase = async (token, address) => {
    const zksigner = await getZkSigner();

    var contract = new Contract(token, ERC20, zksigner!);
    dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve fee token for spend"))

    const gasLimitEstimate = await contract.approve.estimateGas(address, approve_amount)

    var txParams = {
      gasLimit: BigInt(gasLimitEstimate.toString()),
    };

    const txHandle = await contract.approve(address, approve_amount, txParams);
    await txHandle.wait();

  }

  const approveTokenAmountRaw = async (token, address) => {
    const zksigner = await getZkSigner();

    var contract = new Contract(token, ERC20, zksigner!);
    dispatch(walletSlice.actions.updaetTransactionModalStatus("Approve token for spend"))

    var txParams = await getPaymasterParams()

    try{
      var gasLimitEstimate = await contract.approve.estimateGas(address, approve_amount, txParams)
      txParams.gasLimit = BigInt(gasLimitEstimate.toString())
    } catch (e){
      console.log('error')
      console.log(e)
    }

    return await contract.approve.populateTransaction(address, approve_amount, txParams);
  }

  const checkAllowance = async (token, address, amount = min_approve_amount.toFixed()) => {
    if (ethers.getAddress(token) == CONTRACTS.eth)
      return true

    const web3contract = new Contract(token, ERC20, provider);

    var res = await web3contract.allowance(getSignerAddress(), address)
    return new BigNumber(res.toString()).gte(amount)
  }

  const getAllowance = async (token, address) => {
    if (ethers.getAddress(token) == CONTRACTS.eth)
      return new BigNumber(approve_amount).toFixed()

    const web3contract = new Contract(token, ERC20, provider);

    var res = await web3contract.allowance(getSignerAddress(), address)
    return new BigNumber(res.toString()).toFixed()
  }

  const calculateLiquidityPool = async (pair1, pair2, amountA, amountB, stable = false) => {
    try {

      var pair1Address = pair1.address
      var pair2Address = pair2.address

      if (pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase())
        pair1Address = CONTRACTS.weth

      if (pair2.address.toLowerCase() == CONTRACTS.eth.toLowerCase())
        pair2Address = CONTRACTS.weth


      const pair = await getTokenPair(pair1Address, pair2Address, stable);

      if (pair == '0x000000000000000000000000000000000')
        throw '0 pair address'

      const switchPair = new Contract(pair, PAIR, provider);


      const token0 = await switchPair.token0()
      const token1 = await switchPair.token1()

      var amountAFinal = amountA;
      var amountBFinal = amountB;

      const router = new Contract(CONTRACTS.router, ROUTER, provider);
      const to = ethers.getAddress(getSignerAddress() as string);

      var deadline = (await getDeadline()).timestamp + (60 * 60)

      var reserves = await switchPair.getReserves()
      var reserveNew = [pair1Address.toLowerCase() == token0.toLowerCase() ? (reserves[0]) : (reserves[1])]
      reserveNew.push(pair1Address.toLowerCase() == token0.toLowerCase() ? (reserves[1]) : (reserves[0]))
      reserves = reserveNew

      var supply = await switchPair.totalSupply()

      if (amountA != null) {
        amountA = new BigNumber(amountA).times(Math.pow(10, pair1.decimals))
        amountB = new BigNumber(amountA).times(reserves[1]).div(reserves[0])
      } else if (amountB != null) {
        amountB = new BigNumber(amountB).times(Math.pow(10, pair2.decimals))
        amountA = new BigNumber(amountB).times(reserves[0]).div(reserves[1])
      }

      var share = new BigNumber(amountA).div(amountA.plus(reserves[0])).times(100)

      return {
        pair1: pair1,
        pair2: pair2,
        amountA: new BigNumber(amountA).div(Math.pow(10, pair1.decimals)).toFixed(),
        amountB: new BigNumber(amountB).div(Math.pow(10, pair2.decimals)).toFixed(),
        liquidity: share.times(supply).div(Math.pow(10, 18)).toFixed(),
        share: share.toFixed(),
        success: true
      }
    } catch (e) {
      return { pair1: null, pair2: null, amountA: null, amountB: null, share: 0, success: false }
    }
  }

  const createLiquidityPool = async (pair1, pair2, amountA, amountB, _fee = 30, stable = false, slippage = 0.5, pair = null) => {
    await chainSafetyCheck()

    if (pair1.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
      return createLiquidityPoolETH(pair2, amountB, amountA, pair1, _fee, stable, slippage)
    } else if (pair2.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
      return createLiquidityPoolETH(pair1, amountA, amountB, pair2, _fee, stable, slippage)
    }

    const token0 = pair1.address < pair2.address ? pair1.address : pair2.address;
    const token1 = pair1.address < pair2.address ? pair2.address : pair1.address;

    const pair1Final = pair1.address < pair2.address ? pair1 : pair2;
    const pair2Final = pair1.address < pair2.address ? pair2 : pair1;

    var amountAFinal = pair1.address < pair2.address ? amountA : amountB;
    var amountBFinal = pair1.address < pair2.address ? amountB : amountA;

    amountAFinal = new BigNumber(amountAFinal).times(Math.pow(10, pair1Final.decimals)).toFixed(0)
    amountBFinal = new BigNumber(amountBFinal).times(Math.pow(10, pair2Final.decimals)).toFixed(0)

    var paymasterParams = await getPaymasterParams()
    var additionalParams = paymasterParams.customData ? Utils.deepClone(paymasterParams) :  { gasLimit: paymasterParams.gasLimit, customData: null }

    try {

      const zksigner = await getZkSigner();
      const to = ethers.getAddress(getSignerAddress() as string);
      var deadline = (await getDeadline()).timestamp + (60 * 60)

      if ((await checkAllowance(token0, CONTRACTS.router, amountAFinal)) == false)
        await approveTokenAmount(token0, CONTRACTS.router)

      if ((await checkAllowance(token1, CONTRACTS.router, amountBFinal)) == false)
        await approveTokenAmount(token1, CONTRACTS.router)

      var routerNew = new Contract(CONTRACTS.router, ROUTER, zksigner!);

      const gasLimitEstimate = await routerNew.addLiquidity.estimateGas(
        token0,
        token1,
        amountAFinal,
        amountBFinal,
        new BigNumber(100).minus(slippage).times(amountAFinal).div(100).toFixed(0),
        new BigNumber(100).minus(slippage).times(amountBFinal).div(100).toFixed(0),
        to,
        deadline,
        _fee,
        stable,
        {
          ...additionalParams,
          gasLimit: null
        }
      );

      additionalParams.gasLimit = BigInt(gasLimitEstimate.toString())
      paymasterParams.gasLimit = BigInt(gasLimitEstimate.toString())

      estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())
      
      const txHandle = await routerNew.addLiquidity(
        token0,
        token1,
        amountAFinal,
        amountBFinal,
        new BigNumber(100).minus(slippage).times(amountAFinal).div(100).toFixed(0),
        new BigNumber(100).minus(slippage).times(amountBFinal).div(100).toFixed(0),
        to,
        deadline,
        _fee,
        stable,
        {
          ...additionalParams,
        }
      );

      var startTime = Date.now()
      var receipt = await txHandle.wait()
      let txResults = getTransactionResults(paymasterParams, receipt, startTime)

      if (pair)
        addLiquidityToStorage(pair)

      var res = {
        amountA: 1,
        amountB: 1,
        liquidity: 5,
        share: 100
      }

      return {
        amountA: new BigNumber(amountAFinal).div(Math.pow(10, 18)).toFixed(),
        amountB: new BigNumber(amountBFinal).div(Math.pow(10, 18)).toFixed(),
        liquidity: new BigNumber(res.liquidity).div(Math.pow(10, 18)).toFixed(),
        share: res.share,
        success: true,
        ...txResults,
        error: ''
      }

    } catch (e) {
      console.log(e)
      let txResults = getTransactionResults()

      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        error: JSON.stringify(e),
        share: null,
        success: false,
        ...txResults
      }
    }

  }

  const createLiquidityPoolETH = async (pair1, amount1, ethAmount, ethPair, _fee = 30, stable = false, slippage, pair = null) => {
    var amountAFinal = new BigNumber(amount1).times(Math.pow(10, pair1.decimals)).toFixed(0)
    var amountETH = new BigNumber(ethAmount).times(Math.pow(10, 18)).toFixed(0)

    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      var deadline = (await getDeadline()).timestamp + (60 * 60)

      if ((await checkAllowance(pair1.address, CONTRACTS.router, amountAFinal)) == false)
        await approveTokenAmount(pair1.address, CONTRACTS.router)

      const zksigner = await getZkSigner();

      var routerNew = new Contract(CONTRACTS.router, ROUTER, zksigner!);

      var paymasterParams = await getPaymasterParams()
      var additionalParams = paymasterParams.customData ? Utils.deepClone(paymasterParams) :  { gasLimit: paymasterParams.gasLimit, customData: null }
  
      const gasLimitEstimate = await routerNew.addLiquidityETH.estimateGas(
        pair1.address,
        amountAFinal,
        new BigNumber(100).minus(slippage).times(amountAFinal).div(100).toFixed(0),
        new BigNumber(100).minus(slippage).times(amountETH).div(100).toFixed(0),
        to,
        deadline,
        _fee,
        stable,
        {
          value: amountETH,
          ...additionalParams,
          gasLimit: null
        }
      );

      additionalParams.gasLimit = BigInt(gasLimitEstimate.toString())
      paymasterParams.gasLimit = BigInt(gasLimitEstimate.toString())

      estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())

      const txHandle = await routerNew.addLiquidityETH(
        pair1.address,
        amountAFinal,
        new BigNumber(100).minus(slippage).times(amountAFinal).div(100).toFixed(0),
        new BigNumber(100).minus(slippage).times(amountETH).div(100).toFixed(0),
        to,
        deadline,
        _fee,
        stable,
        {
          value: amountETH,
          ...additionalParams
        }
      );

      var startTime = Date.now()
      var receipt = await txHandle.wait()
      let txResults = getTransactionResults(paymasterParams, receipt, startTime)


      const pair1Final = pair1.address < CONTRACTS.weth ? pair1.address : CONTRACTS.weth;
      const pair2Final = pair1.address < CONTRACTS.weth ? CONTRACTS.weth : pair1.address;

      if (pair)
        addLiquidityToStorage(pair)

      var res = {
        amountA: 1,
        amountB: 1,
        liquidity: 5,
        share: 100
      }


      return {
        amountA: new BigNumber(amount1).toFixed(),
        amountB: new BigNumber(ethAmount).toFixed(),
        liquidity: new BigNumber(res.liquidity).div(Math.pow(10, 18)).toFixed(),
        share: res.share,
        success: true,
        error: '',
        ...txResults
      }

    } catch (e) {
      console.log(e)
      let txResults = getTransactionResults()
      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults
      }
    }

  }

  const removeLiquidityPermit = async (pair1, pair2, pairAddress = null, stable = false) => {
    if (pairAddress == null) {
      if (pair1.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
        pairAddress = await getTokenPair(CONTRACTS.weth, pair2, stable)
      } else if (pair2.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
        pairAddress = await getTokenPair(pair1, CONTRACTS.weth, stable)
      } else {
        pairAddress = await getTokenPair(pair1, pair2, stable)
      }
    }

    const zksigner = await getZkSigner();

    if ((await checkAllowance(pairAddress!, CONTRACTS.router)) == false) {
      var contract = new Contract(pairAddress!, PAIR, zksigner!);
      const txHandle = await contract.approve(CONTRACTS.router, approve_amount,
        {
        }
      );

      await txHandle.wait();
    }

    return true

  };

  const removeLiquidityWithPermit = async (assetA, assetB, lpAmount, sig, stable, minAmountA, minAmountB) => {
    try {
      await chainSafetyCheck()
      const to = ethers.getAddress(getSignerAddress() as string);
      const zksigner = await getZkSigner();

      var deadline = (await getDeadline()).timestamp + (60 * 60)

      var routerNew = new Contract(CONTRACTS.router, ROUTER, zksigner!);

      var txHandle

      var paymasterParams = await getPaymasterParams()
      var additionalParams = paymasterParams.customData ? Utils.deepClone(paymasterParams) :  { gasLimit: paymasterParams.gasLimit, customData: null }
  

      if (assetA.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) {

        const gasLimitEstimate = await routerNew.removeLiquidityETH.estimateGas(
          assetB.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, assetB.decimals)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, 18)).toFixed(0),
          to,
          deadline,
          stable,
          {
            ...additionalParams,
            gasLimit: null
          }
        );


        additionalParams.gasLimit = BigInt(gasLimitEstimate.toString())
        paymasterParams.gasLimit = BigInt(gasLimitEstimate.toString())

        estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())

        txHandle = await routerNew.removeLiquidityETH(
          assetB.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, assetB.decimals)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, 18)).toFixed(0),
          to,
          deadline,
          stable,
          {
            ...additionalParams
          }
        );

      } else if (assetB.address.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
        const gasLimitEstimate = await routerNew.removeLiquidityETH.estimateGas(
          assetA.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, assetA.decimals)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, 18)).toFixed(0),
          to,
          deadline,
          stable,
          {
            ...additionalParams,
            gasLimit: null
          }
        );

        additionalParams.gasLimit = BigInt(gasLimitEstimate.toString())
        paymasterParams.gasLimit = BigInt(gasLimitEstimate.toString())

        estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())

        txHandle = await routerNew.removeLiquidityETH(
          assetA.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, assetA.decimals)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, 18)).toFixed(0),
          to,
          deadline,
          stable,
          {
            ...additionalParams
          }
        );
      } else {
        const gasLimitEstimate = await routerNew.removeLiquidity.estimateGas(
          assetA.address,
          assetB.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, assetA.decimals)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, assetB.decimals)).toFixed(0),
          to,
          deadline,
          stable,
          {
            ...additionalParams,
            gasLimit: null
          }
        );

        additionalParams.gasLimit = BigInt(gasLimitEstimate.toString())
        paymasterParams.gasLimit = BigInt(gasLimitEstimate.toString())

        estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())

        txHandle = await routerNew.removeLiquidity(
          assetA.address,
          assetB.address,
          new BigNumber(lpAmount).times(Math.pow(10, 18)).toFixed(0),
          new BigNumber(minAmountA).times(Math.pow(10, assetA.decimals)).toFixed(0),
          new BigNumber(minAmountB).times(Math.pow(10, assetB.decimals)).toFixed(0),
          to,
          deadline,
          stable,
          {
            ...additionalParams
          }
        );
      }

      var startTime = Date.now()
      var receipt = await txHandle.wait()
      let txResults = getTransactionResults(paymasterParams, receipt, startTime)

      return {
        amountA: minAmountA,
        amountB: minAmountB,
        liquidity: new BigNumber(0).toFixed(),
        share: 100,
        success: true,
        error: '',
        ...txResults
      }

    } catch (e) {
      console.log(e)
      let txResults = getTransactionResults()

      return {
        amountA: null,
        amountB: null,
        liquidity: null,
        share: null,
        success: false,
        error: JSON.stringify(e),
        ...txResults
      }
    }

  }

  const getHasEnoughTokensForGas = () => {
    let fee = getFeeAsset()
    const reqAmount = new BigNumber(1).div(fee.price).toFixed()

    if (new BigNumber(fee.balance).gte(reqAmount))
      return true

    return false
  }



  const getPaymasterParams = async () => {
    let fee = getFeeAsset()

    var _provider = new Provider(chain!.rpcUrls.default.http[0]);

    const paymaster = CONTRACTS.paymaster_v2
    const gasPrice = await _provider.getGasPrice();

    if (fee.address == CONTRACTS.eth)
      return {
        gasPrice: gasPrice,
        gasLimit: BigInt(2500000),
      };

    const paymasterParams = utils.getPaymasterParams(paymaster, {
      type: 'ApprovalBased',
      token: fee.address,
      minimalAllowance: BigInt(approve_amount),
      innerInput: new Uint8Array()
    });

    return {
      gasPrice,
      maxFeePerGas: gasPrice,
      maxPriorityFeePerGas: gasPrice,
      gasLimit: BigInt(2500000),
      type: 0x71,
      from: ethers.getAddress(getSignerAddress() as string),
      customData: {
        gasPerPubdata: utils.DEFAULT_GAS_PER_PUBDATA_LIMIT,
        paymasterParams: paymasterParams
      },
    };
  }

  const GetPinnedTokens = () => {
    return [
      CONTRACTS.eth.toLowerCase(),
      CONTRACTS.weth.toLowerCase(),
      CONTRACTS.WBTC.toLowerCase(),
      CONTRACTS.USDC.toLowerCase(),
      CONTRACTS.USDT.toLowerCase(),
      CONTRACTS.DAI.toLowerCase(),
    ]

  }

  const getTokenInformation = async (contract) => {
    try {
      contract = ethers.getAddress(contract)
      const to = ethers.getAddress(getSignerAddress() as string);

      if (contract.toLowerCase() == CONTRACTS.eth.toLowerCase()) {
        return {
          success: true,
          address: contract,
          symbol: 'ETH',
          decimals: 18,
          name: 'Ethereum',
          //@ts-ignore
          balance: tokens[0].amount,
          active: true
        }
      }

      if (store.getState().tokens[contract.toLowerCase()]){
        return {
          success: true,
          ...store.getState().tokens[contract.toLowerCase()]
        }
      }


      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });
      const contractCallContext: ContractCallContext[] = [
        {
          reference: 'decimals',
          contractAddress: contract,
          abi: ERC20,
          calls: [{ reference: 'decimals', methodName: 'decimals', methodParameters: [] }]
        },
        {
          reference: 'symbol',
          contractAddress: contract,
          abi: ERC20,
          calls: [{ reference: 'symbol', methodName: 'symbol', methodParameters: [] }]
        },
        {
          reference: 'name',
          contractAddress: contract,
          abi: ERC20,
          calls: [{ reference: 'name', methodName: 'name', methodParameters: [] }]
        },
        {
          reference: 'balanceOf',
          contractAddress: contract,
          abi: ERC20,
          calls: [{ reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [to] }]
        }
      ]

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      let symbol = multiRes.results['symbol'].callsReturnContext[0].returnValues[0]
      let decimals = new BigNumber(multiRes.results['decimals'].callsReturnContext[0].returnValues[0]).toFixed()
      let balance = new BigNumber(multiRes.results['balanceOf'].callsReturnContext[0].returnValues[0]).div(Math.pow(10, 18)).toFixed()
      let name = multiRes.results['name'].callsReturnContext[0].returnValues[0]

      dispatch(walletSlice.actions.updateToken({ balance, symbol, address, decimals, name, logo: UnknownToken, active: false }))

      return {
        success: true,
        address: contract,
        symbol,
        decimals,
        name,
        balance,
        amount: balance,
        logo: UnknownToken,
        active: true,
        price: '0.00'
      }
    } catch (e) {
      return {
        success: false,
        address: contract,
        symbol: '',
        decimals: '',
        name: '',
        balance: '0.00',
        amount: '0.00',
        logo: UnknownToken,
        active: true,
        price: '0.00'
      }

    }
  }


  const ChangePoolFee = async (pair, val) => {
    try {
      if (new BigNumber(val).gt(1000) || new BigNumber(val).lt(1))
        throw 'out of fee range'

      const zksigner = await getZkSigner();


      const to = ethers.getAddress(getSignerAddress() as string);

      var pairContract = new Contract(pair, PAIR, zksigner!);

      var txHandle = await pairContract.changeFeeType(val.toFixed(),
        {

        }
      )

      console.log(txHandle)

      var txResult = await txHandle.wait()

      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }
  }

  const ChangePoolDelegate = async (pair, del) => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);

      const zksigner = await getZkSigner();

      var pairContract = new Contract(pair, PAIR, zksigner!);

      var txHandle = await pairContract.delegate(del,
        {

        }
      )

      console.log(txHandle)

      var txResult = await txHandle.wait()

      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }
  }

  const ChangeVEMuteDelegate = async (_id, del) => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      const zksigner = await getZkSigner();

      var veContract = new Contract(CONTRACTS.VEMUTE, VEMUTE, zksigner!);
      var gasEst = await veContract.delegate.estimateGas(_id, del)

      var txHandle = await veContract.delegate(_id, del,
        {
          gasLimit: BigInt(gasEst.toString())
        })

      var txResult = await txHandle.wait()

      await getDAOInfo()

      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }
  }


  const LockMute = async (amount, lock_days, should_approve) => {

    try {
      if (new BigNumber(amount).lte(0))
        throw 'out of range'

      const zksigner = await getZkSigner();
      var veContract = new Contract(CONTRACTS.VEMUTE, VEMUTE, zksigner!);

      if (should_approve)
        await approveTokenAmount(CONTRACTS.MUTE, CONTRACTS.VEMUTE)

      var lock_time = new BigNumber(lock_days).times(60 * 60 * 24)

      const gasLimitEstimate = await veContract.Lock.estimateGas(new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0), lock_time.toFixed())
      console.log(gasLimitEstimate.toString())
      var txHandle = await veContract.Lock(
        new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0),
        lock_time.toFixed(),
        {
          gasLimit: BigInt(gasLimitEstimate.toString())
          /*
          customData: {
            feeToken: tokens[0].address,
          }
          */
        }
      )

      var txResult = await txHandle.wait()
      await getDAOInfo()
      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }

  }

  const LockKoi = async (amount, lock_days, should_approve) => {
    try {
      if (new BigNumber(amount).lte(0))
        throw 'out of range'

      const zksigner = await getZkSigner();
      var veContract = new Contract(CONTRACTS.VEKOI, VEKOI, zksigner!);

      if (should_approve)
        await approveTokenAmount(CONTRACTS.KOI, CONTRACTS.VEKOI)

      var lock_time = new BigNumber(lock_days).times(60 * 60 * 24)

      const gasLimitEstimate = await veContract.Lock.estimateGas(new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0), lock_time.toFixed())
      console.log(gasLimitEstimate.toString())
      var txHandle = await veContract.Lock(
        new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0),
        lock_time.toFixed(),
        {
          gasLimit: BigInt(gasLimitEstimate.toString())
        }
      )

      var txResult = await txHandle.wait()
      await getDAOInfo()
      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }

  }

  const UpgradeLegacyToVe = async () => {
    try {
      const zksigner = await getZkSigner();
      var veContract = new Contract(CONTRACTS.VEMUTE, VEMUTE, zksigner!);

      var txHandle = await veContract.LockLegacy(
        {
          /*
          customData: {
            feeToken: tokens[0].address,
          }
          */
        }
      )

      var txResult = await txHandle.wait()
      await getDAOInfo()
      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }

  }


  const RedeemDMute = async (index, v2 = false) => {
    console.log(index, v2)
    try {
      const zksigner = await getZkSigner();
      var dMuteContract = new Contract(CONTRACTS.DMUTE, DMUTE, zksigner!);

      if (v2 == true)
        dMuteContract = new Contract(CONTRACTS.DMUTE_v3, DMUTE, zksigner!);

      const to = ethers.getAddress(getSignerAddress() as string);

      var txHandle = await dMuteContract.RedeemTo(
        [index],
        to,
        {
          /*
          customData: {
            feeToken: tokens[0].address,
          }
          */
        }
      )

      console.log(txHandle)
      var txResult = await txHandle.wait()
      await getDAOInfo()
      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }

  }

  const RedeemVEMute = async (index) => {
    try {
      const zksigner = await getZkSigner();
      var veContract = new Contract(CONTRACTS.VEMUTE, DMUTE, zksigner!);

      const to = ethers.getAddress(getSignerAddress() as string);

      var txHandle = await veContract.Redeem(
        [index],
        {
          /*
          customData: {
            feeToken: tokens[0].address,
          }
          */
        }
      )

      var txResult = await txHandle.wait()
      await getDAOInfo()
      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }

  }

  const RedeemVEKOI = async (index) => {
    try {
      const zksigner = await getZkSigner();
      var veContract = new Contract(CONTRACTS.VEKOI, VEKOI, zksigner!);

      const to = ethers.getAddress(getSignerAddress() as string);

      var txHandle = await veContract.Redeem(
        [index],
        {
          /*
          customData: {
            feeToken: tokens[0].address,
          }
          */
        }
      )

      var txResult = await txHandle.wait()
      await getDAOInfo()
      return { success: true }

    } catch (e) {
      console.log(e)
      return { success: false }
    }

  }



  const getDAOInfo = async () => {
    try {
      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });
      const to = ethers.getAddress(getSignerAddress() as string);
      var veContract = new Contract(CONTRACTS.VEMUTE, VEMUTE, await getZkSigner());

      const contractCallContext: ContractCallContext[] = [
        {
          reference: 'dmute_balance',
          contractAddress: CONTRACTS.MUTE,
          abi: ERC20,
          calls: [
            { reference: 'dmute_balance', methodName: 'balanceOf', methodParameters: [CONTRACTS.DMUTE] },
            { reference: 'allowance', methodName: 'allowance', methodParameters: [to, CONTRACTS.DMUTE] },
            { reference: 'dmute_balance_v3', methodName: 'balanceOf', methodParameters: [CONTRACTS.DMUTE_v3] },
            { reference: 'allowance_v3', methodName: 'allowance', methodParameters: [to, CONTRACTS.DMUTE_v3] },
            { reference: 'dmute_balance_ve', methodName: 'balanceOf', methodParameters: [CONTRACTS.VEMUTE] },
            { reference: 'allowance_ve', methodName: 'allowance', methodParameters: [to, CONTRACTS.VEMUTE] },
          ]
        },
        {
          reference: 'dmute_info',
          contractAddress: CONTRACTS.DMUTE,
          abi: DMUTE,
          calls: [
            { reference: 'dmute_balance_user', methodName: 'balanceOf', methodParameters: [to] },
            { reference: 'dmute_supply', methodName: 'totalSupply', methodParameters: [] },
            { reference: 'dmute_delegate', methodName: 'delegates', methodParameters: [to] },
            { reference: 'GetUnderlyingTokens', methodName: 'GetUnderlyingTokens', methodParameters: [to] },
            { reference: 'GetUserLockLength', methodName: 'GetUserLockLength', methodParameters: [to] }
          ]
        },
        {
          reference: 'dmute_info_v3',
          contractAddress: CONTRACTS.DMUTE_v3,
          abi: DMUTE,
          calls: [
            { reference: 'dmute_balance_user', methodName: 'balanceOf', methodParameters: [to] },
            { reference: 'dmute_supply', methodName: 'totalSupply', methodParameters: [] },
            { reference: 'dmute_delegate', methodName: 'delegates', methodParameters: [to] },
            { reference: 'GetUnderlyingTokens', methodName: 'GetUnderlyingTokens', methodParameters: [to] },
            { reference: 'GetUserLockLength', methodName: 'GetUserLockLength', methodParameters: [to] }
          ]
        },
        {
          reference: 've_info',
          contractAddress: CONTRACTS.VEMUTE,
          abi: VEMUTE,
          calls: [
            { reference: 've_votes', methodName: 'GetVotingTokens', methodParameters: [to] },
            { reference: 've_vote_supply', methodName: 'getPastTotalSupply', methodParameters: [new BigNumber((await veContract.clock()).toString()).minus(1).toFixed()] },
            { reference: 've_delegate', methodName: 'delegates', methodParameters: [to] },
            { reference: 'GetUnderlyingTokens', methodName: 'GetUnderlyingTokens', methodParameters: [to] },
            { reference: 've_nft_count', methodName: 'balanceOf', methodParameters: [to] },
            { reference: 've_legacy_upgraded', methodName: 'GetLegacyUpgraded', methodParameters: [to] }
          ]
        },
        {
          reference: 'vekoi_info',
          contractAddress: CONTRACTS.VEKOI,
          abi: VEMUTE,
          calls: [
            { reference: 've_votes', methodName: 'GetVotingTokens', methodParameters: [to] },
            { reference: 've_vote_supply', methodName: 'getPastTotalSupply', methodParameters: [new BigNumber((await veContract.clock()).toString()).minus(1).toFixed()] },
            { reference: 've_delegate', methodName: 'delegates', methodParameters: [to] },
            { reference: 'GetUnderlyingTokens', methodName: 'GetUnderlyingTokens', methodParameters: [to] },
            { reference: 've_nft_count', methodName: 'balanceOf', methodParameters: [to] },
          ]
        },
        {
          reference: 'koiallowance',
          contractAddress: CONTRACTS.KOI,
          abi: ERC20,
          calls: [
            { reference: 'allowance_ve', methodName: 'allowance', methodParameters: [to, CONTRACTS.VEKOI] },
            { reference: 'koi_balance_ve', methodName: 'balanceOf', methodParameters: [CONTRACTS.VEKOI] },
          ]
        },
      ]

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);

      var bal = multiRes.results['dmute_balance'].callsReturnContext[0].returnValues[0]
      var bal_v3 = multiRes.results['dmute_balance'].callsReturnContext[2].returnValues[0]
      var bal_ve = multiRes.results['dmute_balance'].callsReturnContext[4].returnValues[0]
      var bal_vekoi = multiRes.results['koiallowance'].callsReturnContext[1].returnValues[0]

      var bal_user = multiRes.results['dmute_info'].callsReturnContext[0].returnValues[0]
      var bal_user_v3 = multiRes.results['dmute_info_v3'].callsReturnContext[0].returnValues[0]
      var bal_user_ve = multiRes.results['ve_info'].callsReturnContext[0].returnValues[0]
      var bal_user_ve_koi = multiRes.results['vekoi_info'].callsReturnContext[0].returnValues[0]

      var dmute_supply = multiRes.results['dmute_info'].callsReturnContext[1].returnValues[0]
      var dmute_supply_v3 = multiRes.results['dmute_info_v3'].callsReturnContext[1].returnValues[0]
      var ve_supply = multiRes.results['ve_info'].callsReturnContext[1].returnValues[0]
      var ve_supply_koi = multiRes.results['vekoi_info'].callsReturnContext[1].returnValues[0]

      var dmute_underlying_bal = multiRes.results['dmute_info'].callsReturnContext[3].returnValues[0]
      var dmute_underlying_bal_v3 = multiRes.results['dmute_info_v3'].callsReturnContext[3].returnValues[0]
      var ve_underlying_bal = multiRes.results['ve_info'].callsReturnContext[3].returnValues[0]
      var ve_underlying_bal_koi = multiRes.results['vekoi_info'].callsReturnContext[3].returnValues[0]

      var lock_length = new BigNumber(multiRes.results['dmute_info'].callsReturnContext[4].returnValues[0]).toNumber()
      var lock_length_v3 = new BigNumber(multiRes.results['dmute_info_v3'].callsReturnContext[4].returnValues[0]).toNumber()

      var ve_nft_count = new BigNumber(multiRes.results['ve_info'].callsReturnContext[4].returnValues[0]).toNumber()
      var ve_nft_count_koi = new BigNumber(multiRes.results['vekoi_info'].callsReturnContext[4].returnValues[0]).toNumber()

      var allowance_ve = multiRes.results['koiallowance'].callsReturnContext[0].returnValues[0]
      var ve_legacy_upgraded = multiRes.results['ve_info'].callsReturnContext[5].returnValues[0]

      var contractCallContextLocks: ContractCallContext[] = [
        {
          reference: 've_info',
          contractAddress: CONTRACTS.VEMUTE,
          abi: VEMUTE,
          calls: []
        },
        {
          reference: 'vekoi_info',
          contractAddress: CONTRACTS.VEKOI,
          abi: VEMUTE,
          calls: []
        }
      ]

      for (let i = 0; i < lock_length; i++) {
        contractCallContextLocks.push({
          reference: '_userLocks' + String(i),
          contractAddress: CONTRACTS.DMUTE,
          abi: DMUTE,
          calls: [{ reference: '_userLocks' + String(i), methodName: '_userLocks', methodParameters: [to, i] }]
        })
      }

      for (let i = 0; i < lock_length_v3; i++) {
        contractCallContextLocks.push({
          reference: '_userLocks' + String(i) + '_v3',
          contractAddress: CONTRACTS.DMUTE_v3,
          abi: DMUTE,
          calls: [{ reference: '_userLocks' + String(i), methodName: '_userLocks', methodParameters: [to, i] }]
        })
      }

      for (let _index = 0; _index < ve_nft_count; _index++) {
        contractCallContextLocks[0].calls.push({ reference: String(_index), methodName: 'tokenOfOwnerByIndex', methodParameters: [to, _index] })
      }


      for (let _index = 0; _index < ve_nft_count_koi; _index++) {
        contractCallContextLocks[1].calls.push({ reference: String(_index), methodName: 'tokenOfOwnerByIndex', methodParameters: [to, _index] })
      }

      const multiResLocks: ContractCallResults = await multicall.call(contractCallContextLocks);

      var user_locks: any = []
      var ve_locks: any = []
      var vekoi_locks: any = []

      for (let i = 0; i < lock_length; i++) {
        var locked_amount = new BigNumber(multiResLocks.results['_userLocks' + String(i)].callsReturnContext[0].returnValues[0]).div(Math.pow(10, 18)).toFixed()
        var unlock_time = new BigNumber(multiResLocks.results['_userLocks' + String(i)].callsReturnContext[0].returnValues[1]).toFixed()
        var tokens_minted = new BigNumber(multiResLocks.results['_userLocks' + String(i)].callsReturnContext[0].returnValues[2]).div(Math.pow(10, 18)).toFixed()

        user_locks.push({
          locked_amount,
          unlock_time,
          tokens_minted,
          index: i,
          v2: false
        })
      }

      for (let i = 0; i < lock_length_v3; i++) {
        var locked_amount = new BigNumber(multiResLocks.results['_userLocks' + String(i) + '_v3'].callsReturnContext[0].returnValues[0]).div(Math.pow(10, 18)).toFixed()
        var unlock_time = new BigNumber(multiResLocks.results['_userLocks' + String(i) + '_v3'].callsReturnContext[0].returnValues[1]).toFixed()
        var tokens_minted = new BigNumber(multiResLocks.results['_userLocks' + String(i) + '_v3'].callsReturnContext[0].returnValues[2]).div(Math.pow(10, 18)).toFixed()

        user_locks.push({
          locked_amount,
          unlock_time,
          tokens_minted,
          index: i,
          v2: true
        })
      }

      if (multiResLocks.results['ve_info']) {
        var contractCallContextVe: ContractCallContext[] = [
          {
            reference: 've_info',
            contractAddress: CONTRACTS.VEMUTE,
            abi: VEMUTE,
            calls: []
          },
          {
            reference: 've_del',
            contractAddress: CONTRACTS.VEMUTE,
            abi: VEMUTE,
            calls: []
          },
          {
            reference: 've_meta',
            contractAddress: CONTRACTS.VEMUTE,
            abi: VEMUTE,
            calls: []
          }
        ]

        for (let i = 0; i < multiResLocks.results['ve_info'].callsReturnContext.length; i++) {
          let _id = new BigNumber(multiResLocks.results['ve_info'].callsReturnContext[i].returnValues[0]).toString()
          contractCallContextVe[0].calls.push(
            { reference: String(_id), methodName: 'GetLockInfo', methodParameters: [_id] }
          )
          contractCallContextVe[1].calls.push(
            { reference: String(_id), methodName: 'delegates', methodParameters: [_id] }
          )

          contractCallContextVe[2].calls.push(
            { reference: String(_id), methodName: 'tokenURI', methodParameters: [_id] }
          )
        }

        const multiResVE: ContractCallResults = await multicall.call(contractCallContextVe);
        for (let i = 0; i < multiResVE.results['ve_info'].callsReturnContext.length; i++) {
          ve_locks.push({
            index: multiResVE.results['ve_info'].originalContractCallContext.calls[i].reference,
            amount: new BigNumber(multiResVE.results['ve_info'].callsReturnContext[i].returnValues[0]).div(Math.pow(10, 18)).toString(),
            time: new BigNumber(multiResVE.results['ve_info'].callsReturnContext[i].returnValues[1]).toString(),
            vote_weight: new BigNumber(multiResVE.results['ve_info'].callsReturnContext[i].returnValues[2]).div(Math.pow(10, 18)).toString(),
            legacy: multiResVE.results['ve_info'].callsReturnContext[i].returnValues[3],
            delegate: multiResVE.results['ve_del'].callsReturnContext[i].returnValues[0],
            meta: multiResVE.results['ve_meta'].callsReturnContext[i].returnValues[0],
          })
        }
      }

      if (multiResLocks.results['vekoi_info']) {
        var contractCallContextVe: ContractCallContext[] = [
          {
            reference: 've_info',
            contractAddress: CONTRACTS.VEKOI,
            abi: VEKOI,
            calls: []
          },
          {
            reference: 've_del',
            contractAddress: CONTRACTS.VEKOI,
            abi: VEKOI,
            calls: []
          },
          {
            reference: 've_meta',
            contractAddress: CONTRACTS.VEKOI,
            abi: VEKOI,
            calls: []
          }
        ]

        for (let i = 0; i < multiResLocks.results['vekoi_info'].callsReturnContext.length; i++) {
          let _id = new BigNumber(multiResLocks.results['vekoi_info'].callsReturnContext[i].returnValues[0]).toString()
          contractCallContextVe[0].calls.push(
            { reference: String(_id), methodName: 'GetLockInfo', methodParameters: [_id] }
          )
          contractCallContextVe[1].calls.push(
            { reference: String(_id), methodName: 'delegates', methodParameters: [_id] }
          )

          contractCallContextVe[2].calls.push(
            { reference: String(_id), methodName: 'tokenURI', methodParameters: [_id] }
          )
        }

        const multiResVE: ContractCallResults = await multicall.call(contractCallContextVe);
        for (let i = 0; i < multiResVE.results['ve_info'].callsReturnContext.length; i++) {
          vekoi_locks.push({
            index: multiResVE.results['ve_info'].originalContractCallContext.calls[i].reference,
            amount: new BigNumber(multiResVE.results['ve_info'].callsReturnContext[i].returnValues[0]).div(Math.pow(10, 18)).toString(),
            time: new BigNumber(multiResVE.results['ve_info'].callsReturnContext[i].returnValues[1]).toString(),
            vote_weight: new BigNumber(multiResVE.results['ve_info'].callsReturnContext[i].returnValues[2]).div(Math.pow(10, 18)).toString(),
            legacy: multiResVE.results['ve_info'].callsReturnContext[i].returnValues[3],
            delegate: multiResVE.results['ve_del'].callsReturnContext[i].returnValues[0],
            meta: multiResVE.results['ve_meta'].callsReturnContext[i].returnValues[0],
          })
        }
      }

      var muteTVL = new BigNumber(bal).plus(bal_v3).plus(bal_ve).div(Math.pow(10, 18)).times(getMuteTokenPrice()).toFixed()
      var koiTVL = new BigNumber(bal_vekoi).div(Math.pow(10, 18)).times(getKoiTokenPrice()).plus(muteTVL).toFixed()

      let daoInfo = {
        success: true,
        tvl: koiTVL,
        dmute_mute_balance: new BigNumber(bal).plus(bal_v3).plus(bal_ve).div(Math.pow(10, 18)).toFixed(),
        total_locked_koi: new BigNumber(bal).plus(bal_v3).plus(bal_ve).div(Math.pow(10, 18)).times(12.5).plus(new BigNumber(bal_vekoi).div(Math.pow(10,18))).toFixed(),
        dmute_balance_user: new BigNumber(bal_user_ve).div(Math.pow(10, 18)).toFixed(),
        vekoi_balance_user: new BigNumber(bal_user_ve_koi).div(Math.pow(10, 18)).toFixed(),

        dmute_underlying_bal: new BigNumber(dmute_underlying_bal).plus(dmute_underlying_bal_v3).plus(ve_underlying_bal).div(Math.pow(10, 18)).toFixed(),
        dmute_delegate: multiRes.results['ve_info'].callsReturnContext[2].returnValues[0],
        dmute_address: CONTRACTS.VEMUTE,
        vekoi_address: CONTRACTS.VEKOI,
        mute_address: CONTRACTS.MUTE,
        koi_address: CONTRACTS.KOI,
        user_locks,
        ve_locks,
        vekoi_locks,
        ve_mute_balance: new BigNumber(bal).plus(bal_v3).plus(bal_ve).div(Math.pow(10, 18)).toFixed(),
        ve_underlying_bal: new BigNumber(ve_underlying_bal).div(Math.pow(10, 18)).toFixed(),
        vekoi_underlying_bal: new BigNumber(ve_underlying_bal_koi).div(Math.pow(10, 18)).toFixed(),

        ve_supply: new BigNumber(ve_supply).div(Math.pow(10, 18)).toFixed(),
        ve_supply_koi: new BigNumber(ve_supply_koi).div(Math.pow(10, 18)).plus(new BigNumber(ve_supply).div(Math.pow(10, 18)).times(12.5)).toFixed(),
        ve_supply_koi_solo: new BigNumber(ve_supply_koi).div(Math.pow(10, 18)).toFixed(),

        shouldApprove: new BigNumber(allowance_ve).div(Math.pow(10, 18)).toFixed(),
        share: new BigNumber(bal_user_ve_koi).div(new BigNumber(ve_supply_koi)).times(100).toFixed(2),
        legacy_upgraded: ve_legacy_upgraded
      }

      dispatch(walletSlice.actions.updateDaoInfo(daoInfo))

    } catch (e) {
      console.log(e)
      let daoInfo = {
        success: true,
        dmute_mute_balance: '0',
        tvl: '0',
        dmute_balance_user: '0',
        dmute_underlying_bal: '0',
        dmute_delegate: '0x0000000000000000000000000000000000000000',
        dmute_address: CONTRACTS.DMUTE_v3,
        vekoi_address: CONTRACTS.VEKOI,
        mute_address: CONTRACTS.MUTE,
        koi_address: CONTRACTS.KOI,
        user_locks: [],
        ve_locks: [],
        shouldApprove: '0',
        share: '0',
        legacy_upgraded: false
      }
      dispatch(walletSlice.actions.updateDaoInfo(daoInfo))

    }
  }

  const getAmplifierV2Pools = () => {
    const amp_pools: any = []
    let _allPairs = Utils.deepClone(store.getState().allPairs)

    for (let i in CONTRACTS.AMPLIFIER_V2) {
      var lp_apy = '0'
      var tvl = '0'
      var lp_price = '0'
      let token0 = getTokenFromContract(CONTRACTS.AMPLIFIER_V2[i].token0)
      let token1 = getTokenFromContract(CONTRACTS.AMPLIFIER_V2[i].token1)

      for (let k in _allPairs) {
        let pair = _allPairs[k]
        if (CONTRACTS.AMPLIFIER_V2[i].pair.toLowerCase() == pair.id.toLowerCase()) {
          lp_apy = pair.apy
          tvl = pair.reserveUSD
          lp_price = new BigNumber(pair.totalSupply).div(pair.reserveUSD).toFixed()
        }
      }
      amp_pools.push({
        ...CONTRACTS.AMPLIFIER_V2[i],
        lp_apy,
        token0,
        token1,
        tvl,
        lp_price,
        v2: true
      })
    }


    return amp_pools
  }

  const getAmplifierV2Multiplier = async (_id, _balance) => {

    const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });
    const contractCallContext: ContractCallContext[] = []

    const _address = getSignerAddress()
    const _veIds: any = []

    //@ts-ignore
    const ve_locks = store.getState().daoInfo.vekoi_locks
    for (let i in ve_locks) {
      _veIds.push(ve_locks[i].index)
    }

    contractCallContext.push(
      {
        reference: 'amp',
        contractAddress: _id,
        abi: AMPLIFIER_REDUX,
        calls: [
          { reference: 'calculateMultiplier', methodName: 'calculateMultiplier', methodParameters: [_address, _balance, _veIds] },
          { reference: 'payoutFor', methodName: 'payoutFor', methodParameters: [_address, _balance, _veIds] },
        ]
      })


    const multiRes: ContractCallResults = await multicall.call(contractCallContext);
    return {
      multiplier: new BigNumber(multiRes.results['amp'].callsReturnContext[0].returnValues[0]).div(Math.pow(10, 18)).toFixed(2),
      payout: new BigNumber(multiRes.results['amp'].callsReturnContext[1].returnValues[0]).div(Math.pow(10, 18)).toFixed(2),
    }


  }

  const getAmplifierV2Info = async () => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);

      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });

      var contractCallContext: ContractCallContext[] = []

      const _address = to
      const _veIds: any = []

      /*
      //@ts-ignore
      const ve_locks = store.getState().daoInfo.ve_locks
      for (let i in ve_locks) {
        _veIds.push(ve_locks[i].index)
      }

      contractCallContext.push(
        {
          reference: 'oracle',
          contractAddress: CONTRACTS.MUTE_ORACLE,
          abi: MUTE_ORACLE,
          calls: [
            { reference: 'getCurrentEthPrice', methodName: 'getCurrentEthPrice', methodParameters: [] },
            { reference: 'getCurrentMutePrice', methodName: 'getCurrentMutePrice', methodParameters: [] },
            { reference: 'getTokenPriceA', methodName: 'getTokenPrice', methodParameters: [_tokenA, _lp] },
            { reference: 'getTokenPriceB', methodName: 'getTokenPrice', methodParameters: [_tokenB, _lp] },
            { reference: 'getLPValue', methodName: 'getLPValue', methodParameters: [_lp, _address, _balance] },
            { reference: 'veRatioToLPHoldings', methodName: 'veRatioToLPHoldings', methodParameters: [_address, _lp, _balance, _veIds] },
          ]
        })

      */
      
      /*
      contractCallContext.push(
        {
          reference: 'treasury',
          contractAddress: CONTRACTS.AMPLIFIER_TREASURY_MUTE,
          abi: AMPLIFIER_TREASURY,
          calls: [
            { reference: 'getCurrentMutePrice', methodName: 'getCurrentMutePrice', methodParameters: [] },
          ]
      })

        */


      var amplifiers: any[] = []
      let _allPairs = Utils.deepClone(store.getState().allPairs)

      for (let i in CONTRACTS.AMPLIFIER_V2) {


      contractCallContext.push(
        {
          reference: 'oracle' + String(i),
          contractAddress: CONTRACTS.AMPLIFIER_V2[i].oracle,
          abi: MUTE_ORACLE,
          calls: [
            { reference: 'getCurrentMutePrice', methodName: 'getCurrentMutePrice', methodParameters: [] },
            { reference: 'getPayoutTokenPrice', methodName: 'getTokenPrice', methodParameters: [CONTRACTS.AMPLIFIER_V2[i].payoutToken, CONTRACTS.AMPLIFIER_V2[i].pair] },

          ]
        })

        contractCallContext.push(
          {
            reference: 'amp' + String(i),
            contractAddress: CONTRACTS.AMPLIFIER_V2[i].id,
            abi: AMPLIFIER_REDUX,
            calls: [
              { reference: 'redeemRewardsView', methodName: 'redeemRewardsView', methodParameters: [_address] },
              { reference: 'GetUserStakes', methodName: 'GetUserStakes', methodParameters: [_address] },
              { reference: 'GetUsedVeInfo', methodName: 'GetUsedVeInfo', methodParameters: [_veIds] },
              { reference: 'management_fee', methodName: 'management_fee', methodParameters: [] },
              { reference: 'percentageMulti', methodName: 'percentageMulti', methodParameters: [] },
              { reference: 'totalDebt', methodName: 'totalDebt', methodParameters: [] },
              { reference: 'totalPayoutGiven', methodName: 'totalPayoutGiven', methodParameters: [] },
              { reference: 'maxDeposit', methodName: 'maxDeposit', methodParameters: [] },
            ]
          })

        contractCallContext.push(
          {
            reference: 'allowance' + String(i),
            contractAddress: CONTRACTS.AMPLIFIER_V2[i].pair,
            abi: PAIR,
            calls: [
              { reference: 'myLP', methodName: 'balanceOf', methodParameters: [to] },
              { reference: 'allowance', methodName: 'allowance', methodParameters: [to, CONTRACTS.AMPLIFIER_V2[i].id] },
              { reference: 'index0', methodName: 'index0', methodParameters: [] },
              { reference: 'index1', methodName: 'index1', methodParameters: [] },
              { reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [CONTRACTS.AMPLIFIER_V2[i].id] },
            ]
          })

          contractCallContext.push(
            {
              reference: 'payout' + String(i),
              contractAddress: CONTRACTS.AMPLIFIER_V2[i].treasury,
              abi: AMPLIFIER_TREASURY,
              calls: [
                { reference: 'payout', methodName: 'payoutToken', methodParameters: [] },
              ]
            })
      }

      //const multiResOracle: ContractCallResults = await multicall.call(contractCallContext);

      for (let i in CONTRACTS.AMPLIFIER_V2) {
        let amp_info = {
          ...Utils.deepClone(CONTRACTS.AMPLIFIER_V2[i]),
          pair_apy: '0.00',
          totalSupply: '0.00',
          reserve0: '0.00',
          reserve1: '0.00',
          lp_price: '0.00',
          tvl: '0',
        }


        for (let k in _allPairs) {
          let pair = _allPairs[k]
          if (CONTRACTS.AMPLIFIER_V2[i].pair.toLowerCase() == pair.id.toLowerCase()) {
            amp_info.pair_apy = pair.apy
            amp_info.totalSupply = new BigNumber(pair.totalSupply).toFixed()
            amp_info.reserve0 = new BigNumber(pair.reserve0).toFixed()
            amp_info.reserve1 = new BigNumber(pair.reserve1).toFixed()
            amp_info.lp_price = new BigNumber(pair.reserveUSD).div(pair.totalSupply).toFixed()
            break
          }
        }

        const multiResOracle: ContractCallResults = await multicall.call(contractCallContext);

        /*
        var getCurrentEthPrice = multiResOracle.results['oracle'].callsReturnContext[0].returnValues[0]
        var getCurrentMutePrice = multiResOracle.results['oracle'].callsReturnContext[1].returnValues[0]
        var getTokenPriceA = multiResOracle.results['oracle'].callsReturnContext[2].returnValues[0]
        var getTokenPriceB = multiResOracle.results['oracle'].callsReturnContext[3].returnValues[0]
        var getLPValue = new BigNumber(multiResOracle.results['oracle'].callsReturnContext[4].returnValues[0]).div(Math.pow(10, 18)).toFixed(4)
        var veRatioToLPHoldings = multiResOracle.results['oracle'].callsReturnContext[5].returnValues[0]
        */

        var getCurrentMutePrice = multiResOracle.results['oracle' + String(i)].callsReturnContext[0].returnValues[0]
        var getPayoutTokenPrice = multiResOracle.results['oracle' + String(i)].callsReturnContext[1].returnValues[0]

        var redeemRewardsView = multiResOracle.results['amp' + String(i)].callsReturnContext[0].returnValues[0]
        var GetUserStakes = multiResOracle.results['amp' + String(i)].callsReturnContext[1].returnValues
        var GetUsedVeInfo = multiResOracle.results['amp' + String(i)].callsReturnContext[2].returnValues[0]
        var management_fee = multiResOracle.results['amp' + String(i)].callsReturnContext[3].returnValues[0]
        var percentageMulti = multiResOracle.results['amp' + String(i)].callsReturnContext[4].returnValues[0]
        var totalDebt = multiResOracle.results['amp' + String(i)].callsReturnContext[5].returnValues[0]
        var totalPayoutGiven = multiResOracle.results['amp' + String(i)].callsReturnContext[6].returnValues[0]
        var maxDeposit = multiResOracle.results['amp' + String(i)].callsReturnContext[7].returnValues[0]


        var lp_bal = multiResOracle.results['allowance' + String(i)].callsReturnContext[0].returnValues[0]
        var allowance = multiResOracle.results['allowance' + String(i)].callsReturnContext[1].returnValues[0]
        var index0 = new BigNumber(multiResOracle.results['allowance' + String(i)].callsReturnContext[2].returnValues[0])
        var index1 = new BigNumber(multiResOracle.results['allowance' + String(i)].callsReturnContext[3].returnValues[0])


        var payoutToken = multiResOracle.results['payout' + String(i)].callsReturnContext[0].returnValues[0]

        amp_info.tvl = new BigNumber(amp_info.lp_price).times(totalDebt).div(Math.pow(10, 18)).toFixed()

        var totalUserStake = new BigNumber(0)

        let index = 0
        let user_stakes: any[] = []
        let fee0 = new BigNumber(0)
        let fee1 = new BigNumber(0)


        for (let i in GetUserStakes) {

          const stake = {
            stakeAmount: new BigNumber(GetUserStakes[i][0]).div(Math.pow(10, 18)).toFixed(),
            stakedTime: new BigNumber(GetUserStakes[i][1]).toFixed(),
            rewardAmount: new BigNumber(GetUserStakes[i][2]).div(Math.pow(10, 18)).toFixed(),
            rewardClaimed: new BigNumber(GetUserStakes[i][3]).div(Math.pow(10, 18)).toFixed(),
            epochDuration: new BigNumber(GetUserStakes[i][4]).toFixed(),
            lastRedeem: new BigNumber(GetUserStakes[i][5]).toFixed(),
            supplyIndex0: new BigNumber(GetUserStakes[i][6]).toFixed(),
            supplyIndex1: new BigNumber(GetUserStakes[i][7]).toFixed(),
          }

          if (index0.minus(stake.supplyIndex0).gt(0)) {
            fee0 = fee0.plus(new BigNumber(stake.stakeAmount).times(index0.minus(stake.supplyIndex0)))
          }

          if (index1.minus(stake.supplyIndex1).gt(0)) {
            fee1 = fee1.plus(new BigNumber(stake.stakeAmount).times(index1.minus(stake.supplyIndex1)))
          }

          user_stakes.push(stake)

          totalUserStake = totalUserStake.plus(stake.stakeAmount)

          index++

        }
        var localPayout = getTokenFromContract(payoutToken)
        var amplifier = {
          ...amp_info,
          success: true,
          v2: true,
          id: amp_info.id,
          pair: amp_info.pair,
          token0: getTokenFromContract(amp_info.token0),
          token1: getTokenFromContract(amp_info.token1),
          stable: amp_info.stable,
          multiplier: new BigNumber(amp_info.ampBoost).toFixed(),
          user_stakes: user_stakes,
          // bps points additional on base APY
          // example min apy = 5, max apy = 10, multi = 0.06 -> 5.3%
          baseAPY: new BigNumber(amp_info.apy).div(100).toFixed(2),
          maxAPY: new BigNumber(amp_info.apy).div(100).times(amp_info.ampBoost).toFixed(2),
          lpAPY: amp_info.pair_apy,
          lp_apy: amp_info.pair_apy,
          ampBoost: amp_info.ampBoost,
          lpPrice: amp_info.lp_price,
          tvl: new BigNumber(amp_info.tvl).toFixed(),
          maxDeposit: new BigNumber(maxDeposit).div(Math.pow(10, 18)).toFixed(),
          tbv: new BigNumber(maxDeposit).div(Math.pow(10, 18)).times(getPayoutTokenPrice).div(Math.pow(10, 18)).toFixed(),
          maxTBV: new BigNumber(CONTRACTS.AMPLIFIER_V2[i].maxRewards).times(getPayoutTokenPrice).div(Math.pow(10, 18)).toFixed(),

          totalStaked: new BigNumber(totalDebt).div(Math.pow(10, 18)).toFixed(),
          totalClaimedRewards: new BigNumber(totalPayoutGiven).div(Math.pow(10, 18)).toFixed(),

          currentClaimable: new BigNumber(redeemRewardsView).div(Math.pow(10, 18)).toFixed(),
          currentClaimableFee0: new BigNumber(fee0).toFixed(),
          currentClaimableFee1: new BigNumber(fee1).toFixed(),

          totalUserStake: new BigNumber(totalUserStake).toFixed(),
          underlyingAssetA: new BigNumber(totalUserStake).div(amp_info.totalSupply).times(amp_info.reserve0).toFixed(),
          underlyingAssetB: new BigNumber(totalUserStake).div(amp_info.totalSupply).times(amp_info.reserve1).toFixed(),

          managementFee: new BigNumber(amp_info.fee).toFixed(),

          approved: new BigNumber(allowance).div(Math.pow(10, 18)).toFixed(),
          balance: new BigNumber(lp_bal).div(Math.pow(10, 18)).toFixed(),
          divisor: new BigNumber(percentageMulti).div(Math.pow(10, 18)).toFixed(2),
          payoutToken: localPayout
        }

        amplifiers.push(amplifier)

      }

      dispatch(walletSlice.actions.updateAmplifiersV2(amplifiers))

      return amplifiers

    } catch (e) {
      console.log(e)
      return {
        success: false
      }
    }

  }

  const getAmplifierPools = async (amplifier) => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);

      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });

      const contractCallContext: ContractCallContext[] = []
      const amInfo: any = {}

      for (let i in amplifier) {
        amInfo[amplifier[i].id] = amplifier[i]

        contractCallContext.push(
          {
            reference: amplifier[i].id,
            contractAddress: amplifier[i].id,
            abi: AMPLIFIER,
            calls: [
              { reference: 'totalStakers', methodName: 'totalStakers', methodParameters: [] },
              { reference: 'totalRewards', methodName: 'totalRewards', methodParameters: [] },
              { reference: 'totalReclaimed', methodName: 'totalReclaimed', methodParameters: [] },
              { reference: 'totalClaimedRewards', methodName: 'totalClaimedRewards', methodParameters: [] },
              { reference: 'startTime', methodName: 'startTime', methodParameters: [] },
              { reference: 'firstStakeTime', methodName: 'firstStakeTime', methodParameters: [] },
              { reference: 'endTime', methodName: 'endTime', methodParameters: [] },
              { reference: 'dripInfo', methodName: 'dripInfo', methodParameters: [to] },
              { reference: 'management_fee', methodName: 'management_fee', methodParameters: [] },

              { reference: 'totalStake', methodName: 'totalStake', methodParameters: [] },
              { reference: 'totalUserStake', methodName: 'totalUserStake', methodParameters: [to] },
              { reference: 'calculateMultiplier', methodName: 'calculateMultiplier', methodParameters: [to, true] },
              { reference: '_percentageMin', methodName: '_percentageMin', methodParameters: [] },
              { reference: 'userStakedBlock', methodName: 'userStakedBlock', methodParameters: [to] },
              { reference: 'payout', methodName: 'payout', methodParameters: [] },
            ]
          })

          contractCallContext.push(
          {
            reference: 'token_'  + amplifier[i].id,
            contractAddress: amplifier[i].pair,
            abi: ERC20,
            calls: [
              { reference: 'myLP', methodName: 'balanceOf', methodParameters: [to] },
              { reference: 'allowance', methodName: 'allowance', methodParameters: [to, amplifier[i].id] }
            ]
          })
      }

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);
      var amplifiers: any[] = []
      for (let i in multiRes.results) {
        if (multiRes.results[i].callsReturnContext[0].success == false || i.includes('token_'))
          continue

        
        let amp_info = amInfo[i]
        var totalStakers = multiRes.results[i].callsReturnContext[0].returnValues[0]
        var totalRewards = multiRes.results[i].callsReturnContext[1].returnValues[0]
        var totalReclaimed = multiRes.results[i].callsReturnContext[2].returnValues[0]
        var totalClaimedRewards = multiRes.results[i].callsReturnContext[3].returnValues[0]
        var startTime = multiRes.results[i].callsReturnContext[4].returnValues[0]
        var firstStakeTime = multiRes.results[i].callsReturnContext[5].returnValues[0]
        var endTime = multiRes.results[i].callsReturnContext[6].returnValues[0]
        var dripInfo = multiRes.results[i].callsReturnContext[7]
        var management_fee = multiRes.results[i].callsReturnContext[8].returnValues[0]
        var totalStaked = multiRes.results[i].callsReturnContext[9].returnValues[0]
        var totalUserStake = multiRes.results[i].callsReturnContext[10].returnValues[0]
        var calculateMultiplier = multiRes.results[i].callsReturnContext[11].returnValues[0]
        var _stakeDivisor = new BigNumber(multiRes.results[i].callsReturnContext[12].returnValues[0])

        var myLP = multiRes.results['token_' + String(i)].callsReturnContext[0].returnValues[0]
        var allowance = multiRes.results['token_' + String(i)].callsReturnContext[1].returnValues[0]

        //perSecondReward / totalLP / multi_current / multi_last / currentRewards
        var mute_token = getMuteToken()
        var perSecondReward = new BigNumber(totalRewards).div(new BigNumber(endTime).minus(new BigNumber(firstStakeTime).eq(0) ? startTime : firstStakeTime));

        let _allPairs = Utils.deepClone(store.getState().allPairs)

        let lp_apy = '0'
        let tvl = '0'
        let lp_totsup = '0'
        let reserve0 = '0'
        let reserve1 = '0'


        for (let k in _allPairs) {
          if (amp_info.pair.toLowerCase() == _allPairs[k].id.toLowerCase()) {
            lp_apy = _allPairs[k].apy
            tvl = new BigNumber(_allPairs[k].reserveUSD).times(new BigNumber(totalStaked).div(Math.pow(10, 18)).div(_allPairs[k].totalSupply)).toFixed()
            lp_totsup = _allPairs[k].totalSupply
            reserve0 = _allPairs[k].reserve0
            reserve1 = _allPairs[k].reserve1

          }
        }

        var amp_apy = perSecondReward.times(60 * 60 * 24 * 365).times(mute_token.price).div(Math.pow(10, 18)).div(tvl).times(100).toFixed(2)

        if (amp_apy == 'Infinity')
          amp_apy = '10000'

        // amp is expired, show 0 apy
        if (new BigNumber(endTime).lt(Date.now() / 1000)) {
          amp_apy = '0.00'

          // show lp apy
          //lp_apy = '0.00'
        }

        amplifiers.push({
          success: true,
          id: amp_info.id,
          pair: amp_info.pair,
          token0: getTokenFromContract(amp_info.token0),
          token1: getTokenFromContract(amp_info.token1),
          totalStakers: new BigNumber(totalStakers).toFixed(),
          totalRewards: new BigNumber(totalRewards).div(Math.pow(10, 18)).toFixed(),
          totalStaked: new BigNumber(totalStaked).div(Math.pow(10, 18)).toFixed(),
          totalReclaimed: new BigNumber(totalReclaimed).div(Math.pow(10, 18)).toFixed(),
          totalClaimedRewards: new BigNumber(totalClaimedRewards).div(Math.pow(10, 18)).toFixed(),
          currentClaimable: new BigNumber(dripInfo.success == true ? dripInfo.returnValues[4] : 0).div(Math.pow(10, 18)).toFixed(),
          currentClaimableFee0: new BigNumber(dripInfo.success == true ? dripInfo.returnValues[5] : 0).toFixed(),
          currentClaimableFee1: new BigNumber(dripInfo.success == true ? dripInfo.returnValues[6] : 0).toFixed(),
          // bps points additional on base APY
          // example min apy = 5, max apy = 10, multi = 0.06 -> 5.3%
          multiplier: new BigNumber(calculateMultiplier).div(_stakeDivisor).toFixed(),
          maxAPY: new BigNumber(amp_apy).toFixed(2),
          baseAPY: new BigNumber(amp_apy).times(_stakeDivisor).div(new BigNumber(Math.pow(10, 18))).toFixed(2),
          lpAPY: lp_apy,
          lpPrice: new BigNumber(tvl).div(lp_totsup).toFixed(),
          totalUserStake: new BigNumber(totalUserStake).div(Math.pow(10, 18)).toFixed(),
          underlyingAssetA: new BigNumber(new BigNumber(totalUserStake).div(Math.pow(10, 18))).div(lp_totsup).times(reserve0).toFixed(),
          underlyingAssetB: new BigNumber(new BigNumber(totalUserStake).div(Math.pow(10, 18))).div(lp_totsup).times(reserve1).toFixed(),
          startTime: new BigNumber(startTime).toFixed(),
          firstStakeTime: new BigNumber(firstStakeTime).toFixed(),
          managementFee: new BigNumber(management_fee).toFixed(),
          endTime: new BigNumber(endTime).toFixed(),
          approved: new BigNumber(allowance).div(Math.pow(10, 18)).toFixed(),
          balance: new BigNumber(myLP).div(Math.pow(10, 18)).toFixed(),
          divisor: new BigNumber(Math.pow(10, 18)).div(_stakeDivisor).toFixed(2),
          tvl: new BigNumber(tvl).toFixed(),
          stable: amp_info.stable,
        })
      }

      dispatch(walletSlice.actions.updateAmplifiers(amplifiers))

      return amplifiers

    } catch (e) {
      console.log(e)
      return {
        success: false
      }
    }
  }

  const filterUsedVeIds = async (ampAddress, ids) => {
    const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });

    const contractCallContext: ContractCallContext[] = []
    const amInfo: any = {}

    if(ids.length == 0)
      return []

    for (let i in ids) {
      contractCallContext.push(
        {
          reference: ids[i],
          contractAddress: ampAddress,
          abi: AMPLIFIER_REDUX,
          calls: [
            { reference: 'usedVeIds_' + String(ids[i]), methodName: 'usedVeIds', methodParameters: [ids[i]] },
          ]
        })
    }

    const multiRes: ContractCallResults = await multicall.call(contractCallContext);
    var validIds: any[] = []
    for (let i in multiRes.results) {

      const expiry_time = new BigNumber(multiRes.results[i].callsReturnContext[0].returnValues[0])
      if(new BigNumber(Date.now() / 1000).gt(expiry_time))
        validIds.push(i.replace("usedVeIds_", ""))
    }

    return validIds
  }


  const stakeInAmplifier = async (id, amount, pair, v2) => {

    try {
      const zksigner = await getZkSigner();
      var _provider = new Provider(chain!.rpcUrls.default.http[0]);

      if ((await checkAllowance(pair, id, new BigNumber(amount).times(Math.pow(10, 18)).toFixed())) == false)
        await approveTokenAmount(pair, id)

      var amplifierContract = new Contract(id, v2 ? AMPLIFIER_REDUX : AMPLIFIER, zksigner!);

      if (v2) {
        const _veIds: any = []

        //@ts-ignore
        const ve_locks = store.getState().daoInfo.vekoi_locks

        for (let i in ve_locks) {
          _veIds.push(ve_locks[i].index)
        }

        const filteredIds = await filterUsedVeIds(id, _veIds)

        const gasPrice = await _provider.getGasPrice();
        const gasLimit = await amplifierContract.deposit.estimateGas(new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0), filteredIds);

        estimateGasRefund(gasPrice.toString(), gasLimit.toString())

        dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm deposit transaction"))

        const txHandle = await amplifierContract.deposit(
          new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0),
          filteredIds,
          {

          }
        );

        await txHandle.wait();

      } else {
        const txHandle = await amplifierContract.stake(
          new BigNumber(amount).times(Math.pow(10, 18)).toFixed(0),
          {

          }
        );

        await txHandle.wait();
      }

      await getAmplifierV2Info()

      return {

        success: true
      }

    } catch (e) {
      console.log(e)
      return {
        success: false
      }
    }

  }

  const withdrawFromAmplifier = async (id) => {

    try {
      const zksigner = await getZkSigner();
      var _provider = new Provider(chain!.rpcUrls.default.http[0]);

      var amplifierContract = new Contract(id, AMPLIFIER, zksigner!);
      const gasPrice = await _provider.getGasPrice();
      const gasLimit = await amplifierContract.withdraw.estimateGas();

      estimateGasRefund(gasPrice.toString(), gasLimit.toString())

      const txHandle = await amplifierContract.withdraw(
        {

        }
      );

      await txHandle.wait();

      return {

        success: true
      }

    } catch (e) {
      console.log(e)
      return {
        success: false
      }
    }

  }

  const payoutFromAmplifier = async (id) => {
    try {
      const zksigner = await getZkSigner();

      var amplifierContract = new Contract(id, AMPLIFIER, zksigner!);
      const txHandle = await amplifierContract.payout(
        {

        }
      );

      await txHandle.wait();

      return {

        success: true
      }

    } catch (e) {
      console.log(e)
      return {
        success: false
      }
    }
  }

  const payoutFromAmplifierV2 = async (id) => {
    try {
      const zksigner = await getZkSigner();
      var _provider = new Provider(chain!.rpcUrls.default.http[0]);

      var amplifierContract = new Contract(id, AMPLIFIER_REDUX, zksigner!);

      const gasPrice = await _provider.getGasPrice();
      const gasLimit = await amplifierContract.redeemRewards.estimateGas();

      estimateGasRefund(gasPrice.toString(), gasLimit.toString())

      const txHandle = await amplifierContract.redeemRewards(
        {

        }
      );

      await txHandle.wait();

      return {

        success: true
      }

    } catch (e) {
      console.log(e)
      return {
        success: false
      }
    }
  }

  const getTransactionResults = (paymasterParams: any = null, receipt: any = null, startTime: any = null) => {
    if(paymasterParams == null)
      return {gasSavedUSD: '', gasSpentUSD: '', time: '0.00', tx: ''}
    
    var endTime = Date.now()

    var gasUsed = receipt.gasUsed.toString()
    var gasEstimated = paymasterParams.gasLimit.toString()
    let gasEstimatedUSD = new BigNumber(gasEstimated).times(paymasterParams.gasPrice.toString()).div(Math.pow(10, 18)).times(store.getState().ethPrice)
    let gasSpentUSD = new BigNumber(gasUsed).times(paymasterParams.gasPrice.toString()).div(Math.pow(10, 18)).times(store.getState().ethPrice)

    return {gasSaved: gasEstimatedUSD.minus(gasSpentUSD).toFixed(2), gasSavedUSD: gasEstimatedUSD.minus(gasSpentUSD).toFixed(2), gasSpentUSD: gasSpentUSD.toFixed(2), time: new BigNumber(endTime).minus(startTime).div(1000).toFixed(2), tx: receipt.hash }
  }

  const claimPoolFees = async (pair) => {
    try {
      const zksigner = await getZkSigner();
      var pool = new Contract(pair, PAIR, zksigner!);
      var paymasterParams = await getPaymasterParams()
      var additionalParams = paymasterParams.customData ? Utils.deepClone(paymasterParams) :  { gasLimit: paymasterParams.gasLimit, customData: null }

      dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"))
  
      const gasLimit = await pool.claimFees.estimateGas({...additionalParams, gasLimit: null});
      
      additionalParams.gasLimit = BigInt(gasLimit.toString())
      paymasterParams.gasLimit = BigInt(gasLimit.toString())

      estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())

      const txHandle = await pool.claimFees({...additionalParams});

      var startTime = Date.now()
      var receipt = await txHandle.wait();
      let txResults = getTransactionResults(paymasterParams, receipt, startTime)

      return { success: true, error: '', ...txResults }

    } catch (e) {
      console.log(e)
      let txResults = getTransactionResults()

      return {
        success: false,
        error: JSON.stringify(e),
        ...txResults
      }
    }

  }

  const convertVeIndex = async(index) => {
    try {
      const zksigner = await getZkSigner();
      var veMute = new Contract(CONTRACTS.VEMUTE, VEMUTE, zksigner!);
      var isApproved = await veMute.isApprovedForAll(zksigner.address, CONTRACTS.KOI_CONVERSION)

      if(isApproved == false){
        var veApprove = await veMute.setApprovalForAll(CONTRACTS.KOI_CONVERSION, true)
        await veApprove.wait()
      }

      var conversion = new Contract(CONTRACTS.KOI_CONVERSION, KOI_CONVERSION, zksigner!);
      var paymasterParams = await getPaymasterParams()
      var additionalParams = paymasterParams.customData ? Utils.deepClone(paymasterParams) :  { gasLimit: paymasterParams.gasLimit, customData: null }
      
      dispatch(walletSlice.actions.updaetTransactionModalStatus("Confirm transaction in your wallet"))
      
      const gasLimit = await conversion.convertVeIndex.estimateGas(index, {...additionalParams, gasLimit: null});
      
      additionalParams.gasLimit = BigInt(gasLimit.toString())
      paymasterParams.gasLimit = BigInt(gasLimit.toString())

      estimateGasRefund(paymasterParams.gasPrice.toString(), paymasterParams.gasLimit.toString())

      const txHandle = await conversion.convertVeIndex(index, {...additionalParams});

      var startTime = Date.now()
      var receipt = await txHandle.wait();
      let txResults = getTransactionResults(paymasterParams, receipt, startTime)

      await getDAOInfo()

      return { success: true, error: '', ...txResults }

    } catch (e) {
      console.log(e)
      let txResults = getTransactionResults()

      return {
        success: false,
        error: JSON.stringify(e),
        ...txResults
      }
    }
  }


  const getBondInfo = async () => {

    try {

      const to = ethers.getAddress(getSignerAddress() as string);

      const multicall = new Multicall({ nodeUrl: chain!.rpcUrls.default.http[0], tryAggregate: true });
      const contractCallContext: ContractCallContext[] = [
        {
          reference: 'bondInfo',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'bondInfo', methodName: 'bondInfo', methodParameters: [] }]
        },
        {
          reference: 'epochStart',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'epochStart', methodName: 'epochStart', methodParameters: [] }]
        },
        {
          reference: 'maxPayout',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'maxPayout', methodName: 'maxPayout', methodParameters: [] }]
        },
        {
          reference: 'startPrice',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'startPrice', methodName: 'startPrice', methodParameters: [] }]
        },
        {
          reference: 'maxPrice',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'maxPrice', methodName: 'maxPrice', methodParameters: [] }]
        },
        {
          reference: 'maxDeposit',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'maxDeposit', methodName: 'maxDeposit', methodParameters: [] }]
        },
        {
          reference: 'totalDebt',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'totalDebt', methodName: 'totalDebt', methodParameters: [] }]
        },
        {
          reference: 'totalPayoutGiven',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'totalPayoutGiven', methodName: 'totalPayoutGiven', methodParameters: [] }]
        },
        {
          reference: 'currentEpoch',
          contractAddress: CONTRACTS.BOND.bond,
          abi: BOND,
          calls: [{ reference: 'currentEpoch', methodName: 'currentEpoch', methodParameters: [] }]
        },
        {
          reference: 'balanceOf',
          contractAddress: CONTRACTS.BOND.lp,
          abi: ERC20,
          calls: [{ reference: 'balanceOf', methodName: 'balanceOf', methodParameters: [to] }]
        },
        {
          reference: 'balanceOfTreasury',
          contractAddress: CONTRACTS.BOND.lp,
          abi: ERC20,
          calls: [{ reference: 'balanceOfTreasury', methodName: 'balanceOf', methodParameters: [CONTRACTS.BOND.treasury] }]
        },
        {
          reference: 'balanceOfTreasuryMute',
          contractAddress: CONTRACTS.MUTE,
          abi: ERC20,
          calls: [{ reference: 'balanceOfTreasuryMute', methodName: 'balanceOf', methodParameters: [CONTRACTS.BOND.treasury] }]
        },
        {
          reference: 'metadata',
          contractAddress: CONTRACTS.BOND.lp,
          abi: PAIR,
          calls: [{ reference: 'metadata', methodName: 'metadata', methodParameters: [] }]
        },
        {
          reference: 'totalSupply',
          contractAddress: CONTRACTS.BOND.lp,
          abi: PAIR,
          calls: [{ reference: 'totalSupply', methodName: 'totalSupply', methodParameters: [] }]
        },
        {
          reference: 'allowance',
          contractAddress: CONTRACTS.BOND.lp,
          abi: PAIR,
          calls: [{ reference: 'allowance', methodName: 'allowance', methodParameters: [to, CONTRACTS.BOND.bond] }]
        },
      ]

      const multiRes: ContractCallResults = await multicall.call(contractCallContext);

      var balance = multiRes.results['balanceOf'].callsReturnContext[0].returnValues[0]
      var balance_t = multiRes.results['balanceOfTreasury'].callsReturnContext[0].returnValues[0]
      var balance_mute = multiRes.results['balanceOfTreasuryMute'].callsReturnContext[0].returnValues[0]

      var metadata = multiRes.results['metadata'].callsReturnContext[0].returnValues
      var supply = multiRes.results['totalSupply'].callsReturnContext[0].returnValues[0]
      var allowance = multiRes.results['allowance'].callsReturnContext[0].returnValues[0]
      var epoch = multiRes.results['currentEpoch'].callsReturnContext[0].returnValues[0]

      var epochStart = multiRes.results['epochStart'].callsReturnContext[0].returnValues[0]
      var maxDeposit = multiRes.results['maxDeposit'].callsReturnContext[0].returnValues[0]
      var totalDebt = multiRes.results['totalDebt'].callsReturnContext[0].returnValues[0]
      var totalPayoutGiven = multiRes.results['totalPayoutGiven'].callsReturnContext[0].returnValues[0]
      var maxPayout = multiRes.results['maxPayout'].callsReturnContext[0].returnValues[0]
      var startPrice = multiRes.results['startPrice'].callsReturnContext[0].returnValues[0]
      var maxPrice = multiRes.results['maxPrice'].callsReturnContext[0].returnValues[0]

      var info = multiRes.results['bondInfo'].callsReturnContext[0].returnValues

      //function metadata() external view returns (uint dec0, uint dec1, uint r0, uint r1, bool st, address t0, address t1) {
      var mute_reserves = new BigNumber(0)
      if (metadata[5] == CONTRACTS.weth) {
        mute_reserves = new BigNumber(metadata[3])
      } else {
        mute_reserves = new BigNumber(metadata[2])
      }

      var lp_per_mute = new BigNumber(mute_reserves).div(supply).times(2)
      var max_purchase = new BigNumber(0)
      var price = new BigNumber(startPrice).div(Math.pow(10, 18)).toFixed()

      if (info.length != 0) {
        max_purchase = info[4]
        price = new BigNumber(info[2]).div(Math.pow(10, 18)).toFixed()
      }

      var apy = new BigNumber(price).div(lp_per_mute)
      var apy_text = ''
      if (apy.lt(1))
        apy_text = '-' + new BigNumber(1).minus(apy).times(100).toFixed(2)
      else
        apy_text = new BigNumber(apy).minus(1).times(100).toFixed(2)

      let _bondInfo: any = [
        {
          token0: getTokenFromContract(CONTRACTS.MUTE),
          token1: getTokenFromContract(CONTRACTS.eth),
          id: CONTRACTS.BOND.bond,
          pool: CONTRACTS.BOND.lp,
          maxDeposit: new BigNumber(maxDeposit).div(Math.pow(10, 18)).toFixed(),
          maxPurchase: new BigNumber(max_purchase).div(Math.pow(10, 18)).toFixed(),
          maxPayout: new BigNumber(maxPayout).div(Math.pow(10, 18)).toFixed(),
          price: price,
          lp_per_mute: lp_per_mute.toFixed(2),
          epoch: new BigNumber(epoch).plus(1).toFixed(),
          started: new BigNumber(epochStart).lte(Date.now() / 1000),
          startTime: new BigNumber(epochStart).toNumber(),
          totalDebt: new BigNumber(totalDebt).div(Math.pow(10, 18)).plus(25000).toFixed(),
          totalPayout: new BigNumber(totalPayoutGiven).div(Math.pow(10, 18)).toFixed(),
          balance: new BigNumber(balance).div(Math.pow(10, 18)).toFixed(),
          apy: apy_text,
          approve: new BigNumber(allowance).lte(0),
          totalBonded: lp_per_mute.times(new BigNumber(totalDebt).div(Math.pow(10, 18)).plus(310)).toFixed(2),
          soldout: new BigNumber(balance_mute).lte(0)
        }
      ]

      dispatch(walletSlice.actions.updateBondInfo(_bondInfo))

      return {

        success: true
      }

    } catch (e) {
      console.log(e)
      return {
        success: false
      }
    }

  }

  const depositBond = async (poolId, amount, approve, max = false, epoch = 0, minAmount = 0) => {
    try {
      const to = ethers.getAddress(getSignerAddress() as string);
      const zksigner = await getZkSigner();

      var bondContract = new Contract(CONTRACTS.BOND.bond, BOND, zksigner!);

      if (approve == true) {
        await approveTokenAmount(poolId, CONTRACTS.BOND.bond)
      }

      var txHandle
      if (max == true) {
        txHandle = await bondContract.depositMax(new BigNumber(amount).times(Math.pow(10, 18)).toFixed(), epoch - 1, 0);
      } else {
        txHandle = await bondContract.deposit(new BigNumber(amount).times(Math.pow(10, 18)).toFixed(), epoch - 1, 0);
      }

      await txHandle.wait();

      return {

        success: true
      }

    } catch (e) {
      console.log(e)
      return {
        success: false
      }
    }

  }

  const checkdMuteRefund = () => {
    const to = ethers.getAddress(getSignerAddress() as string);
    for (let i in DMUTEREFUND) {
      if (DMUTEREFUND[i].address.toLowerCase() == to.toLowerCase()) {
        return { valid: true, amount: DMUTEREFUND[i].amount }
      }
    }

    return { valid: false, amount: '0' }
  }


  return (<walletHookContext.Provider
    value={{
      isETH,
      isUSDC,
      prepareTrade,
      executeTrade,
      createLiquidityPool,
      removeLiquidityPermit,
      removeLiquidityWithPermit,
      getDAOInfo,
      getTokenPair,
      getTokenFromContract,
      addTokenToStorage,
      getTokenInformation,
      stakeInAmplifier,
      withdrawFromAmplifier,
      payoutFromAmplifier,
      depositBond,
      getTokenFromSymbol,
      RedeemDMute,
      RedeemVEMute,
      RedeemVEKOI,
      LockMute,
      LockKoi,
      getPairDayData,
      searchForLiquidity,
      destroyCurrentTrade,
      claimPoolFees,
      currentSwapCost,
      getETH,
      getMuteToken,
      getMuteTokenPrice,
      getKoiToken,
      getKoiTokenPrice,
      loadedInitialList,
      getPairTradeData,
      checkdMuteRefund,
      addTokenToWallet,
      ChangePoolFee,
      updateAmp,
      ChangeVEMuteDelegate,
      UpgradeLegacyToVe,
      GetPinnedTokens,
      setFeeAsset,
      getFeeAsset,
      getFeeAssets,
      getAmplifierV2Pools,
      getAmplifierV2Info,
      getAmplifierV2Multiplier,
      payoutFromAmplifierV2,
      convertVeIndex
    }}
  >
    {children}
  </walletHookContext.Provider>
  )
}


export function useWalletHook() {
  const context = React.useContext(walletHookContext)
  if (!context) throw Error('useWalletHook can only be used within the Web3ReactProvider component')
  return context
}