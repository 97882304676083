import { createSlice, configureStore } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export const initialState = {
    chainDelayed: false,
    tokens: {},
    exchangeTokens: [] as any[],
    addedTokens: [] as any[],
    addedLiquidity: [] as any[],
    swapCostPerGas: 0.00018,
    gasFee: 100,
    ethPrice: 4500,
    ownedPairs: null as  any,
    history: [] as any[],
    cachedPair: null as any,
    initiated: false,
    feeAsset: '0x0000000000000000000000000000000000000000',
    wrongChain: false,
    allPairs: [] as any[],
    bondInfo: [] as any[],
    amplifiers: [] as any[],
    amplifiersv2: [] as any[],
    trendingPairs: [] as any[],
    blocks: [] as any[],
    zkID: null,
    ens: null,
    userRanking: {},
    daoInfo: {},

    selectedWallet: undefined as any,
    userExpertMode: false,
    userLocale: null,
    userClientSideRouter: false,
    userHideClosedPositions: false,
    userSlippageTolerance: 'auto',
    userSlippageToleranceHasBeenMigratedToAuto: true,
    userDeadline: Date.now()/1000,
    pairs: {},
    URLWarningVisible: true,
    hideUniswapWalletBanner: false,
    showSurveyPopup: undefined as any,
    transactionModalStatus: "Confirm transaction in your wallet",
    gasEstimateRefund: {total: '0.00', refund: '0.00', actual: '0.00'}
}
  

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    updateChainDelayed(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.chainDelayed = payload
    },
    updateToken(state, action: PayloadAction<any>)  {
      let token = action.payload
      let symbol = token.symbol
      let address = token.address
      let decimals = token.decimals
      let logo = token.logo ? token.logo : state.tokens[address.toLowerCase()].logo
      let name = token.name
      let balance = token.balance
      let price = token.price
      let active = token.active ? token.active : false

      if(price == null && state.tokens[address.toLowerCase()] && state.tokens[address.toLowerCase()].price) {
        price = state.tokens[address.toLowerCase()].price
      } else if(price == null) {
        price = '0.00'
      }

      state.tokens[address.toLowerCase()] = {
        symbol,
        address,
        decimals,
        logo,
        name,
        balance,
        price,
        active
      }
    },
    updateTokens(state, action: PayloadAction<any>)  {
      let tokens = action.payload
      for(let i in tokens){
        let price = tokens[i].price
        if(price == null && state.tokens[tokens[i].address.toLowerCase()] && state.tokens[tokens[i].address.toLowerCase()].price) {
          price = state.tokens[tokens[i].address.toLowerCase()].price
        } else if(price == null) {
          price = '0.00'
        }
        state.tokens[tokens[i].address.toLowerCase()] = {
          symbol: tokens[i].symbol,
          address: tokens[i].address,
          decimals: tokens[i].decimals,
          logo: tokens[i].logo,
          name: tokens[i].name,
          balance: tokens[i].balance,
          price,
          active: tokens[i].active ? tokens[i].active : false
        }
      }
    },
    updateTokenPrices(state, action: PayloadAction<any>)  {
      let tokens = action.payload
      for(let i in tokens){
        if(state.tokens[tokens[i].address.toLowerCase()])
          state.tokens[tokens[i].address.toLowerCase()].price = tokens[i].price
        //else
          //state.tokens[tokens[i].address.toLowerCase()] = {address: tokens[i].address.toLowerCase(), price: tokens[i].price}
      }
    },
    updateExchangeTokens(state, action: PayloadAction<any>)  {
      state.exchangeTokens = action.payload
    },
    updateAddedLiquidity(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.addedLiquidity = payload
    },
    updateETHPrice(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.ethPrice = payload
    },
    updateOwnedPairs(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.ownedPairs = payload
    },
    updateHistory(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.history = payload
    },
    updateCachedPair(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.cachedPair = payload
    },
    updateWrongChain(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.wrongChain = payload
    },
    updateAllPairs(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.allPairs = payload
    },
    updateBondInfo(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.bondInfo = payload
    },
    updateAmplifiers(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.amplifiers = payload
    },
    updateAmplifiersV2(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.amplifiersv2 = payload
    },
    updateTrendingPairs(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.trendingPairs = payload
    },
    updateBlocks(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.blocks = payload
    },
    updatezkID(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.zkID = payload
    },
    updateENS(state, action: PayloadAction<any>)  {
      let payload = action.payload
      state.ens = payload
    },
    updateUserRanking(state, action: PayloadAction<any>){
      let payload = action.payload
      state.userRanking = payload
    },
    updateDaoInfo(state, action: PayloadAction<any>){
      let payload = action.payload
      state.daoInfo = payload
    },
    updateFeeAsset(state, action: PayloadAction<any>){
      let payload = action.payload
      state.feeAsset = payload
    },
    updaetTransactionModalStatus(state, action: PayloadAction<any>){
      let payload = action.payload
      state.transactionModalStatus = payload
    },
    updateGasEstimate(state, action: PayloadAction<any>){
      state.gasEstimateRefund = action.payload
    }
  }
})




export const { 
  updateChainDelayed, 
  updateToken, 
  updateTokens, 
  updateTokenPrices,
  updateExchangeTokens, 
  updateAddedLiquidity,
  updateETHPrice,
  updateOwnedPairs,
  updateHistory,
  updateCachedPair,
  updateWrongChain,
  updateAllPairs,
  updateBondInfo,
  updateAmplifiers,
  updateAmplifiersV2,
  updateBlocks,
  updateDaoInfo,
  updateENS,
  updateFeeAsset,
  updateGasEstimate,
  updateTrendingPairs,
  updaetTransactionModalStatus,
  updateUserRanking,
  updatezkID
} = walletSlice.actions

