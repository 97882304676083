import styled from 'styled-components';
import searchIcon from '../../assets/images/icons/search.svg';

export const SearchInput = styled.input`
  width: 100%;
  height: 45px;
  padding: 15px 24px 15px 48px;
  font-size: 14px;
  line-height: 150%;
  color: #EEEEEE;
  background-image:url(${searchIcon});
  background-color: var(--color-modal-light-blue);
  background-repeat: no-repeat;
  background-position: 24px center;
  border-radius: 16px;
  border: 1.5px solid #3B4568;

  &::-webkit-input-placeholder {
    font-size: 14px;
    line-height: 150%;
    color: #EEEEEE;
  }
  &::-moz-placeholder {
    font-size: 14px;
    line-height: 150%;
    color: #EEEEEE;
  }
  &:-ms-input-placeholder {
    font-size: 14px;
    line-height: 150%;
    color: #EEEEEE;
  }
  &:-moz-placeholder {
    font-size: 14px;
    line-height: 150%;
    color: #EEEEEE;
  }
`;
