import styled from "styled-components";

export const ButtonsRow = styled.div`
  grid-area: buttons;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media (max-width: 767px) {
    margin-bottom: 0;
  }

  & > button + button {
    margin-left: 30px;

    @media (max-width: 900px) {
      margin-left: 16px;
    }

    @media (max-width: 600px) {
      margin-left: 9px;
    }
  }
`;
