import React, { useState, useRef } from 'react'
import styled, { keyframes } from 'styled-components';
import { useLocation, useHistory, useParams } from "react-router-dom";
import BigNumber from 'bignumber.js';

import { ButtonPrimaryLarge } from '../../common/buttons/Button'
import { AccentButton } from '../../common/buttons/AccentButton'

import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';
import { LiquiditySettingsModal } from './LiquiditySettingsModal'
import { LiquidityFeeModal } from './LiquidityFeeModal'
import AddIcon from '@mui/icons-material/Add';

import { getAmountOut } from '../../web3/helper'

import angleIcon from '../../assets/images/icons/angle-bright.svg'
import externalLink from '../../assets/images/icons/external_link.svg'
import gearIcon from '../../assets/images/icons/gear.svg';
import unknownToken from '../../assets/images/icons/unknown_token.svg';
import { ShadowWidget } from '../../common/ShadowWidget'

import { reduceString, formatNumber, getCleanNumber } from '../../utils';
import { AmountSection } from '../../common/section/AmountSection'
import { LoadingAnimation } from '../../common/loading/loading'
import { LoadingBox } from '../../common/loading/LoadingBox'
import { ConfirmTransactionModal } from '../../common/modal/ConfirmTransactionModal'
import crossLight from '../../assets/images/icons/cross.svg'
import { SuccessfulSubmitModal } from '../../common/modal/SuccessfulSubmitModal'
import { ErrorModal } from '../../common/modal/ErrorModal'
import { Row } from '../../common/Row'
import { TextBright, TextGrey, Text, TextWhite, TextGreen } from '../../common/text/Text'

import { useWalletHook } from '../../web3/walletHook';
import { WalletState } from '../../state';
import { useAccount } from 'wagmi'

import { useWalletSelector } from '../../state/hooks';


interface ParamTypes {
  inputCurrency: string;
  outputCurrency: string;
  stable: string;
}

enum Modal {
  Settings,
  ChangeFee,
  Confirm,
  Switch,
  Connect,
  Slipage,
  Error,
  Deposit,
  Withdraw,
  Success,
  Rewards
}

export const LiquidityPoolSubScreen = ({ poolInfo }) => {
  let location = useLocation()
  const history = useHistory()
  const { inputCurrency, outputCurrency, stable } = useParams<ParamTypes>();

  const [assetA, setAssetA] = useState<any>(undefined)
  const [assetB, setAssetB] = useState<any>(undefined)

  const [assetAAmount, setAssetAAmount] = useState('0')
  const [assetBAmount, setAssetBAmount] = useState('0')
  const [assetLPAmountWithdraw, setAssetLPAmountWithdraw] = useState('0')


  const [settingPoolFee, setSettingPoolFee] = useState((stable == "true") ? 0.05 : 0.3)

  const [poolAPY, setPoolAPY] = useState('0')
  const [poolTVL, setPoolTVL] = useState('0')

  const [poolData, setPoolData] = useState<any>(null)

  const [isDeposit, setIsDeposit] = useState(0)
  const [calculating, setCalculating] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [isModalVisible, setIsModalVisible] = useState(false);
  const tokens = useWalletSelector((state: WalletState) => state.tokens)

  const [modal, setModal] = useState<Modal | null>(null);
  const closeModal = () => setModal(null);
  const [error, setError] = useState('')
  const [slippage, setSlippage] = useState(1);
  const [gasSaved, setGasSaved] = useState('');
  const [confirmTime, setConfirmTime] = useState('0.00');
  const [txHash, settxHash] = useState('');



  const gasRefund = useWalletSelector((state: WalletState) => state.gasEstimateRefund)


  const createLiqModal = useRef<any>(null)
  const addLiqModal = useRef<any>(null)
  const removeLiqModal = useRef<any>(null)

  const { getTokenFromContract, getTokenInformation, searchForLiquidity,
    getPairDayData, createLiquidityPool, removeLiquidityPermit, removeLiquidityWithPermit,
    ChangePoolFee, searchForLiquidityWithAddress, claimPoolFees, currentSwapCost, loadedInitialList } = useWalletHook()

  const { isConnected } = useAccount();


  React.useEffect(() => {
    if (isConnected && loadedInitialList == true) {
      let inputCurrencyTemp = inputCurrency
      let outputCurrencyTemp = outputCurrency

      if (inputCurrencyTemp.toLowerCase() == 'eth')
        inputCurrencyTemp = '0x000000000000000000000000000000000000800a'

      if (outputCurrencyTemp.toLowerCase() == 'eth')
        outputCurrencyTemp = '0x000000000000000000000000000000000000800a'

      var _assetA = getTokenFromContract(inputCurrencyTemp)
      var _assetB = getTokenFromContract(outputCurrencyTemp)

      if (_assetA.symbol != null && JSON.stringify(_assetA) != JSON.stringify(assetA))
        setAssetA(_assetA)
      else if (!_assetA.symbol)
        getTokenInformation(inputCurrencyTemp).then(res => { setAssetA(res) })

      if (_assetB.symbol != null && JSON.stringify(_assetB) != JSON.stringify(assetB))
        setAssetB(_assetB)
      else if (!_assetB.symbol)
        getTokenInformation(outputCurrencyTemp).then(res => { setAssetB(res) })
    }

    return () => { }

  }, [isConnected, loadedInitialList]);

  React.useEffect(() => {
    if (assetB && assetA) {
      if (poolData == null) {
        searchForLiquidity(assetA, assetB, 0, (stable == "true")).then(_poolData => {
          setPoolData(_poolData)
          setIsLoading(false)
          if (_poolData.available == true) {
            setSettingPoolFee(new BigNumber(_poolData.pairFeePercent).toNumber())
            getPairDayData(_poolData.address, new BigNumber(_poolData.pairFeePercent).times(100).toNumber()).then(dayData => {
              try {
                setPoolAPY(dayData.apy)
                setPoolTVL(dayData.tvl)
              } catch (e) {
                console.log(e)

              }
            })
          }
        })
      }
    }

    return () => { }

  }, [assetA, assetB]);

  React.useEffect(() => {
    if (poolData == null)
      setIsLoading(true)
    else
      setIsLoading(false)

    return () => { }

  }, [poolData, poolAPY]);

  const getDepositButtonStatus = () => {
    if (new BigNumber(assetAAmount).lte(0) || new BigNumber(assetBAmount).lte(0))
      return 'Invalid amounts'

    if (assetA && assetB) {
      if (new BigNumber(assetAAmount).gt(assetA.balance) || new BigNumber(assetBAmount).gt(assetB.balance))
        return 'Invalid amounts'

      if (poolData) {
        let needToApproveA = new BigNumber(poolData.approveAssetAAmount).lt(assetAAmount) && assetA.symbol != 'ETH'
        let needToApproveB = new BigNumber(poolData.approveAssetBAmount).lt(assetBAmount) && assetB.symbol != 'ETH'

        if (needToApproveA || needToApproveB) {
          return 'Approve'
        }
      }

      return 'Add liquidity'
    }

    return "Invalid amounts"

  }

  const getWithdrawButtonStatus = () => {
    if (new BigNumber(assetLPAmountWithdraw).lte(0) || new BigNumber(assetLPAmountWithdraw).gt(poolData.balance))
      return 'Invalid amounts'

    if (poolData) {
      if (poolData.approveAssetLP || new BigNumber(poolData.approveLPValue).lt(assetLPAmountWithdraw)) {
        return 'Approve'
      }
    }

    return 'Withdraw liquidity'
  }

  const shouldDisable = () => {
    if (getDepositButtonStatus() == 'Add liquidity' || getDepositButtonStatus() == 'Approve')
      return false

    return true
  }

  const shouldDisableWithdraw = () => {
    if (getWithdrawButtonStatus() == 'Withdraw liquidity' || getWithdrawButtonStatus() == 'Approve')
      return false

    return true
  }

  const provideLiquidity = async () => {
    setModal(Modal.Deposit)
    setCalculating(true)
    var res = await createLiquidityPool(assetA, assetB, assetAAmount, assetBAmount, new BigNumber(settingPoolFee).times(100).toNumber(), (stable == "true"), slippage, poolData.address)
    if (res.success == false) {
      setError(res.error)
      //setModal(Modal.Error)
      setModal(null)
    } else {
      setGasSaved(res.gasSavedUSD!)
      setConfirmTime(res.time)
      settxHash(res.tx)
      setPoolData(await searchForLiquidity(assetA, assetB, 0, (stable == "true")))
      setModal(Modal.Success)
    }
    setCalculating(false)

  }

  const withdrawLiquidity = async () => {
    setCalculating(true)
    setModal(Modal.Deposit)
    /*
    if(poolData.approveAssetLP){
      await Wallet.removeLiquidityPermit(assetA.address, assetB.address, poolData.address)
    }
    */

    if (new BigNumber(poolData.approveLPValue).lt(assetLPAmountWithdraw)) {
      await removeLiquidityPermit(assetA.address, assetB.address, poolData.address)
    }

    let minAmountA = new BigNumber(100).minus(slippage).times(new BigNumber(poolData.assetAAmount).times(assetLPAmountWithdraw).div(poolData.balance)).div(100).toNumber()
    let minAmountB = new BigNumber(100).minus(slippage).times(new BigNumber(poolData.assetBAmount).times(assetLPAmountWithdraw).div(poolData.balance)).div(100).toNumber()
    var res = await removeLiquidityWithPermit(assetA, assetB, assetLPAmountWithdraw, '', (stable == "true"), minAmountA, minAmountB)

    if (res.success == false) {
      setError(res.error)
      //setModal(Modal.Error)
      setModal(null)
    } else {
      setGasSaved(res.gasSavedUSD!)
      setConfirmTime(res.time)
      settxHash(res.tx)
      setPoolData(await searchForLiquidity(assetA, assetB, 0, (stable == "true")))
      setModal(Modal.Success)
    }

    setCalculating(false)
  }

  const minReceivedA = () => {
    if (poolData) {
      let minAmountA = new BigNumber(100).minus(slippage).times(new BigNumber(poolData.assetAAmount).times(assetLPAmountWithdraw).div(poolData.balance)).div(100).toNumber()
      return getCleanNumber(minAmountA)
    }

    return '0.00'
  }

  const minReceivedB = () => {
    if (poolData) {
      let minAmountB = new BigNumber(100).minus(slippage).times(new BigNumber(poolData.assetBAmount).times(assetLPAmountWithdraw).div(poolData.balance)).div(100).toNumber()
      return getCleanNumber(minAmountB)
    }

    return '0.00'
  }

  const setSlippageChange = async (r) => {
    setSlippage(r)
  }

  const changeAssetA = (val) => {
    setAssetAAmount(val)

    if (poolData.available == true)
      setAssetBAmount(new BigNumber(val).times(poolData.assetBTotal).div(poolData.assetATotal).toFixed())
  }

  const changeAssetB = (val) => {
    setAssetBAmount(val)
    if (poolData.available == true)
      setAssetAAmount(new BigNumber(val).times(poolData.assetATotal).div(poolData.assetBTotal).toFixed())
  }

  const changePoolFeeInternal = async (val) => {
    await ChangePoolFee(poolData.address, new BigNumber(val).times(100).toNumber())
    setPoolData(await searchForLiquidityWithAddress(poolData.address, 0, (stable == "true")))
    setModal(null)
  }

  const payoutFees = async () => {
    setCalculating(true)
    setModal(Modal.Deposit)
    var res = await claimPoolFees(poolData.address)

    if (res.success == false) {
      setError(res.error)
      //setModal(Modal.Error)
      setModal(null)
    } else {
      setGasSaved(res.gasSavedUSD!)
      setConfirmTime(res.time)
      settxHash(res.tx)
      setModal(Modal.Success)
      setPoolData(await searchForLiquidity(assetA, assetB, 0, (stable == "true")))
    }

    setCalculating(false)
  }

  const getPoolPrice = () => {
    if (assetAAmount == '0' || assetBAmount == '0')
      return '0'
    if (stable == 'true' && assetA && assetB) {
      var amOut = getAmountOut(new BigNumber(assetAAmount).div(100000).toNumber(), assetAAmount, assetBAmount)
      return amOut.times(new BigNumber(1).div(new BigNumber(assetAAmount).div(100000))).toFixed(4)
    } else {
      return new BigNumber(assetBAmount).div(assetAAmount).isNaN() == false ? new BigNumber(assetBAmount).div(assetAAmount).toFixed() : '0'
    }
  }

  const getTVL = () => {
    if (poolTVL != '0')
      return poolTVL

    if (new BigNumber(assetA.price).gt(0))
      return new BigNumber(poolData.assetATotal).times(assetA.price).times(2).toFixed()

    if (new BigNumber(assetB.price).gt(0))
      return new BigNumber(poolData.assetBTotal).times(assetB.price).times(2).toFixed()

    return 0
  }

  return (
    <Screen>
      <Layout>

        <LiquiditySettingsModal
          isVisible={modal === Modal.Settings}
          onClose={() => setModal(null)}
          defaultLimit={settingPoolFee}
          defaultLimitSlippage={slippage}
          onLimitChange={setSettingPoolFee}
          onLimitChangeSlippage={setSlippage}
          disableFee={poolData ? poolData.available == true : false}
        />

        <LiquidityFeeModal
          isVisible={modal === Modal.ChangeFee}
          onClose={() => setModal(null)}
          defaultLimit={settingPoolFee}
          execute={changePoolFeeInternal}
        />

        <TitleContainer>
          <BackButton onClick={() => history.push('/pool')} />
          {isLoading == true ? <TitleContainer><LoadingBox /></TitleContainer> : (
            <TitleContainerWrap>
              <Title>{poolData ? poolData.symbol : (assetA ? assetA.symbol : '') + '/' + (assetB ? assetB.symbol : '')} </Title>
              <Spacer />
              <Subtitle>{poolData ? (poolData.available ? (poolData.stable ? 'Stable ' : 'Normal ') : 'NEW ') : ''}Liquidity Pool</Subtitle>
            </TitleContainerWrap>
          )}

        </TitleContainer>

        <DataContainer style={{ gridArea: 'exchange' }}>
          <DataContainerColumn>
            <StatRow>
              <ExchangeHeaderButton className={isDeposit == 0 ? 'active' : ''} onClick={() => setIsDeposit(0)}>
                Deposit
              </ExchangeHeaderButton>
              <ExchangeHeaderButton className={isDeposit == 1 ? 'active' : ''} onClick={() => setIsDeposit(1)}>
                Withdraw
              </ExchangeHeaderButton>
              <ExchangeHeaderButton className={isDeposit == 2 ? 'active' : ''} onClick={() => setIsDeposit(2)}>
                Rewards
              </ExchangeHeaderButton>
            </StatRow>

            {
              isDeposit == 0 ?
                (
                  <>
                    <ShadowWidget>
                      <AmountSection
                        value={assetAAmount}
                        onChange={changeAssetA}
                        assets={[]}
                        selectedAsset={assetA}
                        disableEdit={true}
                        onAssetSelect={() => { }}
                        label="Add"
                        price={assetA ? new BigNumber(assetAAmount).times(assetA.price).toFixed(2) : '0.00'}
                      />
                      <Spacer />
                      <AmountSection
                        value={assetBAmount}
                        onChange={changeAssetB}
                        assets={[]}
                        disableEdit={true}
                        selectedAsset={assetB}
                        onAssetSelect={() => { }}
                        label="Add"
                        price={assetB ? new BigNumber(assetBAmount).times(assetB.price).toFixed(2) : '0.00'}
                      />
                    </ShadowWidget>
                    <Spacer />
                    <SettingButton onClick={() => setModal(Modal.Settings)} disabled={false}>
                      <GearIcon src={gearIcon} alt="gear" />
                    </SettingButton>
                    <SpacerBorder />
                    <Spacer />
                    <Spacer />
                    <Spacer />

                    <StatRow>
                      <StatText>
                        Price
                      </StatText>
                      <StatTextHighlight>
                        {getCleanNumber(getPoolPrice())} {assetB ? assetB.symbol : ''} per {assetA ? assetA.symbol : ''}
                      </StatTextHighlight>
                    </StatRow>
                    <StatRow>
                      <StatText>
                        Slippage
                      </StatText>
                      <StatTextHighlight>
                        {new BigNumber(slippage).toFixed(2)}%
                      </StatTextHighlight>
                    </StatRow>
                    <StatRow>
                      <StatText>
                        Pool fee
                      </StatText>
                      <StatTextHighlight>
                        {new BigNumber(settingPoolFee).toFixed(2)}%
                      </StatTextHighlight>
                    </StatRow>
                    <Button onClick={() => { provideLiquidity() }} disabled={shouldDisable()}>
                      <LoadingAnimation isLoading={calculating} />
                      {getDepositButtonStatus()}
                    </Button>
                  </>
                ) : (<></>)
            }
            {
              isDeposit == 1 ?
                (<>
                  <ShadowWidget>
                    <AmountSection
                      value={assetLPAmountWithdraw}
                      onChange={setAssetLPAmountWithdraw}
                      assets={[]}
                      selectedAsset={{ amount: '0', balance: new BigNumber(poolData ? poolData.balance : 0).toFixed(), logo: assetA.logo, symbol: assetA.symbol + '/' + assetB.symbol + ' LP', name: '', value: '', decimals: 18, price: '' }}
                      disableEdit={true}
                      onAssetSelect={() => { }}
                      label="Remove"
                      price={assetA ? new BigNumber(assetAAmount).times(assetA.price).toFixed(2) : '0.00'}
                    />
                  </ShadowWidget>

                  <WithdrawPercentageRow>
                    <WithdrawPercentageButton onClick={() => setAssetLPAmountWithdraw(new BigNumber(poolData ? poolData.balance : 0).times(0.25).toFixed())}>25%</WithdrawPercentageButton>
                    <WithdrawPercentageButton onClick={() => setAssetLPAmountWithdraw(new BigNumber(poolData ? poolData.balance : 0).times(0.50).toFixed())}>50%</WithdrawPercentageButton>
                    <WithdrawPercentageButton onClick={() => setAssetLPAmountWithdraw(new BigNumber(poolData ? poolData.balance : 0).times(0.75).toFixed())}>75%</WithdrawPercentageButton>
                    <WithdrawPercentageButton onClick={() => setAssetLPAmountWithdraw(new BigNumber(poolData ? poolData.balance : 0).times(1.00).toFixed())}>100%</WithdrawPercentageButton>
                  </WithdrawPercentageRow>

                  <Spacer />
                  <SettingButton onClick={() => setModal(Modal.Settings)} disabled={false}>
                    <GearIcon src={gearIcon} alt="gear" />
                  </SettingButton>
                  <SpacerBorder />
                  <Spacer />
                  <Spacer />
                  <Spacer />

                  <StatRow>
                    <StatText>
                      {assetA.symbol} received
                    </StatText>
                    <StatTextHighlight>
                      {getCleanNumber(new BigNumber(poolData ? poolData.assetAAmount : 0).times(assetLPAmountWithdraw).div(poolData ? poolData.balance : 0))} {assetA.symbol}
                    </StatTextHighlight>
                  </StatRow>

                  <StatRow>
                    <StatText>
                      {assetB.symbol} received
                    </StatText>
                    <StatTextHighlight>
                      {getCleanNumber(new BigNumber(poolData ? poolData.assetBAmount : 0).times(assetLPAmountWithdraw).div(poolData ? poolData.balance : 0))} {assetB.symbol}
                    </StatTextHighlight>
                  </StatRow>

                  <StatRow>
                    <StatText>
                      {assetA.symbol} min received
                    </StatText>
                    <StatTextHighlight>
                      {minReceivedA()} {assetA.symbol}
                    </StatTextHighlight>
                  </StatRow>

                  <StatRow>
                    <StatText>
                      {assetB.symbol} min received
                    </StatText>
                    <StatTextHighlight>
                      {minReceivedB()} {assetB.symbol}
                    </StatTextHighlight>
                  </StatRow>

                  <Button onClick={() => withdrawLiquidity()} disabled={shouldDisableWithdraw()}>
                    <LoadingAnimation isLoading={calculating} />
                    {getWithdrawButtonStatus()}
                  </Button>
                </>
                ) : <></>}

            {
              isDeposit == 2 ?
                (<>
                  <DataContainerColumn>
                    <ContainerText>
                      Rewards
                    </ContainerText>

                    {isLoading == true && !poolData ? (<></>) :
                      <>
                        <StatTextHighlight style={{ justifyContent: "flex-start", marginBottom: '15px' }}>
                          <AssetImage src={assetA ? assetA.logo : unknownToken} />
                          {new BigNumber(poolData ? poolData.claimable0 : 0).toFixed(4)}
                          {' ' + (assetA ? (assetA.symbol == 'ETH' ? 'WETH' : assetA.symbol) : '')}
                        </StatTextHighlight>
                        <StatTextHighlight style={{ justifyContent: "flex-start" }}>
                          <AssetImage src={assetB ? assetB.logo : unknownToken} />
                          {new BigNumber(poolData ? poolData.claimable1 : 0).toFixed(4)}
                          {' ' + (assetB ? (assetB.symbol == 'ETH' ? 'WETH' : assetB.symbol) : '')}
                        </StatTextHighlight>
                      </>
                    }



                    <Button onClick={() => payoutFees()} disabled={poolData ? new BigNumber(poolData.claimable0).lte(0) && new BigNumber(poolData.claimable1).lte(0) : true}>
                      <LoadingAnimation isLoading={calculating} />
                      Collect
                    </Button>

                  </DataContainerColumn>
                </>
                ) : <></>}



          </DataContainerColumn>
        </DataContainer>

        <DataContainerAPY style={{ gridArea: 'pool_stats' }}>
          <DataContainerColumn>
            <ContainerText>
              Total Value Locked
            </ContainerText>
            <HeaderText>
              {isLoading == true ? (<LoadingBox />) :
                (poolData ? "$" + formatNumber(getTVL(), 2) : '$0.00')
              }
            </HeaderText>
          </DataContainerColumn>
          <SeperatorLine />
          <DataContainerColumn style={{ width: 'auto' }}>
            <ContainerText>
              APY
            </ContainerText>
            <HeaderTextGreen>
              {isLoading == true ? (<LoadingBox />) :
                (poolAPY + "%")
              }
            </HeaderTextGreen>
          </DataContainerColumn>
        </DataContainerAPY>

        <DataContainer style={{ gridArea: 'my_stats' }}>
          <DataContainerColumn>
            <ContainerText>
              My Pool Stats
            </ContainerText>
            <StatRow>
              <StatText>
                Amount LP
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? formatNumber(new BigNumber(poolData.balance), 2) + " " : "0") + (assetA ? assetA.symbol + "/" : "") + (assetB ? assetB.symbol : '') + " LP")
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Amount {assetA ? assetA.symbol : ''}
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? formatNumber(new BigNumber(poolData.assetAAmount), 2) + " " : "0") + (assetA ? assetA.symbol : ""))
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Amount {assetB ? assetB.symbol : ''}
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? formatNumber(new BigNumber(poolData.assetBAmount), 2) + " " : "0") + (assetB ? assetB.symbol : ""))
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Pool share
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? poolData.share + '%' : '0%'))
                }
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>

        <DataContainer style={{ gridArea: 'total_assets' }}>
          <DataContainerColumn>
            <ContainerText>
              Total Assets In Pool
            </ContainerText>
            <StatRow>
              <StatTextHighlight style={{ justifyContent: "flex-start" }}>
                {assetA ? <AssetImage src={assetA.logo ? assetA.logo : unknownToken} /> : <></>} {assetA ? assetA.symbol : ''}
              </StatTextHighlight>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? formatNumber(new BigNumber(poolData.assetATotal).toFixed(2), 0) : "0.00") + " " + (assetA ? assetA.symbol : ""))
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatTextHighlight style={{ justifyContent: "flex-start" }}>
                {assetB ? <AssetImage src={assetB.logo ? assetB.logo : unknownToken} /> : <></>} {assetB ? assetB.symbol : ''}
              </StatTextHighlight>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? formatNumber(new BigNumber(poolData.assetBTotal).toFixed(2), 0) : "0.00") + " " + (assetB ? assetB.symbol : ""))
                }
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>

        <DataContainer style={{ gridArea: 'pool_details' }}>
          <DataContainerColumn>
            <ContainerText>
              Pool Details
            </ContainerText>
            <StatRow>
              <StatText>
                Pool fee
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  (poolData ? poolData.pairFeePercent + '%' : '0%')
                }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                Pool address
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? reduceString(poolData.address, 7, 4) : ''))
                }
                <ExternalLink src={externalLink} onClick={() => { window.open('https://explorer.zksync.io/address/' + poolData.address, "_blank") }} />
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
                LP vote delegate
              </StatText>
              <StatTextHighlight>
                {isLoading == true ? (<LoadingBox />) :
                  ((poolData ? reduceString(poolData.delegate, 7, 4) : ''))
                }
                <ExternalLink src={externalLink} onClick={() => { window.open('https://explorer.zksync.io/address/' + poolData.delegate, "_blank") }} />
              </StatTextHighlight>
            </StatRow>
            <StatRow style={{ padding: '0' }}>
              <StatText>
                <AccentButton onClick={() => setModal(Modal.ChangeFee)} disabled={poolData ? new BigNumber(poolData.share).lt(50) : true}>Change fee</AccentButton>
              </StatText>
              <StatTextHighlight>
                <AccentButton disabled={true}>Delegate</AccentButton>
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>
      </Layout>

      {assetA && assetB ? (
        <>
          <ConfirmTransactionModal
            isVisible={modal === Modal.Deposit}
            title={isDeposit == 0 ? ('Deposit ' + assetA.symbol + '/' + assetB.symbol + ' LP') : (isDeposit == 1 ? ('Withdraw ' + assetA.symbol + '/' + assetB.symbol + ' LP') : 'Claim Rewards')}
            onClose={closeModal}
            onConfirm={() => { }}
            leftValue={`${isDeposit == 0 ? getCleanNumber(new BigNumber(assetAAmount)) : getCleanNumber(new BigNumber(poolData ? poolData.assetAAmount : 0).times(assetLPAmountWithdraw).div(poolData ? poolData.balance : 0))} ` + (assetA ? assetA.symbol : '')}
            leftIcon={assetA ? assetA.logo : unknownToken}
            rightValue={`${isDeposit == 0 ? getCleanNumber(new BigNumber(assetBAmount)) : getCleanNumber(new BigNumber(poolData ? poolData.assetBAmount : 0).times(assetLPAmountWithdraw).div(poolData ? poolData.balance : 0))} ` + (assetB ? assetB.symbol : '')}
            rightIcon={assetB ? assetB.logo : unknownToken}
            icon={crossLight}
            isLP={true}
            deposit={isDeposit}
            footer={
              isDeposit == 0 || isDeposit == 1 ?
                <>
                  <Row>
                    <TextGrey>Slippage tolerance</TextGrey>
                    <TextWhite>{slippage + '%'}</TextWhite>
                  </Row>
                  <Row>
                    <TextGrey>Gas Cost Estimate</TextGrey>
                    <TextWhite>{"$" + gasRefund.actual}</TextWhite>
                  </Row>
                  <Row>
                    <TextGrey>Gas Refund Estimate</TextGrey>
                    <TextGreen>{"$" + gasRefund.refund}</TextGreen>
                  </Row>
                </> : (isDeposit == 1 ? <></> :
                  <>
                    <StatTextHighlight style={{ justifyContent: "center", width: '100%', alignItems: 'center', marginBottom: '10px', marginTop: '-30px' }}>
                      <AssetImage src={assetA ? assetA.logo : ''} />
                      {new BigNumber(poolData ? poolData.claimable0 : 0).toFixed(4)}
                      {' ' + (assetA ? (assetA.symbol == 'ETH' ? 'WETH' : assetA.symbol) : '')}
                    </StatTextHighlight>
                    <AddIcon style={{ color: 'white', marginLeft: 'auto', marginRight: 'auto', width: '100%' }} />
                    <StatTextHighlight style={{ justifyContent: "center", width: '100%', alignItems: 'center', marginTop: '10px', marginBottom: '60px' }}>
                      <AssetImage src={assetB ? assetB.logo : ''} />
                      {new BigNumber(poolData ? poolData.claimable1 : 0).toFixed(4)}
                      {' ' + (assetB ? (assetB.symbol == 'ETH' ? 'WETH' : assetB.symbol) : '')}
                    </StatTextHighlight>

                    <>
                      <Row>
                        <TextGrey>Gas Cost Estimate</TextGrey>
                        <TextWhite>{"$" + gasRefund.actual}</TextWhite>
                      </Row>
                      <Row>
                        <TextGrey>Gas Refund Estimate</TextGrey>
                        <TextGreen>{"$" + gasRefund.refund}</TextGreen>
                      </Row>

                    </>
                  </>)
            }
          />

          <SuccessfulSubmitModal
            isVisible={modal === Modal.Success}
            onClose={closeModal}
            title={isDeposit == 0 ? ('Deposit ' + assetA.symbol + '/' + assetB.symbol + ' LP Successful!') : (isDeposit == 1 ? ('Withdraw ' + assetA.symbol + '/' + assetB.symbol + ' LP Successful!') : 'Claim Rewards Successful!')}
            description="Funds are avaliable in your wallet."
            savings={'$' + currentSwapCost()}
            costs={'$0.00'}
            onSubmit={() => { }}
            symbolIn={''}
            iconIn={''}
            symbolOut={''}
            iconOut={''}
            amountIn={''}
            amountOut={''}
            section={
              isDeposit == 0 ?
                <>
                  <Row>
                    <TextGrey>Refunded gas fees</TextGrey>
                    <TextGreen>{'$' + gasSaved}</TextGreen>
                  </Row>
                  <Row>
                      <TextGrey>Confirmation time</TextGrey>
                      <TextGreen>{confirmTime}s</TextGreen>
                    </Row>
                  <Row>
                    <TextGrey>{assetA ? assetA.symbol : ''} deposited</TextGrey>
                    <TextWhite>{parseFloat(assetAAmount).toPrecision(2)} {assetA ? assetA.symbol : ''}</TextWhite>
                  </Row>
                  <Row>
                    <TextGrey>{assetB ? assetB.symbol : ''} deposited</TextGrey>
                    <TextWhite>{new BigNumber(assetBAmount).toPrecision(2)} {assetB ? assetB.symbol : ''}</TextWhite>
                  </Row>
                  <Row>
                      <TextGrey>Tx hash</TextGrey>
                      <TextWhite><TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>{reduceString(txHash, 6, 4)}</TxButton></TextWhite>
                    </Row>
                </>


                : (isDeposit == 1 ?

                  <>
                    <Row>
                      <TextGrey>Refunded gas fees</TextGrey>
                      <TextGreen>{'$' + gasSaved}</TextGreen>
                    </Row>
                    <Row>
                      <TextGrey>Confirmation time</TextGrey>
                      <TextGreen>{confirmTime}s</TextGreen>
                    </Row>
                    <Row>
                      <TextGrey>Minimum {assetA ? assetA.symbol : ''} received</TextGrey>
                      <TextWhite>{parseFloat(minReceivedA()).toPrecision(2)} {assetA ? assetA.symbol : ''}</TextWhite>
                    </Row>
                    <Row>
                      <TextGrey>Minimum {assetB ? assetB.symbol : ''} received</TextGrey>
                      <TextWhite>{new BigNumber(minReceivedB()).toPrecision(2)} {assetB ? assetB.symbol : ''}</TextWhite>
                    </Row>
                    <Row>
                      <TextGrey>Tx hash</TextGrey>
                      <TextWhite><TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>{reduceString(txHash, 6, 4)}</TxButton></TextWhite>
                    </Row>
                  </>

                  : <>

                  <Row>
                      <TextGrey>Refunded gas fees</TextGrey>
                      <TextGreen>{'$' + gasSaved}</TextGreen>
                    </Row>
                    <Row>
                      <TextGrey>Confirmation time</TextGrey>
                      <TextGreen>{confirmTime}s</TextGreen>
                    </Row>
                    <Row>
                      <TextGrey>Tx hash</TextGrey>
                      <TextWhite><TxButton onClick={() => window.open("https://era.zksync.network/tx/" + txHash, "_blank")}>{reduceString(txHash, 6, 4)}</TxButton></TextWhite>
                    </Row>
                  
                    </>)

            }
          />
        </>
      ) : <></>

      }



      <ErrorModal
        isVisible={modal === Modal.Error}
        onClose={closeModal}
        title="LP error!"
        description={error}
      />


    </Screen>
  )
}

/*
<Row>
              <TextGrey>Minimum received</TextGrey>
              <TextWhite>{new BigNumber(minReceived).toPrecision(2)} {token2 ? token2.symbol : ''}</TextWhite>
            </Row>

      */
//          <CreatePair externalOpen={createLiqModal} assets={tokens} createPair={addLiquidity} importPool={importPool} addLiquidity={addLiquidityScreenPopup}/>
//          <ImportPool assets={tokens} importPool={importPool} addPool={addPool} />
// <LiquidityHistory history={liquidity.history}/>

/*
<DataContainer style={{gridArea: 'my_rewards'}}>
  <DataContainerColumn>
    <ContainerText>
    Rewards
    </ContainerText>
    <StatRow>
      <StatText>
      Claimable {assetA ? assetA.symbol : ''}
      </StatText>
      <StatTextHighlight>
        {isLoading == true ? (<LoadingBox/>) :
          ((poolData ? new BigNumber(poolData.claimable0).toFixed(4) + " " : "0") + (assetA ? assetA.symbol : ""))
        }
      </StatTextHighlight>
    </StatRow>
    <StatRow>
      <StatText>
      Claimable {assetB ? assetB.symbol : ''}
      </StatText>
      <StatTextHighlight>
      {isLoading == true ? (<LoadingBox/>) :
        ((poolData ? new BigNumber(poolData.claimable1).toFixed(4) + " " : "0") + (assetB ? assetB.symbol : ""))
      }
      </StatTextHighlight>
    </StatRow>
  </DataContainerColumn>
</DataContainer>

*/
const Layout = styled(Grid)`
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "titlecontainer titlecontainer"
  "pool_stats  exchange"
  "my_stats exchange"
  "total_assets exchange"
  "pool_details exchange";


  @media (max-width: 767px) {
    grid-template-columns: 1fr;
    grid-template-areas:
    "titlecontainer titlecontainer"
    "pool_stats pool_stats"
    "exchange  exchange"
    "my_stats my_stats"
    "total_assets total_assets"
    "pool_details pool_details";
  }
`;

const WithdrawPercentageRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 20px;
`

const WithdrawPercentageButton = styled.button`
  height: 30px;
  width: 20%;
  border: 1px solid var(--color-blue);
  background: var(--color-blue-light);
  color: white;
  border-radius: 8px;
  :hover {
    background: var(--color-blue);
  }
`

const ExternalLink = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 8px;
`

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`
const SpacerBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2E3551;
`
const TitleContainer = styled.div`
  position: relative;
  overflow: hidden;
  grid-area: titlecontainer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const TitleContainerWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-end;
    padding: 10px;

  }
`

const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
  border: 1px solid transparent;
  border-radius: 10px;
`

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`

const StatText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7F7D97;
`

const StatTextHighlight = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F6F8FE;
`

const Subtitle = styled.div`
  width: auto;
  padding: 0 10px;
  height: 27px;
  background: rgba(41, 48, 74, 0.4);
  border-radius: 8px;
  display: flex;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  justify-content: center;
  align-items: center;

  color: #777F99;
`

const BackButton = styled.button`
  background: rgba(24, 29, 47, 0.7);
  border-radius: 14px;
  background-image: url(${angleIcon});
  background-size: cover;
  transform: rotate(90deg);
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
`

const DataContainer = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 600px) {
    padding: 16px;
  }
`
const DataContainerAPY = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 600px) {
    padding: 16px;
    flex-direction: column;
  }
`

const SeperatorLine = styled.div`
height: 0;

  @media (max-width: 600px) {
    height: 20px;
  }
`


const DataContainerOffset = styled(DataContainer)`
  margin-top: -145px;

  @media (max-width: 767px) {
    margin-top: 0px;
  }
`

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
`

const ContainerText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #777F99;
  padding-bottom: 18px;
`

const HeaderText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  position: relative;
  display: flex;
  min-height: 30px;
  width: 50%;
`

const HeaderTextGreen = styled.h1`
  position: relative;
  width: 50%;
  min-height: 30px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #0BD98F;
`


const ExchangeHeaderButton = styled.button`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #7F7D97;
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #1D2336;

  &.active {
    color: var(--color-blue-accent);
    border-bottom: 2px solid var(--color-blue-accent);
  }
`

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;


const SettingAnim = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(60deg); }
`

const SettingButton = styled.button`
  width: 24px;
  height: 35px;
  margin-left: 8px;
  padding: 0;

  :disabled{
    opacity: 0.5;
    cursor: not-allowed;

  }

  :hover {
    opacity: 0.5;
    animation: ${SettingAnim} 0.5s forwards;
  }
`;

const GearIcon = styled.img`
  max-width: 100%;
`;


const TxButton = styled.button`
  cursor: pointer;
  color: white;
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
  z-index: 500;
  position: relative;
`;