import React, { useState } from 'react'
import styled from 'styled-components';
import { ButtonPrimaryMedium, ButtonSecondaryLarge } from '../../common/buttons/Button';
import angleIcon from '../../assets/images/icons/angle-light.svg';
import { TextAlt } from '../../common/text/Text';
import BigNumber from 'bignumber.js'
import { Grid } from '../../common/layout/Grid';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';

interface DropdownProps {
  onManageClick: () => any;
  icon0: string;
  icon1: string;
  token0: string;
  token1: string;
  discount: string;
  tbv: string;
  percent: number;
  soldout?: boolean;
  children?: any;
}

export const Dropdown = ({onManageClick, icon0, icon1, token0, token1, discount, tbv, percent, soldout, children}: DropdownProps) => {
  return (
    <StyledDropdown>
      {children ? children : <></>}
      <DropdownButton style={{background: true ? "#111524" : ''}}>
        <IconsRow>
        <IconWrapper>
          <Icon src={icon0} />
          <Icon src={icon1} />
          </IconWrapper>
          <PairName>
          {token0} / {token1}
            <PoolType>
            Normal Liquidity Pool
            </PoolType>
          </PairName>
        </IconsRow>


        <PoolReserveGrouping>
            <PoolReserve>
            <Gray>{discount.includes('-') ? 'ROI' : 'ROI'}</Gray>
            </PoolReserve>
            <PoolReserve style={{color: discount.includes('-') ? 'rgb(253, 118, 107)' : 'rgb(118, 209, 145)'}}>
            {soldout ? "0.00" : discount}%
            </PoolReserve>
        </PoolReserveGrouping>


        <PoolTBV>
        TBV
        <PoolTBVDetail>
        ${tbv}

        <div style={{marginRight: '10px', marginLeft: '10px', width: '100%', height: '100%'}}>
        <Slider

          railStyle={{backgroundColor: '#262436'}}
          trackStyle={{backgroundColor: '#2A70BC'}}
          handleStyle={{width: '0px', height: '0px', borderRadius: '0', border: '0', color: 'rgba(0,0,0,0)'}}
          min={0}
          max={100}
          value={soldout ? 0 : percent}
          step={0.01}
        />
        </div>
        {new BigNumber(soldout ? 0 : percent).toFixed(2)}%
        </PoolTBVDetail>
        </PoolTBV>

        <ManageButton disabled={soldout ? true : false} onClick={() => {onManageClick()}}>{soldout ? 'Sold out' : 'Bond'}</ManageButton>
      </DropdownButton>
    </StyledDropdown>
  )
}

const StyledDropdown = styled.div`
  position: relative;
  overflow: hidden;

  & + & {
    margin-top: 6px;
  }
`;


const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: row;
    justify-content: flex-start;
  }
`;



const DropdownButton = styled(Grid)`

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;

  grid-template-areas:
  "main tvl tbv manage";


  position: relative;
  align-items: center;
  width: 100%;
  padding: 12px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  height: 86px;
  color: #FFFFFF;
  background: var(--color-modal-light-blue);
  border-radius: 16px;
  justify-content: space-between;
  border: 1px solid rgba(255,255,255,0.05);

  @media (max-width: 750px) {
    grid-template-columns: 2fr 1fr;

    grid-template-areas:
    "main manage";
  }
`;

const IconsRow = styled.div`
  grid-area: main;
  display: flex;
  align-items: center;
  margin-right: 2px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #F6F8FE;

  @media (max-width: 600px) {
    margin-right: auto;
    flex-direction: column;
  }
`;


const PairName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 5px;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: #F6F8FE;
`;

const Gray = styled.div`
color: var(--color-gray);
width: 45px;
`
const PoolType = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-gray);
  @media (max-width: 600px) {
    display: none;
  }
`;

const PoolReserveGrouping = styled.div`
  grid-area: tvl;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: var(--color-gray);

  @media (max-width: 750px) {
    display: none;
  }
`;

const PoolTBV = styled.div`
  grid-area: tbv;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  justify-content: flex-start;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-gray);
  width: 80%;
  line-height: 150%;
  letter-spacing: -0.01em;

  @media (max-width: 750px) {
    display: none;
  }
`;
const PoolTBVDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: white;
  line-height: 150%;
  letter-spacing: -0.01em;
  width: 100%;

  @media (max-width: 600px) {
    display: none;
  }
`;

const PoolReserve = styled.div`
  display: flex;
  align-items: left;
  margin-right: auto;
  margin-right: 2px;
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.01em;
  color: white;
`;

const Icon = styled.img`
  width: 24px;
  height: 24px;

  &:first-child {
    z-index: 1;
  }

  & + & {
    transform: translateX(-6px)
  }
`;


const ManageButton = styled(ButtonSecondaryLarge)`
  grid-area: manage;
  max-width: 150px;
  margin-left: auto;
`

const List = styled.ul`
  margin-bottom: 24px;
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + & {
    margin-top: 8px;
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled(ButtonPrimaryMedium)`
  width: 135px;
  height: 48px;

  & + & {
    margin-left: 30px;
  }
`;
