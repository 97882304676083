import React, { useState, useRef } from 'react'
import styled, {keyframes} from 'styled-components';
import {useLocation, useHistory, useParams} from "react-router-dom";
import BigNumber from 'bignumber.js';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import { Widget } from '../../common/widgets/Widget';
import { ButtonPrimaryLarge } from '../../common/buttons/Button'
import { AccentButton } from '../../common/buttons/AccentButton'
import { UpgradeDMuteModal } from '../../common/modal/UpgradeDMuteModal';

import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';
import { ListItem } from '../../common/ListItem';

import data from '../../constants/placeholders/assets.json'
import angleIcon from '../../assets/images/icons/angle-bright.svg'
import externalLink from '../../assets/images/icons/external_link.svg'
import gearIcon from '../../assets/images/icons/gear.svg';

import { reduceString } from '../../utils/reduceString';
import { formatNumber } from '../../utils/formatNumber';
import { AmountSection } from '../../common/section/AmountSection'
import {LoadingAnimation} from '../../common/loading/loading'
import {DaoBannerWidget} from './DaoBannerWidget'
import {LoadingBox} from '../../common/loading/LoadingBox'
import { ShadowWidget } from '../../common/ShadowWidget'
import { DaoLockInfo } from './DaoLockInfo'
import { VELockInfo } from './veLockInfo';
import { VELockInfoKoi } from './veLockInfoKoi';

import { DelegateModal } from '../../common/modal/DelegateModal';
import { useWalletHook } from '../../web3/walletHook';
import { WalletState } from '../../state';
import { useWalletSelector } from '../../state/hooks';

import * as ethers from "ethers";

const queryString = require('query-string');

interface ParamTypes {
  inputCurrency: string;
  outputCurrency: string;
}

enum Modal {
  Settings,
  ChangeFee,
  Delegate,
  UpgradeLegacy
}

export const DaoScreen = () => {
  const [lockTime, setLockTime] = useState(7)

  const [muteAmount, setMuteAmount] = useState('0')
  const [dMuteAmount, setDMuteAmount] = useState('0')
  const [delegate, setDelegate] = useState('')

  const [isDeposit, setIsDeposit] = useState(0)
  const [calculating, setCalculating] = useState(false)

  const [modal, setModal] = useState<Modal | null>(null);

  const { getMuteToken, getKoiToken, getKoiTokenPrice, getMuteTokenPrice, RedeemDMute, RedeemVEMute, RedeemVEKOI, LockMute, LockKoi, ChangeVEMuteDelegate, UpgradeLegacyToVe, convertVeIndex } = useWalletHook()
  const daoInfo = useWalletSelector((state: WalletState) => state.daoInfo as any)
  const tokens = useWalletSelector((state: WalletState) => state.tokens as any)

  const redeem = async (index, v2) => {
    setCalculating(true)
    try{
      await RedeemDMute(index, v2)
    } catch(e) {
      console.log(e)
    }
    setCalculating(false)
  }

  const redeemVe = async (index) => {
    setCalculating(true)
    try{
      await RedeemVEMute(index)
    } catch(e) {
      console.log(e)
    }
    setCalculating(false)
  }

  const redeemVeKoi = async (index) => {
    setCalculating(true)
    try{
      await RedeemVEKOI(index)
    } catch(e) {
      console.log(e)
    }
    setCalculating(false)
  }

  const convertVe = async (index) => {
    setCalculating(true)
    try{
      await convertVeIndex(index)
    } catch(e) {
      console.log(e)
    }
    setCalculating(false)
  }

  const lockMute = async () => {
    setCalculating(true)
    try{
      await LockKoi(muteAmount, lockTime, new BigNumber(daoInfo.shouldApprove).lt(muteAmount))
    } catch(e){

    }
    setCalculating(false)
  }

  const shouldDisableLockMute = () => {
    if(new BigNumber(muteAmount).lte(0) || new BigNumber(muteAmount).gt(getKoiToken().amount))
      return true


    return false
  }

  const getLockMuteStatus = () => {    
    if(new BigNumber(muteAmount).lte(0))
      return 'Insufficient amount'

    if(new BigNumber(muteAmount).gt(getKoiToken().amount))
      return 'Insufficient balance'

    if(new BigNumber(daoInfo.shouldApprove).lt(muteAmount))
      return 'Approve'

    return 'Lock'
  }
  

  const changeDelegate = async (_id, _del) => {
    setCalculating(true)
    await ChangeVEMuteDelegate(_id, _del)
    setCalculating(false)
    setModal(null)
  }

  const upgradeLegacy = async () => {
    setCalculating(true)
    await UpgradeLegacyToVe()
    setCalculating(false)
    setModal(null)
  }

  const getEligibleUpgradeable = () => {
    let _am = 0
    if(daoInfo.user_locks && daoInfo.user_locks.length != 0){
      var curDate = new BigNumber(Date.now()).div(1000)
      for(let i = 0; i <  daoInfo.user_locks.length; i++){
        if(curDate.lt(daoInfo.user_locks[i].unlock_time)){
          _am++
        }
      }
    }

    return _am


  }

  const getDAOTab = () => {
    if(isDeposit == 0){
      return (
        <>
        <ShadowWidget>
        <AmountSection
          value={muteAmount}
          onChange={setMuteAmount}
          assets={[]}
          selectedAsset={getKoiToken()}
          disableEdit={true}
          onAssetSelect={() => {}}
          label="Lock"
          price={getKoiToken() && new BigNumber(muteAmount).gt(0) ? new BigNumber(muteAmount).times(getKoiToken().price).toFixed(2) : '0.00'}
        />
        </ShadowWidget>
        <Spacer/>
        <Spacer/>
        <Spacer/>

        <SpacerBorder/>

        <Spacer/>
        <Spacer/>
        <Spacer/>

        <StatRow>
          <StatText>
          Lock time
          </StatText>
          <StatTextHighlight>
            <div style={{width: '100%', display:'flex', flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto'}}>
            {new BigNumber(lockTime).toFixed(0) } days

            <Slider
              onChange={(nextValues) => {
                setLockTime(nextValues as number)
              }}
              railStyle={{backgroundColor: 'white'}}
              trackStyle={{backgroundColor: '#2A70BC'}}
              handleStyle={{width: '3px', height: '14px', borderRadius: '0', color: 'red'}}
              min={7}
              max={364 * 2}
              defaultValue={7}
              step={7}
            />
            </div>
          </StatTextHighlight>
        </StatRow>

        <Spacer/>
        <Spacer/>
        <Spacer/>

        <StatRow>
          <StatText>
          Vote Shares
          </StatText>
          <StatTextHighlight>
          {formatNumber(new BigNumber(muteAmount).times(lockTime).div(364).toFixed(), 2)} shares
          </StatTextHighlight>
        </StatRow>

        <Button onClick={() => lockMute()} disabled={shouldDisableLockMute()}>
          <LoadingAnimation isLoading={calculating} />
          {getLockMuteStatus()}
        </Button>
        </>
      ) 

    } else if(isDeposit == 1){
      return (
        <>

        <List>
        <StatRow style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Status</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Exp. Date</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>KOI</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Vote Shares</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-end'}}>Delegate</StatTextHighlight>

        </StatRow>
          {daoInfo.user_locks && daoInfo.vekoi_locks.length > 0 ? daoInfo.vekoi_locks.map((item, index) => (
          <VELockInfoKoi 
          info={item} 
          onRedeem={async() => {await redeemVeKoi(item.index)}}
          onConvert={async() => {await convertVe(item.index)}}
          onChangeDelegate={async(res) => {await changeDelegate(item.index, res)}}
          />
        )) : <StatTextHighlight></StatTextHighlight>}
        </List>

        </>

      )
    } else if(isDeposit == 2){
      return (
        <>

        <List>
        <StatRow style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Status</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Exp. Date</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Mute</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Vote Shares</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-end'}}>Delegate</StatTextHighlight>

        </StatRow>

        {daoInfo.user_locks && daoInfo.ve_locks.length > 0 ? daoInfo.ve_locks.map((item, index) => (
          <VELockInfo 
          info={item} 
          onRedeem={async() => {await redeemVe(item.index)}}
          onConvert={async() => {await convertVe(item.index)}}
          onChangeDelegate={async(res) => {await changeDelegate(item.index, res)}}
          />
        )) : <StatTextHighlight></StatTextHighlight>}
        </List>

        </>

      )
    } else {
      return (
        <>

        <List>
        <StatRow style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Status</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Exp. Date</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-start'}}>Mute</StatTextHighlight>
            <StatTextHighlight style={{width: '100%', justifyContent: 'flex-end'}}>dMute</StatTextHighlight>
        </StatRow>

        {daoInfo.user_locks && daoInfo.user_locks.length > 0 ? daoInfo.user_locks.map((item, index) => (
          <DaoLockInfo info={item} onRedeem={(res) => {redeem(item.index, item.v2)}}/>
        )) : <StatTextHighlight>No redemptions available</StatTextHighlight>}

        </List>

        <Button onClick={() => setModal(Modal.UpgradeLegacy)} disabled={calculating || (daoInfo && daoInfo.user_locks && daoInfo.user_locks.length == 0 || daoInfo.legacy_upgraded == true)}>
          <LoadingAnimation isLoading={calculating} />

          {daoInfo.legacy_upgraded == true ? 'Already upgraded' :
          `Upgrade all eligible (${getEligibleUpgradeable()})`
          }
          
        </Button>

        </>

      )

    }
  }

  return (
    <Screen>
      <Layout>

        <TitleContainer>
          <Title>Vote Escrow Koi</Title>

        </TitleContainer>

        <DataContainer style={{gridArea: 'exchange'}}>
          <DataContainerColumn>
            <StatRow>
            <ExchangeHeaderButton className={isDeposit == 0 ? 'active' : ''} onClick={() => setIsDeposit(0)}>
              Lock
            </ExchangeHeaderButton>
            <ExchangeHeaderButton className={isDeposit == 1 ? 'active' : ''} onClick={() => setIsDeposit(1)}>
              Owned (veKoi)
            </ExchangeHeaderButton>
            <ExchangeHeaderButton className={isDeposit == 2 ? 'active' : ''} onClick={() => setIsDeposit(2)}>
              Owned (veMute)
            </ExchangeHeaderButton>
            {daoInfo && daoInfo.user_locks && daoInfo.user_locks.length > 0 ? 
            <ExchangeHeaderButton className={isDeposit == 3 ? 'active' : ''} onClick={() => setIsDeposit(3)}>
            Legacy
            </ExchangeHeaderButton> :
            <></>
            }

            </StatRow>

            {getDAOTab()}

          </DataContainerColumn>
        </DataContainer>

        <DataContainer style={{gridArea: 'pool_stats'}}>
          <DataContainerColumn>
            <ContainerText>
            Total Value Locked (incl. veMUTE & legacy)
            </ContainerText>
            <HeaderText>
            {daoInfo.success != true ||  getMuteTokenPrice() == '0.00' ? (<LoadingBox/>) :
              '$' + formatNumber(daoInfo.tvl, 0)
            }
            </HeaderText>
          </DataContainerColumn>
        </DataContainer>

        <DataContainer style={{gridArea: 'my_stats'}}>
          <DataContainerColumn>
            <ContainerText>
            veKOI Stats
            </ContainerText>
            <StatRow>
              <StatText>
              Total Locked KOI (and MUTE)
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (formatNumber(new BigNumber(daoInfo.total_locked_koi), 2) + ' KOI')
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Total veKOI Vote Shares
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (formatNumber(new BigNumber(daoInfo.ve_supply_koi_solo), 2) + ' shares')
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              User Cumalative Locked KOI
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (formatNumber(new BigNumber(daoInfo.vekoi_underlying_bal), 2) + ' KOI')
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              User veKOI Vote Shares
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (formatNumber(new BigNumber(daoInfo.vekoi_balance_user).toPrecision(8), 2) + ' shares')
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              User Vote Share Percentage
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (daoInfo.share + '%')
              }
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>

        <DataContainer style={{gridArea: 'pool_details'}}>
          <DataContainerColumn>
            <ContainerText>
            Contract Details
            </ContainerText>
            <StatRow>
              <StatText>
              KOI address
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (reduceString(daoInfo.koi_address, 7, 4))
              }
              <ExternalLink src={externalLink} onClick={() => {window.open('https://era.zksync.network/address/' + daoInfo.koi_address, "_blank")}} />
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              veKOI address
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (reduceString(daoInfo.vekoi_address, 7, 4))
              }
              <ExternalLink src={externalLink} onClick={() => {window.open('https://era.zksync.network/address/' + daoInfo.vekoi_address, "_blank")}} />
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              MUTE address
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (reduceString(daoInfo.mute_address, 7, 4))
              }
              <ExternalLink src={externalLink} onClick={() => {window.open('https://era.zksync.network/address/' + daoInfo.mute_address, "_blank")}} />
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              veMUTE address
              </StatText>
              <StatTextHighlight>
              {daoInfo.success != true ? (<LoadingBox/>) :
                (reduceString(daoInfo.dmute_address, 7, 4))
              }
              <ExternalLink src={externalLink} onClick={() => {window.open('https://era.zksync.network/address/' + daoInfo.dmute_address, "_blank")}} />
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>

        <DaoBannerWidget/>



        <UpgradeDMuteModal
          isVisible={modal == Modal.UpgradeLegacy}
          onClose={() => setModal(null)}
          onSubmit={() => upgradeLegacy()}
        />

        <DelegateModal
          isVisible={modal == Modal.Delegate}
          onChange={setDelegate}
          address={delegate}
          onClose={() => setModal(null)}
          onConfirm={() => changeDelegate(null, null)}
          validText={ethers.isAddress(delegate)}
        />
      </Layout>
    </Screen>
  )
}

const List = styled.ul`
  padding-right: 19px;
  min-height: 300px;
  max-height: 325px;
  overflow-y: auto;
`;


const LI = styled(ListItem)`
  margin-top: 0.5px;
  margin-bottom: 0.5px;
`;


const Layout = styled(Grid)`

  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "titlecontainer titlecontainer"
  "banner banner"
  "pool_stats  exchange"
  "my_stats exchange"
  "pool_details exchange"
  "pool_details exchange";

  row-gap: 8px;
  column-gap: 8px;

  @media (max-width: 767px) {
    grid-template-areas:
    "titlecontainer titlecontainer"
    "pool_stats pool_stats"
    "exchange  exchange"
    "my_stats my_stats"
    "pool_details pool_details"
    "banner banner"
    ;
  }
`;


const ExternalLink = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 8px;
`

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`
const SpacerBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2E3551;
`
const TitleContainer = styled.div`
  positon: relative;
  grid-area: titlecontainer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`

const StatText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7F7D97;
`

const StatTextHighlight = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F6F8FE;
`

const Subtitle = styled.div`
  width: auto;
  padding: 0 10px;
  height: 27px;
  background: rgba(41, 48, 74, 0.4);
  border-radius: 8px;
  display: flex;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  justify-content: center;
  align-items: center;

  color: #777F99;
`

const BackButton = styled.button`
  background: rgba(24, 29, 47, 0.7);
  border-radius: 14px;
  background-image: url(${angleIcon});
  background-size: cover;
  transform: rotate(90deg);

  width: 40px;
  height: 40px;
`

const DataContainer = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  z-index: 1;

  @media (max-width: 600px) {
    padding: 16px;
  }
`

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
`

const ContainerText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #777F99;
  padding-bottom: 18px;
`

const HeaderText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  position: relative;
  display: flex;
  min-height: 30px;
  width: 50%;
`

const HeaderTextGreen = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #0BD98F;
  position: relative;
  display: flex;
  min-height: 30px;
  width: 50%;
`


const ExchangeHeaderButton = styled.button`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #7F7D97;
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #1D2336;

  &.active {
    color: var(--color-blue-accent);
    border-bottom: 2px solid var(--color-blue-accent);
  }
`

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;


const SettingAnim = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(60deg); }
`

const SettingButton = styled.button`
  width: 24px;
  height: 35px;
  margin-left: 8px;
  padding: 0;

  :disabled{
    opacity: 0.5;
    cursor: not-allowed;

  }

  :hover {
    opacity: 0.5;
    animation: ${SettingAnim} 0.5s forwards;
  }
`;

const GearIcon = styled.img`
  max-width: 100%;
`;
