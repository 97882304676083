import React, { ReactNode, useEffect } from 'react'
import { Loader } from '../Loader'
import { Modal } from './Modal'
import { IconWrapper, ModalDescription } from './ModalComponents'
import { AddressSection } from '../section/AddressSection'
import styled from 'styled-components'
import { ButtonPrimaryLarge } from '../buttons/Button'

export interface ConfirmTransactionModalProps {
  isVisible: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onChange: (val: string) => void;
  validText: boolean;
  address: string;
}

export const DelegateModal = ({
  isVisible,
  onClose,
  onConfirm,
  validText,
  onChange,
  address,
}: ConfirmTransactionModalProps) => {
  useEffect(() => {
   // const timer = setTimeout(() => isVisible && onConfirm(), 100);
    return () => {} //clearTimeout(timer);
  }, [isVisible]);

  return (
    <Modal title={"Delegate dMute"} isVisible={isVisible} onClose={onClose}>
        <AddressSection
            address={address}
            onChange={onChange}
            label={'Delegate Address'}
        />
      <Button onClick={() => onConfirm()} disabled={validText == false}>
        {validText == true ? 'Delegate' : 'Invalid address'}
      </Button>

    </Modal>
  );
};

const Line = styled.div`
width: 100%;
height: 0px;
margin-top: 25px;
margin-bottom: 25px;
border: 1px solid #262436;

`
const Footer = styled.div`
  margin-top: 12px;
  padding-bottom: 25px;
`;


const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;