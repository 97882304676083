import React from 'react';
import ReactECharts from 'echarts-for-react';
import {graphic} from 'echarts';
import styled, {keyframes} from 'styled-components'
import {LoadingBox} from '../loading/LoadingBox'
import BigNumber from 'bignumber.js'
import {formatNumber} from '../../utils/formatNumber'


const pad = num => ("0" + num).slice(-2); // or use padStart

const getTimeFromDate = timestamp => {
  const date = new Date(timestamp * 1000);
  let hours = date.getHours(),
    minutes = date.getMinutes(),
    seconds = date.getSeconds();
  return pad(hours) + ":" + pad(minutes)// + ":" + pad(seconds)
}

export const Chart = ({title, logo, data, chartLoading, price}) => {

  const [hoveredPrice, setHoveredPrice] = React.useState<any>(null)

  var hourData: String[] = []
  for(let i = 24; i > 0; i--){
    var now = Date.now() / 1000
    hourData.push(getTimeFromDate(Math.floor(now - (60 * 60 * i))))
  }
  const options = {
    animationDuration: 50,
    xAxis: {
      type: 'category',
      data: hourData,
      boundaryGap: false,
    },
    grid: {
      left: '0%',
      right: '0%',
      bottom: '0%',
      top: '0%',
      containLabel: false,
      splitLine: {
        show: false
      },
    },
    yAxis: {
      type: 'value',
      boundaryGap: false,
      min: function (value) {
          return value.min * 0.85;
      },
      max: function (value) {
          return value.max * 1.15;
      },
      axisLine : {
        onZero: false
      },
      splitLine: {
        show: false
      },
    },
    series: [
      {
        data: data,
        type: 'line',
        z: -1,
        smooth: false,
        sampling: 'lttb',
        itemStyle: {
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#345AE1'
            },
            {
              offset: 1,
              color: '#30A8FF'
            }
          ]),
          borderRadius: '16px'
        },
        lineStyle: {
          width: 2
        },
        showSymbol: false,

        areaStyle: {
          shadowBlur: 10,
          opacity: 0.2,
          color: new graphic.LinearGradient(0, 0, 0, 1, [
            {
              offset: 0,
              color: '#345AE1'
            },
            {
              offset: 1,
              color: 'rgba(0,0,0,0)'
            }
          ])
        },

      },
    ],
    tooltip: {
      trigger: 'axis',
      show: true,
      showContent: false,
      valueFormatter: (value) => {
        setHoveredPrice(formatNumber(value, 3))
        return value
      },
      backgroundColor: 'rgba(0,0,0,0)',
      borderColor: 'rbga(0,0,0,0)',
      extraCssText: 'box-shadow: 0 0 0px rgba(0, 0, 0, 0);',
      textStyle: {
          color: 'rgba(0,0,0,0)',
          boxShadow: 'rgba(0,0,0,0)',
      }
    },
  };

  const greenArrow = () => {
    return <svg width="20" height="20"
            viewBox="0 0 24 24" fill="none" stroke="rgb(118, 209, 145)" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round">
            <line x1="7" y1="17" x2="17" y2="7"></line>
            <polyline points="7 7 17 7 17 17"></polyline>
            </svg>
  }

  const redArrow = () => {
    return <svg  width="20" height="20"
            viewBox="0 0 24 24" fill="none" stroke="rgb(253, 118, 107)" stroke-width="2"
            stroke-linecap="round" stroke-linejoin="round" transform="rotate(90 0 0)">
            <line x1="7" y1="17" x2="17" y2="7"></line>
            <polyline points="7 7 17 7 17 17"></polyline>
            </svg>
  }

  const calculatePrice = (start, end) => {
      var price =  new BigNumber(end).minus(start).div(start).times(100)

      if(price.gte(0))
        return (<ChartChange>{price.abs().toFixed(2)}% {greenArrow()}</ChartChange>)

      return (<ChartChangeRed>{price.abs().toFixed(2)}% {redArrow()}</ChartChangeRed>)
  }

  if(chartLoading == true){
    return (
       <Body>
        <LoadingBox/>
       </Body>
    )
  }
  return <Body>
          {
            data.length > 0 ? (
              <>
              <ChartTitle>{title}</ChartTitle>
                        <div style={{display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-between'}}>
                          <ChartPrice>${hoveredPrice ? hoveredPrice : formatNumber(price, 3)}</ChartPrice>
                          {calculatePrice(data[0], hoveredPrice ? hoveredPrice : data[data.length - 1])}
                        </div>

                <div style={{height: '100%', width: '100%'}} onMouseLeave={() => {setHoveredPrice(null)}}>
                <ReactECharts option={options} style={{ height: '100%' }} />
                </div>
                <ChartButton>
                <ChartSubText>1d</ChartSubText>
                </ChartButton>
                </>
            )

            : <ChartTitle>No data available</ChartTitle>

          }


          </Body>
};





const Body = styled.div`
  grid-area: chart;
  position: relative;
  overflow: hidden;
  padding: 20px;
  height: 398px;
  min-width: 250px;
  max-width: 900px;
  max-height: 450px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(0,0,0,0);
  background: var(--color-modal-blue);
  box-shadow: rgba(255,255,255, 0.05) 0px 0px 30px;
  border: 1px solid rgba(255,255,255,0.05);
  border-radius: 16px;
  color: white;

`

const ChartTitle = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 150%;
  color: white;
`
const ChartPrice = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 200%;
  color: white;
  text-align: left;
`
const ChartChange = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 200%;
  color: rgb(136,198,149);
  color: white;
  text-align: left;
`

const ChartChangeRed = styled(ChartChange)`
  color: rgb(253, 118, 107);
  color: white;
`
const ChartSubText = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 200%;
  color: var(--color-white);
  text-align: center;
`

const ChartButton = styled.button`
  width: 30px;
  height: 30px;
  justify-content: flex-end;
  align-items: flex-end;
  margin-left: auto;
  border: 1px solid var(--color-blue);
  border-radius: 8px;
  background: var(--color-blue-accent);
`
