import React, { ReactNode } from "react";
import styled from "styled-components";
import { transition } from "../styles";

interface ListItemProps {
  children: ReactNode;
  onClick: () => void;
  className?: string;
  key?: number;
}

export const ListItem = ({children, onClick, className, key}: ListItemProps) => {
  return (
    <Item className={className} key={key}>
      <Button onClick={onClick} className={className}>
        {children}
      </Button>
    </Item>
  )
}

const Item = styled.li`
  ${transition.fast}

  border-radius: 6px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(255,255,255, 0);
  border: 1px solid rgba(84,190,249, 0);

  :hover {
    background-color: rgba(84,190,249, 0.1);
    border: 1px solid rgba(84,190,249, 0.5);
  }
  &.disabled { 
    opacity: 0.5;
    cursor: not-allowed;
  }
  & + & {
    margin-top: 5px;
  }
`;

const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0;
  &.disabled { 
    cursor: not-allowed;
  }
`;
