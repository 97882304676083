import React, { useState, useRef } from 'react'
import styled, {keyframes} from 'styled-components';
import {useLocation, useHistory, useParams} from "react-router-dom";
import BigNumber from 'bignumber.js';
import Slider from 'rc-slider';

import 'rc-slider/assets/index.css';
import { Widget } from '../../common/widgets/Widget';
import { ButtonPrimaryLarge } from '../../common/buttons/Button'
import { AccentButton } from '../../common/buttons/AccentButton'

import { Grid } from '../../common/layout/Grid';
import { Screen } from '../../common/layout/Screen'
import { Title } from '../../common/text/Title';
import { ListItem } from '../../common/ListItem';

import data from '../../constants/placeholders/assets.json'
import angleIcon from '../../assets/images/icons/angle-bright.svg'
import externalLink from '../../assets/images/icons/external_link.svg'
import gearIcon from '../../assets/images/icons/gear.svg';

import { reduceString } from '../../utils/reduceString';
import { formatNumber } from '../../utils/formatNumber';
import { AmountSection } from '../../common/section/AmountSection'
import {LoadingAnimation} from '../../common/loading/loading'
import {DaoBannerWidget} from './DaoBannerWidget'
import {LoadingBox} from '../../common/loading/LoadingBox'
import { ShadowWidget } from '../../common/ShadowWidget'
import { DaoLockInfo } from './DaoLockInfo'
import muteDesignIcon from '../../assets/images/icons/mute_design_icon.png'

import { useWalletHook } from '../../web3/walletHook';
import { WalletState } from '../../state';
import { useWalletSelector } from '../../state/hooks';

const queryString = require('query-string');

interface ParamTypes {
  inputCurrency: string;
  outputCurrency: string;
}

enum Modal {
  Settings,
  ChangeFee
}

function timeConverter(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':00';
  return time;
}

function timer(startTime) {
  if(startTime == null)
    return null

  let seconds = startTime - (Date.now() / 1000)

  if(seconds < 0)
    return null

  var days        = Math.floor(seconds/24/60/60);
  var hoursLeft   = Math.floor((seconds) - (days*86400));
  var hours       = Math.floor(hoursLeft/3600);
  var minutesLeft = Math.floor((hoursLeft) - (hours*3600));
  var minutes     = Math.floor(minutesLeft/60);
  var remainingSeconds = Math.floor(seconds % 60)

  function pad(n) {
    return (n < 10 ? "0" + n : n);
  }
  let time = pad(days) + "d:" + pad(hours) + "h:" + pad(minutes) + "m:" + pad(remainingSeconds) + 's'
  return time
}


export const RewardsScreen = () => {

  const rewardsStartWeek = 2790
  const userRank = useWalletSelector((state: WalletState) => state.userRanking)
  const daoInfo = useWalletSelector((state: WalletState) => state.daoInfo as any)

  const startWeek = Math.floor(Date.now()/ 604800000)
  const endWeek = startWeek + 1

  const [endDate, setEndDate] = useState(timer(endWeek * 604800))

  const [calculating, setCalculating] = useState(false)
  const {  LockMute } = useWalletHook()



  const claimRewards = () => {

  }

  const getEstimatedRewards = () => {
    if(userRank['totalFees'] && rewardsStartWeek <= startWeek){
      return new BigNumber(userRank['totalFees']).div(userRank['globalFees']).times(2000).times(getMultiplier()).toFixed(2)
    }

    return '0.00'
  }

  const getMultiplier = () => {
    if(daoInfo.success == true){
      return new BigNumber(Math.min(new BigNumber(daoInfo.dmute_balance_user).div(10000).plus(1).toNumber(), 2)).toFixed(2)
    }

    return '1.00'
  }

  React.useEffect(() => {
    
    setInterval(() => {
      setEndDate(timer(endWeek * 604800))
    }, 1000)

  }, []);


  return (
    <Screen>
      <Layout>

        <TitleContainer>
          <Title>veMute Rewards</Title>
        </TitleContainer>

        <DataContainer style={{gridArea: 'pool_stats', opacity: rewardsStartWeek > startWeek ? '0.2' : '1'}}>
          <DataContainerColumn>
            <ContainerText>
            Current Cycle
            </ContainerText>
            <HeaderText>
            {userRank['rank'] == null ? (<LoadingBox/>) :

            rewardsStartWeek > startWeek ? '' : startWeek - rewardsStartWeek

            }
            </HeaderText>
          </DataContainerColumn>

          <DataContainerColumn>
            <ContainerText>
            Your Rewards
            </ContainerText>
            <HeaderText>
            {userRank['rank'] == null ? (<LoadingBox/>) :
              rewardsStartWeek > startWeek ? '' : userRank['rank']
            }
            </HeaderText>
          </DataContainerColumn>
        </DataContainer>


        <DataContainer style={{gridArea: 'my_stats'}}>
          <DataContainerColumn>
            <ContainerText>
            Reward stats
            </ContainerText>
            <StatRow>
              <StatText>
              {rewardsStartWeek > startWeek ? 'Reward epoch start time' : 'Epoch end time'}
              </StatText>
              <StatTextHighlight>
              {userRank['rank'] == null ? (<LoadingBox/>) :
                endDate
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              My rewards claimed
              </StatText>
              <StatTextHighlight>
              {userRank['rank'] == null ? (<LoadingBox/>) :
                rewardsStartWeek > startWeek ? '$0.00' : '$' + formatNumber(userRank['totalVolume'],0) 
              }
              </StatTextHighlight>
            </StatRow>
            <StatRow>
              <StatText>
              Reward contract
              </StatText>
              <StatTextHighlight>
              {userRank['rank'] == null ? (<LoadingBox/>) :
              getEstimatedRewards() + ' Mute'
              }
              </StatTextHighlight>
            </StatRow>
          </DataContainerColumn>
        </DataContainer>

        <DataContainer style={{gridArea: 'rewards'}}>
          <DataContainerColumn>
          <ContainerText>
          Rewards (vested for 7 days via dMute)
          </ContainerText>

            <HeaderText style={{width: '100%'}}>
            <MuteIcon src={muteDesignIcon} />
              0.00 MUTE

            </HeaderText>
            <Button onClick={() => claimRewards()} disabled={true}>
              <LoadingAnimation isLoading={calculating} />
              {rewardsStartWeek > startWeek ? 'Starts in ' + endDate : 'No rewards'}
            </Button>

          </DataContainerColumn>
        </DataContainer>

        <DaoBannerWidget/>
      </Layout>
    </Screen>
  )
}

const List = styled.ul`
  padding-right: 19px;
  min-height: 325px;
  overflow-y: auto;
`;


const LI = styled(ListItem)`
  margin-top: 0.5px;
  margin-bottom: 0.5px;
`;


const MuteIcon = styled.img`
  width: 40px;
  height: 40px;
  margin-right: 20px;
`;

const Layout = styled(Grid)`

  grid-template-columns: 1fr 1fr;
  grid-template-areas:
  "titlecontainer titlecontainer"
  "banner banner"
  "pool_stats  rewards"
  "my_stats  rewards";


  
  row-gap: 8px;
  column-gap: 8px;

  @media (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
    "banner banner"

    "titlecontainer titlecontainer"
    "pool_stats pool_stats"
    "my_stats my_stats"
    "rewards  rewards"
    ;
  }
`;


const ExternalLink = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
  margin-left: 8px;
`

const Spacer = styled.div`
  width: 25px;
  height: 6px;
`
const SpacerBorder = styled.div`
  width: 100%;
  height: 1px;
  border-bottom: 2px solid #2E3551;
`
const TitleContainer = styled.div`
  positon: relative;
  grid-area: titlecontainer;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const AssetImage = styled.img`
  height: 25px;
  width: 25px;
  margin-right: 5px;
`

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 12px;
`

const StatText = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: right;
  color: #7F7D97;
`

const StatTextHighlight = styled.p`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  width: 50%;
  text-align: right;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #F6F8FE;
`

const Subtitle = styled.div`
  width: auto;
  padding: 0 10px;
  height: 27px;
  background: rgba(41, 48, 74, 0.4);
  border-radius: 8px;
  display: flex;

  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  text-align: center;
  justify-content: center;
  align-items: center;

  color: #777F99;
`

const BackButton = styled.button`
  background: rgba(24, 29, 47, 0.7);
  border-radius: 14px;
  background-image: url(${angleIcon});
  background-size: cover;
  transform: rotate(90deg);

  width: 40px;
  height: 40px;
`

const DataContainer = styled.div`
  padding: 20px 20px;
  background: var(--color-modal-blue);
  border-radius: 18px;
  font-size: 6px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  justify-content: space-between;
  width: 100%;
  min-width: 0;
  z-index: 1;

  @media (max-width: 600px) {
    padding: 16px;
  }
`

const DataContainerColumn = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80px;
  justify-content: space-between;
  width: 100%;
`

const ContainerText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 100%;
  color: #777F99;
  padding-bottom: 18px;
`

const HeaderText = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #FFFFFF;
  position: relative;
  align-items: center;
  display: flex;
  min-height: 30px;
  width: 50%;
`

const HeaderTextGreen = styled.h1`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 39px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #0BD98F;
  position: relative;
  display: flex;
  min-height: 30px;
  width: 50%;
`


const ExchangeHeaderButton = styled.button`
  font-family: 'Aeonik';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #7F7D97;
  width: 100%;
  height: 55px;
  border-bottom: 2px solid #1D2336;

  &.active {
    color: var(--color-blue-accent);
    border-bottom: 2px solid var(--color-blue-accent);
  }
`

const Button = styled(ButtonPrimaryLarge)`
  margin-top: 40px;
  width: 100%;
  @media(max-width: 600px) {
    margin-top: 24px;
  }
`;


const SettingAnim = keyframes`
 0% { transform: rotate(0deg); }
 100% { transform: rotate(60deg); }
`

const SettingButton = styled.button`
  width: 24px;
  height: 35px;
  margin-left: 8px;
  padding: 0;

  :disabled{
    opacity: 0.5;
    cursor: not-allowed;

  }

  :hover {
    opacity: 0.5;
    animation: ${SettingAnim} 0.5s forwards;
  }
`;

const GearIcon = styled.img`
  max-width: 100%;
`;
