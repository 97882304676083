import { createRoot } from 'react-dom/client';
import { App } from "./App";
import { StrictMode } from 'react'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'

const root = createRoot(
  document.getElementById("app") as HTMLElement
);
root.render(
  <StrictMode>
    <App />
  </StrictMode>
);

if (process.env.REACT_APP_SERVICE_WORKER !== 'false') {
  //serviceWorkerRegistration.register()
}

//if (process.env.NODE_ENV !== "development")
    //console.log = () => {};

//potentially causing cache issues in android
serviceWorkerRegistration.unregister()


