import React from 'react'
import styled from 'styled-components';
import crossIcon from '../../assets/images/icons/cross-grey.svg'

export const CloseButton: React.FC<React.ButtonHTMLAttributes<HTMLButtonElement>> = (props) => (
  <Button {...props}>
    <img src={crossIcon} alt="Close"/>
  </Button>
)

const Button = styled.button`
  display: flex;
  width: 24px;
  height: 24px;
  padding: 0;

  img {
    max-width: 100%;
  }

  :hover {
    opacity: 0.5;
  }
`;
