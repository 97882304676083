import React, { ReactNode } from 'react'
import styled from "styled-components";

interface MainProps {
  children: ReactNode
}

export const Main = ({children}: MainProps) => {
  return (
    <View>
      <ViewInner>
        {children}
      </ViewInner>
    </View>
  )
}

const View = styled.main`
  position: relative;
  background: var(--color-dark-blue);
  min-height: 100vh;
  height: 100%;
  width: 100vw;

`;

const ViewInner = styled.div`
  width: 90vw;

  max-width: 1300px;

  margin: 0 auto;
  padding: 40px 32px 55px;

  @media (max-width: 1200px) {
    width: auto;
    padding: 24px 16px 38px;
    justify-content: center;
  }

  @media (max-width: 1000px) {
    width: auto;
    justify-content: center;

    padding: 24px 16px 38px;
  }
`;
